import React, { useContext, useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import { AuthContext } from '../auth/context/AuthContext';
import { DashboardContext } from '../context/Dashboard/DashboardContentx';
import { DataGasLiftContext } from '../context/DataGasLift/DataGasLiftContext';
import { message } from 'antd';
import { getDataFlow, getDataFlowGraph, postDataFlow } from '../services/api/flowAPI';

export const useTabFlow = () => {
    const { User } = useContext(AuthContext);
    const { userDataInfo } = useContext(DashboardContext);
    const { dataFilterUserGasLift, stateMessageApis, getStateMessageAlert} = useContext(DataGasLiftContext);
    const [flagGraphDomain, setFlagGraphDomain] = useState(false);

    const { data: queryDataFlow, isLoading: loadDataFlow, isError: errorDataFlow,  refetch: refetchDataFlow,} = useQuery({
        queryKey: ["DataFlow"],
        retry: 2,
        enabled: false,
        queryFn: () =>
        getDataFlow(
            userDataInfo.operator,
            userDataInfo.lease,
            userDataInfo.region,
            dataFilterUserGasLift.county,
            dataFilterUserGasLift.field,
            dataFilterUserGasLift.well_id,
            User.user_name
          ),
        onSuccess: (data) => {
          if(data.statusType === "SUCCESS"){
            message.success(`get Data Flow Table Successfull`);
          }
          else if(data.statusType === "ERROR"){
            const ErrorMesagge = data.statusMessage;
            if(ErrorMesagge.includes("Error al calcular Psi")){
              message.info(`The declaration of ranges does not allow to visualize the table`);
            }else{
              message.success(`get Data Flow Table Successfull`);
            }
          }
        },
        onError: (error) => {
          message.error( `An error has occurred when in the query`);
        },
      });

      const { data: queryDataFlowGraph, isLoading: loadDataFlowGraph, isError: errorDataFlowGraph,  refetch: refetchDataFlowGraph,} = useQuery({
        queryKey: ["DataFlowGraph"],
        retry: 2,
        enabled: false,
        queryFn: () =>
        getDataFlowGraph(
            userDataInfo.operator,
            userDataInfo.lease,
            userDataInfo.region,
            dataFilterUserGasLift.county,
            dataFilterUserGasLift.field,
            dataFilterUserGasLift.well_id,
            User.user_name
          ),
        onSuccess: (data) => {
            if(data.statusType === "SUCCESS"){
              if (stateMessageApis) {
                message.success(`get Data Flow Graph Successfull`);
                getStateMessageAlert(false)
              }
            }
            else if(data.statusType === "ERROR"){
              const ErrorMesagge = data.statusMessage;
              const ErrorCode = data.statusCode;
              if (stateMessageApis) {
                if(ErrorMesagge.includes("Error al calcular Psi")){
                  message.info(`There is a problem when performing the calculations, please modify the minimum and maximum ranges of Oil Flow Rate`);
                }
                else if(ErrorCode === 500201){
                  message.info(`There is a problem when performing the calculations, please modify the minimum and maximum ranges of Oil Flow Rate`);
                }
                else{
                  message.success(`An error has occurred in the calculation of the flow`);
                }
              }
            }
        },
        onError: (error) => {
            message.error( `An error has occurred when in the query`);
        },
      });

    const mutationCreateFlow = useMutation({
        mutationFn: postDataFlow,
        onSuccess: (data) => {
          if(data.statusType === "SUCCESS"){
            message.success(`get Data Flow Graph Successfull`);
          }
          else if(data.statusType === "ERROR"){
            const ErrorMesagge = data.statusMessage;
            const ErrorCode = data.statusCode;
            if(ErrorMesagge.includes("Error al calcular Psi")){
              message.info(`There is a problem when performing the calculations, please modify the minimum and maximum ranges of Oil Flow Rate`);
            }
            else if(ErrorCode === 500201){
              message.info(`There is a problem when performing the calculations, please modify the minimum and maximum ranges of Oil Flow Rate`);
            }
            else{
              message.success(`An error has occurred in the calculation of the flow`);
            }
          }
        },
        onError: (error) => {
          message.error( `An error has occurred when create`);
        },
      });
  return {
    queryDataFlow,
    refetchDataFlow,
    queryDataFlowGraph,
    refetchDataFlowGraph,
    mutationCreateFlow,
    flagGraphDomain,
    setFlagGraphDomain
  }
}
