import React, { useEffect, useState } from 'react'

import dataGas from '../data/dataGas.json';
import dataOil from '../data/dataOil.json';
import { DATA_ALERTS, INITIAL_TYPE_GRAPH, PLATFORM_REAL_TIME, WELL_SENSOR_DEVICE } from '../components/elements/types/type_dashboard'
import { INITIAL_DATA_ALERTS_DCA } from '../components/elements/types/type_dca';
import { INITIAL_DATA_ALERTS_GASLIFT } from '../components/elements/types/type_gas_lift';
import { toBeEnabled } from '@testing-library/jest-dom/matchers';
import { INITIAL_DATA_ALERTS_PLUNGER_LIFT } from "../components/elements/types/type_plunger_Lift"
export const useDashboard = () => {

    const initialOptionsNavbar = {
        menuWellBore:false,
        addWellfalse:false
    }
    const initialDeviceRealTime = {
      list_device_gaslift: WELL_SENSOR_DEVICE.data,
      list_device_dca: WELL_SENSOR_DEVICE.data,
      device_dca: PLATFORM_REAL_TIME.forecasting,
      device_gaslift:PLATFORM_REAL_TIME.gas_lift,
      device_plungerlift:PLATFORM_REAL_TIME.plunger_lift,
    }
    const initialUserDataInfo = {
        operator:"",
        lease:"",
        region:""
    }

    const initialHistoryData = {
        id: 0,
        date: "",
        oil_rate: null,
        water_rate: null,
        gas_rate: null,
        wellhead_pressure: null,
        wellhead_tem: null,
        injection_gas: null,
        injection_pressure: null,
    };

    const initialDomainGraph = {
        press_max_wpc: null,
        press_min_wpc: null,
        temp_max_wpc: null,
        temp_min_wpc: null,
        injrate_max_wic: null,
        injrate_min_wic: null,
        injpress_max_wic: null,
        injpress_min_wic: null,
        rate_max_ph: null,
        rate_min_ph: null,
        cum_max_ph: null,
        cum_min_ph: null,
    }
    const initialDomainGraphPL = {
        gas_rate_min_wpc: null,
        gas_rate_max_wpc: null,
        press_max_wpc: null,
        press_min_wpc: null,
        gas_rate_min_ph: null,
        gas_rate_max_ph: null,
        liquid_rate_min_ph: null,
        liquid_rate_max_ph: null,
    }

    const initialFirstModal = [
        {modal:"filters",enabled:false},
        {modal:"parameters",enabled:false},
    ]

    const initialStandartParameters= {
        "Date":"",	
        "Static_Pressure":"",
        "Flowing_Temperature":"",
        "Oil_Rate":"",
        "Gas_Rate":"",
        "Water_Rate":"",
        "Acumulative_Gas":"",
        "Acumulative_Oil":"",
        "Injection_Pressure":"",
        "Injection_Gas_Rate":"",
        "Volumetric_Flow_Rate":"",
        "Pipeline_Pressure"	:"",
        "Tubing_Pressure":"",
        "Casing_Pressure":"",
        "Liquid":"",
        "Corrected_Flow_Rate":"",
        "Liquid_Flow_Rate":""
    }
    const initialViewLoadingGeneral = {
        loadSaveFilters:false
    }

    const initialSlideGraph = {
        gas_lift: 1,
        plunger_lift: 1
    }

    const [optionsNavbar, setOptionsNavbar] = useState(initialOptionsNavbar);
    const [userDataInfo, setUserDataInfo] = useState(initialUserDataInfo);
    const [selectedWell, setSelectedWell] = useState(null);
    const [selectedWellDCA, setSelectedWellDCA] = useState(null);
    const [selectedReservoirSCT, setSelectedReservoirSCT] = useState(null);
    const [selectedWellPL, setSelectedWellPL] = useState(null);
    const [dataPage, setDataPage] = useState("");
    const [openFirstModal, setOpenFirstModal] = useState(initialFirstModal);
    const [dataDomainGraph, setDataDomainGraph] = useState(initialDomainGraph)
    const [dataDomainGraphPL, setDataDomainGraphPL] = useState(initialDomainGraphPL)
    const [dataAlertGasLift, setDataAlertGasLift] = useState(INITIAL_DATA_ALERTS_GASLIFT)
    const [dataAlertDCA, setDataAlertDCA] = useState(INITIAL_DATA_ALERTS_DCA)
    const [dataAlertPlungerLift, setDataAlertPlungerLift] = useState(INITIAL_DATA_ALERTS_PLUNGER_LIFT)
    const [someAlert, setSomeAlert] = useState(DATA_ALERTS)
    const [checkTypeGraph, setCheckTypeGraph] = useState(INITIAL_TYPE_GRAPH);
    const [deviceRealTime, setDeviceRealTime] = useState(initialDeviceRealTime);
    const [selectIntervalVLP, setSelectIntervalVLP] = useState();
    const [standartParameters, setStandartParameters] = useState(initialStandartParameters);
    const [viewLoadingGeneral, setViewLoadingGeneral] = useState(initialViewLoadingGeneral);
    const [slideGraph, setSlideGraph] = useState(initialSlideGraph);
    
    const [isOpen2, setOpen2] = useState(false);
    useEffect(()=>{
        const getDataPage = sessionStorage.getItem('dataPage');
        const getOptionNavbar = JSON.parse(sessionStorage.getItem('optionNavbar'));
        const getUserDataInfo = JSON.parse(sessionStorage.getItem('userDataInfo'));
        const getSelectedWell = sessionStorage.getItem('selectedWell');
        const getSelectedWellDCA = sessionStorage.getItem('selectedWellDCA');
        const getSelectedReservoirSCT = sessionStorage.getItem('selectedReservoirSCT');
        const getSelectedWellPL = sessionStorage.getItem('selectedWellPL');
        const getDataDomainGraph = JSON.parse(sessionStorage.getItem('dataDomainGraph'));
        const getDataDomainGraphPL = JSON.parse(sessionStorage.getItem('dataDomainGraphPL'));
        const getDataAlertGasLift = JSON.parse(sessionStorage.getItem('dataAlertGasLift'));
        const getDataAlertPlungerLift = JSON.parse(sessionStorage.getItem('dataAlertPlungerLift'));
        const getDataAlertDCA = JSON.parse(sessionStorage.getItem('dataAlertDCA'));
        const getSomeAlert = JSON.parse(sessionStorage.getItem('someAlert'));
        const getCheckTypeGraph = JSON.parse(sessionStorage.getItem('checkTypeGraph'));
        const getDeviceRealTime = JSON.parse(sessionStorage.getItem('deviceRealTime'));
        const getSelectIntervalVLP = JSON.parse(sessionStorage.getItem('selectIntervalVLP'));
        const getIsOpenModalAllocattion = JSON.parse(sessionStorage.getItem('isOpenModalAllocation'));

        if (getDataPage){
            setDataPage(getDataPage);
        }
        if (getOptionNavbar) {
            setOptionsNavbar(getOptionNavbar);
        } 
        if (getUserDataInfo) {
            setUserDataInfo(getUserDataInfo);
        } 
        if (getSelectedWell) {
            setSelectedWell(getSelectedWell);
        } 
        if (getSelectedWellDCA) {
            setSelectedWellDCA(getSelectedWellDCA);
        }
        if (getSelectedReservoirSCT) {
            setSelectedReservoirSCT(getSelectedReservoirSCT);
        }
        if (getSelectedWellPL) {
            setSelectedWellPL(getSelectedWellPL);
        }
        if(getDataDomainGraph){
            setDataDomainGraph(getDataDomainGraph);
        }
        if(getDataDomainGraphPL){
            setDataDomainGraphPL(getDataDomainGraphPL);
        }
        if(getDataAlertGasLift){
            setDataAlertGasLift(getDataAlertGasLift);
        }
        if(getDataAlertPlungerLift){
            setDataAlertPlungerLift(getDataAlertPlungerLift);
        }
        if(getDataAlertDCA){
            setDataAlertDCA(getDataAlertDCA);
        }
        if(getSomeAlert){
            setSomeAlert(getSomeAlert);
        }
        if (getCheckTypeGraph) {
            setCheckTypeGraph(getCheckTypeGraph);
        }
        if (getDeviceRealTime) {
        setDeviceRealTime(getDeviceRealTime);
        }
        if (getSelectIntervalVLP) {
            setSelectIntervalVLP(getSelectIntervalVLP);
        }
        if (getIsOpenModalAllocattion) {
            setOpen2(getIsOpenModalAllocattion);
        }
    },[])

    const toggleOpenFirstModal = (modalName) => {
        setOpenFirstModal((prev) =>
          prev.map((item) =>
            item.modal === modalName ? { ...item, enabled: !item.enabled } : item
          )
        );
      };

    const getDataPage = (options) => {
        setDataPage(options);
        sessionStorage.setItem('dataPage', options);
    }

    const getStandarParameters = (options) => {
        setStandartParameters(options);
        sessionStorage.setItem('standartParameters', options);
    }

    const getOptionsNavbar = (options) => {
        setOptionsNavbar(options);
        sessionStorage.setItem('optionNavbar', JSON.stringify(options));
    }

    const getUserDataInfo = (options) => {
        setUserDataInfo(options);
        sessionStorage.setItem('userDataInfo', JSON.stringify(options));
    }

    const getSelectedWell = (options) => {
        setSelectedWell(options);
        sessionStorage.setItem('selectedWell', options);
    }

    const getSelectedWellDCA = (options) => {
        setSelectedWellDCA(options);
        sessionStorage.setItem('selectedWellDCA', options);
    }

    const getSelectedReservoirSCT = (options) => {
        setSelectedReservoirSCT(options);
        sessionStorage.setItem('selectedReservoirSCT', options);
    }
    const getSelectedWellPL = (options) => {
        setSelectedWellPL(options);
        sessionStorage.setItem('selectedWellPL', options);
    }

    const getDataDomainGraph = (options) => {
        setDataDomainGraph(options);
        sessionStorage.setItem('dataDomainGraph', JSON.stringify(options))
    }
    const getDataDomainGraphPL = (options) => {
        setDataDomainGraphPL(options);
        sessionStorage.setItem('dataDomainGraphPL', JSON.stringify(options))
    }

    const getDataAlertGasLift = (options) => {
        setDataAlertGasLift(options);
        sessionStorage.setItem('dataAlertGasLift', JSON.stringify(options))
    }
    const getDataAlertPlungerLift = (options) => {
        setDataAlertPlungerLift(options);
        sessionStorage.setItem('dataAlertPlungerLift', JSON.stringify(options))
    }

    const getDataAlertDCA = (options) => {
        setDataAlertDCA(options);
        sessionStorage.setItem('dataAlertDCA', JSON.stringify(options))
    }
    const getSomeAlert = (options) => {
        setSomeAlert(options);
        sessionStorage.setItem('someAlert', JSON.stringify(options))
    }
    const getCheckTypeGraph = (options) => {
        setCheckTypeGraph(options);
        sessionStorage.setItem('checkTypeGraph', JSON.stringify(options));
    }
    const getDeviceRealTime = (dataDevice) => {
        setDeviceRealTime(dataDevice);
        sessionStorage.setItem('deviceRealTime', JSON.stringify(dataDevice));
    }

    const getSelectIntervalVLP = (options) => {
        setSelectIntervalVLP(options);
        sessionStorage.setItem('selectIntervalVLP', JSON.stringify(options));
    }
    const getIsOpenModalAllocattion = (options) => {
        setOpen2(options);
        sessionStorage.setItem('isOpenModalAllocation', JSON.stringify(options));
    }

    const getViewLoadingGeneral = (options) => {
        setViewLoadingGeneral(options);
    }

    const getSlideGraph = (options) => {
        setSlideGraph(options);
    }

    return {
        dataPage,
        optionsNavbar,
        userDataInfo,
        selectedWell,
        selectedWellDCA,
        selectedReservoirSCT,
        selectedWellPL,
        dataDomainGraph,
        dataDomainGraphPL,
        dataAlertGasLift,
        dataAlertPlungerLift,
        dataAlertDCA,
        someAlert,
        checkTypeGraph,
        deviceRealTime,
        selectIntervalVLP,
        standartParameters,
        viewLoadingGeneral,
        slideGraph,
        getDataPage,
        getOptionsNavbar,
        getUserDataInfo,
        getSelectedWell,
        getSelectedWellDCA,
        getSelectedReservoirSCT,
        getSelectedWellPL,
        getDataDomainGraph,
        getDataDomainGraphPL,
        getDataAlertGasLift,
        getDataAlertPlungerLift,
        getDataAlertDCA,
        getSomeAlert,
        dataGas,
        dataOil,
        isOpen2,
        openFirstModal,
        setOpenFirstModal,
        toggleOpenFirstModal,
        getCheckTypeGraph,
        getDeviceRealTime,
        getSelectIntervalVLP,
        getStandarParameters,
        getIsOpenModalAllocattion,
        getViewLoadingGeneral,
        getSlideGraph
    }
}