import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

const CustomTooltip = ({ active, payload }) => {
  const data = payload[0]?.payload;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: '10px', border: '1px solid #ccc' }}>
        {data?.steps && (
        <>
          <p>{`Pwf = ${data.steps} psia`}</p>
          <p className="label text-[#873d01]">{`Q = ${Math.round(data.qo)} STB/D`}</p>
        </>
        )}
        {data?.steps1 && (
          <>
            <p>{`Pwf = ${data.steps1} psia`}</p>
            <p className="label text-[#82ca9d]">{`Q = ${Math.round(data.qo)} STB/D`}</p>
          </>
        )}
        {data?.steps2 && (
          <>
            <p>{`Pwf = ${data.steps2} psia`}</p>
            <p className="label text-[#c73c00]">{`Q = ${Math.round(data.qo)} STB/D`}</p>
          </>
        )}
        {data?.steps3 && (
          <>
            <p>{`Pwf = ${data.steps3} psia`}</p>
            <p className="label text-[#0045c7]">{`Q = ${Math.round(data.qo)} STB/D`}</p>
          </>
        )}
        {data?.steps4 && (
          <>
            <p>{`Pwf = ${data.steps4} psia`}</p>
            <p className="label text-[#f0ad4e]">{`Q = ${Math.round(data.qo)} STB/D`}</p>
          </>
        )}
      </div>
    );
  }

  return null;
};
export const ChartStraightLine = ({ dataResults, optionDataIPR, dataIPR, IPRModel }) => {
  const [data, setData] = useState();
  const [dataGraphic, setDataGraphic] = useState([]);

  useEffect(() => {
    if (!!dataIPR) {
      setDataGraphic(prevData => {
        // Verifica si ya existe un elemento con el mismo 'date' y 'name'
        const alreadyExists = prevData.some(data => data?.date === dataIPR?.date && data?.name === dataIPR?.name);
        // Si no existe la data, agrega el nuevo dato, si existe la mantiene igual
        return alreadyExists ? prevData : [...prevData, dataIPR];
      });
    }
  }, [dataIPR]);

  const transformData = (dataGraphicIPR) => {
    
    if (!dataGraphicIPR || !Array.isArray(dataGraphicIPR) || dataGraphicIPR.length === 0) return [];
    
    // Filtrar los datos según las opciones seleccionadas
    const filteredData = dataGraphicIPR?.flatMap((item) => {
      // Verificamos si el nombre está en las opciones seleccionadas (optionDataIPR)
      if (optionDataIPR.includes(item.name)) {
        // Detectamos si 'qo' tiene múltiples fases (objetos anidados) o si solo tiene una fase como 'vogel'
        const phases = item.qo && typeof item.qo === 'object' ? Object.keys(item.qo) : [];
    
        // Obtén la posición de 'item.name' en 'optionDataIPR'
        const position = optionDataIPR.indexOf(item.name);
    
        if (phases.length > 0) {
          // Si hay múltiples fases, iteramos sobre ellas y aplanamos el resultado directamente
          if (item.ipr_model === IPRModel) {
            return item.qo[IPRModel].map((qoValue, i) => ({
              qo: qoValue,
              [`steps${position + 1}`]: item.steps[i].toFixed(2), // Usamos la posición en lugar del índice
            }));
          } else {
            message.warning("Select to different scenario")
          }
        }
      }
      return [];
    }).filter(Boolean);

    const result = filteredData;
    
    return result; // Retornar el resultado transformado
  };
  
  useEffect(() => {
    if(!!dataResults && !!dataGraphic && optionDataIPR?.length > 0){
      const newData = transformData(dataGraphic)
      setData(dataResults.concat(newData))
    } else if (!!dataResults) {
      setData(dataResults)
    } else if (!!dataGraphic && optionDataIPR?.length > 0){
      const newData = transformData(dataGraphic)
      setData(newData)
    } else {
      setData(null)
    }
  }, [dataResults, dataGraphic, optionDataIPR])

  const isValidData = Array.isArray(data) && data?.length > 0;

  // Inicializa maxValueY de forma segura
  const maxValueY = isValidData
    ? Math.max(
      ...data?.map(d => Math.max(Number(d.steps || 0), Number(d.steps1 || 0), Number(d.steps2 || 0), Number(d.steps3 || 0), Number(d.steps4 || 0))) 
    ) : 0;

  // Lógica para determinar tickStepY basada en maxValueY
  let tickStepY = 100; // Valor predeterminado
  if (maxValueY > 100000) {
    tickStepY = 10000;
  } else if (maxValueY > 10000) {
    tickStepY = 1000;
  } else if (maxValueY > 1000) {
    tickStepY = 500;
  } else if (maxValueY > 100) {
    tickStepY = 100;
  } else if (maxValueY > 10) {
    tickStepY = 10;
  } else if (maxValueY > 0) {
    tickStepY = 1;
  }

  // Calcula roundedMaxY y genera ticksY de forma segura
  const roundedMaxY = isValidData ? Math.ceil(maxValueY / tickStepY) * tickStepY : 0;

  // Encuentra el valor máximo de qo
  const maxValue = isValidData ? Math.max(...data?.map(d => d.qo)) : 0;

  // Determina el paso de tick basado en el valor máximo
  let tickStep;
  if (maxValue <= 10) {
    tickStep = 1; // Ajusta para valores muy pequeños, cada 1 unidad
  } else if (maxValue <= 100) {
    tickStep = 10; // Para valores hasta 100, cada 10 unidades
  } else if (maxValue <= 1000) {
    tickStep = 100; // Para valores hasta 1000, cada 100 unidades
  } else {
    tickStep = 1000; // Para valores mayores, cada 1000 unidades
  }

  // Ajusta para tu caso específico, donde los ticks deben ser cada 5 si el máximo es 23
  if (maxValue <= 25) {
    tickStep = 5; // Cada 5 unidades para valores hasta 25
  }

  // Calcula el valor máximo ajustado al paso de tick y genera los ticks
  const roundedMax = isValidData ? Math.ceil(maxValue / tickStep) * tickStep : 0;

  const baseColor = '#873d01';

  // Función para obtener el color según el key
  const getColor = (key, index) => {
    if (key === 'steps') {
      return baseColor; // Color fijo para 'steps'
    } else if (key.startsWith('steps')) {
      const dynamicColors = ['#873d01', '#82ca9d', '#c73c00', '#0045c7', '#f0ad4e']; // Colores dinámicos
      return dynamicColors[index % dynamicColors.length]; // Asigna un color basado en el índice
    }
    return '#0045c7'; // Color por defecto si no es un 'steps'
  };

  const getDashArrayForIndex = (index) => {
      const dashArrays = ['3 3', '7 2', '4 1', '5 5', '4 3']; 
      return dashArrays[index % dashArrays.length]; 
  };

  const getStepKeys = (data) => {
    const keys = new Set();
    
    data?.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key.startsWith('steps')) {
          keys.add(key); // Añade cualquier clave que comience con 'steps'
        }
      });
    });
  
    // Convertimos el Set a un array y lo ordenamos
    return Array.from(keys).sort((a, b) => {
      const numA = parseInt(a.replace('steps', ''), 10); // Extrae el número de la clave
      const numB = parseInt(b.replace('steps', ''), 10); // Extrae el número de la clave
      return numA - numB; // Ordena de menor a mayor
    });
  };

  const stepsKeys = getStepKeys(data);

  return (
    <div className="flex w-[1170px] h-[710px]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={500}
          data={data}
          margin={{
            left: 20,
            bottom: 30,
          }}
        >
          <XAxis
            dataKey="qo"
            domain={[0, roundedMax]} 
            tickCount={15}
            label={{
              value: `Liquid Rate (STB/D)`,
              position: 'insideBottom',
              offset: 0,
              dy: 10,
            }}
            allowDataOverflow={true} 
            type='number'
          />
          <YAxis
            domain={[0, roundedMaxY]}
            tickCount={10}
            allowDataOverflow={true}
            label={{
              value: `Bottomhole flowing pressure (psia)`,
              angle: -90,
              position: 'insideLeft',
              offset: -10,
              style: { textAnchor: "middle" },
            }}
            type="number"
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            layout="horizontal"     // Opciones: "vertical", "horizontal"
            align="center"         // Opciones: "left", "center", "right"
            wrapperStyle={{ bottom: '5px', right: 0, lineHeight: 0 }} // Ajustar el estilo para mover la leyenda
            payload={[
              { value: optionDataIPR?.length > 0 ? 'Case scenario' : 'Liquid Rate (STB/D)', type: 'line', color: '#873d01' }
            ]}
          />
          {stepsKeys?.map((key, index) => (
            <Line 
              key={key} 
              type="monotone" 
              dataKey={key} 
              stroke={getColor(key, index)}
              strokeDasharray={key != "steps" ? getDashArrayForIndex(index) : false} 
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
