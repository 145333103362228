import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { DashboardPLContext } from "../../context/DashboardPlungerLift/DashboardPLContext.jsx";
import { ReactComponent as SaveAlert } from "../../assets/img/icons/save.svg";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import stylesDashboardPlunger from "./DashboardPlungerLift.module.css";
import Carrusel from "../../components/PlungerLift/Complements/Carrusel/Carrusel.jsx";
import { CardGraph } from "../../components/PlungerLift/Complements/CardGraph";
import ChartProdHistory from "../../components/PlungerLift/GraphicsDashboard/ChartProdHistory/ChartProdHistory";
import ChartWellHead from "../../components/PlungerLift/GraphicsDashboard/ChartWellHead/ChartWellHead";
import { message, Modal, Select, Switch } from "antd";
import { ReactComponent as ExpandIcon } from "../../assets/icon/expand1.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icon/arrow-repeat.svg";
import { DATA_ALERTS } from "../../components/elements/types/type_dashboard";
import {
  DATA_DOMAIN_GRAPH,
  DATA_PLUNGER_LIFT,
  INITIAL_DATA_ALERTS_PLUNGER_LIFT,
  SELECT_DASHBOARD_PL,
  STATE_ALERTS,
} from "../../components/elements/types/type_plunger_Lift";
import {
  STATUS_CODE_GET_INTERPOLATION_PLUNGER_LIFT,
  STATUS_CODE_PLUNGER_LIFT,
  STATUS_TYPE_GET_INTERPOLATION_PLUNGER_LIFT,
} from "../../components/elements/types/type_response_API";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";
import ChartPlungerL from "../../components/PlungerLift/GraphicsDashboard/ChartPlungerL/ChartPlungerL.jsx";
import { useFilters } from "../../hooks/useFilters";
import { Tooltip as AntdTooltip } from "antd";
import { ImportFile } from "../../components/PlungerLift/Complements/ImportFile";
import { ErrorPage } from "../../components/PlungerLift/Complements/ErrorPage";
import DownloadTemplate from "../../components/PlungerLift/Complements/DownloadTemplate/DownloadTemplate.jsx";
import {
  template_params_alarm_plunger_lift,
  template_params_history_plunger_lift,
} from "../../components/elements/types/type_templates";
import { ErrorLoadingDashboard } from "../../components/ErrorPage";
import { LoadingDashboard } from "../../components/Loading";
import ImportFileDataHistory from "../../components/PlungerLift/Complements/ImportFileDataHistory.jsx";
import { AlertModal } from "../../components/AlertModal.jsx";
import { validateJsonEmptyAlert } from "../../helpers/helpersValidate.js";

const {
  contedorPrincipal,
  contenedorWell,
  contenedorArtificialLift,
  contenedorTubingWell,
  contenedorEconomicMetrics,
  contenedorCasingWellHead,
  contenedorPipeLineConditions,
  contenedorProductionConditions,
  contenedorGraph1WellHeadProducion,
  contenedorVlp,
  contenedorProductionHistory,
  contenedorGraph3VlpHistory,
  containerWellGeneral,
  titleGeneralWell,
  subTitleGeneralWell,
  containerCountryWell,
  containerGeneralCard,
  titleCards,
  containerGeneralProducttionConditions,
  containerCustomProducttionConditions,
  column1ProductionConditions,
  column2ProductionConditions,
  containerPrincipal,
  containerTitleGraph,
  containerButonsExpand,
  tittle,
  subtitle,
  subtitleWellProd,
  containerSelects,
  sizeSelects,
  containerPrincipalInputDomain,
  containerInputDomain,
  styleInputDomain,
  containerData,
  sizeCheckbox,
  sizeTextCheckbox,
  containerCheck,
  containerSelectsWellProd,
  containerBtns,
  selectedInput,
  unselectedInput,
} = stylesDashboardPlunger;
const dataWell = {
  well_name: "",
  company: "",
  country: "",
  region: "",
  field: "",
  well_orientation: "",
};

const dataTestAlarm = {
  alm_GLRrequired: 0,
  alm_GLRproduced: "--",
  alm_casing_pressure_required: 0,
  alm_casing_pressure_available: "--",
  alm_cycle_day: 0,
  twc_Pressure: "--",
  twc_temperature: "--",
  em_npv: "--",
  em_roi: "--",
  cwc_Pressure: "--",
  cwc_temperature: "--",
  pic_Pressure: "--",
  pic_temperature: "--",
  prc_GasRate: "--",
  prc_LiquidRate: "--",
  prc_Glr: "--",
  prc_GasVolumePer: "--",
  prc_LiquidVolumPer: "--",
};

const numberPreGraph = {
  chartWellCond: 0,
  chartProdHistory: 1,
};
export const DashboardPlungerLift = () => {
  const dataIOTAllPlatforms = [];
  const { Option } = Select;
  const {
    checkTypeGraph,
    someAlert,
    deviceRealTime,
    selectedWell,
    selectedWellPL,
    dataAlertPlungerLift,
    dataDomainGraphPL,
    getDataDomainGraphPL,
    getSomeAlert,
    getDataAlertPlungerLift,
    getDataPage,
    getCheckTypeGraph,
    getDeviceRealTime,
    getSlideGraph,
    slideGraph
  } = useContext(DashboardContext);
  const {
    selectDashboardPL,
    getSelectDashboardPL,
    validateAlertPlungerLift,
    getValidateAlertPlungerLift,
    viewLoadingPlungerLift,
  } = useContext(DashboardPLContext);
  const {
    // User,
    userDataInfo,
    dataFilterUserPL,
    mutationUpdateDataPlungerLift,
    mutationCalcCasing,
    mutationCalcPressure,
    dataWellPL,
    loadDataWellPL,
    listqIPR,
    dataCalcCasing,
    dataCalcPressure,
    dataCalcInterpolation,
    mutationUploadExcelFile,
    processedData,
    listWellPL,
    refetchProcessedData,
    refetchListqIPR,
    refetchDataWellPL,
    refetchCalcDataCasing,
    refetchCalcDataPressure,
    refetchCalcDataInterpolation,
    getDataFilterPL,
    refetchListWellPL,
  } = useTabPlungerLift();
  const { dataFilter, User } = useFilters();
  const seletedFilter = dataFilter ? dataFilter?.data : null;
  const [selectedOption, setSelectedOption] = useState("Line");
  const [inputInformation, setInputInformation] = useState(dataWell);
  const [timePlungerLift, setTimePlungerLift] = useState();
  const [switchPlungerLift, setSwitchPlungerLift] = useState(false);
  const [dataCardDashboard, setDataCardDashboard] = useState(dataTestAlarm);
  const [dataTableTubing, setDataTableTubing] = useState(
    DATA_PLUNGER_LIFT.initialDataTubing
  );
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [domainGraph, setDomainGraph] = useState(DATA_DOMAIN_GRAPH);
  
  useEffect(() => {
    getDataPage(NAME_PAGES.plunger_lift.dashboard);
  }, []);

  useEffect(() => {
    getDataPage(NAME_PAGES.plunger_lift.dashboard);
    if (listWellPL?.data && selectedWellPL !== null) {
      const dataWellFind = listWellPL?.data.find(
        (item) => item.well_id === selectedWellPL
      );
      if (dataWellFind) {
        const dataFilterNew = {
          county: dataWellFind?.county,
          field: dataWellFind?.field,
          well_id: dataWellFind?.well_id,
          well_platforms: dataWellFind?.well_platforms,
          well_name: dataWellFind?.well_name,
        };
        getDataFilterPL(dataFilterNew);
      }
    } else {
      refetchListWellPL();
    }
  }, [selectedWellPL, listWellPL]);

  useEffect(() => {
    if (
      dataFilterUserPL?.county &&
      dataFilterUserPL?.field &&
      dataFilterUserPL?.well_id
    ) {
      refetchDataWellPL();
      refetchListqIPR();
      refetchProcessedData();
    }
  }, [dataFilterUserPL]);

  useEffect(() => {
    if (
      !!dataFilterUserPL &&
      dataWellPL?.statusCode === STATUS_CODE_PLUNGER_LIFT.SUCCESS &&
      !!dataWellPL?.data
    ) {
      const {
        well_name,
        company,
        country,
        region,
        field,
        well_orientation,
        well_id,
        data_alert,
        data_check,
        data_domain_graph,
        slide_graph_plungerlift
      } = dataWellPL?.data || {};
      setInputInformation({
        ...inputInformation,
        well_name: well_name,
        company: company,
        country: country,
        region: region,
        field: field,
        well_orientation: well_orientation,
        well_id: well_id,
      });
      setTimePlungerLift(dataWellPL?.data?.timeRealTimeDasboard);
      setSwitchPlungerLift(dataWellPL?.data?.checksRealTime?.dashboard);
      getDataAlertPlungerLift(
        data_alert && !!Object.keys(data_alert).length
          ? data_alert
          : INITIAL_DATA_ALERTS_PLUNGER_LIFT
      );
      getSomeAlert({
        ...someAlert,
        messageChange: false,
        plunger_lift:
          data_check && Object.keys(data_check).length
            ? data_check
            : DATA_ALERTS.plunger_lift,
      });
      const domainGraph = Array.isArray(data_domain_graph) && data_domain_graph.length === 0 ? DATA_DOMAIN_GRAPH : data_domain_graph;
      setDomainGraph(domainGraph);
      getDataDomainGraphPL(domainGraph);
      if (slide_graph_plungerlift !== null || slide_graph_plungerlift !== undefined) {
        getSlideGraph({
          ...slideGraph,
          plunger_lift: slide_graph_plungerlift
        })
      }
      setTimeout(() => {
        if (Object.values(dataTableTubing[2]).every((value) => value !== "")) {
          sequentialExecutionPlungerLift();
        }
      }, 400);
    }
  }, [dataFilterUserPL, dataWellPL?.data, dataTableTubing]);

  useEffect(() => {
    if (
      dataWellPL?.statusCode === STATUS_CODE_PLUNGER_LIFT.SUCCESS &&
      !!dataWellPL?.data &&
      !!selectedWellPL
    ) {
      const currentWellId = dataFilterUserPL?.well_id;
      const dataWell = dataWellPL?.data;

      if (dataWellPL?.data?.well_id === currentWellId) {
        const filterDataWellEquipment = dataWellPL?.data?.dataEquipment;
        const arrayModify = filterDataWellEquipment.map((item) => ({
          id: item.id,
          nameTubing: item.name,
          measured_depth: Number(item.measured_depth),
          external_diameter: Number(item.external_diameter),
          internal_diameter: Number(item.internal_diameter),
        }));

        setDataTableTubing(arrayModify);
      } else {
        setDataTableTubing(DATA_PLUNGER_LIFT.initialDataTubing);
      }

      if (
        dataWell?.type_graph_dashboard?.production_history &&
        dataWell?.type_graph_dashboard?.wellhead_production_conditions
      ) {
        getDeviceRealTime({
          ...deviceRealTime,
          device_plungerlift: {
            ...deviceRealTime.device_plungerlift,
            selected_device_graph: {
              chartProdHistory:
                dataWell?.type_graph_dashboard?.production_history
                  .device_sensor,
              chartWellCond:
                dataWell?.type_graph_dashboard?.wellhead_production_conditions,
            },
            selected_platform_graph: {
              chartProdHistory:
                dataWell?.type_graph_dashboard?.production_history
                  .device_sensor,
              chartWellCond:
                dataWell?.type_graph_dashboard?.wellhead_production_conditions,
            },
          },
        });
        getCheckTypeGraph({
          ...checkTypeGraph,
          plunger_lift: {
            production_history:
              dataWell?.type_graph_dashboard?.production_history?.type_graph,
            wellhead_production:
              dataWell?.type_graph_dashboard?.wellhead_production_conditions
                ?.type_graph,
          },
        });
      } else {
        getSelectDashboardPL(SELECT_DASHBOARD_PL);
      }
    } else if (
      dataWellPL?.statusCode === STATUS_CODE_PLUNGER_LIFT.ERROR &&
      !dataWellPL?.statusCode
    ) {
      getSelectDashboardPL(SELECT_DASHBOARD_PL);
    }
  }, [dataWellPL]);

  useEffect(() => {
    if (
      dataCalcInterpolation?.statusCode ===
        STATUS_CODE_GET_INTERPOLATION_PLUNGER_LIFT?.SUCCESS &&
      !!selectedWellPL
    ) {
      const dataInterpolation = dataCalcInterpolation?.data[0];
      setDataCardDashboard((prevState) => ({
        ...prevState,
        alm_GLRrequired: Number(dataInterpolation?.glr_req?.toFixed(2)),
        alm_cycle_day: Number(dataInterpolation?.cycle_day_glr?.toFixed(2)),
        alm_casing_pressure_required: Number(
          dataInterpolation?.pc_inter_glr?.toFixed(2)
        ),
      }));
    }
  }, [dataCalcInterpolation]);

  useEffect(() => {
    //MODIFICAR ESTOS VALORES SI SE DESEO INCLUIR UN NUEVO
    const validateAlert = {
      ...someAlert.plunger_lift.valuesAlert,
      [STATE_ALERTS.alert_alm_GLRrequired]: validateTrueCount(
        "alm_GLRrequired",
        dataCardDashboard?.alm_GLRrequired,
        dataAlertPlungerLift.alm_GLRrequired_max,
        dataAlertPlungerLift.alm_GLRrequired_min
      ),
      [STATE_ALERTS.alert_alm_GLRproduced]: validateTrueCount(
        "alm_GLRproduced",
        dataCardDashboard?.alm_GLRproduced,
        dataAlertPlungerLift.alm_GLRproduced_max,
        dataAlertPlungerLift.alm_GLRproduced_min
      ),
      [STATE_ALERTS.alert_alm_casing_pressure_required]: validateTrueCount(
        "alm_casing_pressure_required",
        dataCardDashboard?.alm_casing_pressure_required,
        dataAlertPlungerLift.alm_casing_pressure_required_max,
        dataAlertPlungerLift.alm_casing_pressure_available_min
      ),
      [STATE_ALERTS.alert_alm_casing_pressure_available]: validateTrueCount(
        "alm_casing_pressure_available",
        dataCardDashboard?.alm_casing_pressure_available,
        dataAlertPlungerLift.alm_casing_pressure_available_max,
        dataAlertPlungerLift.alm_casing_pressure_available_min
      ),
      [STATE_ALERTS.alert_alm_cycle_day]: validateTrueCount(
        "alm_cycle_day",
        dataCardDashboard?.alm_cycle_day,
        dataAlertPlungerLift.alm_cycle_day_max,
        dataAlertPlungerLift.alm_cycle_day_min
      ),
      [STATE_ALERTS.alert_twc_Pressure]: validateTrueCount(
        "twc_Pressure",
        dataCardDashboard?.twc_Pressure,
        dataAlertPlungerLift.twc_Pressure_max,
        dataAlertPlungerLift.twc_Pressure_min
      ),
      [STATE_ALERTS.alert_twc_temperature]: validateTrueCount(
        "twc_temperature",
        dataCardDashboard?.twc_temperature,
        dataAlertPlungerLift.twc_temperature_max,
        dataAlertPlungerLift.twc_temperature_min
      ),
      [STATE_ALERTS.alert_em_npv]: validateTrueCount(
        "em_npv",
        dataCardDashboard?.em_npv,
        dataAlertPlungerLift.em_npv_max,
        dataAlertPlungerLift.em_npv_min
      ),
      [STATE_ALERTS.alert_em_roi]: validateTrueCount(
        "em_roi",
        dataCardDashboard?.em_roi,
        dataAlertPlungerLift.em_roi_max,
        dataAlertPlungerLift.em_roi_min
      ),
      [STATE_ALERTS.alert_cwc_Pressure]: validateTrueCount(
        "cwc_Pressure",
        dataCardDashboard?.cwc_Pressure,
        dataAlertPlungerLift.cwc_Pressure_max,
        dataAlertPlungerLift.cwc_Pressure_min
      ),
      [STATE_ALERTS.alert_cwc_temperature]: validateTrueCount(
        "cwc_temperature",
        dataCardDashboard?.cwc_temperature,
        dataAlertPlungerLift.cwc_temperature_max,
        dataAlertPlungerLift.cwc_temperature_min
      ),
      [STATE_ALERTS.alert_pic_Pressure]: validateTrueCount(
        "pic_Pressure",
        dataCardDashboard?.pic_Pressure,
        dataAlertPlungerLift.pic_Pressure_max,
        dataAlertPlungerLift.pic_Pressure_min
      ),
      [STATE_ALERTS.alert_pic_temperature]: validateTrueCount(
        "pic_temperature",
        dataCardDashboard?.pic_temperature,
        dataAlertPlungerLift.pic_temperature_max,
        dataAlertPlungerLift.pic_temperature_min
      ),
      [STATE_ALERTS.alert_prc_GasRate]: validateTrueCount(
        "prc_GasRate",
        dataCardDashboard?.prc_GasRate,
        dataAlertPlungerLift.prc_GasRate_max,
        dataAlertPlungerLift.prc_GasRate_min
      ),
      [STATE_ALERTS.alert_prc_LiquidRate]: validateTrueCount(
        "prc_LiquidRate",
        dataCardDashboard?.prc_LiquidRate,
        dataAlertPlungerLift.prc_LiquidRate_max,
        dataAlertPlungerLift.prc_LiquidRate_min
      ),
      [STATE_ALERTS.alert_prc_Glr]: validateTrueCount(
        "prc_Glr",
        dataCardDashboard?.prc_Glr,
        dataAlertPlungerLift.prc_Glr_max,
        dataAlertPlungerLift.prc_Glr_min
      ),
      [STATE_ALERTS.alert_prc_GasVolumePer]: validateTrueCount(
        "prc_GasVolumePer",
        dataCardDashboard?.prc_GasVolumePer,
        dataAlertPlungerLift.prc_GasVolumePer_max,
        dataAlertPlungerLift.prc_GasVolumePer_min
      ),
      [STATE_ALERTS.alert_prc_LiquidVolumPer]: validateTrueCount(
        "prc_LiquidVolumPer",
        dataCardDashboard?.prc_LiquidVolumPer,
        dataAlertPlungerLift.prc_LiquidVolumPer_max,
        dataAlertPlungerLift.prc_LiquidVolumPer_min
      ),
    };

    const trueCount = Object.values(validateAlert).filter(
      (value) => value
    ).length;

    if (trueCount >= 0 && someAlert.plunger_lift.countCheck !== undefined) {
      const realValueTrueCount =
        trueCount <= 0 ? 0 : trueCount - someAlert.plunger_lift.countCheck;
      getSomeAlert({
        ...someAlert,
        plunger_lift: {
          ...someAlert.plunger_lift,
          countAlert: realValueTrueCount,
          valuesAlert: validateAlert,
        },
      });
    }
  }, [
    dataAlertPlungerLift,
    dataCardDashboard,
    someAlert.plunger_lift.valuesCheck,
  ]);

  useEffect(() => {
    // const dataIOT = dataIOTAllPlatforms?.requestData;
    if (switchPlungerLift && !!timePlungerLift) {
      const intervalId = setInterval(() => {
        if (Object.values(dataTableTubing[2]).every((value) => value !== "")) {
          sequentialExecutionPlungerLift();
        }
      }, timePlungerLift);
      return () => clearInterval(intervalId);
    }
  }, [
    dataTableTubing,
    timePlungerLift,
    switchPlungerLift /* dataIOTAllPlatforms, refetchDataIOTAllPlatforms */,
  ]);

  useEffect(() => {
    // Verifica si hay información de entrada válida y si los datos necesitan inicialización
    if (
      !!inputInformation &&
      Array.isArray(processedData?.data?.data_domain_graph) &&
      processedData?.data.data_domain_graph.length === 0
    ) {
      // Obtener selected_platform_graph de deviceRealTime
      const selectedPlatformGraph =
        deviceRealTime?.device_plungerlift?.selected_platform_graph;

      if (selectedPlatformGraph) {
        // Procesar chartProdHistory
        const chartProdHistory = selectedPlatformGraph.chartProdHistory;
        if (chartProdHistory) {
          //numberPreGraph identifica que grafica es, y el otro parametro es la grafica
          resetDomain(numberPreGraph["chartProdHistory"], "chartProdHistory");
        }
        // Procesar chartWellCond
        const chartWellCond = selectedPlatformGraph.chartWellCond;
        //Se evalua si chartwell tambien es un objeto, debido a que debe tener 3 llaves
        if (chartWellCond && typeof chartWellCond === "object") {
          Object.entries(chartWellCond).forEach(([key, value]) => {
            if (value) {
              resetDomain(numberPreGraph["chartWellCond"], "chartWellCond");
            }
          });
        } else {
          console.error(
            "Dashboard_PlungerLift: chartWellCond is not defined or is not a valid object."
          );
        }
      } else {
        console.error(
          "Dashboard_PlungerLift: selected_platform_graph was not found in device_plungerlift."
        );
      }
    }
  }, [deviceRealTime, processedData, inputInformation]);
  const validateTrueCount = (name, valueCard, valueMax, valueMin) => {
    if (valueCard !== null) {
      if (someAlert?.plunger_lift?.valuesCheck[name] === false) {
        const validateTrue = someAlert?.plunger_lift.valuesAlert[name];
        return validateTrue;
      } else if (someAlert?.plunger_lift?.valuesCheck[name]) {
        const validateTrue =
          valueCard > valueMax || valueCard < valueMin ? true : false;
        return validateTrue;
      }
    }
  };
  const sequentialExecutionPlungerLift = async (newDataVLP) => {
    let qoMax = [];

    switch (dataWellPL?.data?.ipr_model) {
      case "vogel":
        qoMax = listqIPR?.data?.qo?.vogel;
        break;
      case "straight":
        qoMax = listqIPR?.data?.qo?.straight;
        break;
      case "fetko":
        qoMax = listqIPR?.data?.qo?.fetko;
        break;
      case "composite":
        qoMax = listqIPR?.data?.qo?.qliq_pet;
        break;
      default:
        qoMax = [];
        break;
    }

    const dataWell = dataWellPL?.data;
    const dataCalculate = {
      id_tubing: Number(dataTableTubing[2].internal_diameter),
      od_tubing_in: Number(dataTableTubing[2].external_diameter),
      wh_temp_casing: Number(dataWell.cwp_wellhead_temperature),
      wh_press_casing: Number(dataWell.cwp_wellhead_pressure),
      wh_press_tubing: Number(dataWell.twp_wellhead_pressure),
      res_temp: Number(dataWell.reservoir_temperature),
      plunger_weight: Number(dataWell.plunger_weight),
      plunger_rising_vel: Number(dataWell.plunger_rising_velocity),
      plunger_fall_vel_liq: Number(dataWell.plunger_fvl),
      plunger_fall_vel_gas: Number(dataWell.plunger_fvg),
      plunger_depth_md: Number(dataWell.plunger_depth),
      id1_casing: Number(dataTableTubing[0].internal_diameter),
      id2_casing: Number(dataTableTubing[1].internal_diameter),
      id3_casing: Number(dataTableTubing[1].internal_diameter),
      length1: 2,
      length2: 1,
      length3: 100,
      sgg_mix: Number(dataWell?.res_gas_gravity),
      yn2: dataWell?.res_N2,
      yco2: dataWell?.res_CO2,
      yh2s: dataWell?.res_H2S,
      qo_max: qoMax,
      qg_sc: Number(dataWell.production_gas_rate),
      qg_supp_sc: Number(dataWell.production_supplemental_gas_rate),
      ql_sc: Number(dataWell.production_liquid_rate),
    };

    const measuredDepths = dataWell?.dataDeviation?.map(
      (item) => item.measured_depth
    );
    const trueVertical = dataWell?.dataDeviation?.map((item) => item.tvd);

    const dataCalculatePress = {
      num_intervals: 10,
      mid_perf_md: Number(dataWell.reservoir_mid_perf_tvd_depth),
      res_temp: Number(dataWell.reservoir_temperature),
      wh_temp: Number(dataWell.twp_wellhead_temperature),
      gas_sgg_mix: Number(dataWell?.res_gas_gravity),
      yN2: dataWell?.res_N2,
      yCO2: dataWell?.res_CO2,
      yH2S: dataWell?.res_H2S,
      measured_depth_table: measuredDepths,
      true_vertical_depth_table: trueVertical,
      tolerance: 0.01,
    };
    async function executeFunction() {
      await mutationCalcCasing.mutateAsync({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: dataFilterUserPL.county,
        field: dataFilterUserPL.field,
        well: dataFilterUserPL.well_id,
        username: User.user_name,
        data: dataCalculate,
      });
      await new Promise((resolve) => setTimeout(resolve, 100));
      await new Promise((resolve) => setTimeout(resolve, 100));
      refetchCalcDataCasing();
      await mutationCalcPressure.mutateAsync({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: dataFilterUserPL.county,
        field: dataFilterUserPL.field,
        well: dataFilterUserPL.well_id,
        username: User.user_name,
        data: dataCalculatePress,
      });

      await new Promise((resolve) => setTimeout(resolve, 100));
      refetchCalcDataPressure();
      await new Promise((resolve) => setTimeout(resolve, 100));
      refetchCalcDataInterpolation();
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    executeFunction();
  };

  const secuentialUpdatePl = async (dataPlunger, checked) => {
    const preb = {
      ...dataPlunger,
      checksRealTime: { ...dataPlunger.checksRealTime, dashboard: checked },
    };
    await mutationUpdateDataPlungerLift.mutateAsync({
      operator: userDataInfo?.operator,
      lease: userDataInfo?.lease,
      region: userDataInfo?.region,
      county: dataFilterUserPL?.county,
      field: dataFilterUserPL?.field,
      well: dataFilterUserPL?.well_id,
      username: User?.user_name,
      data: {
        ...dataPlunger,
        checksRealTime: { ...dataPlunger.checksRealTime, dashboard: checked },
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await Promise.all([refetchDataWellPL()]);
  };

  const calculateDomain = (value, contextDomain, domain, reset) => {
    switch (domain) {
      case "max":
        const valueMax = Math.max(
          ...value.filter((values) => values !== undefined)
        );
        const roundedMax = Math.ceil(Math.round(valueMax * 10) / 100) * 10;
        const validateDomain =
          !!contextDomain && !reset ? contextDomain : roundedMax;
        return validateDomain;

      case "min":
        const valueMin = Math.min(
          ...value.filter((values) => values !== undefined)
        );
        const roundedMin = Math.floor(Math.round(valueMin * 10) / 100) * 10;
        const validateDomainMin =
          !!contextDomain && !reset ? contextDomain : roundedMin;
        return validateDomainMin;

      default:
        return null;
    }
  };
  const resetDomain = (numberGraphReset, graph) => {
    let newDataDomain = "";
    //DATA REAL TIME---> se espera la api, por esta razon se deja comentado
    /*       const dataGraphicRT = dataIOTAllPlatforms?.requestData[selectedDeviceGraph[graph]?.split(" ")[0]];
      //Este proceso se realiza por que el well head pressure no esta como LSD dentro de la data si no como parametro
      const selectedDeviceGraphReplace = selectedDeviceGraph[graph] === WELL_SENSOR_DEVICE?.data[6].sensor ? WELL_SENSOR_DEVICE?.data[5].sensor : selectedDeviceGraph[graph];
      const filteredDataRT = dataGraphicRT?.filter(item => item.LSD === selectedDeviceGraphReplace);
      //Variables Grafica 1
      const gasRateValuesRT = filteredDataRT?.map((obj) => obj?.gas_rate).filter((value) => value !== -999.25);
      const liquidRateValuesRT = filteredDataRT?.map((obj) => obj?.liquid_rate).filter((value) => value !== -999.25);
      //Variables Grafica 2 hay que mirar bien porque son 3 pressojes Tubing_Pressure Pipeline_Pressure Casing_Pressure ojooo
      const pressureValuesRT = filteredDataRT?.map((obj) => obj?.pressure).filter((value) => value !== -999.25);
*/

    //DATA HISTORIC
    const dataGraphicHT = processedData?.data;
    //Variables Grafica 1
    // Combina las presiones de Casing, Pipeline y Tubing de dataset2 en un solo arreglo,
    const combinedPressuresHT =
      dataGraphicHT?.dataset2
        ?.flatMap((obj) => [
          obj?.Casing_Pressure,
          obj?.Pipeline_Pressure,
          obj?.Tubing_Pressure,
        ])
        .filter((value) => value !== -999.25) || [];

    //Variables Grafica 3
    const gasRateValuesHT =
      dataGraphicHT?.dataset1
        ?.map((obj) => obj?.gas_rate)
        .filter((value) => value !== -999.25) || [];

    const liquidRateValuesHT =
      dataGraphicHT?.dataset1
        ?.map((obj) => obj?.liquid_rate)
        .filter((value) => value !== -999.25) || [];

    //DATA CONCAT
    const gasRateValues = !checkTypeGraph.plunger_lift.wellhead_production
      ? [...gasRateValuesHT /* ...gasRateValuesRT */]
      : /* gasRateValuesRT */ [];
    const pressureValues = !checkTypeGraph.plunger_lift.wellhead_production
      ? [...combinedPressuresHT /* ...pressureValuesRT */]
      : /* pressureValuesRT */ [];
    //variables segunda grafica
    const gasRateValuesPH = !checkTypeGraph.plunger_lift.production_history
      ? [...gasRateValuesHT /* ...gasRateValuesRT */]
      : /* gasRateValuesRT */ [];
    const liquidRateValues = !checkTypeGraph.plunger_lift.production_history
      ? [...liquidRateValuesHT /* ...liquidRateValuesRT */]
      : /* liquidRateValuesRT */ [];

    switch (numberGraphReset) {
      case 0:
        if (gasRateValues?.length && pressureValues?.length) {
          newDataDomain = {
            gas_rate_max_wpc: calculateDomain(
              gasRateValues,
              dataDomainGraphPL?.gas_rate_max_wpc,
              "max",
              true
            ),
            gas_rate_min_wpc: calculateDomain(
              gasRateValues,
              dataDomainGraphPL?.gas_rate_min_wpc,
              "min",
              true
            ),
            press_max_wpc: calculateDomain(
              pressureValues,
              dataDomainGraphPL?.press_max_wpc,
              "max",
              true
            ),
            press_min_wpc: calculateDomain(
              pressureValues,
              dataDomainGraphPL?.press_min_wpc,
              "min",
              true
            ),
          };
        } else {
          newDataDomain = {
            gas_rate_max_wpc: 0,
            gas_rate_min_wpc: 0,
            press_max_wpc: 0,
            press_min_wpc: 0,
          };
        }
        break;
      case 1:
        if (gasRateValuesPH?.length && liquidRateValues?.length) {
          newDataDomain = {
            gas_rate_max_ph: calculateDomain(
              gasRateValuesPH,
              dataDomainGraphPL?.gas_rate_max_ph,
              "max",
              true
            ),
            gas_rate_min_ph: calculateDomain(
              gasRateValuesPH,
              dataDomainGraphPL?.gas_rate_min_ph,
              "min",
              true
            ),
            liquid_rate_max_ph: calculateDomain(
              liquidRateValues,
              dataDomainGraphPL?.liquid_rate_max_ph,
              "max",
              true
            ),
            liquid_rate_min_ph: calculateDomain(
              liquidRateValues,
              dataDomainGraphPL?.liquid_rate_min_ph,
              "min",
              true
            ),
          };
        } else {
          newDataDomain = {
            gas_rate_max_ph: 0,
            gas_rate_min_ph: 0,
            liquid_rate_max_ph: 0,
            liquid_rate_min_ph: 0,
          };
        }
        break;

      default:
        break;
    }

    setDomainGraph((prevDomain) => {
      const mergedDomain = { ...prevDomain, ...newDataDomain };
      getDataDomainGraphPL(mergedDomain);
      return mergedDomain;
    });
  };
  
  const handleResetLimits = (numberGraphReset, graph) => {
    // En SelectedDeviceGraph determina si el gráfico seleccionado tiene al menos un valor no vacío.
    const selectedDeviceGraph = Object.values(
      Object.fromEntries(
        Object.entries(
          deviceRealTime?.device_plungerlift?.selected_device_graph?.[graph] ||
            {}
        ).filter(([key]) => key !== "type_graph")
      )
    ).some((value) => value !== "");
    
    if (selectedDeviceGraph) {
      resetDomain(numberGraphReset, graph);
    } else {
      message.info("Select a device to reset the domains in this chart");
    }
  };

  const handleTypeGraph = (event, name) => {
    if (checkTypeGraph.plunger_lift[name] === true) {
      getCheckTypeGraph({
        ...checkTypeGraph,
        plunger_lift: { ...checkTypeGraph.plunger_lift, [name]: false },
      });
    } else {
      getCheckTypeGraph({
        ...checkTypeGraph,
        plunger_lift: { ...checkTypeGraph.plunger_lift, [name]: true },
      });
    }
  };

  const handleOptionChange = (name, option) => {
    switch (name) {
      case "chartType":
        setSelectedOption(option);
        break;
      case "time_plunger_lift":
        setTimePlungerLift(option);
        const dataPl = dataWellPL?.data;
        mutationUpdateDataPlungerLift.mutate({
          operator: userDataInfo?.operator,
          lease: userDataInfo?.lease,
          region: userDataInfo?.region,
          county: dataFilterUserPL?.county,
          field: dataFilterUserPL?.field,
          well: dataFilterUserPL?.well_id,
          username: User?.user_name,
          data: {
            ...dataPl,
            timeRealTimeDasboard: option,
          },
        });
      case "gas_rate":
      case "casing_pressure":
      case "tubing_pressure":
      case "pipeline_pressure":
        getDeviceRealTime({
          ...deviceRealTime,
          device_plungerlift: {
            ...deviceRealTime.device_plungerlift,
            selected_platform_graph: {
              ...deviceRealTime.device_plungerlift.selected_platform_graph,
              chartWellCond: {
                ...deviceRealTime.device_plungerlift.selected_platform_graph.chartWellCond,
                [name]: option,
              },
            },
            selected_device_graph: {
              ...deviceRealTime.device_plungerlift.selected_device_graph,
              chartWellCond: {
                ...deviceRealTime.device_plungerlift.selected_device_graph
                  .chartWellCond,
                [name]: option,
              },
            },
          },
        });
        break;
      case "device_sensor":
        getDeviceRealTime({
          ...deviceRealTime,
          device_plungerlift: {
            ...deviceRealTime.device_plungerlift,
            selected_platform_graph: {
              ...deviceRealTime.device_plungerlift.selected_platform_graph,
              chartProdHistory: option,
            },
            selected_device_graph: {
              ...deviceRealTime.device_plungerlift.selected_device_graph,
              chartProdHistory: option,
            },
          },
        });

        break;
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    if (
      name === "gas_rate_min_wpc" ||
      name === "gas_rate_max_wpc" ||
      name === "press_max_wpc" ||
      name === "press_min_wpc" ||
      name === "gas_rate_min_ph" ||
      name === "gas_rate_max_ph" ||
      name === "liquid_rate_min_ph" ||
      name === "liquid_rate_max_ph"
    ) {
      const dataNewDomain = {
        ...dataDomainGraphPL,
        [name]: value !== "" ? Number(value) : null,
      };
      getDataDomainGraphPL(dataNewDomain);
    }
    setDomainGraph((prevState) => ({
      ...prevState,
      [name]: value !== "" ? Number(value) : null,
    }));
  };

  const handleInputBlur = (e) => {
    const { value, name } = e.target;
    const validateAndSetDomain = (
      field,
      comparisonField,
      condition,
      errorMessage
    ) => {
      if (condition) {
        message.warning(errorMessage);
        getDataDomainGraphPL({
          ...dataDomainGraphPL,
          [field]: "",
        });
        setDomainGraph((prevState) => ({
          ...prevState,
          [field]: "",
        }));
      }
    };
    if (
      [
        "gas_rate_min_wpc",
        "gas_rate_max_wpc",
        "press_max_wpc",
        "press_min_wpc",
        "gas_rate_min_ph",
        "gas_rate_max_ph",
        "liquid_rate_min_ph",
        "liquid_rate_max_ph",
      ].includes(name)
    ) {
      const conditions = {
        gas_rate_max_wpc: {
          comparisonField: "gas_rate_min_wpc",
          condition: value < domainGraph.gas_rate_min_wpc,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        gas_rate_min_wpc: {
          comparisonField: "gas_rate_max_wpc",
          condition: value > domainGraph.gas_rate_max_wpc,
          errorMessage: "The minimum value must be less than the maximum",
        },
        press_max_wpc: {
          comparisonField: "press_min_wpc",
          condition: value < domainGraph.press_min_wpc,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        press_min_wpc: {
          comparisonField: "press_max_wpc",
          condition: value > domainGraph.press_max_wpc,
          errorMessage: "The minimum value must be less than the maximum",
        },
        gas_rate_max_ph: {
          comparisonField: "gas_rate_min_ph",
          condition: value < domainGraph.gas_rate_min_ph,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        gas_rate_min_ph: {
          comparisonField: "gas_rate_max_ph",
          condition: value > domainGraph.gas_rate_max_ph,
          errorMessage: "The minimum value must be less than the maximum",
        },
        liquid_rate_max_ph: {
          comparisonField: "liquid_rate_min_ph",
          condition: value < domainGraph.liquid_rate_min_ph,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        liquid_rate_min_ph: {
          comparisonField: "liquid_rate_max_ph",
          condition: value > domainGraph.liquid_rate_max_ph,
          errorMessage: "The minimum value must be less than the maximum",
        },
      };

      const { comparisonField, condition, errorMessage } = conditions[name];
      validateAndSetDomain(name, comparisonField, condition, errorMessage);
    }
  };

  const handleSwitchPlungerLift = (checked) => {
    setSwitchPlungerLift(checked);
    const dataPl = dataWellPL?.data;
    if (!!dataPl) {
      setSwitchPlungerLift(checked);
      async function executeFunction() {
        await secuentialUpdatePl(dataPl, checked);
      }
      executeFunction();
    }
  };

  const handleSaveAlert = () => {
    if (dataAlertPlungerLift && !!selectedWellPL) {
      const data_alert = dataAlertPlungerLift;
      const data_check = someAlert.plunger_lift;
      const dataConcatAlert = {
        ...dataWellPL?.data,
        data_alert: data_alert,
        data_check: data_check,
      };
      const invalidKeys =
        data_alert && data_check
          ? validateJsonEmptyAlert(data_check, data_alert)
          : [];

      if (invalidKeys.length > 0) {
        getValidateAlertPlungerLift({
          ...validateAlertPlungerLift,
          alertCard: true,
        });
        setShowAlert(true);
        setTypeAlert("Warning");
        setMessageAlert(
          "There are empty alerts please complete the boxes of the ranges"
        );
      } else {
        getValidateAlertPlungerLift({
          ...validateAlertPlungerLift,
          alertCard: false,
        });
        setTimeout(() => {
          mutationUpdateDataPlungerLift.mutate({
            operator: seletedFilter?.operator,
            lease: seletedFilter?.lease,
            region: seletedFilter?.region,
            county: dataWellPL?.data?.county,
            field: dataWellPL?.data?.field,
            well: dataWellPL?.data?.well_id,
            username: User?.user_name,
            data: dataConcatAlert,
          });
        }, 500);
        getDataAlertPlungerLift(dataConcatAlert?.data_alert);
        getSomeAlert({ ...someAlert, messageChange: false });
      }
    } else {
      message.info("Please seleted well for save domain alert");
    }
  };

  const handleResetAlert = () => {
    if (!!selectedWell && !!Object.keys(dataWellPL?.data).length) {
      const dataConcatAlert = {
        ...dataWellPL?.data,
        data_alert: [],
        data_check: [],
      };

      async function executeFunction() {
        await new Promise((resolve) => setTimeout(resolve, 100));

        await mutationUpdateDataPlungerLift.mutateAsync({
          operator: seletedFilter?.operator,
          lease: seletedFilter?.lease,
          region: seletedFilter?.region,
          county: dataWellPL?.data?.county,
          field: dataWellPL?.data?.field,
          well: dataWellPL?.data?.well_id,
          username: User?.user_name,
          data: dataConcatAlert,
        });
        await new Promise((resolve) => setTimeout(resolve, 100));
        window.location.reload();
      }
      executeFunction();
    }
  };

  const handleUploadFileHistory = (file) => {
    if (!!selectedWellPL) {
      const params = {
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: dataFilterUserPL.county,
        field: dataFilterUserPL.field,
        well: dataFilterUserPL.well_id,
        username: User.user_name,
      };

      if (typeof file === "object" && file !== null) {
        mutationUploadExcelFile.mutate({ file, params });
      }
    } else {
      message.info("Please select a well before uploading a file.");
    }
  };

  const handleUploadFile = (dataFile) => {
    if (selectedWell !== "null") {
      const dataFilePre = dataFile.map((obj, index) => {
        return { id: index + 1, ...obj };
      });

      const finalDataAlert = {};

      dataFilePre.map((item) => {
        const nameMax = `${item.item}_max`;
        const nameMin = `${item.item}_min`;

        finalDataAlert[nameMin] = item.min >= item.max ? 0 : item.min;
        finalDataAlert[nameMax] = item.max;
      });

      const dataConcatAlert = {
        ...dataWellPL?.data,
        data_alert: finalDataAlert,
      };
      setTimeout(() => {
        mutationUpdateDataPlungerLift.mutate({
          operator: seletedFilter?.operator,
          lease: seletedFilter?.lease,
          region: seletedFilter?.region,
          county: dataWellPL?.data?.county,
          field: dataWellPL?.data?.field,
          well: dataWellPL?.data?.well_id,
          username: User?.user_name,
          data: dataConcatAlert,
        });

        getDataAlertPlungerLift(finalDataAlert);
        getSomeAlert({ ...someAlert, messageChange: false });
      }, 500);
    } else {
      message.info("Please seleted well for save domain alert");
    }
  };

  const handleOpenModal = (cardId) => {
    setSelectedCardId(cardId);
    switch (cardId) {
      case 1:
      case 2:
      case 3:
        setOpen(true);
        break;
      default:
        break;
    }
  };
  const validateJsonEmpty = (json) => {
    const dataValidate = Object.entries(json).filter(
      ([key, value]) => value === null || value === ""
    );
    return dataValidate;
  };
  const handleCloseModalAlert = () => {
    setShowAlert(false);
  };
  const handleCloseModalGraph = () => {
    const invalidKeysGraph = domainGraph
      ? validateGraphDomain("graph" + selectedCardId)
      : false;
    if (invalidKeysGraph && selectedCardId !== 2) {
      getValidateAlertPlungerLift({
        ...validateAlertPlungerLift,
        alertDomain: true,
      });
      setShowAlert(true);
      setTypeAlert("Warning");
      setMessageAlert(
        "There are empty domains please complete the boxes of the ranges"
      );
    } else {
      getValidateAlertPlungerLift({
        ...validateAlertPlungerLift,
        alertDomain: false,
      });
      setOpen(false);
    }
  };
  const validateGraphDomain = (name) => {
    const graphValidationMap = {
      graph1: [
        "gas_rate_min_wpc",
        "gas_rate_max_wpc",
        "press_max_wpc",
        "press_min_wpc",
      ],
      graph3: [
        "gas_rate_min_ph",
        "gas_rate_max_ph",
        "liquid_rate_min_ph",
        "liquid_rate_max_ph",
      ],
    };
    const fieldsToValidate = graphValidationMap[name] || [];

    const validateResult = fieldsToValidate.some((field) => {
      return (
        dataDomainGraphPL[field] === null ||
        dataDomainGraphPL[field] === "" ||
        dataDomainGraphPL[field] === undefined
      );
    });
    return validateResult;
  };

  return (
    <div className="flex flex-col w-full h-screen bg-white">
      <div className="flex">
        <Navbar></Navbar>
        <Sidebar className="flex h-full abosolute" />
      </div>

      {
        /* loadDataIOTAllPlatforms || */ (loadDataWellPL ||  viewLoadingPlungerLift.loadSaveParameters) && !!selectedWellPL ? (
          <div className="flex w-full h-full">
            <LoadingDashboard />
          </div>
        ) : !selectedWellPL ||
          selectedWellPL === "null" ||
          seletedFilter?.checkedWellPlungerLift === null ||
          seletedFilter?.checkedWellPlungerLift === "" ? (
          <ErrorLoadingDashboard />
        ) : (
          <div className={contedorPrincipal}>
            <div className={contenedorWell}>
              <div className={containerWellGeneral}>
                <div>
                  <h3 className={titleGeneralWell}> Well</h3>
                  <h3 className={subTitleGeneralWell}>
                    {inputInformation?.well_name}
                  </h3>
                </div>
                <div>
                  <h3 className={titleGeneralWell}> Company</h3>
                  <h3 className={subTitleGeneralWell}>
                    {" "}
                    {inputInformation?.company}
                  </h3>
                </div>
                <div className={containerCountryWell}>
                  <div>
                    <h3 className={titleGeneralWell}> Country</h3>
                    <h3 className={subTitleGeneralWell}>
                      {" "}
                      {inputInformation?.country}
                    </h3>
                  </div>
                  <div>
                    <h3 className={titleGeneralWell}> Region</h3>
                    <h3 className={subTitleGeneralWell}>
                      {" "}
                      {inputInformation?.region}
                    </h3>
                  </div>
                </div>
                <div>
                  <h3 className={titleGeneralWell}> Field</h3>
                  <h3 className={subTitleGeneralWell}>
                    {" "}
                    {inputInformation?.field}
                  </h3>
                </div>
                <div>
                  <h3 className={titleGeneralWell}> Well Orientation </h3>
                  <h3 className={subTitleGeneralWell}>
                    {inputInformation?.well_orientation}
                  </h3>
                </div>
                <div>
                  <h3 className={titleGeneralWell}> Domain Alert </h3>
                  <div className={containerBtns}>
                    <AntdTooltip
                      placement="bottom"
                      title="UPLOAD"
                      color="#662D91"
                    >
                      <span>
                        <ImportFile
                          className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple cursor-pointer"
                          uploadfile={handleUploadFile}
                        ></ImportFile>
                      </span>
                    </AntdTooltip>
                    <AntdTooltip
                      placement="bottom"
                      title="DOWNLOAD TEMPLATE"
                      color="#662D91"
                    >
                      <span>
                        <DownloadTemplate
                          className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple cursor-pointer"
                          template={template_params_alarm_plunger_lift}
                          fileName="plunger_lift_template.xlsx"
                        />
                      </span>
                    </AntdTooltip>
                    <h3 className={subTitleGeneralWell}>
                      <AntdTooltip
                        placement="bottom"
                        title="SAVE"
                        color="#662D91"
                      >
                        <SaveAlert
                          onClick={() => handleSaveAlert()}
                          className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple cursor-pointer"
                        />
                      </AntdTooltip>
                    </h3>
                    {User?.role !== "Simple User" && (
                      <AntdTooltip
                        placement="bottom"
                        title="RESET"
                        color="#662D91"
                      >
                        <ArrowIcon
                          onClick={() => handleResetAlert()}
                          className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple cursor-pointer"
                        />
                      </AntdTooltip>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={contenedorArtificialLift}>
              <div className={containerGeneralCard}>
                <h3 className={titleCards}>Artificial Lift Metrics</h3>
                <CardGraph
                  title={"GLR required "}
                  value={dataCardDashboard?.alm_GLRrequired}
                  unit={"(SCF/B)"}
                  name={"alm_GLRrequired"}
                ></CardGraph>
                <CardGraph
                  title={"GLR produced "}
                  value={dataCardDashboard?.alm_GLRproduced}
                  unit={"(SCF/B)"}
                  name={"alm_GLRproduced"}
                ></CardGraph>
                <CardGraph
                  title={"Casing pressure required "}
                  value={dataCardDashboard?.alm_casing_pressure_required}
                  unit={"(psia)"}
                  name={"alm_casing_pressure_required"}
                ></CardGraph>
                <CardGraph
                  title={"Casing pressure available "}
                  value={dataCardDashboard?.alm_casing_pressure_available}
                  unit={"(psia)"}
                  name={"alm_casing_pressure_available"}
                ></CardGraph>
                <CardGraph
                  title={"Cycles per day "}
                  value={dataCardDashboard?.alm_cycle_day}
                  unit={"(Cycle/day)"}
                  name={"alm_cycle_day"}
                ></CardGraph>
              </div>
            </div>
            <div className={contenedorTubingWell}>
              <div className={containerGeneralCard}>
                <h3 className={titleCards}>Tubing Wellhead Conditions</h3>
                <CardGraph
                  title={"Pressure "}
                  value={dataCardDashboard?.twc_Pressure}
                  unit={"(psia)"}
                  name={"twc_Pressure"}
                ></CardGraph>
                <CardGraph
                  title={"Temperature "}
                  value={dataCardDashboard?.twc_temperature}
                  unit={"(deg F)"}
                  name={"twc_temperature"}
                ></CardGraph>
              </div>
            </div>
            <div className={contenedorEconomicMetrics}>
              <div className={containerGeneralCard}>
                <h3 className={titleCards}>Economics Metrics</h3>
                <CardGraph
                  title={"NPV "}
                  value={dataCardDashboard?.em_npv}
                  unit={"(MM$)"}
                  name={"em_npv"}
                ></CardGraph>
                <CardGraph
                  title={"ROI "}
                  value={dataCardDashboard?.em_roi}
                  unit={"(MM$)"}
                  name={"em_roi"}
                ></CardGraph>
              </div>
            </div>
            <div className={contenedorCasingWellHead}>
              <div className={containerGeneralCard}>
                <h3 className={titleCards}>Casing Wellhead Conditions</h3>
                <CardGraph
                  title={"Pressure "}
                  value={dataCardDashboard?.cwc_Pressure}
                  unit={"(psia)"}
                  name={"cwc_Pressure"}
                ></CardGraph>
                <CardGraph
                  title={"Temperature "}
                  value={dataCardDashboard?.cwc_temperature}
                  unit={"(deg F)"}
                  name={"cwc_temperature"}
                ></CardGraph>
              </div>
            </div>
            <div className={contenedorPipeLineConditions}>
              <div className={containerGeneralCard}>
                <h3 className={titleCards}>Pipeline Conditions</h3>
                <CardGraph
                  title={"Pressure "}
                  value={dataCardDashboard?.pic_Pressure}
                  unit={"(psia)"}
                  name={"pic_Pressure"}
                ></CardGraph>
                <CardGraph
                  title={"Temperature "}
                  value={dataCardDashboard?.pic_temperature}
                  unit={"(deg F)"}
                  name={"pic_temperature"}
                ></CardGraph>
              </div>
            </div>
            <div className={contenedorProductionConditions}>
              <div className={containerGeneralProducttionConditions}>
                <h3 className={titleCards}>Production Conditions</h3>
                <div className={containerCustomProducttionConditions}>
                  <div className={column1ProductionConditions}>
                    <CardGraph
                      title={"Gas Rate "}
                      value={dataCardDashboard?.prc_GasRate}
                      unit={"(Mscf/d)"}
                      name={"prc_GasRate"}
                    ></CardGraph>
                    <CardGraph
                      title={"Liquid Rate "}
                      value={dataCardDashboard?.prc_LiquidRate}
                      unit={"(bbl/d)"}
                      name={"prc_LiquidRate"}
                    ></CardGraph>
                    <CardGraph
                      title={"GLR "}
                      value={dataCardDashboard?.prc_Glr}
                      unit={"(scf/bbl)"}
                      name={"prc_Glr"}
                    ></CardGraph>
                  </div>

                  <div className={column2ProductionConditions}>
                    <CardGraph
                      title={"Gas Volume per Cycle "}
                      value={dataCardDashboard?.prc_GasVolumePer}
                      unit={"(Mscf)"}
                      name={"prc_GasVolumePer"}
                    ></CardGraph>
                    <CardGraph
                      title={"Liquid volume per Cycle "}
                      value={dataCardDashboard?.prc_LiquidVolumPer}
                      unit={"(bbl)"}
                      name={"prc_LiquidVolumPer"}
                    ></CardGraph>
                  </div>
                </div>
              </div>
            </div>
            <div className={contenedorGraph1WellHeadProducion}>
              <div className={containerPrincipal}>
                <div className={containerTitleGraph}>
                  <h1 className={tittle}>{"Wellhead Production Conditions"}</h1>
                  <div className={containerButonsExpand}>
                    <AntdTooltip
                      placement="bottom"
                      title="RESET DOMAIN GRAPH"
                      color="#662D91"
                    >
                      <ArrowIcon
                        className="w-6 h-6 hover:bg-pc-grey3 ml-[10px]"
                        onClick={() => handleResetLimits(0, "chartWellCond")}
                      />
                    </AntdTooltip>
                    <AntdTooltip
                      placement="bottom"
                      title="ZOOM GRAPH"
                      color="#662D91"
                    >
                      <ExpandIcon
                        className="w-5 h-5 cursor-pointer hover:bg-pc-grey3"
                        onClick={() => handleOpenModal(1)}
                      />
                    </AntdTooltip>
                  </div>
                </div>

                <div className={containerSelectsWellProd}>
                  <div>
                    <label htmlFor="chartType" className={subtitleWellProd}>
                      Gas Rate
                    </label>
                    <Select
                      className={sizeSelects}
                      name={"gas_rate"}
                      placeholder={"Selected option"}
                      value={
                        deviceRealTime?.device_plungerlift
                          ?.selected_device_graph?.chartWellCond.gas_rate ||
                        "Selected option"
                      }
                      onChange={(value) =>
                        handleOptionChange("gas_rate", value)
                      }
                    >
                      <Option value="">Selected option</Option>
                      <Option value="gas_rate_1">Gas rate 1</Option>
                      <Option value="gas_rate_2">Gas rate 2</Option>
                    </Select>
                  </div>
                  <div>
                    <label htmlFor="chartType" className={subtitleWellProd}>
                      Casing Pressure
                    </label>
                    <Select
                      className={sizeSelects}
                      name={"casing_pressure"}
                      placeholder={"Selected option"}
                      value={
                        deviceRealTime?.device_plungerlift
                          ?.selected_device_graph?.chartWellCond
                          .casing_pressure || "Selected option"
                      }
                      onChange={(value) =>
                        handleOptionChange("casing_pressure", value)
                      }
                    >
                      <Option value="">Selected option</Option>
                      <Option value="casing_pressure_1">
                        Casing Pressure 1
                      </Option>
                      <Option value="casing_pressure_2">
                        Casing Pressure 2
                      </Option>
                    </Select>
                  </div>
                  <div>
                    <label htmlFor="chartType" className={subtitleWellProd}>
                      Tubing Pressure
                    </label>
                    <Select
                      className={sizeSelects}
                      name={"tubing_pressure"}
                      placeholder={"Selected option"}
                      value={
                        deviceRealTime?.device_plungerlift
                          ?.selected_device_graph?.chartWellCond
                          .tubing_pressure || "Selected option"
                      }
                      onChange={(value) =>
                        handleOptionChange("tubing_pressure", value)
                      }
                    >
                      <Option value="">Selected option</Option>
                      <Option value="tubing_pressure_1">
                        Tubing Pressure 1
                      </Option>
                      <Option value="tubing_pressure_2">
                        Tubing Pressure 2
                      </Option>
                    </Select>
                  </div>
                  <div>
                    <label htmlFor="chartType" className={subtitleWellProd}>
                      Pipeline Pressure
                    </label>
                    <Select
                      className={sizeSelects}
                      name={"pipeline_pressure"}
                      placeholder={"Selected option"}
                      value={
                        deviceRealTime?.device_plungerlift
                          ?.selected_device_graph?.chartWellCond
                          ?.pipeline_pressure || "Selected option"
                      }
                      onChange={(value) =>
                        handleOptionChange("pipeline_pressure", value)
                      }
                    >
                      <Option value="">Selected option</Option>
                      <Option value="pipeline_pressure_1">
                        Pipeline Pressure 1
                      </Option>
                      <Option value="pipeline_pressure_2">
                        Pipeline Pressure 2
                      </Option>
                    </Select>
                  </div>
                  <div className={containerBtns}>
                    <AntdTooltip
                      placement="bottom"
                      title="UPLOAD"
                      color="#662D91"
                    >
                      <span>
                        <ImportFileDataHistory
                          className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple cursor-pointer"
                          uploadfile={handleUploadFileHistory}
                        ></ImportFileDataHistory>
                      </span>
                    </AntdTooltip>
                    <AntdTooltip
                      placement="bottom"
                      title="DOWNLOAD TEMPLATE"
                      color="#662D91"
                    >
                      <span>
                        <DownloadTemplate
                          className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple cursor-pointer"
                          template={template_params_history_plunger_lift}
                          fileName="plunger_lift_history_template.xlsx"
                        />
                      </span>
                    </AntdTooltip>
                  </div>
                </div>
                <div className={containerData}>
                  <div className={containerCheck}>
                    <input
                      className={`${sizeCheckbox} ${
                        !checkTypeGraph?.plunger_lift.wellhead_production
                          ? selectedInput
                          : unselectedInput
                      }`}
                      type="checkbox"
                      onChange={(event) =>
                        handleTypeGraph(event, "wellhead_production")
                      }
                    />
                    <label htmlFor="" className={sizeTextCheckbox}>
                      {" "}
                      Historical Data + RT avg{" "}
                    </label>
                  </div>
                  <div className={containerCheck}>
                    <input
                      className={`${sizeCheckbox} ${
                        checkTypeGraph?.plunger_lift.wellhead_production
                          ? selectedInput
                          : unselectedInput
                      }`}
                      type="checkbox"
                      onChange={(event) =>
                        handleTypeGraph(event, "wellhead_production")
                      }
                    />
                    <label htmlFor="" className={sizeTextCheckbox}>
                      Real Time Data
                    </label>
                  </div>
                </div>
              </div>
              <ChartWellHead
                dataIOT={[]}
                dataHistory={processedData?.data}
                title={"Wellhead Production Conditions"}
                dataDomainGraph={domainGraph}
                statusGraph={checkTypeGraph.plunger_lift.wellhead_production}
              ></ChartWellHead>
            </div>
            <div className={contenedorGraph3VlpHistory}>
              <Carrusel tooltips={["Plunger Lift", "Production History"]}>
                <div className={contenedorVlp}>
                  <div className={containerPrincipal}>
                    <div className={containerTitleGraph}>
                      <h1 className={tittle}>{"Plunger Lift"}</h1>
                      <AntdTooltip
                        placement="bottom"
                        title="ZOOM GRAPH"
                        color="#662D91"
                      >
                        <ExpandIcon
                          className="w-5 h-5 cursor-pointer hover:bg-pc-grey3"
                          onClick={() => handleOpenModal(2)}
                        />
                      </AntdTooltip>
                    </div>

                    <div className={containerSelectsWellProd}>
                      <div>
                        <label htmlFor="chartType" className={subtitleWellProd}>
                          User Run
                        </label>
                        <Switch
                          style={{
                            backgroundColor: switchPlungerLift
                              ? "#662D91"
                              : "#707070",
                            borderColor: switchPlungerLift
                              ? "#662D91"
                              : "#707070",
                          }}
                          checked={switchPlungerLift}
                          size="small"
                          onChange={handleSwitchPlungerLift}
                          disabled={
                            dataCalcInterpolation &&
                            dataCalcInterpolation?.statusType ===
                              STATUS_TYPE_GET_INTERPOLATION_PLUNGER_LIFT?.ERROR
                          }
                        ></Switch>
                      </div>
                      <div>
                        <label htmlFor="chartType" className={subtitleWellProd}>
                          Automatic Run
                        </label>
                        <Select
                          className={sizeSelects}
                          name={"time_plunger_lift"}
                          placeholder={"Selected option"}
                          value={timePlungerLift || ""}
                          onChange={(value) =>
                            handleOptionChange("time_plunger_lift", value)
                          }
                          disabled={
                            !switchPlungerLift ||
                            (dataCalcInterpolation &&
                              dataCalcInterpolation?.statusType ===
                                STATUS_TYPE_GET_INTERPOLATION_PLUNGER_LIFT?.ERROR)
                          }
                        >
                          <Option style={{ fontSize: "12px" }} value="">
                            Selected option
                          </Option>
                          <Option value={900000}>15 minutes</Option>
                          <Option value={1800000}>30 minutes</Option>
                          <Option value={3600000}>1 hour</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  {dataCalcInterpolation &&
                  dataCalcInterpolation?.statusType ===
                    STATUS_TYPE_GET_INTERPOLATION_PLUNGER_LIFT?.ERROR ? (
                    <ErrorPage />
                  ) : (
                    <ChartPlungerL
                      dataCasingChart={dataCalcCasing?.data || []}
                      dataPressChart={dataCalcPressure?.data || []}
                      dataInterpolation={dataCalcInterpolation?.data?.[0] || []}
                    ></ChartPlungerL>
                  )}
                </div>
                <div className={contenedorProductionHistory}>
                  <div className={containerPrincipal}>
                    <div className={containerTitleGraph}>
                      <h1 className={tittle}>{"Production History"}</h1>
                      <div className={containerButonsExpand}>
                        <AntdTooltip
                          placement="bottom"
                          title="RESET DOMAIN GRAPH"
                          color="#662D91"
                        >
                          <ArrowIcon
                            className="w-6 h-6 hover:bg-pc-grey3 ml-[10px]"
                            onClick={() =>
                              handleResetLimits(1, "chartProdHistory")
                            }
                          />
                        </AntdTooltip>
                        <AntdTooltip
                          placement="bottom"
                          title="ZOOM GRAPH"
                          color="#662D91"
                        >
                          <ExpandIcon
                            className="w-5 h-5 cursor-pointer hover:bg-pc-grey3"
                            onClick={() => handleOpenModal(3)}
                          />
                        </AntdTooltip>
                      </div>
                    </div>

                    <div className={containerSelects}>
                      <div>
                        <label htmlFor="chartType" className={subtitle}>
                          Chart Type:
                        </label>
                        <Select
                          id="chartType"
                          value={selectedOption}
                          onChange={(value) =>
                            handleOptionChange("chartType", value)
                          }
                          className={sizeSelects}
                        >
                          <Option value="Line">Line</Option>
                          <Option value="Bar">Bar</Option>
                        </Select>
                      </div>
                      <div>
                        <Select
                          className={sizeSelects}
                          name={"device_sensor"}
                          value={
                            deviceRealTime.device_plungerlift
                              .selected_device_graph.chartProdHistory ||
                            "Selected option"
                          }
                          onChange={(value) =>
                            handleOptionChange("device_sensor", value)
                          }
                        >
                          <Option value="smi_27_ip">SMI-27 IP Separator</Option>
                          <Option value="smi_27_lp">SMI-27 LP Separator</Option>
                        </Select>
                      </div>
                    </div>
                    <div className={containerData}>
                      <div className={containerCheck}>
                        <input
                          className={`${sizeCheckbox} ${
                            !checkTypeGraph.plunger_lift.production_history
                              ? selectedInput
                              : unselectedInput
                          }`}
                          type="checkbox"
                          onChange={(event) =>
                            handleTypeGraph(event, "production_history")
                          }
                        />
                        <label htmlFor="" className={sizeTextCheckbox}>
                          {" "}
                          Historical Data + RT avg{" "}
                        </label>
                      </div>
                      <div className={containerCheck}>
                        <input
                          className={`${sizeCheckbox} ${
                            checkTypeGraph.plunger_lift.production_history
                              ? selectedInput
                              : unselectedInput
                          }`}
                          type="checkbox"
                          onChange={(event) =>
                            handleTypeGraph(event, "production_history")
                          }
                        />
                        <label htmlFor="" className={sizeTextCheckbox}>
                          Real Time Data
                        </label>
                      </div>
                    </div>
                  </div>
                  <ChartProdHistory
                    title={"Production History"}
                    dataIOT={[]}
                    dataHistory={processedData?.data}
                    dataDomainGraph={domainGraph}
                    typeGraph={selectedOption}
                    statusGraph={checkTypeGraph.plunger_lift.production_history}
                  ></ChartProdHistory>
                </div>
              </Carrusel>
            </div>
            <div className={contedorPrincipal}>
              <AlertModal
                showAlert={showAlert}
                handleCloseModal={handleCloseModalAlert}
                messageAlert={messageAlert}
                typeModal={typeAlert}
              ></AlertModal>
              <Modal
                title={`${
                  selectedCardId === 1
                    ? "Wellhead production conditions"
                    : selectedCardId === 2
                    ? "Plunger Lift"
                    : selectedCardId === 3
                    ? "Production History"
                    : ""
                } `}
                centered
                open={isOpen}
                onOk={handleCloseModalGraph}
                onCancel={() => setOpen(false)}
                okButtonProps={{
                  className: "bg-[#662D91]",
                }}
                width="90%"
                style={{
                  marginLeft: "80px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <div /* className="h-[100%] " */>
                  {selectedCardId === 1 && (
                    <div className={contenedorGraph1WellHeadProducion}>
                      <div className={containerPrincipal}>
                        <div className={containerSelectsWellProd}>
                          <div>
                            <label
                              htmlFor="chartType"
                              className={subtitleWellProd}
                            >
                              Gas Rate
                            </label>
                            <Select
                              className={sizeSelects}
                              name={"gas_rate"}
                              placeholder={"Selected option"}
                              value={
                                deviceRealTime.device_plungerlift
                                  .selected_device_graph.chartWellCond
                                  .gas_rate || "Selected option"
                              }
                              onChange={(value) =>
                                handleOptionChange("gas_rate", value)
                              }
                            >
                              <Option value="">Selected option</Option>
                              <Option value="gas_rate_1">Gas rate 1</Option>
                              <Option value="gas_rate_2">Gas rate 2</Option>
                            </Select>
                          </div>
                          <div>
                            <label
                              htmlFor="chartType"
                              className={subtitleWellProd}
                            >
                              Casing Pressure
                            </label>
                            <Select
                              className={sizeSelects}
                              name={"casing_pressure"}
                              placeholder={"Selected option"}
                              value={
                                deviceRealTime.device_plungerlift
                                  .selected_device_graph.chartWellCond
                                  .casing_pressure || "Selected option"
                              }
                              onChange={(value) =>
                                handleOptionChange("casing_pressure", value)
                              }
                            >
                              <Option value="">Selected option</Option>
                              <Option value="casing_pressure_1">
                                Casing Pressure 1
                              </Option>
                              <Option value="casing_pressure_2">
                                Casing Pressure 2
                              </Option>
                            </Select>
                          </div>
                          <div>
                            <label
                              htmlFor="chartType"
                              className={subtitleWellProd}
                            >
                              Tubing Pressure
                            </label>
                            <Select
                              className={sizeSelects}
                              name={"tubing_pressure"}
                              placeholder={"Selected option"}
                              value={
                                deviceRealTime.device_plungerlift
                                  .selected_device_graph.chartWellCond
                                  .tubing_pressure || "Selected option"
                              }
                              onChange={(value) =>
                                handleOptionChange("tubing_pressure", value)
                              }
                            >
                              <Option value="">Selected option</Option>
                              <Option value="tubing_pressure_1">
                                Tubing Pressure 1
                              </Option>
                              <Option value="tubing_pressure_2">
                                Tubing Pressure 2
                              </Option>
                            </Select>
                          </div>
                          <div>
                            <label
                              htmlFor="chartType"
                              className={subtitleWellProd}
                            >
                              Pipeline Pressure
                            </label>
                            <Select
                              className={sizeSelects}
                              name={"pipeline_pressure"}
                              placeholder={"Selected option"}
                              value={
                                deviceRealTime.device_plungerlift
                                  .selected_device_graph.chartWellCond
                                  .pipeline_pressure || "Selected option"
                              }
                              onChange={(value) =>
                                handleOptionChange("pipeline_pressure", value)
                              }
                            >
                              <Option value="">Selected option</Option>
                              <Option value="pipeline_pressure_1">
                                Pipeline Pressure 1
                              </Option>
                              <Option value="pipeline_pressure_2">
                                Pipeline Pressure 2
                              </Option>
                            </Select>
                          </div>
                          <div className={containerBtns}>
                            <AntdTooltip
                              placement="bottom"
                              title="UPLOAD"
                              color="#662D91"
                            >
                              <span>
                                <ImportFileDataHistory
                                  className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple cursor-pointer"
                                  uploadfile={handleUploadFileHistory}
                                ></ImportFileDataHistory>
                              </span>
                            </AntdTooltip>
                            <AntdTooltip
                              placement="bottom"
                              title="DOWNLOAD TEMPLATE"
                              color="#662D91"
                            >
                              <span>
                                <DownloadTemplate
                                  className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple cursor-pointer"
                                  template={
                                    template_params_history_plunger_lift
                                  }
                                  fileName="plunger_lift_history_template.xlsx"
                                />
                              </span>
                            </AntdTooltip>
                          </div>
                          <div className={containerBtns}>
                            <AntdTooltip
                              placement="bottom"
                              title="RESET DOMAIN GRAPH"
                              color="#662D91"
                            >
                              <ArrowIcon
                                className="w-6 h-6 hover:bg-pc-grey3 "
                                onClick={() =>
                                  handleResetLimits(0, "chartWellCond")
                                }
                              />
                            </AntdTooltip>
                          </div>
                        </div>
                        <div className={containerData}>
                          <div className={containerCheck}>
                            <input
                              className={`${sizeCheckbox} ${
                                !checkTypeGraph.plunger_lift.wellhead_production
                                  ? selectedInput
                                  : unselectedInput
                              }`}
                              type="checkbox"
                              onChange={(event) =>
                                handleTypeGraph(event, "wellhead_production")
                              }
                            />
                            <label htmlFor="" className={sizeTextCheckbox}>
                              {" "}
                              Historical Data + RT avg{" "}
                            </label>
                          </div>
                          <div className={containerCheck}>
                            <input
                              className={`${sizeCheckbox} ${
                                checkTypeGraph.plunger_lift.wellhead_production
                                  ? selectedInput
                                  : unselectedInput
                              }`}
                              type="checkbox"
                              onChange={(event) =>
                                handleTypeGraph(event, "wellhead_production")
                              }
                            />
                            <label htmlFor="" className={sizeTextCheckbox}>
                              Real Time Data
                            </label>
                          </div>
                        </div>
                        <div className={containerPrincipalInputDomain}>
                          <div className={containerInputDomain}>
                            <label htmlFor="domainY1" className="text-xs">
                              Press max
                            </label>
                            <input
                              type="number"
                              name="press_max_wpc"
                              className={`${styleInputDomain} ${
                                validateAlertPlungerLift.alertDomain &&
                                (domainGraph.press_max_wpc === "" ||
                                  domainGraph.press_max_wpc === null)
                                  ? "bg-warning-200"
                                  : "bg-white"
                              }`}
                              min="0"
                              style={{
                                border: "solid 1px",
                                WebkitAppearance: "none",
                                textAlign: "center",
                              }}
                              value={domainGraph.press_max_wpc}
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                            />
                          </div>
                          <div className={containerInputDomain}>
                            <label htmlFor="domainY1" className="text-xs">
                              Gas Rate max
                            </label>
                            <input
                              type="number"
                              name="gas_rate_max_wpc"
                              className={`${styleInputDomain} ${
                                validateAlertPlungerLift.alertDomain &&
                                (domainGraph.gas_rate_max_wpc === "" ||
                                  domainGraph.gas_rate_max_wpc === null)
                                  ? "bg-warning-200"
                                  : "bg-white"
                              }`}
                              min="0"
                              style={{
                                border: "solid 1px",
                                WebkitAppearance: "none",
                                textAlign: "center",
                              }}
                              value={domainGraph.gas_rate_max_wpc}
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <ChartWellHead
                        title={"Wellhead Production Conditions"}
                        dataIOT={[]}
                        dataHistory={processedData?.data}
                        dataDomainGraph={domainGraph}
                        statusGraph={
                          checkTypeGraph.plunger_lift.wellhead_production
                        }
                      ></ChartWellHead>
                      <div className={containerPrincipalInputDomain}>
                        <div className={containerInputDomain}>
                          <label htmlFor="domainY1" className="text-xs">
                            Press min
                          </label>
                          <input
                            type="number"
                            name="press_min_wpc"
                            className={`${styleInputDomain} ${
                              validateAlertPlungerLift.alertDomain &&
                              (domainGraph.press_min_wpc === "" ||
                                domainGraph.press_min_wpc === null)
                                ? "bg-warning-200"
                                : "bg-white"
                            }`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.press_min_wpc}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                        <div className={containerInputDomain}>
                          <label htmlFor="domainY1" className="text-xs">
                            Gas Rate min
                          </label>
                          <input
                            type="number"
                            name="gas_rate_min_wpc"
                            className={`${styleInputDomain} ${
                              validateAlertPlungerLift.alertDomain &&
                              (domainGraph.gas_rate_min_wpc === "" ||
                                domainGraph.gas_rate_min_wpc === null)
                                ? "bg-warning-200"
                                : "bg-white"
                            }`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.gas_rate_min_wpc}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedCardId === 2 && (
                    <div className={contenedorVlp}>
                      <div className={containerPrincipal}>
                        <div className={containerSelectsWellProd}>
                          <div>
                            <label
                              htmlFor="chartType"
                              className={subtitleWellProd}
                            >
                              User Run
                            </label>
                            <Switch
                              style={{
                                backgroundColor: switchPlungerLift
                                  ? "#662D91"
                                  : "#707070",
                                borderColor: switchPlungerLift
                                  ? "#662D91"
                                  : "#707070",
                              }}
                              checked={switchPlungerLift}
                              size="small"
                              onChange={handleSwitchPlungerLift}
                            ></Switch>
                          </div>
                          <div>
                            <label
                              htmlFor="chartType"
                              className={subtitleWellProd}
                            >
                              Automatic Run
                            </label>
                            <Select
                              className={sizeSelects}
                              name={"time_plunger_lift"}
                              placeholder={"Selected option"}
                              value={timePlungerLift || ""}
                              onChange={(value) =>
                                handleOptionChange("time_plunger_lift", value)
                              }
                              disabled={!switchPlungerLift}
                            >
                              <Option style={{ fontSize: "12px" }} value="">
                                Selected option
                              </Option>
                              <Option value={9000}>15 minutes</Option>
                              <Option value={18000}>30 minutes</Option>
                              <Option value={36000}>1 hour</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <ChartPlungerL
                        dataCasingChart={dataCalcCasing?.data || []}
                        dataPressChart={dataCalcPressure?.data || []}
                        dataInterpolation={
                          dataCalcInterpolation?.data?.[0] || []
                        }
                      ></ChartPlungerL>
                    </div>
                  )}
                  {selectedCardId === 3 && (
                    <div className={contenedorProductionHistory}>
                      <div className={containerPrincipal}>
                        <div className={containerSelects}>
                          <div>
                            <label htmlFor="chartType" className={subtitle}>
                              Chart Type:
                            </label>
                            <Select
                              id="chartType"
                              value={selectedOption}
                              onChange={(value) =>
                                handleOptionChange("chartType", value)
                              }
                              className={sizeSelects}
                            >
                              <Option value="Line">Line</Option>
                              <Option value="Bar">Bar</Option>
                            </Select>
                          </div>
                          <div>
                            <Select
                              className={sizeSelects}
                              name={"device_sensor"}
                              value={
                                deviceRealTime.device_plungerlift
                                  .selected_device_graph.chartProdHistory ||
                                "Selected option"
                              }
                              onChange={(value) =>
                                handleOptionChange("device_sensor", value)
                              }
                            >
                              <Option value="smi_27_ip">
                                SMI-27 IP Separator
                              </Option>
                              <Option value="smi_27_lp">
                                SMI-27 LP Separator
                              </Option>
                            </Select>
                          </div>
                          <div>
                            <AntdTooltip
                              placement="bottom"
                              title="RESET DOMAIN GRAPH"
                              color="#662D91"
                            >
                              <ArrowIcon
                                className="w-6 h-6 hover:bg-pc-grey3 ml-[10px]"
                                onClick={() =>
                                  handleResetLimits(1, "chartProdHistory")
                                }
                              />
                            </AntdTooltip>
                          </div>
                        </div>
                        <div className={containerData}>
                          <div className={containerCheck}>
                            <input
                              className={`${sizeCheckbox} ${
                                !checkTypeGraph.plunger_lift.production_history
                                  ? selectedInput
                                  : unselectedInput
                              }`}
                              type="checkbox"
                              checked={
                                selectDashboardPL.production_history
                                  .type_graph ===
                                "production_history_historical_rt"
                                  ? true
                                  : false
                              }
                              onChange={(event) =>
                                handleTypeGraph(event, "production_history")
                              }
                            />
                            <label htmlFor="" className={sizeTextCheckbox}>
                              {" "}
                              Historical Data + RT avg{" "}
                            </label>
                          </div>
                          <div className={containerCheck}>
                            <input
                              className={`${sizeCheckbox} ${
                                checkTypeGraph.plunger_lift.production_history
                                  ? selectedInput
                                  : unselectedInput
                              }`}
                              type="checkbox"
                              onChange={(event) =>
                                handleTypeGraph(event, "production_history")
                              }
                            />
                            <label htmlFor="" className={sizeTextCheckbox}>
                              Real Time Data
                            </label>
                          </div>
                        </div>
                        <div className={containerPrincipalInputDomain}>
                          <div className={containerInputDomain}>
                            <label htmlFor="domainY1" className="text-xs">
                              Liquid Rate max
                            </label>
                            <input
                              type="number"
                              name="liquid_rate_max_ph"
                              className={`${styleInputDomain} ${
                                validateAlertPlungerLift.alertDomain &&
                                (domainGraph.liquid_rate_max_ph === "" ||
                                  domainGraph.liquid_rate_max_ph === null)
                                  ? "bg-warning-200"
                                  : "bg-white"
                              }`}
                              min="0"
                              style={{
                                border: "solid 1px",
                                WebkitAppearance: "none",
                                textAlign: "center",
                              }}
                              value={domainGraph.liquid_rate_max_ph}
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                            />
                          </div>
                          <div className={containerInputDomain}>
                            <label htmlFor="domainY1" className="text-xs">
                              Gas Rate max
                            </label>
                            <input
                              type="number"
                              name="gas_rate_max_ph"
                              className={`${styleInputDomain} ${
                                validateAlertPlungerLift.alertDomain &&
                                (domainGraph.gas_rate_max_ph === "" ||
                                  domainGraph.gas_rate_max_ph === null)
                                  ? "bg-warning-200"
                                  : "bg-white"
                              }`}
                              min="0"
                              style={{
                                border: "solid 1px",
                                WebkitAppearance: "none",
                                textAlign: "center",
                              }}
                              value={domainGraph.gas_rate_max_ph}
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <ChartProdHistory
                        title={"Production History"}
                        dataIOT={[]}
                        dataHistory={processedData?.data}
                        dataDomainGraph={domainGraph}
                        typeGraph={selectedOption}
                        statusGraph={
                          checkTypeGraph.plunger_lift.production_history
                        }
                      ></ChartProdHistory>
                      <div className={containerPrincipalInputDomain}>
                        <div className={containerInputDomain}>
                          <label htmlFor="domainY1" className="text-xs">
                            Liquid Rate min
                          </label>
                          <input
                            type="number"
                            name="liquid_rate_min_ph"
                            className={`${styleInputDomain} ${
                              validateAlertPlungerLift.alertDomain &&
                              (domainGraph.liquid_rate_min_ph === "" ||
                                domainGraph.liquid_rate_min_ph === null)
                                ? "bg-warning-200"
                                : "bg-white"
                            }`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.liquid_rate_min_ph}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                        <div className={containerInputDomain}>
                          <label htmlFor="domainY1" className="text-xs">
                            Gas Rate min
                          </label>
                          <input
                            type="number"
                            name="gas_rate_min_ph"
                            className={`${styleInputDomain} ${
                              validateAlertPlungerLift.alertDomain &&
                              (domainGraph.gas_rate_min_ph === "" ||
                                domainGraph.gas_rate_min_ph === null)
                                ? "bg-warning-200"
                                : "bg-white"
                            }`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.gas_rate_min_ph}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Modal>
            </div>
          </div>
        )
      }
    </div>
  );
};
