import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { COLORS_PIE_STAGE } from '../../../elements/types/type_screening_tool';

export const ChartPieStage = ({dataRank, rank, typeRank}) => {

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if(dataRank?.data){
      const intervals = dataRank?.[rank] ? dataRank[rank][typeRank]?.intervals : [];
      const valuesRank = Object.entries(intervals)
      ?.filter(([key]) => key.startsWith('wells_Interval_'))
      .map(([key, value]) => {
        const intervalNumber = key.split('_').pop();
        const intervalKey = `Interval_${intervalNumber}`;
        const interval = intervals[intervalKey];
        const message = intervals[`message${intervalNumber}`]
        return {
          name: key,
          value: value,
          interval: interval,
          message: message
        };
      });
      
      setData(valuesRank);
      
      const totalValue = valuesRank.reduce((sum, item) => sum + item.value, 0);
      setTotal(totalValue);
    }
  }, [rank, dataRank, typeRank]);

  return (
    <div className='flex w-full h-full'>
      <div className="w-1/2 h-full">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={100} height={50}>
            <Pie
              data={data}
              cx="60%"
              cy="50%"
              outerRadius={110}
              fill="#8884d8"
              dataKey="value"
            >
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS_PIE_STAGE[index % COLORS_PIE_STAGE.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-1/2 flex flex-col items-end justify-center pr-[10px]">
        {data?.map((entry, index) => {
          const percentage = Math.round((entry.value / total) * 100);
          
          return (
            <div key={`label-${index}`} className="text-[14px] mb-4 text-right">
              <div style={{ color: COLORS_PIE_STAGE[index % COLORS_PIE_STAGE.length], fontWeight: 'bold' }}>
                Interval: {entry.interval}
              </div>
              <div>Wells: {entry.value} ({percentage}%)</div>
              <div>{entry.message}</div>
            </div>
          );
        })}
      </div>
    </div>
  )
}
