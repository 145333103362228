import { serviceEndPoints } from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_FLOW = serviceEndPoints.FLOW.BASE_URL_FLOW + "/outflow_inflow";

export const getDataFlow = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_FLOW}/calculate_outflow_inflow/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem in data tuning");
    }
};

export const getDataFlowGraph = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_FLOW}/grouped_inflow_and_outflow_information/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem in data tuning");
    }
};

export const postDataFlow = async (dataJson) => {
    const { operator, lease, region, county, field, well, username, data } = dataJson;
    if (!validateParams(operator, lease, region, county, field, well, username)) return;

    try {
        const response = await fetch(`${BASE_URL_FLOW}/upload_json_outflow_inflow/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
            body: JSON.stringify(data)
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting data");
    }
};

export const getDataPerformanceFlow = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_FLOW}/calculate_performance_flow/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem in data tuning");
    }
};

export const postDataPerformanceFlow = async (dataJson) => {
    const { operator, lease, region, county, field, well, username, data } = dataJson;
    if (!validateParams(operator, lease, region, county, field, well, username)) return;

    try {
        const response = await fetch(`${BASE_URL_FLOW}/upload_json_performance_flow/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm"
            },
            body: JSON.stringify(data)
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting data");
    }
};
