//Validar que la data cumpla con los keys de la tipificacion
export function AddKeysMissing(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  keys2.forEach((key) => {
    if (!keys1.includes(key)) {
      obj1[key] = obj2[key];
    }
  });
  // keys1.forEach((key) => {
  //   if (!keys2.includes(key)) {
  //     delete obj1[key];
  //   }
  // });
  return obj1;
}

export function AddKeysMissingDomain(baseObj, referenceObj) {
  const result = {};
  // Iterar sobre las claves del segundo objeto (referenceObj)
  for (const key in referenceObj) {
    if (key in baseObj) {
      // Si el key existe en el primero, conservar su valor
      result[key] = baseObj[key];
    } else {
      // Si falta en el primero, inicializarlo con null
      result[key] = null;
    }
  }
  // Devolver el objeto sincronizado
  return result;
}

export function validateJsonEmptyAlert(data,data2) {
  const { valuesCheck } = data;
  const validationObject = data2; // Segundo objeto
  // 1. Buscar claves que estén null, undefined o vacías en validationObject
  const invalidKeys = Object.keys(validationObject).filter((key) => {
    const isMinOrMaxKey = key.endsWith("_min") || key.endsWith("_max");
    const value = validationObject[key];
    // Incluir solo claves relacionadas con "_min" o "_max" y que tengan valores inválidos
    return isMinOrMaxKey && (value === null || value === undefined || value === "");
  });
  // 2. Filtrar claves por valuesCheck (solo incluir si están en `true`)
  const result = invalidKeys
    .map((key) => key.replace(/_min$|_max$/, "")) // Extraer el nombre base sin _min/_max
    .filter((baseKey) => valuesCheck[baseKey] === true); // Filtrar si el valor en valuesCheck es `true`
  return result;
}
