import React, { useContext, useRef } from "react";
import { message, Modal } from "antd";
import { read, utils } from "xlsx";
import { ReactComponent as IconUpload } from "../../../assets/img/icons/upload.svg";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { REQUIRED_COLUMNS_DOCUMENT_HISTORY } from "../../elements/types/type_plunger_Lift";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";

const { confirm } = Modal;

const ImportFileDataHistory = ({ uploadfile }) => {
  const fileInputRef = useRef(null);
  const { selectedWellPL, userDataInfo } = useContext(DashboardContext);

  const handleImport = (event) => {
      const files = event.target.files;
      const dataFile = event.target.files[0];

      confirm({
        title: "Do you want to upload production history data?",
        icon: <ExclamationCircleFilled />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          if (files) {
            const file = files[0];
            //validation type file
            const validExtensions = ['.xls', '.xlsx', '.csv'];
            const fileType = file.name.split('.').pop();
            const mimeType = file.type;

            if (
              (validExtensions.includes(`.${fileType}`)) || 
              (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
              mimeType === 'application/vnd.ms-excel')
            ) {
              const reader = new FileReader();
  
              reader.onload = (e) => {
                const wb = read(e.target.result);
                const sheetName = wb.SheetNames[0];
                const sheets = wb.Sheets[sheetName];
                if (sheets) {
                  const jsonData = utils.sheet_to_json(sheets, { header: 1 });
                  const fileColumns = jsonData[0] || [];

                  const hasAllRequiredColumns = REQUIRED_COLUMNS_DOCUMENT_HISTORY.every(column => fileColumns.includes(column));

                  if (!hasAllRequiredColumns) {
                    message.error("The uploaded file is missing one or more required columns.");
                    uploadfile(null);
                  } else {
                    const data = utils.sheet_to_json(sheets);
                    uploadfile(file);
                    message.success("Upload successfully");
                  }
                }
              };
              reader.readAsArrayBuffer(file);
            }else{
              message.info('Please upload a valid Excel file');
            }
            event.target.value = '';
          }
        },
        onCancel() {
          console.log("Cancel");
          event.target.value = '';
        },
      });
  };

  const handleImageClick = () => {

    if (!!selectedWellPL && selectedWellPL !== "null") {
      fileInputRef.current.click();
    }else{
      const message = ( !userDataInfo?.lease || !userDataInfo?.operator || !userDataInfo?.region  ? "Please select a filter in the navigation bar" : !selectedWellPL || selectedWellPL === "null" ? "Please select a well to add historical information" : "");
      Modal.info({
        title: "Warning",
        icon: <ExclamationCircleFilled/>,
        okText: "OK",
        okType: "danger",
        content: (<p className="text-[15px]">{message}</p>),
      });
    }
  };
  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        name="file"
        id="inputGroupFile"
        onChange={handleImport}
        style={{ display: "none" }}
      />
      <label htmlFor="fileInput">
        <IconUpload
          size={32}
          className="flex w-4 h-4 bg-opacity-50 cursor-pointer fill-neutral-500 hover:fill-pa-purple"
          onClick={handleImageClick}
        />
      </label>
    </div>
  );
};

export default ImportFileDataHistory;
