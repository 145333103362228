// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select-multiple-devices .ant-select-selection-overflow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
}

.custom-select-multiple-parameters .ant-select-selection-overflow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
}

.custom-select-multiple.has-items .ant-select-selection-overflow {
  max-height: 47px;
  overflow-y: auto;
}

.custom-select-multiple .ant-select-selection-overflow-item {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-align: center;
  padding: 1px;
  box-sizing: border-box;
}

.fetch-button {
  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: none;
  cursor: pointer;
}

.fetch-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/TabPlotting/plotting.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,QAAQ;AACV;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,4DAA4D;EAC5D,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,qDAAqD;EACrD,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,2CAA2C;AAC7C","sourcesContent":[".custom-select-multiple-devices .ant-select-selection-overflow {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 1px;\n}\n\n.custom-select-multiple-parameters .ant-select-selection-overflow {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 1px;\n}\n\n.custom-select-multiple.has-items .ant-select-selection-overflow {\n  max-height: 47px;\n  overflow-y: auto;\n}\n\n.custom-select-multiple .ant-select-selection-overflow-item {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  white-space: normal;\n  overflow: hidden;\n  text-align: center;\n  padding: 1px;\n  box-sizing: border-box;\n}\n\n.fetch-button {\n  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);\n  color: white;\n  padding: 8px 16px;\n  border-radius: 4px;\n  font-size: 14px;\n  font-weight: bold;\n  transition: transform 0.2s ease, box-shadow 0.2s ease;\n  border: none;\n  cursor: pointer;\n}\n\n.fetch-button:hover {\n  transform: translateY(-2px);\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
