import React, { useContext} from 'react';
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx"
import { message, Switch } from 'antd';
import { DashboardPLContext } from '../../../context/DashboardPlungerLift/DashboardPLContext';

export const KpiGraph = ({dataKpi, nameValue, val_max, val_min, stateCheck, stateColor}) => {

    const {dataAlertPlungerLift, getDataAlertPlungerLift, getSomeAlert, someAlert, selectedWellPL} = useContext(DashboardContext);
   const {validateAlertPlungerLift} = useContext(DashboardPLContext)
    const data = dataKpi
    const valueAlertMin = !val_min ? "" : val_min;
    const valueAlertMax = !val_max ? "" : val_max;

    const handleChangeInput = (e) => {
      const {name, value} = e.target;
      getDataAlertPlungerLift ({
        ...dataAlertPlungerLift,
        [name]: value !== "" ? Number(value) : null,
      })
      getSomeAlert({...someAlert, messageChange: true})
    }
    
    const handleBlurInput = (e) => {
      const {name, value} = e.target;
      if((name === `${nameValue}_min` && Number(value) > dataAlertPlungerLift[`${nameValue}_max`]) || (name === `${nameValue}_max` && Number(value) < dataAlertPlungerLift[`${nameValue}_min`])){
        message.warning("The minimum value must be less than the maximum value")
        if(dataAlertPlungerLift[`${nameValue}_max`]){
          getDataAlertPlungerLift ({
            ...dataAlertPlungerLift,
            [name]: null,
          })
        }
      }
    }
    
    const handleCheckAlert = (event, name) => {
      
      if (name === nameValue && someAlert.plunger_lift.valuesCheck[nameValue] === true){
          getSomeAlert({
            ...someAlert,
            messageChange: true,
            plunger_lift: {
              ...someAlert.plunger_lift,
              valuesAlert: {
                ...someAlert.plunger_lift.valuesAlert, 
                [name]: stateColor ? true : false},
              valuesCheck: {
                ...someAlert.plunger_lift.valuesCheck,
                [name]: false},
              countAlert: someAlert.plunger_lift.valuesAlert[nameValue] ? someAlert.plunger_lift.countAlert - 1 : someAlert.plunger_lift.countAlert,
              countCheck: someAlert.plunger_lift.valuesAlert[nameValue] ? someAlert.plunger_lift.countCheck + 1 : someAlert.plunger_lift.countCheck
          }
        })
      } else if (name === nameValue && someAlert.plunger_lift.valuesCheck[nameValue] === false) {
        getSomeAlert({
          ...someAlert,
          messageChange: true,
          plunger_lift: {
            ...someAlert.plunger_lift,
            valuesCheck: {...someAlert.plunger_lift.valuesCheck,[name]: true},
            countAlert: someAlert.plunger_lift.valuesAlert[nameValue] ? someAlert.plunger_lift.countAlert + 1 : someAlert.plunger_lift.countAlert,
            countCheck: someAlert.plunger_lift.valuesAlert[nameValue] ? someAlert.plunger_lift.countCheck - 1 : someAlert.plunger_lift.countCheck
          }
        })
      }
    };

    return (
      <>
        { data &&
          <div className='flex flex-row w-full h-full'>
            <input 
              type="number" 
              name={`${nameValue}_min`}
              onChange={handleChangeInput}
              onBlur={handleBlurInput}
              value={valueAlertMin} 
              className={`mr-1 flex w-full h-[20px] text-right items-start justify-start text-[11px]  ${validateAlertPlungerLift.alertCard && (valueAlertMin === null || valueAlertMin === "") && someAlert.plunger_lift.valuesCheck[nameValue] ? 'bg-warning-200' : 'bg-pc-grey4'}`}
              disabled={stateCheck === null || !selectedWellPL || selectedWellPL === "null"? true : false}
            />
            <p className='flex h-[20px] items-start justify-start text-[14px]'>-</p>
            <input 
              type="number" 
              name={`${nameValue}_max`} 
              onChange={handleChangeInput}
              onBlur={handleBlurInput}
              value={valueAlertMax} 
              className={`ml-1 flex w-full h-[20px] text-left items-start justify-start text-[11px] ${validateAlertPlungerLift.alertCard && (valueAlertMax === null || valueAlertMax === "" ) && someAlert.plunger_lift.valuesCheck[nameValue] ? 'bg-warning-200' : 'bg-pc-grey4'}`}
              disabled={stateCheck === null || !selectedWellPL || selectedWellPL === "null"? true : false}
            />
            <Switch
              style={{
              backgroundColor: someAlert?.plunger_lift?.valuesCheck[nameValue] ? "#662D91" : "#707070",
              borderColor: someAlert.plunger_lift?.valuesCheck[nameValue] ? "#662D91" : "#707070",
              }}
              checked={someAlert?.plunger_lift?.valuesCheck[nameValue]}
              onChange={(event) => handleCheckAlert(event, nameValue)}
              size="small"
              disabled={stateCheck === null || !selectedWellPL || selectedWellPL === "null"? true : false}
            ></Switch>
          </div>
        }
    </>
    )
}
