import React, { useContext, useEffect, useRef, useState } from 'react';
import { ResponsiveBullet } from '@nivo/bullet';
import { DashboardContext } from '../../../context/Dashboard/DashboardContentx';
import { message, Switch } from 'antd';
import { DashboardDCAContext } from '../../../context/DasboardDCA/DashboardDCAContext';


export const KpiGraph = ({dataKpi, nameValue, val_max, val_min, stateCheck, stateColor}) => {
    
    const {dataAlertDCA, getDataAlertDCA, getSomeAlert, someAlert, selectedWellDCA} = useContext(DashboardContext);
    const {validateAlertDCA} = useContext(DashboardDCAContext)
    const data = dataKpi

    const valueAlertMin = !val_min ? "" : val_min;
    const valueAlertMax = !val_max ? "" : val_max;
    
    const handleChangeInput = (e) => {
        const {name, value} = e.target;
        getDataAlertDCA({
          ...dataAlertDCA,
          [name]: value !== "" ? Number(value) : null,
          });
        getSomeAlert({...someAlert, messageChange: true});
    }

    const handleBlurInput = (e) => {
      const {name, value} = e.target;
      if((name === `${nameValue}_min` && Number(value) > dataAlertDCA[`${nameValue}_max`]) || (name === `${nameValue}_max` && Number(value) < dataAlertDCA[`${nameValue}_min`])){
        message.warning("The minimum value must be less than the maximum value")
        if(dataAlertDCA[`${nameValue}_max`]){
          getDataAlertDCA ({
            ...dataAlertDCA,
            [name]: null,
          })
        }
      }
    }
    
    const handleCheckAlert = (event, name) => {

      if (name === nameValue && someAlert.forecasting.valuesCheck[nameValue] === true){
        getSomeAlert({
          ...someAlert,
          messageChange: true,
          forecasting: {
            ...someAlert.forecasting,
            valuesAlert: {
              ...someAlert.forecasting.valuesAlert, 
              [name]: stateColor ? true : false},
            valuesCheck: {
              ...someAlert.forecasting.valuesCheck,
              [name]: false},
            countAlert: someAlert.forecasting.valuesAlert[nameValue] ? someAlert.forecasting.countAlert - 1 : someAlert.forecasting.countAlert,
            countCheck: someAlert.forecasting.valuesAlert[nameValue] ? someAlert.forecasting.countCheck + 1 : someAlert.forecasting.countCheck
          }
        })
      } else {
        getSomeAlert({
          ...someAlert,
          messageChange: true, 
          forecasting: {
            ...someAlert.forecasting,
            valuesCheck: {...someAlert.forecasting.valuesCheck,[name]: true},
            countAlert: someAlert.forecasting.valuesAlert[nameValue] ? someAlert.forecasting.countAlert + 1 : someAlert.forecasting.countAlert,
            countCheck: someAlert.forecasting.valuesAlert[nameValue] ? someAlert.forecasting.countCheck - 1 : someAlert.forecasting.countCheck
          }
        })
      }
    };

    return (
        <>
          { data &&
            <div className='w-full h-full flex flex-row'>
              <input 
                type="number"
                name={`${nameValue}_min`} 
                onChange={handleChangeInput} 
                onBlur={handleBlurInput}
                value={valueAlertMin} 
                className={`mr-1 flex w-full h-[20px] text-right items-start justify-start text-[11px] ${validateAlertDCA.alertCard && (valueAlertMin === null || valueAlertMin === "") && someAlert.forecasting.valuesCheck[nameValue] ? 'bg-warning-200' : 'bg-pc-grey4'}`}
                disabled={stateCheck === null || !selectedWellDCA || selectedWellDCA === "null" ? true : false}/>
              <p className='flex h-[20px] items-start justify-start text-[14px]'>-</p>
              <input 
                type="number" 
                name={`${nameValue}_max`} 
                onChange={handleChangeInput} 
                onBlur={handleBlurInput}
                value={valueAlertMax} 
                className={`ml-1 flex w-full h-[20px] text-left items-start justify-start text-[11px] ${validateAlertDCA.alertCard && (valueAlertMax === null || valueAlertMax === "") && someAlert.forecasting.valuesCheck[nameValue] ? 'bg-warning-200' : 'bg-pc-grey4'}`}
                disabled={stateCheck === null || !selectedWellDCA || selectedWellDCA === "null" ? true : false}/>
              <Switch
                style={{
                backgroundColor: someAlert?.forecasting.valuesCheck[nameValue] ? "#662D91" : "#707070",
                borderColor: someAlert?.forecasting.valuesCheck[nameValue] ? "#662D91" : "#707070",
                }}
                checked={someAlert?.forecasting.valuesCheck[nameValue]}
                onChange={(event) => handleCheckAlert(event, nameValue)}
                size='small'
                disabled={stateCheck === null || !selectedWellDCA || selectedWellDCA === "null" ? true : false}
              ></Switch>
            </div>
          }
        </>
    )
}
