import { serviceEndPoints } from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_SCREENING = serviceEndPoints.SCREENING.URL_SCREENING;

export const getScreeningRank = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/get_rank_stats/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem in data screening");
    }
};

export const getScreeningDonut = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_donut_rank/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem in data screening");
    }
};

export const getScreeningDonutDepth = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_donut_without_depth/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem in data screening");
    }
};

export const getScreening3DRank = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_3d_rank/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return response.text();
    } catch (error) {
        throw new Error("Problem in data screening");
    }
};

export const getScreening3DRankDepth = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_3d_rank_depth/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return response.text();
    } catch (error) {
        throw new Error("Problem in data screening");
    }
};

export const getMultipleScatter = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/plot_multiple_scatter/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem in data multiple scatter");
    }
};

export const getListReservoir = async (operator, lease, region, username) => {
    if (!validateParams(operator, lease, region, username)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/metadata/${operator}/${lease}/${region}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem in data screening");
    }
};

export const uploadScreening = async (dataJson) => {
    const { operator, lease, region, county, field, reservoir: well, userName: username, data } = dataJson;
    if (!validateParams(operator, lease, region, county, field, well, username, data)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool_input/upload_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga",
            },
            body: JSON.stringify(data),
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem uploading screening data");
    }
};

export const putScreening = async (dataJson) => {
    const { operator, lease, region, county, field, reservoir: well, userName: username, data } = dataJson;
    if (!validateParams(operator, lease, region, county, field, well, username, data)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool_update/update_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga",
            },
            body: JSON.stringify(data),
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem updating screening data");
    }
};

export const deleteScreening = async (dataJson) => {
    const { operator, lease, region, county, field, reservoir: well, userName: username } = dataJson;
    if (!validateParams(operator, lease, region, county, field, well, username)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool_deleted/delete_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
            method: "DELETE",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga",
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem deleting screening data");
    }
};

export const getFileScreening = async (operator, lease, region, county, field, wellid, username) => {
    if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

    try {
        const response = await fetch(`${BASE_URL_SCREENING}/screening_tool/data_for_rank/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga",
            },
        });
        return response.text();
    } catch (error) {
        throw new Error("Problem in data screening");
    }
};

