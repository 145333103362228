import { serviceEndPoints } from "../../config/config";
import { validateParams } from "../utils/utils";

const DB_URL_LIST = serviceEndPoints.MAP.DB_URL_LIST_MAP;
const DB_URL_ALERTS = serviceEndPoints.MAP.DB_URL_ALERT_MAP;

export const getListDCA = async (operator, lease, region, userName) => {
  if (!validateParams(operator, lease, region, userName)) return;

  try {
    const response = await fetch(`${DB_URL_LIST}/parameterslist/${operator}/${lease}/${region}/${userName}/parametersdca`, {
      method: "GET",
      headers: {
        "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting the DCA list");
  }
};

export const getListGasLift = async (operator, lease, region, username) => {
  if (!validateParams(operator, lease, region, username)) return;

  try {
    const response = await fetch(`${DB_URL_LIST}/parameterslist/${operator}/${lease}/${region}/${username}/parametersgaslift`, {
      method: "GET",
      headers: {
        "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting the Gas Lift list");
  }
};

export const getAlerts = async (typeProcess, operator, lease, region, county, field, user) => {
  if (!validateParams(typeProcess, operator, lease, region, county, field, user)) return;

  try {
    const response = await fetch(`${DB_URL_ALERTS}/alerts/${typeProcess}/${operator}/${lease}/${region}/${county}/${field}/${user}`, {
      method: "GET",
      headers: {
        "Authorization": "Token vD0m0TRqWs1gG9KWX8CVoOihAuH2jxUqSbX3mSK5S",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting alerts");
  }
};
