import React, { useEffect, useState } from "react";
import styleChartWellHead from "./ChartWellHead.module.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { DATA_STANDARD_RT } from "../../../elements/types/type_standard_parameters";
const {
  containerPrincipal,
  containerLegend,
  styleRefenence1,
  styleRefenence2,
  styleRefenence3,
  styleRefenence4,
  sizeReference,
  separatorGraph,
} = styleChartWellHead;

const formatNumber = (value) => {
  // 2 decimal numbers format, except integers
  if (!isNaN(value) && value !== "") {
    const num = parseFloat(value);
    return num % 1 === 0 ? num : num.toFixed(2);
  }
  return value;
};
function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          padding: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderColor: "#662D91",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        
        <p style={{ color: "#ff6347", fontSize: "11px" }}>
          {`Casing Pressure: ` + (data.pressure_casing.toString())+ ` (psia)`}
        </p>
        <p style={{ color: "#00bfff", fontSize: "11px" }}>
          {`Tubing Pressure: ` + (data.pressure_tubing.toString())+ ` (psia)`}
        </p>
        <p style={{ color: "#ffd700", fontSize: "11px" }}>
          {`Pipeline Pressure: ` + (data.pressure_pipeline.toString()) + ` (psia)`}
        </p>
  
      </div>
    );
  }
  return null;
}
function CustomTooltip2({ active, payload }) {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          padding: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderColor: "#662D91",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        
        <p style={{ color: "#FF0000", fontSize: "11px" }}>
          {`Gas Rate: ` +formatNumber(data?.gas_rate) + ` (Mscf/D)`}
        </p>
        
  
      </div>
    );
  }
  return null;
}
const ChartWellHead = ({ dataIOT, dataHistory, dataDomainGraph, statusGraph }) => {
  const pressureMax = dataDomainGraph?.press_max_wpc;
  const pressureMin = dataDomainGraph?.press_min_wpc;
  const gasRateMax = dataDomainGraph?.gas_rate_max_wpc;
  const gasRateMin = dataDomainGraph?.gas_rate_min_wpc;
  const [yAxisDomain1, setYAxisDomain1] = useState([gasRateMin, gasRateMax]);
  const [yAxisDomain2, setYAxisDomain2] = useState([pressureMin, pressureMax]);
  const [dataFinalGraph1, setDataFinalGraph1] = useState([]);
  const [dataFinalGraph2, setDataFinalGraph2] = useState([]);
  useEffect(() => {
    setYAxisDomain1([gasRateMin, gasRateMax]);
    setYAxisDomain2([pressureMin, pressureMax]);
  }, [pressureMax, gasRateMax, pressureMin, gasRateMin])
  useEffect(()=>{
    const formattedData = dataIOT?.map((item, index) => ({
      "date": String(item[DATA_STANDARD_RT.GENERAL.PARAMETER_000]).split(" ")[0],
      'pressure_tubing': parseFloat(item[DATA_STANDARD_RT.GENERAL.PARAMETER_001]),
      "pressure_casing": parseFloat(item[DATA_STANDARD_RT.PLUNGER_LIFT.PARAMETER_015]),
      "pressure_pipeline": parseFloat(item[DATA_STANDARD_RT.PLUNGER_LIFT.PARAMETER_021]),
      "pressure_pipelgas_rateine": parseFloat(item[DATA_STANDARD_RT.GENERAL.PARAMETER_004]),
    })) || [];
  
    if(dataHistory && !statusGraph){
      const dataHistoryMod1 = dataHistory?.dataset1?.map((item, index) => ({
        "date": String(item["date"]),
        "gas_rate": typeof item["gas_rate"] === "number" 
          ? item["gas_rate"].toFixed(4) 
          : null,
      })).filter(item => 
        item["gas_rate"] !== null 
      );

      const dataHistoryMod2 = dataHistory?.dataset2?.map((item, index) => ({
        "date": String(item["date"]),
        "pressure_casing": typeof item["Casing_Pressure"] === "number" 
          ? parseFloat(item["Casing_Pressure"].toFixed(4)) 
          : null,
        "pressure_tubing": typeof item["Tubing_Pressure"] === "number" 
          ? parseFloat(item["Tubing_Pressure"].toFixed(4)) 
          : null,
        "pressure_pipeline": typeof item["Pipeline_Pressure"] === "number" 
          ? parseFloat(item["Pipeline_Pressure"].toFixed(4)) 
          : null,
      })).filter(item => 
        item["Casing_Pressure"] !== null && 
        item["Tubing_Pressure"] !== null && 
        item["Pipeline_Pressure"] !== null 
      );
      
      const dataConcat1 = dataHistoryMod1.concat(formattedData);
      const dataConcat2 = dataHistoryMod2.concat(formattedData);
      setDataFinalGraph1(dataConcat1);
      setDataFinalGraph2(dataConcat2);
    }else if(statusGraph){
      setDataFinalGraph1(formattedData);
      setDataFinalGraph2(formattedData);
    }
  },[dataIOT,dataHistory]);

  const maxGraph1 = Math.max(...dataFinalGraph1.map(d => d.gas_rate));
  const minGraph1 = Math.min(...dataFinalGraph1.map(d => d.gas_rate));

  const maxGraph2 = Math.max(...dataFinalGraph2.map(d => d.gas_rate));
  const minGraph2 = Math.min(...dataFinalGraph2.map(d => d.gas_rate));

  return (
    <div className={containerPrincipal}>
      <div style={{ width: "100%", height: "550px" }}>
        <ResponsiveContainer
          width="100%"
          height="30%"
          style={{
            backgroundColor: "#f9f9f9",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <LineChart
            data={dataFinalGraph1}
            margin={{ top: 5, right: 5, bottom: -15, left: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={false} axisLine={{ strokeWidth: 1 }} />
            <YAxis
              yAxisId={1}
              label={{
                value: "Gas Rate (Mscf/D)",
                angle: -90,
                position: "insideLeft",
                offset: 17,
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tick={{
                fontSize: 12,
              }}
              domain={yAxisDomain1}
              allowDecimals={false} 
              allowDataOverflow={true}
              tickFormatter={(value) => Math.round(value)} 
            />
            <Tooltip content={<CustomTooltip2 />} />
            <Line
              type="monotone"
              dataKey="gas_rate"
              stroke="#FF0000"
              name="Gas rate"
              yAxisId={1}
            />
          </LineChart>
        </ResponsiveContainer>
        <div className={separatorGraph}></div>
        <ResponsiveContainer
          width="100%"
          height="70%"
          style={{
            backgroundColor: "#f9f9f9",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <LineChart 
            data={dataFinalGraph2} 
            syncId="syncChart"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              label={{
                value: "Date",
                position: "insideBottom",
                offset: -1,
                style: { fontSize: 12, textAnchor: "middle" },
              }}
              tick={{ fontSize: 12 }}
            />

            <YAxis
              type="number"
              yAxisId={1}
              label={{
                value: "Pressure (psia)",
                angle: -90,
                position: "insideLeft",
                offset: 17,
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tick={{
                fontSize: 12,
              }}
              domain={yAxisDomain2}
              allowDecimals={false} 
              allowDataOverflow={true}
              tickFormatter={(value) => Math.round(value)} 
            />
           <Tooltip content={<CustomTooltip />} />

            <Line
              yAxisId={1}
              type="monotone"
              dataKey="pressure_casing"
              stroke="#ff6347"
              name="pressure_casing"
            />
            <Line
              yAxisId={1}
              type="monotone"
              dataKey="pressure_tubing"
              stroke="#00bfff"
              name="pressure_tubing"
            />
            <Line
              yAxisId={1}
              type="monotone"
              dataKey="pressure_pipeline"
              stroke="#ffd700"
              name=" pressure_pipeline"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className={containerLegend}>
        <span className={sizeReference}>
          <span className={styleRefenence2}>●</span> Gas Rate
        </span>
        <span className={sizeReference}>
          <span className={styleRefenence1}>●</span>Casing Pressure
        </span>
        <span className={sizeReference}>
          <span className={styleRefenence3}>●</span> Pipeline Pressure
        </span>
        <span className={sizeReference}>
          <span className={styleRefenence4}>●</span>Tubing Pressure
        </span>
      </div>
    </div>
  );
};

export default ChartWellHead;
