import React, { useEffect, useState } from 'react';

export const TablePVTCalculatePL = ({dataTable}) => {

  const [dataTablePVT, setDataTablePVT] = useState([]);

  useEffect(() => {
    if(!!dataTable){
      setDataTablePVT(dataTable);
    }else{
      setDataTablePVT([]);
    }

  }, [dataTable]);

  const decimalRound = (number, round) => {
    let valRound = number != undefined && number != null ? Number(number.toFixed(round)) : null;
    return valRound;
  };

 return (
    <div className='flex w-[1220px] h-full overflow-hidden rounded-lg border border-gray-200'>
      <table className="min-w-full text-left text-base font-light">
        <thead className="font-medium">
          <tr>
              <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
                Pressure (psia)
              </th>
              <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
                Gas Z factor (dimensionless)
              </th>
              <th scope="col" className="px-6 py-4 border border-gray-200 text-left">
                Gas density (Ibm/ft3)
              </th>
          </tr>
        </thead>
        <tbody>
          {dataTablePVT && dataTablePVT.map((item, index) => {
            return (
              <tr key={index}>
                <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(item.press, 0)}</td>
                <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(item.z_factor, 3)}</td>
                <td className="border border-gray-200 px-6 py-4 text-left">{decimalRound(item.den_avg, 3)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

