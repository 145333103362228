export const config = {
    PORT_DB : process.env.REACT_APP_PORT_DB,
}

export const serviceEndPoints = {
    DCA : {
        BASE_URL_DCA : process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
        BASE_URL_DATA_HISTORY : process.env.REACT_APP_BASE_URL_DATA_HISTORY,
        BASE_URL_VOLUMETRACKER: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
        BASE_URL_VOLUMETRACKER_RT: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
        BASE_URL_DEVICE: process.env.REACT_APP_BASE_URL_PLOTTING
    },
    FLOW : {
        BASE_URL_FLOW : process.env.REACT_APP_BASE_URL_PROCESS
    },
    FORECAST : {
        FILE_FORECAST: process.env.REACT_APP_FILE_FORECAST,
        RUN_FORECAST: process.env.REACT_APP_RUN_FORECAST,
        OUTPUT_FORECAST: process.env.REACT_APP_OUTPUT_FORECAST
    },
    GASLIFT : {
        BASE_URL_WELL: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
        BASE_URL_DATA_HISTORY: process.env.REACT_APP_BASE_ULR_DATA_HISTORY,
        BASE_URL_VOLUMETRACKER: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
        BASE_URL_VOLUMETRACKER_RT: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
        BASE_URL_DEVICE: process.env.REACT_APP_BASE_URL_PLOTTING
    },
    IOT: {
        BASE_URL_IOT: process.env.REACT_APP_BASE_URL_IOT
    },
    IPR : {
        BASE_URL_IPR: process.env.REACT_APP_BASE_URL_PROCESS
    },
    MAP : {
        DB_URL_LIST_MAP: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
        DB_URL_ALERT_MAP: process.env.REACT_APP_BASE_URL_ALERTS_MAP
    },
    PVT : {
        BASE_URL_PVT: process.env.REACT_APP_BASE_URL_PROCESS
    },
    TUNING : {
        BASE_URL_TUNING: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
        BASE_URL_TUNING_VLP: process.env.REACT_APP_BASE_URL_PROCESS
    },
    USERS: {
        URL_FILTER: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL
    },
    SCREENING: {
        URL_SCREENING: process.env.REACT_APP_BASE_ULR_SCREENING_TOOL,
    },
    PLOTTING: {
        URL_PLOTTING: process.env.REACT_APP_BASE_URL_PLOTTING,
        URL_PARAMETERS_PLOTTING: process.env.REACT_APP_BASE_URL_PARAMETERS_PLOTTING
    },
    PLUNGER_LIFT: {
        URL_PLUNGER_LIFT: process.env.REACT_APP_BASE_URL_CASING_PRESSURE
    }
}

export const keyIntegration = {
    keyEncryptPass : process.env.REACT_APP_KEYENCRYPT_PASS,
    keyEncrypt : process.env.REACT_APP_KEYENCRYPT
}
