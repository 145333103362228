export const ALL_TABLES_HISTORY = { 
    sensitivity_1: [
        {
            period_1 : "df_cp_hist_1000_1"
        },
        {
            period_2 : "df_cp_hist_1000_2"
        },
        {
            period_3 :  "df_cp_hist_1000_3"
        },
        {
            period_4 : "df_cp_hist_1000_4"
        }
    ], 
    sensitivity_2: [
        {
            period_1 : "df_cp_hist_500_1"
        },
        {
            period_2 : "df_cp_hist_500_2"
        },
        {
            period_3 :  "df_cp_hist_500_3"
        },
        {
            period_4: "df_cp_hist_500_4"
        }
    ], 
    sensitivity_3: [
        {
            period_1 : "df_cp_hist_100_1"
        },
        {
            period_2 : "df_cp_hist_100_2"
        },
        {
            period_3 :  "df_cp_hist_100_3"
        },
        {
            period_4 : "df_cp_hist_100_4"
        }
    ], 
}

export const ALL_TABLES_FORECAST = {
    semiCustom: [
    {
        semiCustom_1 : "df_cp_comb_semi_custom"
    },
    {
        semiCustom_2 : "df_cp_summary_semi_custom"
    },
    {
        semiCustom_3 : "df_cp_forecast_semi_custom"
    }
    ],
    fullCustom: [
        {
            fullCustom_1 : "df_cp_comb_full_custom"
        },
        {
            fullCustom_2 : "df_cp_summary_full_custom"
        },
        {
            fullCustom_3 : "df_cp_forecast_full_custom"
        }
    ]
}

export const DATA_CREATE_WELL_DCA = {
  well_id: "",
  well_name: "",
  discretized_well: false,
  well_platforms: "",
  fluid_type: "",
  region: "",
  county: "",
  field: "",
  latitude: ``,
  longitude: ``,
  productForescast: "",
  generateForeGas: "",
  generateForeOil: "",
  generateForeOilWater: "",
  reporting_frequency: "",
  sensitivities: "",
  periods: "",
  standars_deviation: "",
  forecastTime: "",
  production_start_Date: "",
  abandonment_Rate: "",
  ultimate_recoverable: "",
  customScenario: "default",
  startMonth: "",
  initialFluidRate: "",
  start_select: "",
  end_select: "",
  forecastHorizont: "",
  type_graph_dashboard: [],
  data_alert: [],
  data_check: [],
  selected_device_graph: {},
  selected_platform_graph: {},
  volumeTracker: {
    AIDCA: {},
    performance: {},
    production_history: {},
  },
};

  export const WELL_SENSOR = {
    well: [
      {
        name: "SMI-27"
      },
      {
        name: "V-191"
      }
    ]
  }

  export const WELL_SENSOR_TYPE = {
    data: [
      {
        name: "SMI-27",
        sensor: "SMI-27 IP Separator"
      },
      {
        name: "SMI-27",
        sensor: "SMI-27 LP Separator"
      },
      {
        name: "SMI-27",
        sensor: "SMI-27 Sales Gas Meter"
      },
      {
        name: "SMI-27",
        sensor: "SMI-27 Fuel Gas Meter"
      },
      {
        name: "SMI-27",
        sensor: "SMI-27 LACT"
      },
      {
        name: "V-191",
        sensor: "V-191 Test Separator"
      }
    ]
  }

  export const STATE_ALERTS = {
    alert_wc_pressure: "wc_pressure",
    alert_wc_temp: "wc_temp",
    alert_pp_oil_rate: "pp_oil_rate",
    alert_pp_gas_rate: "pp_gas_rate",
    alert_pp_water_rate: "pp_water_rate",
  }

  export const INITIAL_DATA_ALERTS_DCA = {
    wc_pressure_min: 0,
    wc_temp_min: 0,
    bhc_pressure_min: 0,
    bhc_temp_min: 0,
    pp_oil_rate_min: 0,
    pp_gas_rate_min: 0,
    pp_water_cute_min: 0,
    pp_water_rate_min: 0,
    pp_wor_min: 0,
    pp_gor_min: 0,
    alm_injection_min: 0,
    alm_inj_gas_min: 0,
    alm_inj_GLR_min: 0,
    alm_formation_min: 0,
    alm_water_cut_min: 0,
    wc_pressure_max: 0,
    wc_temp_max: 0,
    bhc_pressure_max: 0,
    bhc_temp_max: 0,
    pp_oil_rate_max: 0,
    pp_gas_rate_max: 0,
    pp_water_cute_max: 0,
    pp_water_rate_max: 0,
    pp_wor_max: 0,
    pp_gor_max: 0,
    alm_injection_max: 0,
    alm_inj_gas_max: 0,
    alm_inj_GLR_max: 0,
    alm_formation_max: 0,
    alm_water_cut_max: 0,
};

export const REQUIRED_COLUMNS_DOCUMENT_HISTORY = [
  "date",
  "oil_rate",
  "gas_rate",
  "water_rate",
  "cumulative_oil",
  "cumulative_gas",
  "cumulative_water",
  "gor",
 	"w_cut"
];

export const INITIAL_COORDINATES = {
  latitudeDegrees: '',
  latitudeMinutes: '',
  latitudeSeconds: '',
  latitudeDirection: '',
  longitudeDegrees: '',
  longitudeMinutes: '',
  longitudeSeconds: '',
  longitudeDirection: ''
}

export const MODELS = {
  tableForecast: false,
  periodSelection: false,
}