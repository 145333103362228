export const NAVBAR_TOOLTIP = {
    add_edit_wells: "Add / Edit Wells",
    add_edit_reservoir: "Add / Edit Reservoir",
    settings: "Settings" ,
    save: "Save",
    dashboard: {
        dca: "Dashboard Forecasting",
        gas_lift: "Dashboard Gas Lift",
        screening_tool: "Dashboard Screnning Tool",
        plunger_lift: "Dashboard Plunger Lift"
    },
    add_edit_filer: "Add Filter",
    config_parameters: "Config Parameters",
    return_process: "Return"
}