import { serviceEndPoints } from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_IPR = serviceEndPoints.IPR.BASE_URL_IPR;
const BASE_URL_PLUNGER_LIFT = serviceEndPoints.PLUNGER_LIFT.URL_PLUNGER_LIFT;
const BASE_URL_WELL = serviceEndPoints.GASLIFT.BASE_URL_WELL;

export const uploadJsonIPRPL = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, data)) return;

  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/upload_json/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during upload_json service operation:", error);
  }
};

export const calculateQIPRPL = async (operator, lease, region, county, field, well, username) => {
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/calculate_q/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during calculate_q service operation:", error);
  }
};

export const calculatePwfIPR = async (operator, lease, region, county, field, well) => {
  if (!validateParams(operator, lease, region, county, field, well)) return;

  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/calculate_pwf/${operator}/${lease}/${region}/${county}/${field}/${well}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during calculate_pwf service operation:", error);
  }
};

export const calculateCasing = async (operator, lease, region, county, field, well, username) => {
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift_casing/calculate_table/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during calculate_table service operation:", error);
  }
};

export const uploadJsonCasing = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, data)) return;

  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift_casing/upload_json/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during upload_json service operation:", error);
  }
};

export const calculatePressure = async (operator, lease, region, county, field, well, username) => {
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift/calculate_press/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during calculate_press service operation:", error);
  }
};

export const uploadJsonPressure = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, data)) return;

  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift/upload_json_calc_press/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during upload_json_calc_press service operation:", error);
  }
};

export const calculateInterpolation = async (operator, lease, region, county, field, well, username) => {
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift_casing/calculate_interpolation/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during calculate_interpolation service operation:", error);
  }
};


export const dataWellPlungerLift = async (operator, lease, region, country, field, well, username, type) => {
  if (!validateParams(operator, lease, region, country, field, well, username, type)) return;

  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/${type}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during parameters service operation:", error);
  }
};

export const uploadJsonWellPL = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, type, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, type, data)) return;

  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/${type}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during upload parameters service operation:", error);
  }
};

export const updateJsonWellPL = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, type, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, type, data)) return;

  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/${type}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during update parameters service operation:", error);
  }
};

export const deleteWellPL = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, type } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, type)) return;

  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/${type}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during delete parameters service operation:", error);
  }
};

export const listWellPlungerLift = async (operator, lease, region, username, type) => {
  if (!validateParams(operator, lease, region, username, type)) return;

  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameterslist/${operator}/${lease}/${region}/${username}/${type}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during parameterslist service operation:", error);
  }
};


export const calculatePVT = async (operator, lease, region, country, field, well, username) => {
  if (!validateParams(operator, lease, region, country, field, well, username)) return;

  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_pvt/calculate_pvt/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during calculate_pvt service operation:", error);
  }
};

export const uploadJsonPVT = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, data)) return;

  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_pvt/upload_json_pvt/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during upload_json_pvt service operation:", error);
  }
};

export const updateParametersPlungerLift = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, data)) return;

  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersplungerlift`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during update parameters service operation:", error);
  }
};

export const uploadExcelFile = async (fileData, dataParams) => {
  const { operator, lease, region, county, field, well, username } = dataParams;
  if (!validateParams(operator, lease, region, county, field, well, username, fileData)) return;

  const formData = new FormData();
  formData.append("file", fileData);

  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift/upload_excel/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: formData,
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during upload_excel service operation:", error);
  }
};

export const getProcessedData = async (operator, lease, region, county, field, well, username) => {
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift/get_excel/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during get_excel service operation:", error);
  }
};


export const uploadDataGraphics = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, data)) return;

  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/upload_graph/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during upload_graph service operation:", error);
  }
};

export const dataGraphic = async (operator, lease, region, country, field, well, username, nameIpr) => {
  if (!validateParams(operator, lease, region, country, field, well, username, nameIpr)) return;

  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/get_graph/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/${nameIpr}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during get_graph service operation:", error);
  }
};

export const listDataGraphic = async (operator, lease, region, country, field, well, username) => {
  if (!validateParams(operator, lease, region, country, field, well, username)) return;

  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/list_graph/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during list_graph service operation:", error);
  }
};

export const deleteDataGraphic = async (dataJson) => {
  const { operator, lease, region, country, field, well, username, nameIPRPL } = dataJson;
  if (!validateParams(operator, lease, region, country, field, well, username, nameIPRPL)) return;

  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/delete_graph/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/${nameIPRPL}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during delete_graph service operation:", error);
  }
};

export const deleteListDataGraphic = async (dataJson) => {
  const { operator, lease, region, country, field, well, username } = dataJson;
  if (!validateParams(operator, lease, region, country, field, well, username)) return;

  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/delete_all_graph/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("PlungerLift: Error during delete_all_graph service operation:", error);
  }
};
