import React from 'react'
import { DataGasLiftContext } from './DataGasLiftContext'
import { useDataGasLift } from '../../hooks/useDataGasLift'

export const DataGasLiftProvider = ({ children }) => {
  const {
    dataCreated,
    dataFilterUserGasLift,
    dataWellTables,
    dataWell,
    dataFluid,
    datReservoir,
    enableCheckLab,
    lastSelectDataIPR,
    viewLoadingGasLift,
    validateAlertGasLift,
    stateMessageApis,
    getDataCreated,
    getDataFilterUserGaslift,
    getDataWellTables,
    getDataWell,
    getDataFluid,
    getDataReservoir,
    getEnableCheckLab,
    handleSelectWellGaslift,
    getSelectDataIPR,
    getViewLoadingGasLift,
    getValidateAlertGasLift,
    getStateMessageAlert
  } = useDataGasLift()
  
  return (
    <DataGasLiftContext.Provider value={{
      dataCreated,
      dataFilterUserGasLift,
      dataWellTables,
      dataWell,
      dataFluid,
      datReservoir,
      enableCheckLab,
      lastSelectDataIPR,
      viewLoadingGasLift,
      validateAlertGasLift,
      stateMessageApis,
      getDataCreated,
      getDataFilterUserGaslift,
      getDataWellTables,
      getDataWell,
      getDataFluid,
      getDataReservoir,
      getEnableCheckLab,
      handleSelectWellGaslift,
      getSelectDataIPR,
      getViewLoadingGasLift,
      getValidateAlertGasLift,
      getStateMessageAlert
    }}>
      {children}
    </DataGasLiftContext.Provider>
  )
}
