import React, { useEffect, useState } from "react";
import styleChartProd from "./ChartProdHistory.module.css";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  ComposedChart,
} from "recharts";
import { DATA_STANDARD_RT } from "../../../elements/types/type_standard_parameters";
const {
  containerPrincipal,
  containerLegend,
  styleRefenence1,
  styleRefenence3,
  sizeReference,
} = styleChartProd;
const formatNumber = (value) => {
  // 2 decimal numbers format, except integers
  if (!isNaN(value) && value !== "") {
    const num = parseFloat(value);
    return num % 1 === 0 ? num : num.toFixed(2);
  }
  return value;
};
function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          padding: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderColor: "#662D91",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <p style={{ color: "#FF0000", fontSize: "11px" }}>
          {`Gas Rate: ` + formatNumber(data.gas_rate) + ` (Mscf/d)`}
        </p>
        <p style={{ color: "#0000FF", fontSize: "11px" }}>
          {`Liquid Rate: ` + formatNumber(data.liquid_rate) + ` (bbl/d)`}
        </p>
      </div>
    );
  }
  return null;
}

const ChartProdHistory = ({
  typeGraph,
  dataIOT,
  dataHistory,
  dataDomainGraph,
  statusGraph,
}) => {
  const liquidRateMax = dataDomainGraph?.liquid_rate_max_ph;
  const liquidRateMin = dataDomainGraph?.press_min_wpc;
  const gasRateMax = dataDomainGraph?.gas_rate_max_ph;
  const gasRateMin = dataDomainGraph?.gas_rate_min_ph;
  const [yAxisDomain1, setYAxisDomain1] = useState([gasRateMin, gasRateMax]);
  const [yAxisDomain2, setYAxisDomain2] = useState([
    liquidRateMin,
    liquidRateMax,
  ]);
  const [dataFinalGraph, setDataFinalGraph] = useState([]);
  useEffect(() => {
    setYAxisDomain1([gasRateMin, gasRateMax]);
    setYAxisDomain2([liquidRateMin, liquidRateMax]);
  }, [liquidRateMax, gasRateMax, liquidRateMin, gasRateMin]);
  useEffect(() => {
    const formattedData =
      dataIOT?.map((item, index) => ({
        date: String(item[DATA_STANDARD_RT.GENERAL.PARAMETER_000]).split(
          " "
        )[0],
        gas_rate: parseFloat(item[DATA_STANDARD_RT.GENERAL.PARAMETER_001]),
        liquid_rate: parseFloat(
          item[DATA_STANDARD_RT.PLUNGER_LIFT.PARAMETER_016]
        ),
      })) || [];

    if (dataHistory && !statusGraph) {
      const dataHistoryMod = dataHistory?.dataset1
        ?.map((item, index) => ({
          date: String(item["date"]),
          gas_rate:
            typeof item["gas_rate"] === "number"
              ? item["gas_rate"].toFixed(4)
              : null,
          liquid_rate:
            typeof item["liquid_rate"] === "number"
              ? item["liquid_rate"].toFixed(4)
              : null,
        }))
        .filter(
          (item) => item["gas_rate"] !== null && item["liquid_rate"] !== null
        );

      const dataConcat = dataHistoryMod
        ? dataHistoryMod.concat(formattedData)
        : [];
      setDataFinalGraph(dataConcat);
    } else if (statusGraph) {
      setDataFinalGraph(formattedData);
    }
  }, [dataIOT, dataHistory]);

  const getMaxValueFromKeys = (data, keys) => {
    return Math.max(
      ...data.flatMap((item) =>
        keys.map((key) => (typeof item[key] === "number" ? item[key] : 0))
      )
    );
  };

  const getMaxAndMinValues = (data, keys) => {
    const allValues = data.flatMap(
      (item) => keys.map((key) => item[key] || 0) // Usar 0 si el valor es null o undefined
    );

    return {
      max: Math.max(...allValues),
      min: Math.min(...allValues),
    };
  };
  const result = getMaxAndMinValues(dataFinalGraph, [
    "gas_rate",
    "liquid_rate",
  ]);

  return (
    <div className={containerPrincipal}>
      <div style={{ width: "100%", height: "350px", padding: "5px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={dataFinalGraph}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tick={{
                fontSize: 12,
              }}
              label={{
                value: "Date",
                position: "insideBottom",
                offset: -5,
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
            />
            <YAxis
              yAxisId={1}
              label={{
                value: "Flow Line Gas Rate (Mscf/D)",
                angle: -90,
                position: "insideLeft",
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tick={{
                fontSize: 12,
              }}
              domain={yAxisDomain2}
              allowDecimals={false}
              allowDataOverflow={true}
              tickFormatter={(value) => Math.round(value)}
            />
            <YAxis
              yAxisId={2}
              orientation="right"
              label={{
                value: "Flow line Gas Rate (Bbi/D)",
                angle: -90,
                position: "right",
                offset: -5,
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tick={{
                fontSize: 12,
              }}
              domain={yAxisDomain1}
              allowDecimals={false}
              allowDataOverflow={true}
              tickFormatter={(value) => Math.round(value)}
            />
            <Tooltip content={<CustomTooltip />} />
            {typeGraph === "Bar" ? (
              <>
                <Bar
                  dataKey="liquid_rate"
                  name="liquid_rate"
                  fill="#3737FF"
                  yAxisId={1}
                />
                <Bar
                  dataKey="gas_rate"
                  name="gas_rate"
                  fill="#FF2727"
                  yAxisId={2}
                />
              </>
            ) : (
              <>
                <Line
                  type="monotone"
                  dataKey="liquid_rate"
                  stroke="#0000FF"
                  name="liquid_rate"
                  yAxisId={1}
                />
                <Line
                  type="monotone"
                  dataKey="gas_rate"
                  stroke="#FF0000"
                  name="gas_rate"
                  yAxisId={2}
                />
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
        <div className={containerLegend}>
          <span className={sizeReference}>
            <span className={styleRefenence3}>●</span> Liquid
          </span>
          <span className={sizeReference}>
            <span className={styleRefenence1}>●</span> Gas
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChartProdHistory;
