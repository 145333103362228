import React, { useContext, useEffect, useState } from "react";
import { KpiGraph } from "./KpiGraph";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx"

const initialData = [
  {
    "id": "",
    "ranges": [
      50,
      100
    ],
    "measures": [
      50
    ],
    "markers": [
      10
    ]
  },
]
export const CardGraph = ({title, value, unit, color, name}) => {
  const { dataAlertDCA, someAlert } = useContext(DashboardContext);
  const [data, setData] = useState(initialData);
  const [shouldBlink, setShouldBlink] = useState(false);
  const [valueAlertMin, setValueAlertMin] = useState();
  const [valueAlertMax, setValueAlertMax] = useState();
  const [realValue, setrealValue] = useState();
  
  useEffect(() => {
    const valueMin = !dataAlertDCA[`${name}_min`] ? 0 : dataAlertDCA[`${name}_min`];
    const valueMax = !dataAlertDCA[`${name}_max`] ? 0 : dataAlertDCA[`${name}_max`];
    const valueData = value === "--" ? null : value;

    setValueAlertMin(valueMin)
    setValueAlertMax(valueMax)
    setrealValue(valueData)
  }, [dataAlertDCA, value])

  useEffect(() => {

    if((realValue > valueAlertMax || realValue < valueAlertMin) && realValue != null){
      setShouldBlink(someAlert.forecasting.valuesCheck[name] ? true : false);
    }else{
      setShouldBlink(false);
    }
  }, [realValue, valueAlertMin,  valueAlertMax, someAlert])

  const blinkingStyle = {
    animation: shouldBlink ? 'blinking 0.8s infinite alternate' : 'none'
  };

  return (

    <div
      className={`flex flex-row w-full h-[62px] rounded-md p-2 bg-white`}
      style={blinkingStyle}
    >
      <div className="flex flex-col w-1/2 h-full mr-1">
          <h1 className="text-[13px]">
              {title}
              <label className="text-[10px]">{unit}</label>
          </h1>
          <span className={`relative top-[-5px] text-[1.7rem] ${color ? "text-green-500": "text-pc-grey3"}`}>{value === null ? "--" : value}</span>
      </div>
      <div className="flex flex-col w-1/2 h-full ">
          <div className="flex w-full h-[70%] ">
            <KpiGraph
            dataKpi={data}
            nameValue={name}
            val_max={valueAlertMax}
            val_min={valueAlertMin}
            stateCheck={realValue}
            stateColor={shouldBlink}
            ></KpiGraph>
          </div>
          <div className="flex flex-row w-full h-[30%] pl-6">
            <label htmlFor="" className="h-full mr-2 text-xs"> KPI </label>
            <input type="text" className="flex w-full h-full bg-pc-grey2 text-white text-xs " />
          </div>
      </div>
    </div>
  );
};