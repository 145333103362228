import { message } from 'antd'
import {getListDCA, getListGasLift, getAlerts } from '../services/api/mapApi';
import { useMutation, useQuery } from 'react-query'
import { useContext, useState } from 'react'
import { AuthContext } from '../auth/context/AuthContext'
import { DashboardContext } from '../context/Dashboard/DashboardContentx'
import { DashboardDCAContext } from '../context/DasboardDCA/DashboardDCAContext';
import { DataGasLiftContext } from '../context/DataGasLift/DataGasLiftContext'
import { DashboardPLContext } from '../context/DashboardPlungerLift/DashboardPLContext';

export const useTapMap = () => {
  const {User} = useContext(AuthContext)
  const {getUserDataInfo, userDataInfo} = useContext(DashboardContext);
  const {getDataFilterUserDCA, dataFilterUserDCA} = useContext(DashboardDCAContext)
  const {getDataFilterUserGaslift, dataFilterUserGasLift, } = useContext(DataGasLiftContext);
  const { dataFilterUserPL } = useContext(DashboardPLContext);
  const [viewLoading, setViewLoading] = useState(true)

  const { data: dataListDCA, isLoading: loadListWellDCA, isError: ErrorListWellDCA, refetch: refetchListDCA } = useQuery({
    queryKey: ['dataListDCA'],
    queryFn: () => getListDCA( userDataInfo?.operator, userDataInfo?.lease, userDataInfo?.region, User?.user_name ),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      setViewLoading(false)
    },
    onError: (error) => {
      setViewLoading(false)
    }
  });

  const { data: listWellsGasLift, isLoading: loadListWellsGasLift, isError: ErrorListWellsGasLift, refetch: refetchListWellsGasLift } = useQuery({
    queryKey: ['get_list_wells_gas_lift'],
    queryFn: () => getListGasLift(userDataInfo?.operator, userDataInfo?.lease, userDataInfo?.region, User?.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  const { data: alertGasLift, isLoading: loadAlert, isError: ErrorAlertGasLift, refetch: refetchAlertGasLift } = useQuery({
    queryKey: ['get_alert_gas_lift'],
    queryFn: () => getAlerts("gas_lift",userDataInfo?.operator, userDataInfo?.lease, userDataInfo?.region, dataFilterUserGasLift?.county, dataFilterUserGasLift?.field, User?.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  const { data: alertDCA, isLoading: loadAlertDCA, isError: ErrorAlertDCA, refetch: refetchAlertDCA } = useQuery({
    queryKey: ['get_alert_dca'],
    queryFn: () => getAlerts("dca", userDataInfo?.operator, userDataInfo?.lease, userDataInfo?.region, dataFilterUserDCA?.county, dataFilterUserDCA?.field, User?.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  const { data: alertVLP, isLoading: loadalertVLP, isError: ErroralertVLP, refetch: refetchalertVLP } = useQuery({
    queryKey: ['get_alert_vlp'],
    queryFn: () => getAlerts("vlp", userDataInfo?.operator, userDataInfo?.lease, userDataInfo?.region, dataFilterUserGasLift?.county, dataFilterUserGasLift?.field, User?.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })
  
  const { data: alertPlungerLift, isLoading: loadalertPlungerLift, isError: ErroralertPlungerLift, refetch: refetchalertPlungerLift } = useQuery({
    queryKey: ['get_alert_plungerlift'],
    queryFn: () => getAlerts("plunger", userDataInfo?.operator, userDataInfo?.lease, userDataInfo?.region, dataFilterUserPL.county, dataFilterUserPL.field,User?.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  return {
    userDataInfo,
    getUserDataInfo,
    dataListDCA,
    refetchListDCA,
    listWellsGasLift,
    refetchListWellsGasLift,
    getDataFilterUserDCA,
    dataFilterUserDCA,
    getDataFilterUserGaslift,
    dataFilterUserGasLift,
    viewLoading,
    setViewLoading,
    loadListWellDCA,
    loadListWellsGasLift,
    alertGasLift,
    alertDCA,
    alertVLP,
    alertPlungerLift,
    refetchAlertGasLift,
    refetchAlertDCA,
    refetchalertVLP,
    refetchalertPlungerLift,
    ErrorListWellDCA
  }
}