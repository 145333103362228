import { Modal, Select, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  Offcanvas,
  Ripple,
  initTE,
} from "tw-elements";
import { ReactComponent as ExpandIcon } from '../../assets/icon/expand1.svg';
import { ReactComponent as SaveAlert } from "../../assets/img/icons/save.svg";
import { FrameGraph1 } from '../../components/DCA/FrameGraph1';
import { FrameGraph2 } from '../../components/DCA/FrameGraph2';
import { FrameGraph3 } from '../../components/DCA/FrameGraph3';
import { CardGraph } from '../../components/DCA/complements/CardGraph';
import { ImportFile } from '../../components/DCA/complements/ImportFileAlerts';
import { DATA_ALERTS, INITIAL_TYPE_GRAPH } from '../../components/elements/types/type_dashboard';
import { INITIAL_DATA_ALERTS_DCA, STATE_ALERTS } from "../../components/elements/types/type_dca";
import { STATUS_DATA_DCA } from "../../components/elements/types/type_response_API";
import { ErrorLoadingDashboard } from '../../components/ErrorPage';
import { Navbar } from '../../components/layout/Navbar';
import { Sidebar } from '../../components/layout/Sidebar';
import { Loading, LoadingDashboard } from '../../components/Loading';
import { DashboardDCAContext } from "../../context/DasboardDCA/DashboardDCAContext";
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { useFilters } from '../../hooks/useFilters';
import { useTabDCA } from '../../hooks/useTabDCA';
import { useTabIOT } from '../../hooks/useTabIOT';
import { Tooltip as AntdTooltip } from "antd"
import DownloadTemplate from '../../components/DCA/complements/DownloadTemplate/DownloadTemplate';
import { template_params_alarms_DCA } from '../../components/elements/types/type_templates';
import { validateJsonEmptyAlert } from '../../helpers/helpersValidate';
import { AlertModal } from '../../components/AlertModal';
import { NAME_PAGES } from '../../components/elements/types/type_pages';

const dataWellDCA = {
  well_name: "",
  fluid_type: "",
  field: "",
  region: "",
  country: "",
  well_id: ""
}
const initialDataDashboardDCA = {
  staticPressure: null,
  tempValue: null,
  oilValues: null,
  gasValues: null,
  waterValues: null
}
const initialVolumeTacker = {
  AI_DCA: { separator_type: "", platform: "" },
  performance: { separator_type: "", platform: "" },
  production_history: { separator_type: "", platform: "", fcat: "", water_cut: "", fcat_gas: "" }
}
const intiialSelectedDeviceGraph = {
  chartAIDCA: "",
  chartPerformance: "",
  chartProdHistory: "",
}
export const DashboardDCA = () => {
  const { Option } = Select;
  const { dataFilter, User } = useFilters();
  const { getDataPage, selectedWellDCA, getDataAlertDCA, dataAlertDCA, getSomeAlert, someAlert, checkTypeGraph, getCheckTypeGraph, deviceRealTime, getDeviceRealTime } = useContext(DashboardContext);
  const { checkedValuesLastCol, dataCardsDCA, getValidateAlertDCA, validateAlertDCA, viewLoadingDCA} = useContext(DashboardDCAContext);
  const keyCheck = Object.keys(checkedValuesLastCol).find(key => checkedValuesLastCol[key] === true) || "";
  const {
    userDataInfo,
    dataHistoryDCA,
    dataFilterUserDCA,
    refetchListWellDCA,
    dataWellDCA,
    refetchDataWellDCA,
    refetchDataHistoryDCA,
    dataForecast,
    refetchDataForecast,
    mutationUpdateJsonWellDCA,
    loadDataWellDCA,
    dataIOTAllPlatforms,
    refetchDataIOTAllPlatforms,
    loadDataIOTAllPlatforms,
    mutationUploadJsonVolumeTracker,
    refetchChartDCAProductionData,
    chartDCAProductionData,
    chart_ai_dca,
    refetchChart_ai_dca,
    chartDCAData,
    refetchChartDCAPerformanceData,
    chartDCAPerformanceData,
    listWellDCA,
    getDataFilterUserDCA
  } = useTabDCA(`df_cp_forecast_${keyCheck}`);
  const {
    dataListDevicesDCA,
  } = useTabIOT();

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Line');
  const [information, setInformation] = useState(dataWellDCA);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const seletedFilter = dataFilter ? dataFilter?.data : null;
  const [dataDashboardDCA, setDataDashboardDCA] = useState(initialDataDashboardDCA);
  const [inputVolumeTracker, setInputVolumeTracker] = useState(initialVolumeTacker);
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [loadVolumeT, setLoadVolumeT] = useState(false);
  const [selectedDeviceGraph, setSelectedDeviceGraph] = useState(intiialSelectedDeviceGraph);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  
  const [shouldBlink, setShouldBlink] = useState(false);
  const [dataGraphDCAProduction, setDataGraphDCAProduction] = useState([]);
  const [dataGraphDCAPerformance, setDataGraphDCAPerformance] = useState([]);

  useEffect(() => {
    initTE({ Offcanvas, Ripple });
    getDataPage(NAME_PAGES.DCA.dashboard);
  }, [])

  useEffect(() => {
    if (listWellDCA?.data &&!!selectedWellDCA ) {
      const dataWellFind = listWellDCA?.data.find((item) => item.well_id === selectedWellDCA);
      if (dataWellFind) {
        const dataFilterNew = {
          county: dataWellFind?.county,
          field: dataWellFind?.field,
          well_id: dataWellFind?.well_id,
          well_platforms: dataWellFind?.well_platforms,
          well_name: dataWellFind?.well_name,
        };
        getDataFilterUserDCA(dataFilterNew);
      }    
    } else {
      refetchListWellDCA();
    }
  }, [selectedWellDCA, listWellDCA])
  
  useEffect(() => {
    if (
      dataFilterUserDCA?.county &&
      dataFilterUserDCA?.field &&
      dataFilterUserDCA?.well_id
    ) {
      refetchDataHistoryDCA();
      refetchDataWellDCA();
      getCheckTypeGraph(INITIAL_TYPE_GRAPH);
      if (keyCheck != "") {
        refetchDataForecast(`df_cp_forecast_${keyCheck}`);
      }
    }
  }, [dataFilterUserDCA])

  useEffect(() => {
    setTimeout(() => {
      if (dataFilterUserDCA != null) {
        if (dataWellDCA?.statusCode === STATUS_DATA_DCA.SUCCESS) {
          const {
            well_name,
            region,
            field,
            county,
            fluid_type,
            well_id,
            data_alert,
            check_Alert,
            type_graph_dashboard,
            volumeTracker,
            selected_platform_graph,
            selected_device_graph,
          } = dataWellDCA?.data || {};

          setInformation({
            ...information,
            well_name: well_name,
            fluid_type: fluid_type,
            field: field,
            region: region,
            country: county,
            well_id: well_id
          })

          if (!!selected_device_graph) {
            setSelectedDeviceGraph(selected_device_graph)
          }

          if (volumeTracker) {
            setInputVolumeTracker({ ...inputVolumeTracker, production_history: volumeTracker.production_history });
          }

          if (!!data_alert && !!Object.keys(data_alert).length) {
            getDataAlertDCA(data_alert);
          } else {
            getDataAlertDCA(INITIAL_DATA_ALERTS_DCA);
          }

          if (!!dataWellDCA?.data.type_graph_dashboard && !!Object.keys(type_graph_dashboard).length) {
            getCheckTypeGraph({
              ...checkTypeGraph,
              forecasting: type_graph_dashboard
            });
          } else {
            getCheckTypeGraph({
              ...checkTypeGraph,
              forecasting: INITIAL_TYPE_GRAPH.forecasting
            });
          }

          if (!!check_Alert && !!Object.keys(check_Alert).length) {
            getSomeAlert({
              ...someAlert,
              messageChange: false,
              forecasting: check_Alert
            })
          } else {
            getSomeAlert({
              ...someAlert,
              messageChange: false,
              forecasting: DATA_ALERTS.forecasting
            });
          }

          getDeviceRealTime({
            ...deviceRealTime,
            device_dca: {
              ...deviceRealTime.device_dca,
              selected_platform_graph: {
                chartAIDCA: selected_platform_graph?.chartAIDCA,
                chartPerformance: selected_platform_graph?.chartPerformance,
                chartProdHistory: selected_platform_graph?.chartProdHistory,
              },
              selected_device_graph: {
                chartAIDCA: selected_device_graph?.chartAIDCA,
                chartPerformance: selected_device_graph?.chartPerformance,
                chartProdHistory: selected_device_graph?.chartProdHistory,
              },
            },
          });
          setTimeout(() => {
            refetchDataIOTAllPlatforms();
            refetchChartDCAProductionData();
            refetchChart_ai_dca();
            refetchChartDCAPerformanceData();
          }, 400);
        }
      }
    }, 1000)
  },[dataFilterUserDCA,dataWellDCA?.data, listWellDCA]);
  
  useEffect(() => {
    if (chartDCAProductionData) setDataGraphDCAProduction(chartDCAProductionData?.data);
  }, [chartDCAProductionData]);

  useEffect(()=>{
    if(chartDCAPerformanceData) setDataGraphDCAPerformance(chartDCAPerformanceData?.data);
  },[chartDCAPerformanceData]);

  useEffect(() => {
    if (dataCardsDCA) {
      setDataDashboardDCA({
        ...dataDashboardDCA,
        staticPressure: dataCardsDCA?.wellhead_conditions_press
          ? dataCardsDCA.wellhead_conditions_press.toFixed(2)
          : null, // O algún valor predeterminado
        tempValue: dataCardsDCA?.wellhead_conditions_temp
          ? dataCardsDCA.wellhead_conditions_temp.toFixed(2)
          : null,
        oilValues: dataCardsDCA?.production_performance_oil_rate
          ? dataCardsDCA.production_performance_oil_rate.toFixed(2)
          : null,
        gasValues: dataCardsDCA?.production_performance_gas_rate
          ? dataCardsDCA.production_performance_gas_rate.toFixed(2)
          : null,
        waterValues: dataCardsDCA?.production_performance_water_rate
          ? dataCardsDCA.production_performance_water_rate.toFixed(2)
          : null,
        worValues: dataCardsDCA?.production_performance_wor
          ? dataCardsDCA?.production_performance_wor.toFixed(2) : null,
        gorValues: dataCardsDCA?.production_performance_gor
          ? dataCardsDCA?.production_performance_gor.toFixed(2) : null,
      });
    }
  }, [dataCardsDCA])

  useEffect(() => {
    const validateAlert = {
      ...someAlert.forecasting.valuesAlert,
      [STATE_ALERTS.alert_wc_pressure]: validateTrueCount('wc_pressure', dataDashboardDCA?.staticPressure, dataAlertDCA.wc_pressure_max, dataAlertDCA.wc_pressure_min),
      [STATE_ALERTS.alert_wc_temp]: validateTrueCount('wc_temp', dataDashboardDCA?.tempValue, dataAlertDCA.wc_temp_max, dataAlertDCA.wc_temp_min),
      [STATE_ALERTS.alert_pp_oil_rate]: validateTrueCount('pp_oil_rate', dataDashboardDCA?.oilValues, dataAlertDCA.pp_oil_rate_max, dataAlertDCA.pp_oil_rate_min),
      [STATE_ALERTS.alert_pp_gas_rate]: validateTrueCount('pp_gas_rate', dataDashboardDCA?.gasValues, dataAlertDCA.pp_gas_rate_max, dataAlertDCA.pp_gas_rate_min),
      [STATE_ALERTS.alert_pp_water_rate]: validateTrueCount('pp_water_rate', dataDashboardDCA?.waterValues, dataAlertDCA.pp_water_rate_max, dataAlertDCA.pp_water_rate_min)
    }

    const trueCount = Object.values(validateAlert).filter(value => value).length

    if (trueCount >= 0 && someAlert.forecasting.countCheck !== undefined) {

      const realValueTrueCount = trueCount <= 0 ? 0 : trueCount - someAlert.forecasting.countCheck;

      getSomeAlert({
        ...someAlert,
        forecasting: {
          ...someAlert.forecasting,
          countAlert: realValueTrueCount, 
          valuesAlert: validateAlert, 
        }})
    }
  }, [dataAlertDCA, dataDashboardDCA, someAlert.forecasting.valuesCheck]);

  const validateTrueCount = (name, valueCard, valueMax, valueMin) => {
    if (valueCard !== null) {
      if (someAlert?.forecasting?.valuesCheck[name] === false) {
        const validateFalse = someAlert?.forecasting?.valuesAlert[name];
        return validateFalse;
      }
      else if (someAlert?.forecasting?.valuesCheck[name]) {
        const validateTrue = valueCard > valueMax || valueCard < valueMin ? true : false
        return validateTrue;
      }
    }
  }

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleOpenModalZoom = (id) => {
    switch (id) {
      case 1:
        setOpen1(true)
        break;
      case 2:
        setOpen2(true)
        break;
      case 3:
        setOpen3(true)
        break;
      default:
        break;
    }
  }

  const handleCloseModalZoom = (id) => {
    switch (id) {
      case 1:
        setOpen1(false)
        break;
      case 2:
        setOpen2(false)
        break;
      case 3:
        setOpen3(false)
        break;
      default:
        break;
    }
  }

  const handleOptionChange = (selectedOption, option) => {
    switch (selectedOption) {
      case "chartType":
        setSelectedOption(option);
        break;
      case "graphAIDCA":
        getDeviceRealTime({
          ...deviceRealTime,
          device_dca: {
            ...deviceRealTime.device_dca,
            selected_platform_graph: {
              ...deviceRealTime.device_dca.selected_platform_graph,
              chartAIDCA: option?.split(" ")[0],
            },
            selected_device_graph: {
              ...deviceRealTime.device_dca.selected_device_graph,
              chartAIDCA: option,
            },
          },
        });
        setSelectedDeviceGraph((prevData) => ({
          ...prevData,
          chartAIDCA: option
        }));
        break;
      case "graphPerformance":
        getDeviceRealTime({
          ...deviceRealTime,
          device_dca: {
            ...deviceRealTime.device_dca,
            selected_platform_graph: {
              ...deviceRealTime.device_dca.selected_platform_graph,
              chartPerformance: option?.split(" ")[0],
            },
            selected_device_graph: {
              ...deviceRealTime.device_dca.selected_device_graph,
              chartPerformance: option,
            },
          },
        });
        setSelectedDeviceGraph((prevData) => ({
          ...prevData,
          chartPerformance: option
        }));
        break;
      case "graphProduction":
        getDeviceRealTime({
          ...deviceRealTime,
          device_dca: {
            ...deviceRealTime.device_dca,
            selected_platform_graph: {
              ...deviceRealTime.device_dca.selected_platform_graph,
              chartProdHistory: option?.split(" ")[0],
            },
            selected_device_graph: {
              ...deviceRealTime.device_dca.selected_device_graph,
              chartProdHistory: option,
            },
          },
        });
        setSelectedDeviceGraph((prevData) => ({
          ...prevData,
          chartProdHistory: option
        }));
        break;
      case "separatorProductionHistory":
        setInputVolumeTracker((prevData) => ({
          ...prevData,
          production_history: {
            ...prevData?.production_history,
            separator_type: option
          }
        }));
        break;
      default:
        break;
    }
  };

  const handleOpenModal = (cardId) => {
    setSelectedCardId(cardId);
    switch (cardId) {
      case 1:
      case 2:
      case 3:
        setOpen(true);
        break;
      default:
        break;
    }
  };

  const handleChangeFluidInformation = (e, graph) => {
    const { name, value } = e.target;
    switch (graph) {
      case "graphAIDCA":
        setInputVolumeTracker((prevData) => ({
          ...prevData,
          AI_DCA: {
            ...prevData?.AI_DCA,
            [name]: Number(value)
          }
        }));
        break;
      case "graphPerformance":
        setInputVolumeTracker((prevData) => ({
          ...prevData,
          performance: {
            ...prevData?.performance,
            [name]: Number(value)
          }
        }));
        break;
      case "graphProduction":
        setInputVolumeTracker((prevData) => ({
          ...prevData,
          production_history: {
            ...prevData?.production_history,
            [name]: Number(value)
          }
        }));
        break;
      default:
        break;
    }
  };

  const handleClickAverages = () => {
    let bodyVT = { wells: [] };
    let dataConcatAverage = null;
    let isInputNonEmpty = false;
    if (
      !!inputVolumeTracker?.production_history?.fcat &&
      !!inputVolumeTracker?.production_history?.water_cut &&
      !!inputVolumeTracker?.production_history?.fcat_gas &&
      !!inputVolumeTracker?.production_history?.separator_type &&
      selectedCardId === 3
    ) {
      if ((inputVolumeTracker?.production_history?.fcat <= 100) &&
        (inputVolumeTracker?.production_history?.water_cut <= 100) &&
        (inputVolumeTracker?.production_history?.fcat_gas <= 100)){ 
        bodyVT.wells = [
          {
            well_id: dataWellDCA?.data?.well_id,
            fcat: inputVolumeTracker.production_history.fcat,
            fcat_gas: inputVolumeTracker.production_history.fcat_gas,
            water_cut: inputVolumeTracker.production_history.water_cut,
            separator_type: inputVolumeTracker.production_history.separator_type.replace(/^\w+-\d+\s*/, ""), // Eliminar prefijo si es necesario
          },
        ];
        isInputNonEmpty = true;
      } else {
        message.warning("Please, modify the invalid information in highlighted fields");
      }
    } else if (!isInputNonEmpty && selectedCardId === 3) {
      message.warning("Please validate that the information is complete");
    }

    if (selectedCardId === 3) {
      dataConcatAverage = {
        ...dataWellDCA?.data,
        volumeTracker: {
          ...dataWellDCA?.data?.volumeTracker,
          production_history: inputVolumeTracker?.production_history,
        },
      };
    }

    if (isInputNonEmpty) {
      async function executeFunctionVT() {
        setLoadVolumeT(true);
        await mutationUploadJsonVolumeTracker.mutateAsync({
          operator: seletedFilter?.operator,
          lease: seletedFilter?.lease,
          region: seletedFilter?.region,
          county: dataWellDCA?.data?.county,
          field: dataWellDCA?.data?.field,
          userName: User?.user_name,
          data: bodyVT,
        });
        await new Promise(resolve => setTimeout(resolve, 500));
        await mutationUpdateJsonWellDCA.mutateAsync({
          operator: seletedFilter?.operator,
          lease: seletedFilter?.lease,
          region: seletedFilter?.region,
          county: dataWellDCA?.data?.county,
          field: dataWellDCA?.data?.field,
          wellid: dataWellDCA?.data?.well_id,
          userName: User?.user_name,
          data: dataConcatAverage,
        });
        await new Promise(resolve => setTimeout(resolve, 1000));
        refetchDataIOTAllPlatforms();
        setOpen(false);
        setLoadVolumeT(false);
      }
      executeFunctionVT();
    }
  }

  const handleUploadFile = (dataFile) => {
    if (!!selectedWellDCA) {
      const dataFileAlert = dataFile.map((obj, index) => {
        return { id: index + 1, ...obj };
      });

      const finalDataAlert = {};

      dataFileAlert.map(item => {
        const nameMax = `${item.item}_max`;
        const nameMin = `${item.item}_min`;

        finalDataAlert[nameMin] = item.min >= item.max ? 0 : item.min;
        finalDataAlert[nameMax] = item.max;
      });

      const dataConcatAlertDCA = { ...dataAlertDCA, data_alert: finalDataAlert }
      setTimeout(() => {
        mutationUpdateJsonWellDCA.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataAlertDCA?.county,
          field: dataAlertDCA?.field,
          wellid: dataAlertDCA?.well_id,
          userName: User?.user_name,
          data: dataConcatAlertDCA
        })
      }, 500);
      getDataAlertDCA(dataConcatAlertDCA?.data_alert);
      getSomeAlert({ ...someAlert, messageChange: false })
    } else {
      message.info("Please seleted well for save domain alert")
    }
  }

  const handleSaveAlert = () => {
    if(dataAlertDCA && !!selectedWellDCA){
      const data_alert = dataAlertDCA;
      const data_check = someAlert.forecasting;
      const dataConcatAlertDCA = {
        ...dataWellDCA,
         data_alert: data_alert,
        data_check: data_check
      };
      const invalidKeys = data_alert && data_check ? validateJsonEmptyAlert(data_check,data_alert) : [];
      if(invalidKeys.length > 0){
        getValidateAlertDCA({
          ...validateAlertDCA,
          alertCard:true
        });
        setShowAlert(true);
        setTypeAlert("Warning");
        setMessageAlert( "There are empty alerts please complete the boxes of the ranges");
      }
      else{
        getValidateAlertDCA({
          ...validateAlertDCA,
          alertCard:false
        });
        setTimeout(() => {
          mutationUpdateJsonWellDCA.mutate({
            operator: userDataInfo.operator, 
            lease: userDataInfo.lease, 
            region: userDataInfo.region, 
            county: dataAlertDCA?.county,
            field: dataAlertDCA?.field,
            wellid: dataAlertDCA?.well_id,
            userName: User?.user_name, 
            data: dataConcatAlertDCA
          })
        }, 500);
        getDataAlertDCA(dataConcatAlertDCA?.data_alert);
        getSomeAlert({...someAlert, messageChange: false})
      }
    }else{
      message.info("Please seleted well for save domain alert")
    }
  }

  const handleTypeGraph = (event, name) => {

    if (checkTypeGraph.forecasting[name] === true) {
      getCheckTypeGraph({ ...checkTypeGraph, forecasting: { ...checkTypeGraph.forecasting, [name]: false } })

    } else {
      getCheckTypeGraph({ ...checkTypeGraph, forecasting: { ...checkTypeGraph.forecasting, [name]: true } })
    }
  };

  const handleCloseModalAlert = () => {
    setShowAlert(false);
  };
  
  return (
    <div className='flex flex-col w-full h-screen bg-white'>
      <div className='flex'>
        <Navbar
          wellboreOption={wellboreOption}
        ></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      {(loadDataIOTAllPlatforms || loadDataWellDCA || viewLoadingDCA.loadSaveParameters) && (!!selectedWellDCA)
        ?
        <div className='flex w-full h-full'>
          <LoadingDashboard />
        </div> 
        : !selectedWellDCA || 
          selectedWellDCA === "null" || 
          seletedFilter?.checkedWellDCA === null || 
          seletedFilter?.checkedWellDCA === "" ? 
        <ErrorLoadingDashboard/>
        :
        <div className="grid grid-cols-3 grid-rows-10 w-full h-full gap-x-6 pr-2 pl-[90px] bg-pc-grey4">
          <div className="w-full h-full grid grid-cols-4 grid-rows-10 gap-x-[1em] gap-y-[1em] row-span-6 overflow-y-auto ml-[1em] mt-[1em] pb-[2em]">
            {/* Tarjeta 1: Form */}
            <div className="card w-full col-span-2 bg-white p-[0.5em] grid-rows-1 grid">
              <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                Well information
              </label>
              <div className="grid grid-cols-2 grid-rows-4 w-full h-full gap-y-[1em] ">
                <div className="flex  flex-col col-span-2 w-full h-1/6 max-h-[45px]">
                  <label className="w-full font-bold  text-pc-grey2 text-[14px] p-1">
                    Well Name
                  </label>
                  <input className="w-full pl-1" value={information?.well_name} readOnly/>
                </div>
                <div className="flex  flex-col w-full col-span-2  h-1/6 max-h-[45px]">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Fluid Type
                  </label>
                  <input className="w-full pl-1" value={information?.fluid_type} readOnly/>
                </div>
                <div className="flex flex-col w-full col-span-2  h-1/6 max-h-[45px]">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Field
                  </label>
                  <input className="w-full pl-1" value={information?.field} readOnly/>
                </div>
                <div className="flex  flex-col w-full col-span-2  h-1/6 max-h-[45px]">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                    Region
                  </label>
                  <input className="w-full pl-1"  value={information?.region} readOnly/>
                </div>
                <div className="flex  flex-col w-full  col-span-2 h-1/6 max-h-[45px]">
                  <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                  Country
                  </label>
                  <input className="w-full pl-1"  value={information?.country} readOnly/>
                </div>
                <div className="flex flex-col w-[157px] h-[42px] ml-[8px] justify-start text-start items-start">
                    <label className="w-full text-pc-grey2 font-bold text-[14px]">
                      Domain Alert
                    </label>
                    <div className="flex flex-row w-full mt-[5px]">
                      <AntdTooltip
                        placement="bottom"
                        title="UPLOAD"
                        color="#662D91"
                      >
                        <span>
                          <ImportFile uploadfile={handleUploadFile}></ImportFile>
                        </span>
                      </AntdTooltip>
                      <AntdTooltip
                        placement="bottom"
                        title="DOWNLOAD TEMPLATE"
                        color="#662D91"
                      >
                        <span>
                          <DownloadTemplate
                            className="ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple cursor-pointer"
                            template={template_params_alarms_DCA}
                            fileName="DCA_template.xlsx"

                          />
                        </span>
                      </AntdTooltip>
                      <AntdTooltip
                        placement="bottom"
                        title="SAVE ALARMS"
                        color="#662D91"
                      >
                        <span>
                          <SaveAlert
                            onClick={() => handleSaveAlert()}
                            className="w-4 h-5 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple" />
                        </span>
                      </AntdTooltip>
                    </div>
                  </div>
                </div>
              </div>
              {/* Tarjeta 5: ARTIFICIAL LIFT METRICS */}
              <div className="card w-full col-span-2  bg-white p-[0.5em] row-span-3">
                <div className="grid grid-cols-2 grid-rows-5 w-full h-full">
                  <div className="flex  flex-col col-span-2 w-full">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Artificial Lift Metrics
                    </label>
                    <CardGraph
                      title={"Injection Pressure "}
                      value={"--"}
                      unit={"(psig)"}
                      name={"alm_injection"}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col col-span-2 w-full justify-center">
                    <CardGraph
                      title={"Injection Gas Rate "}
                      value={'--'}
                      unit={"(MSCFD)"}
                      name={"alm_inj_gas"}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col col-span-2 w-full justify-center">
                    <CardGraph
                      title={"GLR Injection "}
                      value={"--"}
                      unit={"(SCF/STB)"}
                      name={"alm_inj_GLR"}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col col-span-2 w-full justify-center">
                    <CardGraph
                      title={"GLR Formation "}
                      value={"--"}
                      unit={"(SCF/STB)"}
                      name={"alm_formation"}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col col-span-2 w-full justify-center">
                    <CardGraph
                      title={"Water Cut "}
                      value={"--"}
                      unit={"(%)"}
                      name={"alm_water_cut"}
                    ></CardGraph>
                  </div>
                </div>
              </div>
              {/* Tarjeta 2: WELLHEAD CONDITIONS */}
              <div className="card w-full col-span-2 bg-white p-[0.5em]">
                <div className="grid grid-cols-2 grid-rows-2 w-full h-full">
                  <div className="col-span-2 w-full">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Wellhead Conditions
                    </label>
                    <CardGraph
                      title={"PRESS "}
                      unit={"(psig)"}
                      name={"wc_pressure"}
                      value={dataDashboardDCA.staticPressure != 0 ? dataDashboardDCA.staticPressure : 0}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col col-span-2 w-full justify-center">
                    <CardGraph
                      title={"TEMP "}
                      unit={"(deg f)"}
                      name={"wc_temp"}
                      value={dataDashboardDCA.tempValue != 0 ? dataDashboardDCA.tempValue : 0}
                    ></CardGraph>
                  </div>
                </div>
              </div>
              {/* Tarjeta 6: BH Condition*/}
              <div className="card w-full  col-span-2 bg-white p-[0.5em]">
                <div className="grid grid-cols-2 grid-rows-2 w-full h-full">
                  <div className="col-span-2 w-full">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      BH Condition
                    </label>
                    <CardGraph
                      title={"PRESS "}
                      value={"--"}
                      unit={"(psia)"}
                      name={"bhc_pressure"}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col col-span-2 w-full justify-center">
                    <CardGraph
                      title={"TEMP "}
                      value={"--"}
                      unit={"(deg f)"}
                      name={"bhc_temp"}
                    ></CardGraph>
                  </div>
                </div>
              </div>
              {/* Tarjeta 4: PRODUCTION PERFORMANCE */}
              <div className="card w-full bg-white grid-rows-1 p-[0.5em] col-span-4 ">
                <div className="grid grid-cols-2  w-full h-full">
                  <div className="w-full">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Production Performance
                    </label>
                    <CardGraph
                      title={"Oil Rate"}
                      unit={"(STB/D)"}
                      name={"pp_oil_rate"}
                      value={dataDashboardDCA.oilValues != 0 ? dataDashboardDCA.oilValues : 0}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col w-full justify-center">
                    <CardGraph
                      title={"Water Rate"}
                      unit={"(BBL/D)"}
                      name={"pp_water_rate"}
                      value={dataDashboardDCA?.waterValues != 0 ? dataDashboardDCA?.waterValues : 0}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col w-full justify-center">
                    <CardGraph
                      title={"Gas Rate"}
                      unit={"(MSCF/D)"}
                      name={"pp_gas_rate"}
                      value={dataDashboardDCA?.gasValues != 0 ? dataDashboardDCA?.gasValues : 0}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col w-full justify-center">
                    <CardGraph
                      title={"WOR "}
                      value={dataDashboardDCA?.worValues != 0 ? dataDashboardDCA?.worValues : 0}
                      unit={"(Dimensionless)"}
                      name={"pp_wor"}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col w-full justify-center">
                    <CardGraph
                      title={"Water Cut "}
                      value={"--"}
                      unit={"(%)"}
                      name={"pp_water_cute"}
                    ></CardGraph>
                  </div>
                  <div className="flex  flex-col w-full justify-center ">
                    <CardGraph
                      title={"GOR "}
                      value={dataDashboardDCA?.gorValues != 0 ? dataDashboardDCA?.gorValues : 0}
                      unit={"(SCF/STB)"}
                      name={"pp_gor"}
                    ></CardGraph>
                  </div>
                </div>
              </div>
            </div>
            {!wellboreOptionState && (
              <div className="w-full h-full grid grid-cols-2 gap-x-[1em] gap-y-[1em] grid-rows-6  col-span-2 row-span-6 overflow-y-auto mt-[1em] pb-[2em]">
                <div className="card w-full row-span-3  bg-white p-[0.5em] ">
                  <div className='flex flex-row w-full  h-[5%] mb-[0.5em]'>
                    <ExpandIcon onClick={() => handleOpenModalZoom(1)} className="w-5 h-5 hover:bg-pc-grey3 cursor-pointer" />
                    <Select
                      style={{
                        backgroundColor: !selectedDeviceGraph?.chartAIDCA
                          ? "#FEF9C3"
                          : "",
                        border: !selectedDeviceGraph?.chartAIDCA
                          ? "1px solid #B91C1C"
                          : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      className="w-[180px] h-[18px] ml-[10px] text-left"
                      name={"chartAIDCA"}
                      value={selectedDeviceGraph?.chartAIDCA}
                      onChange={(value) =>
                        handleOptionChange("graphAIDCA", value)
                      }
                    >
                      <Option value="">Select option</Option>
                      {dataListDevicesDCA?.data?.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className='flex flex-row w-full h-[5%] pl-[2em] max-h-[10%]'>
                    <label className="text-pc-grey2 font-bold text-[14px] p-1 mr-6">
                      AI DCA
                    </label>
                    <div className='flex flex-row w-[40%] h-full items-center text-right justify-end gap-2'>
                      <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.forecasting.dcaGraph ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                        type="checkbox"
                        checked={checkTypeGraph.forecasting.dcaGraph}
                        onChange={(event) => handleTypeGraph(event, "dcaGraph")}
                        disabled={!selectedWellDCA || selectedWellDCA === "null" ? true : false}
                      />
                      <label htmlFor="" className='text-bc-gray text-[14px]'>Historical Data + RT avg</label>
                    </div>
                    <div className='flex flex-row w-[30%] h-full items-center text-right justify-end gap-2'>
                      <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.forecasting.dcaGraph ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                        type="checkbox"
                        checked={checkTypeGraph.forecasting.dcaGraph}
                        onChange={(event) => handleTypeGraph(event, "dcaGraph")}
                        disabled={!selectedWellDCA || selectedWellDCA === "null" ? true : false}
                      />
                      <label htmlFor="" className='text-bc-gray text-[14px]'>Real Time Data</label>
                    </div>
                  </div>
                  <div className="flex flex-col h-[90%]">
                    <FrameGraph1
                      open={open1}
                      onCloseModal={() => handleCloseModalZoom(1)}
                      dataHistory={dataHistoryDCA}
                      dataIOT={dataIOTAllPlatforms?.requestData !== null
                        ? dataIOTAllPlatforms?.requestData[
                        selectedDeviceGraph?.chartAIDCA?.split(" ")[0]
                        ]
                        : []}
                      typeDevice={selectedDeviceGraph?.chartAIDCA}
                      dataForecastDCA={dataForecast}
                      dataRt={chart_ai_dca?.data}
                      statusGraph={checkTypeGraph?.forecasting?.dcaGraph}
                    />
                  </div>
                </div>
                <div className="card w-full row-span-3  bg-white p-[0.5em] ">
                  <div className='flex flex-row w-full  h-[5%] mb-[0.5em]'>
                    <ExpandIcon onClick={() => handleOpenModalZoom(2)} className="w-5 h-5 hover:bg-pc-grey3 cursor-pointer" />
                    <Select
                      style={{
                        backgroundColor: !selectedDeviceGraph?.chartPerformance
                          ? "#FEF9C3"
                          : "",
                        border: !selectedDeviceGraph?.chartPerformance
                          ? "1px solid #B91C1C"
                          : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      className="w-[180px] h-[18px] ml-[10px] text-left"
                      name={"chartPerformance"}
                      value={selectedDeviceGraph?.chartPerformance}
                      onChange={(value) =>
                        handleOptionChange("graphPerformance", value)
                      }
                    >
                      <Option value="">Select option</Option>
                      {dataListDevicesDCA?.data?.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <div className="flex  flex-col col-span-2 w-full h-[5%] pl-[75px] max-h-[10%] ">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Performance
                    </label>
                  </div>
                  <div className="flex flex-col h-[90%]">
                    <FrameGraph2
                      open={open2}
                      onCloseModal={() => handleCloseModalZoom(2)}
                      dataHistory={dataHistoryDCA}
                      dataIOT={dataIOTAllPlatforms?.requestData !== null
                        ? dataIOTAllPlatforms?.requestData[
                        selectedDeviceGraph?.chartPerformance?.split(" ")[0]
                        ]
                        : []}
                      typeDevice={selectedDeviceGraph?.chartPerformance}
                    />
                  </div>
                </div>
                <div className="card w-full row-span-3  bg-white pt-[0.5em] col-span-2">
                  <div className='flex flex-row gap-4 w-full pl-[0.5rem] '>
                    <ExpandIcon onClick={() => handleOpenModalZoom(3)} className="w-5 h-5 hover:bg-pc-grey3 cursor-pointer" />
                    <Select
                      style={{
                        backgroundColor: !selectedDeviceGraph?.chartProdHistory
                          ? "#FEF9C3"
                          : "",
                        border: !selectedDeviceGraph?.chartProdHistory
                          ? "1px solid #B91C1C"
                          : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      className="w-[180px] h-[18px] ml-[10px] text-left"
                      name={"chartProdHistory"}
                      value={selectedDeviceGraph?.chartProdHistory}
                      onChange={(value) =>
                        handleOptionChange("graphProduction", value)
                      }
                    >
                      <Option value="">Select option</Option>
                      {dataListDevicesDCA?.data
                      ?.filter((item) => item.includes("Separator"))
                      .map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <label htmlFor="chartType" className="mr-2">Chart Type:</label>
                    <Select
                      id="chartType"
                      value={selectedOption}
                      onChange={(value) => handleOptionChange("chartType", value)}
                      className="w-[70px] h-[20px]"
                    >
                      <Option value="Line">Line</Option>
                      <Option value="Bar">Bar</Option>
                    </Select>
                    <button
                      className="w-[75px] h-5 hover:bg-pc-grey3 ml-[10px] bg-pa-purple text-white rounded-md text-[12px]"
                      onClick={() => handleOpenModal(3)}
                    >
                      Allocation
                    </button>
                  </div>
                  <div className='flex flex-row w-full h-[10%] pl-[2em] max-h-[15%]'>
                    <label className="text-pc-grey2  w-[18%] font-bold text-[14px] p-1 mr-6">
                      Production History
                    </label>
                    <div className='flex flex-row w-2/4 h-full items-center text-right justify-end gap-2'>
                      <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.forecasting.production_history ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                        type="checkbox"
                        checked={checkTypeGraph.forecasting.production_history}
                        onChange={(event) => handleTypeGraph(event, "production_history")}
                        disabled={!selectedWellDCA || selectedWellDCA === "null" ? true : false}
                      />
                      <label htmlFor="" className='text-bc-gray text-[14px]'>Historical Data + RT avg</label>
                    </div>
                    <div className='flex flex-row w-[20%] h-full items-center text-right justify-end gap-2'>
                      <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.forecasting.production_history ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                        type="checkbox"
                        checked={checkTypeGraph?.forecasting.production_history}
                        onChange={(event) => handleTypeGraph(event, "production_history")}
                        disabled={!selectedWellDCA || selectedWellDCA === "null" ? true : false}
                      />
                      <label htmlFor="" className='text-bc-gray text-[14px]'>Real Time Data</label>
                  </div>
                </div>
                <div className="flex flex-col h-[90%]">
                  <FrameGraph3 open={open3} 
                    onCloseModal={() => handleCloseModalZoom(3)} 
                    typeGraph3={selectedOption} 
                    dataHistory={dataHistoryDCA} 
                    dataIOT={dataGraphDCAProduction ? dataGraphDCAProduction : []}
                    typeDevice={selectedDeviceGraph?.chartProdHistory}
                    statusGraph={checkTypeGraph?.forecasting?.production_history}
                  />
                </div>
              </div>
            </div>
          )}

          <AlertModal
            showAlert={showAlert}
            handleCloseModal={handleCloseModalAlert}
            messageAlert={messageAlert}
            typeModal={typeAlert}
          ></AlertModal>

          <Modal
            title="Allocation Information"
            centered
            open={isOpen}
            onOk={() => {
              if (!loadVolumeT) {
                handleClickAverages();
              }
            }}
            onCancel={() => {
              if (!loadVolumeT) {
                setOpen(false);
              }
            }}
            okButtonProps={{
              className: "bg-[#662D91]",
            }}
            width={720}
            cancelButtonProps={{disabled: loadVolumeT}}
          > 
            {mutationUploadJsonVolumeTracker.isLoading  ? 
              <div 
              className="flex flex-col w-full text-center justify-center mt-8 h-[320px]"                >
                <Loading/>
              </div>
            :
              <div className="flex flex-col w-full h-full mt-[45px] ">
                {selectedCardId === 3 && (
                  <div className="grid grid-cols-2 w-[550px] h-[320px] p-2 ml-[20px] ">
                    <label
                      htmlFor=""
                      className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mb-[7px] mt-[15px] "
                    >
                      Measuring Fluid Device Location
                    </label>
                    <Select
                      style={{
                        backgroundColor: !inputVolumeTracker?.production_history?.separator_type
                          ? "#FEF9C3"
                          : "",
                        border: !inputVolumeTracker?.production_history?.separator_type
                          ? "3px solid #B91C1C"
                          : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      className="w-full max-w-[260px] h-[47px] text-left mt-[15px] "
                      name={"production_history_modal"}
                      value={inputVolumeTracker?.production_history?.separator_type}
                      onChange={(value) =>
                        handleOptionChange("separatorProductionHistory", value)
                      }
                    >
                      <Option value="">Select option</Option>
                      {dataListDevicesDCA?.data?.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <label
                      htmlFor=""
                      className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mb-[7px] mt-[15px] "
                    >
                      Well Fluid Allocation Factor (%)
                    </label>
                    <input
                      type="number"
                      min="0"
                      style={{ WebkitAppearance: "none" }}
                      name="fcat"
                      value={inputVolumeTracker?.production_history?.fcat}
                      onChange={(e) => handleChangeFluidInformation(e, "graphProduction")}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[260px] h-[47px] rounded-[4px] px-3 border border-solid  mb-[17px] `}
                    />
                    <label
                      htmlFor=""
                      className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mb-[7px] mt-[15px] "
                    >
                      Well Gas Allocation Factor (%)
                    </label>
                    <input
                      type="number"
                      min="0"
                      style={{ WebkitAppearance: "none" }}
                      name="fcat_gas"
                      value={inputVolumeTracker?.production_history?.fcat_gas}
                      onChange={(e) => handleChangeFluidInformation(e, "graphProduction")}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[260px] h-[47px] rounded-[4px] px-3 border border-solid  mb-[17px] `}
                    />
                    <label
                      htmlFor=""
                      className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Well Water Cut (%)
                    </label>
                    <input
                      type="number"
                      min="0"
                      style={{ WebkitAppearance: "none" }}
                      name="water_cut"
                      value={inputVolumeTracker?.production_history?.water_cut}
                      onChange={(e) => handleChangeFluidInformation(e, "graphProduction")}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[260px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]`}
                    />
                  </div>
                )}
              </div>
            }
          </Modal>
        </div>
      }
    </div>
  )
}