import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Bar,
  Brush,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { DashboardDCAContext } from '../../../../context/DasboardDCA/DashboardDCAContext';
import { WELL_SENSOR_DEVICE } from '../../../elements/types/type_dashboard';
import { REQUIRED_COLUMNS_DOCUMENT_HISTORY } from '../../../elements/types/type_dca';
import { DATA_STANDARD_RT } from '../../../elements/types/type_standard_parameters';
import { formatDateHist, formatDateIOT } from '../../../utils/utils';

function renderTooltip({ active, payload, label }) {
  if (!active || !payload || !payload.length) return null;

  const { oil_rate: rateOil, gas_rate: rateGas, water_rate: rateWater } = payload[0].payload;

  const formatValue = (value, decimals = 1) => parseFloat(value).toFixed(decimals);

  const formatDate = (date) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  };

  return (
    <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
      <p style={{ marginBottom: '5px', textAlign: 'left' }}>{`Date: ${formatDate(label)}`}</p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1 }}>
          {rateGas !== undefined && (
            <p style={{ color: '#ce0f41' }}>
              {`GAS RATE: ${formatValue(rateGas)} MSCF/D`}
            </p>
          )}
          {rateOil !== undefined && (
            <p style={{ color: '#2bca41' }}>
              {`OIL RATE: ${formatValue(rateOil)} STB/D`}
            </p>
          )}
          {rateWater !== undefined && (
            <p style={{ color: '#194bff' }}>
              {`WATER RATE: ${formatValue(rateWater)} STB/D`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export const ChartHistory = (props) => {

  const { dataIOTDCA, typeGraph, dataHistory, enableModal, graphType, typeDevice } = props;
  const { dataCardsDCA, getDataCardsDCA } = useContext(DashboardDCAContext);
  const chartRef = useRef();
  const dataDeviceReplace = typeDevice === WELL_SENSOR_DEVICE?.data[6].sensor ? WELL_SENSOR_DEVICE?.data[5].sensor : typeDevice;

  const [valueOffset, setValueOffset] = useState();

  let dataFinalHistory = [];

  const dataFilter = dataIOTDCA?.data_chart?.filter((item) => {
    return item.lsd === dataDeviceReplace &&
      item.oil_rate !== -999.25 &&
      item.water_rate !== -999.25 &&
      item.gas_rate !== -999.25 &&
      item.oil_rate != null &&
      item.water_rate != null &&
      item.gas_rate != null
  });

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        if (chartRef.current) {
          const chartNode = chartRef.current;
          const offsetTop = chartNode.offsetTop;
          const screenHeight = window.innerHeight;
          setValueOffset(enableModal ? offsetTop + 433 : screenHeight - (offsetTop * 0.94));
        }
      }, 1000);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [enableModal]);

  const formattedData = dataFilter
    ?.map((item) => ({
      ...item,
      date: new Date(formatDateIOT(item.date)).getTime(),
    }))
    .filter((item) => !isNaN(item.date))
    .sort((a, b) => a.date - b.date);

  if (dataHistory?.length > 0 && graphType === false) {
    const dataHistoryMod = dataHistory?.map((item) => ({
      ...item,
      date: new Date(formatDateHist(item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[0]])).getTime(),
      oil_rate: item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[1]],
      gas_rate: item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[2]],
      water_rate: item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[3]],
    })).filter(
      (item) =>
        item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[1]] !== -999.25 &&
        item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[2]] !== -999.25 &&
        item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[3]] !== -999.25);

    dataFinalHistory = dataHistoryMod;
  }
  else if (formattedData && graphType === true) {
    dataFinalHistory = formattedData;
  }

  useEffect(() => {
    const lastData = formattedData?.[formattedData.length - 1] || {};
    getDataCardsDCA({
      ...dataCardsDCA,
      production_performance_gas_rate: lastData[DATA_STANDARD_RT.GENERAL.PARAMETER_004],
      production_performance_oil_rate: lastData[DATA_STANDARD_RT.GENERAL.PARAMETER_003],
      production_performance_water_rate: lastData[DATA_STANDARD_RT.GENERAL.PARAMETER_005],
      production_performance_wor: lastData["wor"],
      production_performance_gor: lastData["gor"]
    })
  }, [typeDevice, dataIOTDCA])

  const maxY1Domain = Math.max(...dataFinalHistory?.map(item => item['oil_rate']));
  const maxY2Domain = Math.max(...dataFinalHistory?.map(item => item['gas_rate']));

  const y1Domain = Math.ceil(Math.round(maxY1Domain * 10) / 1000) * 10;
  const y2Domain = Math.ceil(Math.round(maxY2Domain * 10) / 1000) * 10;

  return (
    <div className="w-full h-full" ref={chartRef}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={200}
          data={dataFinalHistory} // dataFinalHistory
          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 45,
          }}
        >
          <Brush dataKey="Date (MM/DD/YYYY)" height={10} stroke="#8884d8" y={valueOffset} />
          <XAxis
            dataKey="date"
            type='number'
            domain={['dataMin', 'dataMax']}
            label={{
              value: 'Date', position: 'bottom',
              fill: 'black'
            }}
            tickFormatter={(tick) =>
              new Date(tick).toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
              })
            }
            tickCount={10}
            tick={{ fill: 'black' }}
            fill='black'
          />
          <YAxis
            yAxisId={1}
            domain={y1Domain}
            label={{
              value: 'Liquid Rate (STB/D)',
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: "middle" },
              fill: 'black'
            }}
            width={110}
            tickFormatter={(value) => Math.round(value)}
            padding={{ bottom: 10 }}
            tick={{ fill: 'black' }}
            fill='black'
          />
          <YAxis
            yAxisId={2}
            orientation="right"
            domain={y2Domain}
            label={{
              value: 'Gas rate (MSCF/D)',
              angle: -90,
              position: 'insideRight',
              style: { textAnchor: "middle" },
              fill: 'black'
            }}
            tickFormatter={(value) => Math.round(value)}
            padding={{ bottom: 10 }}
            tick={{ fill: 'black' }}
            fill='black'
            width={110}
          />
          <Tooltip content={renderTooltip} />
          <ReferenceLine y={0} stroke="#000" yAxisId={1} />
          {typeGraph === 'Bar' ? (
            <>
              <Bar dataKey="gas_rate" name="GAS" fill="#ce0f41" yAxisId={2} />
              <Bar dataKey="water_rate" name="WATER" fill="#194bff" yAxisId={1} />
              <Bar dataKey="oil_rate" name="OIL" fill="#2bca41" yAxisId={1} />
            </>
          ) : (
            <>

              <Line dataKey="water_rate" name="WATER" fill="#194bff" stroke='#194bff' yAxisId={1} />
              <Line dataKey="oil_rate" name="OIL" fill="#2bca41" stroke='#2bca41' yAxisId={1} />
              <Line dataKey="gas_rate" name="GAS" fill="#ce0f41" stroke='#ce0f41' yAxisId={2} />

            </>)}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};