import {
  CaretDownOutlined,
  CaretLeftOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { message, Modal, Select, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertModal } from "../../components/AlertModal";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";
import ChartVogelPL from "../../components/PlungerLift/GraphicsReservoir/ChartVogelPL";
import ChartCompositePL from "../../components/PlungerLift/GraphicsReservoir/ChartCompositePL";
import { DATA_RESERVOIR_PLUNGER_LIFT } from "../../components/elements/types/type_plunger_Lift";
import { STATUS_CODE_PLUNGER_LIFT } from "../../components/elements/types/type_response_API";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import { ReactComponent as SaveData } from "../../assets/img/icons/save.svg";

export const StepMenuEditReservoirPlungerLift = () => {
  const navigate = useNavigate();
  const {
    User,
    userDataInfo,
    dataFilterUserPL,
    setViewLoading,
    mutationSaveDataIPR,
    dataTableIPRPL,
    inputReservoir,
    setInputReservoir,
    listqIPR,
    refetchDataWellPL,
    dataWellPL,
    getSelectDataIPRPL,
    mutationUpdateWellPlungerLift,
    mutationUploadJsonDataGraph,
    mutationDeleteDataGraphicIPR,
    queryListDataGraphic,
    queryDataGraphic,
    refetchListDataGraphic,
    refetchDataGraphic,
    refetchListqIPR
  } = useTabPlungerLift();
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  const { Option } = Select;
  const [selectedValue, setSelectedValue] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [status, setStatus] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [dataInformationEnd, setDataInformationEnd] = useState();
  const [bodyIPR, setBodyIPR] = useState([]);
  const [disabledCheck, setDisabledCheck] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [ActiveChecks, setActiveChecks] = useState([]);
  const [optionOpen, setOptionOpen] = useState();
  const [open, setOpen] = useState();
  useEffect(() => {
    refetchDataWellPL(); 
    refetchListDataGraphic();
    if (inputReservoir.state_reservoir === "saturated") {
      setSelectedValue("saturated");
    } else {
      setSelectedValue("undersaturated");
    }
  }, []);
  
  useEffect(() => {
    setTimeout(() => {
      if (
        dataWellPL?.statusCode === STATUS_CODE_PLUNGER_LIFT.SUCCESS &&
        dataWellPL?.data
      ) {
        setViewLoading(true);
        const filterDataCreate = dataWellPL?.data;
        const filterDataEnd = DATA_RESERVOIR_PLUNGER_LIFT.initialInputReservoir;
        const filteredJson = Object.fromEntries(
          Object.entries(filterDataCreate).filter(
            ([key]) => !(key in filterDataEnd)
          )
        );

        setDataInformationEnd(filteredJson);

        setInputReservoir((prevState) => ({
          ...prevState,
          layer_pressure: filterDataCreate?.changeData?.res_pressure
            ? Number(filterDataCreate?.res_pressure)
            : filterDataCreate?.layer_pressure,
          layer_temperature: filterDataCreate?.changeData?.res_temperature
            ? Number(filterDataCreate?.res_temperature)
            : filterDataCreate?.layer_temperature,
          md_perf_depth: filterDataCreate?.md_perf_depth,
          water_cut_fraction: filterDataCreate?.water_cut_fraction,
          state_reservoir: filterDataCreate?.state_reservoir,
          ipr_model: filterDataCreate?.ipr_model,
          test_pressure_1_s: filterDataCreate?.test_pressure_1_s,
          test_flow_rate_1_s: filterDataCreate?.test_flow_rate_1_s,
          test_pressure_2_s: filterDataCreate?.test_pressure_2_s,
          test_flow_rate_2_s: filterDataCreate?.test_flow_rate_2_s,
          productivity_index_j_s: filterDataCreate?.productivity_index_j_s,
          liquid_flow_rate_s: filterDataCreate?.liquid_flow_rate_s,
          test_pressure_1_us: filterDataCreate?.test_pressure_1_us,
          test_flow_rate_1_us: filterDataCreate?.test_flow_rate_1_us,
          test_pressure_2_us: filterDataCreate?.test_pressure_2_us,
          test_flow_rate_2_us: filterDataCreate?.test_flow_rate_2_us,
          productivity_index_j_us: filterDataCreate?.productivity_index_j_us,
          liquid_flow_rate_us: filterDataCreate?.liquid_flow_rate_us,
          check_phases: filterDataCreate?.check_phases,
          pwf_ipr: filterDataCreate?.pwf_ipr,
          straight_line: filterDataCreate?.straight_line,
          bubblePointPressure: filterDataCreate?.changeData
            ?.bubble_point_pressure
            ? Number(filterDataCreate?.bubble_point_pressure)
            : Number(filterDataCreate?.bubblePointPressure),
        }));

        setSelectedValue(filterDataCreate?.state_reservoir);

        filterDataCreate?.pwf_ipr && filterDataCreate?.straight_line
          ? setActiveChecks([0, 1])
          : filterDataCreate?.pwf_ipr
          ? setActiveChecks([...ActiveChecks, 0])
          : filterDataCreate?.straight_line
          ? setActiveChecks([...ActiveChecks, 1])
          : setActiveChecks([]);
        setTimeout(() => {
          setViewLoading(false);
        }, 200);
      }
      refetchListqIPR()
    }, 500);
  }, [dataWellPL]);

  useEffect(() => {
    const buildDataReservoirBody = () => {
      const reservoirType = inputReservoir.state_reservoir;
      const isSaturated = reservoirType === "saturated";
      const Pb = Number(inputReservoir.bubblePointPressure);
      if (
        (inputReservoir.productivity_index_j_s === "" && isSaturated) ||
        (inputReservoir.productivity_index_j_us === "" && !isSaturated)
      ) {
        const pr = Number(inputReservoir.layer_pressure) || 0;
        const w_cut = Number(inputReservoir.water_cut_fraction) || 0;
        let qliq_test = null;
        const pwfTest = isSaturated
          ? [
              Number(inputReservoir.test_pressure_1_s) || 0,
              Number(inputReservoir.test_pressure_2_s)
                ? Number(inputReservoir.test_pressure_2_s)
                : 0,
            ].filter((value) => value !== 0)
          : [
              Number(inputReservoir.test_pressure_1_us) || 0,
              Number(inputReservoir.test_pressure_2_us)
                ? Number(inputReservoir.test_pressure_2_us)
                : 0,
            ].filter((value) => value !== 0);
        const qoTest = isSaturated
          ? [
              Number(inputReservoir.test_flow_rate_1_s) || 0,
              Number(inputReservoir.test_flow_rate_2_s)
                ? Number(inputReservoir.test_flow_rate_2_s)
                : 0,
            ].filter((value) => value !== 0)
          : [
              Number(inputReservoir.test_flow_rate_1_us) || 0,
              Number(inputReservoir.test_flow_rate_2_us)
                ? Number(inputReservoir.test_flow_rate_2_us)
                : 0,
            ].filter((value) => value !== 0);

        if (isSaturated) {
          if (inputReservoir.check_phases === "three") {
            qliq_test = Number(inputReservoir.liquid_flow_rate_s);
          }
        } else {
          if (inputReservoir.check_phases === "three") {
            qliq_test = Number(inputReservoir.liquid_flow_rate_us);
          }
        }

        const body = !isSaturated
          ? {
              pr,
              pb: Pb,
              j_index: null,
              pwf_test: pwfTest.map((value) => value || 0),
              qo_test: qoTest.map((value) => value || 0),
              reservoir_type: reservoirType,
              phase: inputReservoir.check_phases,
              w_cut: w_cut,
              qliq_test,
            }
          : {
              pr,
              pb: Pb,
              j_index: null,
              pwf_test: pwfTest.map((value) => value || 0),
              qo_test: qoTest.map((value) => value || 0),
              reservoir_type: reservoirType,
              phase: inputReservoir.check_phases,
              w_cut: w_cut,
              qliq_test,
            };
        setBodyIPR(body);
      } else {
        const Pr = Number(inputReservoir.layer_pressure) || 0;
        const w_cut = Number(inputReservoir.water_cut_fraction) || 0;
        const J = isSaturated
          ? Number(inputReservoir.productivity_index_j_s)
          : Number(inputReservoir.productivity_index_j_us);
        let pwfTest, qoTest, qliqtest;
        if ((isSaturated && J) || (!isSaturated && J)) {
          if (isSaturated) {
            const Pwf = Pr - 100;
            const q =
              J * (Pr / 1.8) * (1 - 0.2 * (Pwf / Pr) - 0.8 * (Pwf / Pr) ** 2);
            const fw = w_cut / 100;
            const fo = 1 - fw;
            const A = (1 - 0.2 * (Pwf / Pb) - 0.8 * (Pwf / Pb)) ^ 2;
            const qlistValue =
              J * (fo * (Pr - Pb + (Pb * A) / 1.8) + fw * (Pr - Pwf));

            qliqtest = qlistValue;
            pwfTest = [Pwf];
            qoTest = [q];

            if (inputReservoir.check_phases === "three") {
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_s),
                pwf_test: [],
                qo_test: [],
                reservoir_type: reservoirType,
                phase: inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null,
              };
              setBodyIPR(body);
            } else {
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_s),
                pwf_test: pwfTest,
                qo_test: qoTest,
                reservoir_type: reservoirType,
                phase: inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null,
              };
              setBodyIPR(body);
            }
          } else {
            if (inputReservoir.check_phases === "one") {
              const Pwf = Pr - 100;
              const q = J * 100;
              pwfTest = [Pwf];
              qoTest = [q];
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_us),
                pwf_test: pwfTest,
                qo_test: qoTest,
                reservoir_type: reservoirType,
                phase: inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null,
              };
              setBodyIPR(body);
            } else if (inputReservoir.check_phases === "two") {
              const Pwf = Number(Pb);
              const q = J * (Pr - Pwf);
              pwfTest = [Pwf];
              qoTest = [q];

              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_us),
                pwf_test: pwfTest,
                qo_test: qoTest,
                reservoir_type: reservoirType,
                phase: inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null,
              };
              setBodyIPR(body);
            } else {
              const Pwf = Number(Pb);
              const q = J * (Pr - Pwf);
              pwfTest = [Pwf];
              qoTest = [q];
              qliqtest = J * (Pr - Pb);

              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_us),
                pwf_test: [],
                qo_test: [],
                reservoir_type: reservoirType,
                phase: inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null,
              };
              setBodyIPR(body);
            }
          }
        }
      }
    };

    buildDataReservoirBody();
  }, [inputReservoir, inputReservoir.bubblePointPressure]);

  const typeLine = ["3 3", "7 2", "4 1", "5 5", "4 3"];
  const getcolor = (index) => {
    const dynamicColors = ["#82ca9d", "#c73c00", "#0045c7", "#f0ad4e"];
    return dynamicColors[index % dynamicColors.length];
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const index = inputReservoir?.selectDataIPR?.indexOf(value);

    return (
      <Tag
        closable={closable}
        onClose={onClose}
        style={{ display: "flex", alignItems: "center" }}
      >
        <svg width="20" height="10" style={{ marginRight: 4 }}>
          <line
            x1="0"
            y1="5"
            x2="20"
            y2="5"
            stroke={
              inputReservoir.check_phases !== "three"
                ? getcolor(index)
                : "black"
            }
            strokeWidth={3}
            strokeDasharray={typeLine[index % typeLine.length]}
          />
        </svg>
        {label}
      </Tag>
    );
  };
  const handleKeyDownScenario = (event) => {
  const allowedKeys = ['-', '_'];
  const isLetterOrNumber = /^[a-zA-Z0-9]$/; 
  const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

    if (!allowedKeys.includes(event.key) && 
        !isLetterOrNumber.test(event.key) && 
        !controlKeys.includes(event.key)) {
      event.preventDefault();
    }
  };
  const handleChangeSelect = (name, valueData) => {
    setSelectedValue(valueData);
    const customEvent = {
      target: {
        name: name,
        value: valueData,
      },
    };
    handleChangeDataReservoir(customEvent);

    let updatedLayerPressure = "";
    if (
      (valueData === "saturated" &&
        Number(inputReservoir.layer_pressure) <
          Number(inputReservoir.bubblePointPressure)) ||
      (valueData === "undersaturated" &&
        Number(inputReservoir.layer_pressure) >
          Number(inputReservoir.bubblePointPressure))
    ) {
      updatedLayerPressure = inputReservoir.layer_pressure;
    }

    setInputReservoir((prevState) => ({
      ...prevState,
      test_pressure_1_s: "",
      test_flow_rate_1_s: "",
      test_pressure_2_s: "",
      test_flow_rate_2_s: "",
      test_pressure_1_us: "",
      test_flow_rate_1_us: "",
      test_pressure_2_us: "",
      test_flow_rate_2_us: "",
      productivity_index_j_us: "",
      productivity_index_j_s: "",
      layer_pressure: updatedLayerPressure,
      ipr_model: "",
      check_phases: false,
    }));
  };

  const handleChangeSelect2 = (name, valueData, isMultiple) => {
    let filteredValues = valueData;

    if (isMultiple) {
      filteredValues = valueData?.filter((option) => option && option !== "");
    }

    if (isMultiple && filteredValues.length > 0) {
      const lastSelectedValue = filteredValues[filteredValues.length - 1]; 
      
      getSelectDataIPRPL(lastSelectedValue);
      
      setTimeout(() => {
        refetchDataGraphic();
      }, 500); 
    } else  if  ( name === "check_phases" ) {
      setInputReservoir((prevState) => ({
        ...prevState,
        selectDataIPR: null
      }))
    }

    const customEvent = {
      target: {
        name: name,
        value: filteredValues,
      },
    };
    handleChangeDataReservoir(customEvent);

  };
  
  const isValidateDataIPR = (inputReservoir) => {
    const {
      state_reservoir,
      layer_pressure,
      bubblePointPressure,
      md_perf_depth,
      water_cut_fraction,
      ipr_model,
      check_phases,
      layer_temperature,
      test_pressure_1_s,
      test_flow_rate_1_s,
      productivity_index_j_s,
      test_pressure_1_us,
      test_flow_rate_1_us,
      productivity_index_j_us,
      liquid_flow_rate_s,
      liquid_flow_rate_us,
    } = inputReservoir;

    const hasRequiredFields =
      !!bubblePointPressure &&
      !!water_cut_fraction &&
      ipr_model !== "" &&
      layer_pressure !== "" &&
      !!layer_pressure &&
      md_perf_depth !== "" &&
      state_reservoir !== "" &&
      check_phases !== "" &&
      !!check_phases &&
      layer_temperature !== "";
    switch (state_reservoir) {
      case "saturated":
        const validTest =
          check_phases === "three"
            ? test_pressure_1_s !== ""
            : test_pressure_1_s !== "" && test_flow_rate_1_s !== "";
        const isValidProductivity = validTest
          ? true
          : productivity_index_j_s !== ""
          ? true
          : false;

        const isValidForPhases =
          check_phases === "three" && productivity_index_j_s === ""
            ? test_pressure_1_s !== "" && liquid_flow_rate_s !== ""
            : true;

        return hasRequiredFields && isValidProductivity && isValidForPhases;

      case "undersaturated":
        const validTestus =
          check_phases === "three"
            ? test_pressure_1_us !== ""
            : test_pressure_1_us !== "" && test_flow_rate_1_us !== "";
        const isValidProductivityus = validTestus
          ? true
          : productivity_index_j_us !== ""
          ? true
          : false;

        const isValidForPhasesus =
          check_phases === "three" && productivity_index_j_us === ""
            ? validTestus && liquid_flow_rate_us !== ""
            : true;

        return hasRequiredFields && isValidProductivityus && isValidForPhasesus;
      default:
        break;
    }
  };

  const isValidReservoirState = (inputReservoir) => {
    const {
      state_reservoir,
      layer_pressure,
      layer_temperature,
      bubblePointPressure,
      water_cut_fraction,
      md_perf_depth,
      check_phases,
      test_flow_rate_1_s,
      test_flow_rate_2_s,
      test_pressure_1_s,
      test_pressure_2_s,
      productivity_index_j_s,
      test_flow_rate_1_us,
      test_flow_rate_2_us,
      test_pressure_1_us,
      test_pressure_2_us,
      productivity_index_j_us,
      liquid_flow_rate_s,
      liquid_flow_rate_us,
    } = inputReservoir;

    const validDataRanks =
      layer_pressure >= 14.65 &&
      layer_pressure <= 25000 &&
      layer_temperature >= 70 &&
      layer_temperature <= 450 &&
      bubblePointPressure >= 14.65 &&
      bubblePointPressure <= 25000 &&
      water_cut_fraction >= 0 &&
      water_cut_fraction <= 100 &&
      md_perf_depth >= 100 &&
      md_perf_depth <= 50000;

    switch (state_reservoir) {
      case "saturated":
        const validTest =
          check_phases === "three"
            ? test_pressure_1_s !== "" &&
              test_pressure_1_s >= 14.65 &&
              test_pressure_1_s <= 25000
            : test_pressure_1_s !== "" &&
              test_pressure_1_s >= 14.65 &&
              test_pressure_1_s <= 25000 &&
              test_flow_rate_1_s !== "" &&
              test_flow_rate_1_s >= 0.01 &&
              test_flow_rate_1_s <= 30000;
        const isValidState =
          Number(layer_pressure) <= Number(bubblePointPressure);
        const isValidProductivity = validTest
          ? true
          : productivity_index_j_s >= 0.001 && productivity_index_j_s <= 100;
        const isValidTest2Flow =
          test_flow_rate_2_s !== ""
            ? test_flow_rate_2_s >= 0.01 && test_flow_rate_2_s <= 30000
            : true;
        const isValidTest2 =
          test_pressure_2_s !== ""
            ? test_pressure_2_s >= 14.65 && test_pressure_2_s <= 25000
            : true;
        const validFlowRate =
          check_phases === "three" && productivity_index_j_s === ""
            ? liquid_flow_rate_s !== "" &&
              liquid_flow_rate_s >= 0.01 &&
              liquid_flow_rate_s <= 30000
            : true;
        return (
          isValidState &&
          isValidProductivity &&
          validFlowRate &&
          validDataRanks &&
          isValidTest2Flow &&
          isValidTest2
        );

      case "undersaturated":
        const validTestus =
          check_phases === "three"
            ? test_pressure_1_us !== "" &&
              test_pressure_1_us >= 14.65 &&
              test_pressure_1_us <= 25000
            : test_pressure_1_us !== "" &&
              test_pressure_1_us >= 14.65 &&
              test_pressure_1_us <= 25000 &&
              test_flow_rate_1_us !== "" &&
              test_flow_rate_1_us >= 0.01 &&
              test_flow_rate_1_us <= 30000;
        const isValidStateus =
          Number(layer_pressure) >= Number(bubblePointPressure);
        const isValidProductivityus = validTestus
          ? true
          : productivity_index_j_us >= 0.001 && productivity_index_j_us <= 100;
        const isValidTest2Flowus =
          test_flow_rate_2_us !== ""
            ? test_flow_rate_2_us >= 0.01 && test_flow_rate_2_us <= 30000
            : true;
        const isValidTest2us =
          test_pressure_2_us !== ""
            ? test_pressure_2_us >= 14.65 && test_pressure_2_us <= 25000
            : true;
        const validFlowRateus =
          check_phases === "three" && productivity_index_j_us === ""
            ? liquid_flow_rate_us >= 0.01 && liquid_flow_rate_us <= 30000
            : true;
        return (
          isValidStateus &&
          isValidProductivityus &&
          validFlowRateus &&
          validDataRanks &&
          isValidTest2Flowus &&
          isValidTest2us
        );
      default:
        break;
    }
  };

  const handleCalculate = () => {
    const validateData = isValidateDataIPR(inputReservoir);
    const validateState = isValidReservoirState(inputReservoir);
    if (validateData) {
      if (bodyIPR && Object.keys(bodyIPR).length > 0 && validateState) {
        setTimeout(() => {
          mutationSaveDataIPR.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            county: dataFilterUserPL?.county,
            field: dataFilterUserPL?.field,
            well: dataFilterUserPL?.well_id,
            username: User.user_name,
            data: bodyIPR,
            type: "parametersplungerlift",
          });
        }, 100);
        setInputEmpty(false);
      } else if (!validateState) {
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Please, modify the invalid information in highlighted fields."
        );
      }
    } else {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "Please, complete the missing information in empty fields"
      );
    }
  };

  const handleClickDone = () => {
    const validateData = isValidateDataIPR(inputReservoir);
    let validateInitial = false;
    if (validateData) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "There are empty fields please validate that the information is complete"
      );
      setInputEmpty(true);
      handleStatusChange();
      validateInitial = false;
    } else {
      validateInitial = true;
    }

    if (inputReservoir.state_reservoir === "saturated") {
      if (
        inputReservoir.productivity_index_j_s !== "" &&
        inputReservoir.test_pressure_1_s === "" &&
        inputReservoir.test_pressure_2_s === "" &&
        inputReservoir.ipr_model !== "" &&
        (inputReservoir.check_phases !== "" ||
          inputReservoir.check_phases !== undefined) &&
        inputReservoir.test_flow_rate_1_s === "" &&
        inputReservoir.test_flow_rate_2_s === ""
      ) {
        validateInitial = true;
      } else if (
        (inputReservoir.productivity_index_j_s === "" &&
          inputReservoir.test_pressure_1_s !== "" &&
          inputReservoir.test_pressure_1_s !== "") ||
        (inputReservoir.productivity_index_j_s === "" &&
          inputReservoir.test_pressure_1_s !== "" &&
          inputReservoir.test_pressure_1_s !== "" &&
          inputReservoir.test_pressure_2_s !== "" &&
          inputReservoir.test_flow_rate_2_s !== "" &&
          inputReservoir.ipr_model !== "" &&
          (inputReservoir.check_phases !== "" ||
            inputReservoir.check_phases !== undefined))
      ) {
        validateInitial = true;
      } else {
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "There are empty fields please validate that the information is complete"
        );
        setInputEmpty(true);
        handleStatusChange();
        validateInitial = false;
      }
    }
    if (inputReservoir.state_reservoir === "undersaturated") {
      if (
        inputReservoir.productivity_index_j_us !== "" &&
        inputReservoir.test_pressure_1_us === "" &&
        inputReservoir.test_pressure_2_us === "" &&
        inputReservoir.ipr_model !== "" &&
        (inputReservoir.check_phases !== "" ||
          inputReservoir.check_phases !== undefined) &&
        inputReservoir.test_flow_rate_1_us === "" &&
        inputReservoir.test_flow_rate_2_us === ""
      ) {
        validateInitial = true;
      } else if (
        (inputReservoir.productivity_index_j_us === "" &&
          inputReservoir.test_pressure_1_us !== "" &&
          inputReservoir.test_pressure_1_us !== "") ||
        (inputReservoir.productivity_index_j_us === "" &&
          inputReservoir.test_pressure_1_us !== "" &&
          inputReservoir.test_pressure_1_us !== "" &&
          inputReservoir.test_pressure_2_us !== "" &&
          inputReservoir.test_flow_rate_2_us !== "" &&
          inputReservoir.ipr_model !== "" &&
          (inputReservoir.check_phases !== "" ||
            inputReservoir.check_phases !== undefined))
      ) {
        validateInitial = true;
      } else {
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "There are empty fields please validate that the information is complete"
        );
        setInputEmpty(true);
        handleStatusChange();
        validateInitial = false;
      }
    }

    if (!inputReservoir.bubblePointPressure) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "The bubble point can't be diferent t the Fluid bubble point value"
      );
      setInputEmpty(true);
      setInputReservoir({ ...inputReservoir, bubblePointPressure: null });
      handleStatusChange();
      validateInitial = false;
    } else if (
      inputReservoir.state_reservoir === "saturated" &&
      Number(inputReservoir.layer_pressure) >
        Number(inputReservoir.bubblePointPressure)
    ) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert("The layer pressure can't be greater than bubble point");
      setInputEmpty(true);
      handleStatusChange();
      validateInitial = false;
    } else if (
      inputReservoir.state_reservoir === "undersaturated" &&
      Number(inputReservoir.layer_pressure) <
        Number(inputReservoir.bubblePointPressure)
    ) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert("The layer pressure can't be less than bubble point");
      setInputEmpty(true);
      handleStatusChange();
      validateInitial = false;
    }
    if (validateInitial === true) {
      setInputEmpty(false);
      const filterDataEnd = Object.assign(
        {},
        inputReservoir,
        dataInformationEnd
      );
      setTimeout(() => {
        mutationUpdateWellPlungerLift.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserPL.county,
          field: dataFilterUserPL.field,
          well: dataFilterUserPL.well_id,
          username: User.user_name,
          data: filterDataEnd,
          type: "parametersplungerlift",
        });
      }, 100);
      navigate(`/${NAME_PAGES.plunger_lift.list_well}`);
      message.success("Processing complete!");
    }
  };

  const handleChangeDataReservoir = (e) => {
    const { name, value, checked } = e.target;
    // const nameDataExist = name === "nameDataGraphic" ? queryListDataGraphic.data?.some(obj => obj.name === value ) : null;
    if (inputReservoir.state_reservoir === "saturated") {
      evaluateConditions("saturated", value);
      if (name === "check_phases") {
        if (value !== "" && value !== undefined) {
          setInputReservoir((prevState) => ({
            ...prevState,
            check_phases: value,
            test_pressure_1_s: "",
            test_pressure_2_s: "",
            test_flow_rate_1_s: "",
            test_flow_rate_2_s: "",
            ipr_model: "",
            productivity_index_j_s: "",
          }));
        }
      } else if (name === "productivity_index_j_s") {
        if (value !== "") {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_s: value,
            test_pressure_1_s: "",
            test_pressure_2_s: "",
            test_flow_rate_1_s: "",
            test_flow_rate_2_s: "",
            ipr_model: "",
            liquid_flow_rate_s: "",
          }));
        } else {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_s: "",
          }));
        }
      } else if (
        name === "layer_pressure" &&
        inputReservoir.test_pressure_1_s > Number(value)
      ) {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        setTimeout(() => {
          setInputReservoir((prevState) => ({
            ...prevState,
            test_pressure_1_s: "",
            ...((value === "" || value !== "") && {
              productivity_index_j_s: "",
              ipr_model: "",
            }),
          }));
          setInputEmpty(true);
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert("The value must be less than layer pressure");
        }, 1500);
      } else if (
        name === "test_pressure_1_s" ||
        name === "test_pressure_2_s" ||
        name === "test_flow_rate_1_s" ||
        name === "test_flow_rate_2_s"
      ) {        
        if (
          name === "test_pressure_1_s" &&
          Number(value) > inputReservoir.layer_pressure
        ) {
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: null,
            ...((value === "" || value !== "") && {
              productivity_index_j_s: "",
              ipr_model: "",
            }),
          }));
          setInputEmpty(true);
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert(
            "Bottomhole pressure in Test 1 must be less than layer pressure"
          );
        } else if (
          name === "test_pressure_2_s" &&
          Number(value) > inputReservoir.test_pressure_1_s
        ) {
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: "",
            ...((value === "" || value !== "") && {
              productivity_index_j_s: "",
              ipr_model: "",
            }),
          }));
          setInputEmpty(true);
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert(
            "Bottomhole pressure in Test 2 must be less than Test 1"
          );
        } else {
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: value,
            ...(value === "" && { productivity_index_j_s: "", ipr_model: "" }),
          }));
        }
      } else {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else if (inputReservoir.state_reservoir === "undersaturated") {
      evaluateConditions("undersaturated", value);
      if (name === "check_phases") {
        if (value !== "" && value !== undefined) {
          setInputReservoir((prevState) => ({
            ...prevState,
            check_phases: value,
            test_pressure_1_us: "",
            test_pressure_2_us: "",
            test_flow_rate_1_us: "",
            test_flow_rate_2_us: "",
            ipr_model: "",
            productivity_index_j_us: "",
          }));
        }
      } else if (name === "productivity_index_j_us") {
        if (value === "") {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_us: "",
          }));
        } else {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_us: value,
            test_pressure_1_us: "",
            test_pressure_2_us: "",
            test_flow_rate_1_us: "",
            test_flow_rate_2_us: "",
            ipr_model: "",
            liquid_flow_rate_us: "",
          }));
        }
      } else if (
        name === "test_pressure_1_us" ||
        name === "test_pressure_2_us" ||
        name === "test_flow_rate_1_us" ||
        name === "test_flow_rate_2_us"
      ) {
        if (
          name === "test_pressure_1_us" &&
          Number(value) > inputReservoir.layer_pressure
        ) {
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: null,
            ...((value === "" || value !== "") && {
              productivity_index_j_us: "",
              ipr_model: "",
            }),
          }));
          setInputEmpty(true);
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert(
            "Bottomhole pressure in Test 1 must be less than layer pressure"
          );
        } else if (
          name === "test_pressure_2_us" &&
          Number(value) > inputReservoir.test_pressure_1_us
        ) {
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: "",
            ...((value === "" || value !== "") && {
              productivity_index_j_us: "",
              ipr_model: "",
            }),
          }));
          setInputEmpty(true);
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert(
            "Bottomhole pressure in Test 2 must be less than Test 1"
          );
        } else {
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: value,
            ...((value === "" || value !== "") && {
              productivity_index_j_us: "",
              ipr_model: "",
            }),
          }));
        }
      } else {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const handleBlurDataReservoir = (e) => {
    const { name, value } = e.target;

    if (
      (name === "layer_pressure" ||
        name === "bubblePointPressure" ||
        name === "test_pressure_1_s" ||
        name === "test_pressure_2_s" ||
        name === "test_pressure_1_us" ||
        name === "test_pressure_2_us") &&
      value !== "" &&
      (parseFloat(value) < 14.65 || parseFloat(value) > 25000)
    ) {
      setInputEmpty(true);
      message.warning("The value must be between 14.65 and 25000");
    } else if (
      name === "layer_temperature" &&
      (parseFloat(value) < 70 || parseFloat(value) > 450)
    ) {
      setInputEmpty(true);
      message.warning("The value must be between 70 and 450");
    } else if (
      name === "water_cut_fraction" &&
      (parseFloat(value) < 0 || parseFloat(value) > 100)
    ) {
      setInputEmpty(true);
      message.warning("The value must be between 0 and 100");
    } else if (
      name === "md_perf_depth" &&
      (parseFloat(value) < 100 || parseFloat(value) > 50000)
    ) {
      setInputEmpty(true);
      message.warning("The value must be between 100 and 50000");
    } else if (
      (name === "productivity_index_j_s" ||
        name === "productivity_index_j_us") &&
      (parseFloat(value) < 0.001 || parseFloat(value) > 100)
    ) {
      setInputEmpty(true);
      message.warning("The value must be between 0.001 and 100");
    } else if (
      (name === "test_flow_rate_1_s" ||
        name === "test_flow_rate_2_s" ||
        name === "test_flow_rate_1_us" ||
        name === "test_flow_rate_2_us" ||
        name === "liquid_flow_rate_s" ||
        name === "liquid_flow_rate_us") &&
      (value < 0.01 || value > 30000) &&
      value !== ""
    ) {
      setInputEmpty(true);
      message.warning("The value must be between 0.01 and 30000");
    }

    if (inputReservoir.state_reservoir === "saturated") {
      if (
        name === "layer_pressure" &&
        Number(value) > Number(inputReservoir.bubblePointPressure)
      ) {
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Reservoir pressure must be less than bubble point pressure"
        );
      } else if (
        name === "bubblePointPressure" &&
        Number(value) < Number(inputReservoir.layer_pressure)
      ) {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: null,
        }));
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Bubble point pressure must be greater than layer pressure"
        );
      } else if (
        name === "test_flow_rate_2_s" &&
        Number(value) < inputReservoir.test_flow_rate_1_s &&
        value !== ""
      ) {
        // Allow these fields to be cleared without automatically overwriting them
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: null,
          //We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
          ...((value === "" || value !== "") && {
            productivity_index_j_s: "",
            ipr_model: "",
          }),
        }));
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert("Oil Rate in Test 2 must be greater than Test 1");
      }
    } else if (inputReservoir.state_reservoir === "undersaturated") {
      if (
        name === "layer_pressure" &&
        Number(value) < Number(inputReservoir.bubblePointPressure)
      ) {
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Reservoir pressure must be greater than bubble point pressure"
        );
      } else if (
        name === "bubblePointPressure" &&
        Number(value) > Number(inputReservoir.layer_pressure)
      ) {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: null,
        }));
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Bubble point pressure must be less than layer pressure"
        );
      } else if (
        name === "test_flow_rate_2_us" &&
        Number(value) < inputReservoir.test_flow_rate_1_us &&
        value !== ""
      ) {
        // Allow these fields to be cleared without automatically overwriting them
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: null,
          //We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
          ...((value === "" || value !== "") && {
            productivity_index_j_us: "",
            ipr_model: "",
          }),
        }));
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert("Oil Rate in Test 2 must be greater than Test 1");
      }
    }
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleKeyDown = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const evaluateConditions = (state, value) => {
    const conditions = [
      {
        condition:
          inputReservoir.check_phases !== "three" &&
          state === "saturated" &&
          inputReservoir.test_pressure_1_s === "" &&
          inputReservoir.test_flow_rate_1_s === "" &&
          inputReservoir.test_pressure_2_s === "" &&
          inputReservoir.test_flow_rate_2_s === "" &&
          value !== "",
        updates: [true, false, true, true],
      },
      {
        condition:
          inputReservoir.check_phases !== "three" &&
          state === "saturated" &&
          inputReservoir.productivity_index_j_s === "" &&
          (inputReservoir.test_pressure_1_s !== "" ||
            inputReservoir.test_flow_rate_1_s !== "") &&
          inputReservoir.test_pressure_2_s === "" &&
          inputReservoir.test_flow_rate_2_s === "" &&
          value !== "",
        updates: [true, false, true, true],
      },
      {
        condition:
          inputReservoir.check_phases !== "three" &&
          state === "saturated" &&
          inputReservoir.productivity_index_j_s === "" &&
          inputReservoir.test_pressure_1_s !== "" &&
          inputReservoir.test_flow_rate_1_s !== "" &&
          (inputReservoir.test_pressure_2_s === "" ||
            inputReservoir.test_flow_rate_2_s === "") &&
          value === "",
        updates: [true, false, true, true],
      },
      {
        condition:
          inputReservoir.check_phases !== "three" &&
          state === "saturated" &&
          inputReservoir.productivity_index_j_s === "" &&
          inputReservoir.test_pressure_1_s !== "" &&
          inputReservoir.test_flow_rate_1_s !== "" &&
          (inputReservoir.test_pressure_2_s !== "" ||
            inputReservoir.test_flow_rate_2_s !== "") &&
          inputReservoir.check_phases === "two" &&
          value !== "",
        updates: [true, false, false, true],
      },
      {
        condition:
          inputReservoir.check_phases === "three" &&
          state === "saturated" &&
          inputReservoir.test_pressure_1_s === "" &&
          inputReservoir.test_flow_rate_1_s === "" &&
          inputReservoir.test_pressure_2_s === "" &&
          inputReservoir.test_flow_rate_2_s === "" &&
          value !== "",
        updates: [true, true, true, false],
      },
      {
        condition:
          inputReservoir.check_phases === "three" &&
          state === "saturated" &&
          inputReservoir.productivity_index_j_s === "" &&
          inputReservoir.test_flow_rate_1_s === "" &&
          inputReservoir.test_pressure_2_s === "" &&
          inputReservoir.test_flow_rate_2_s === "" &&
          value !== "",
        updates: [true, true, true, false],
      },
      {
        condition:
          inputReservoir.check_phases !== "three" &&
          state === "undersaturated" &&
          inputReservoir.test_pressure_1_us === "" &&
          inputReservoir.test_flow_rate_1_us === "" &&
          inputReservoir.test_pressure_2_us === "" &&
          inputReservoir.test_flow_rate_2_us === "" &&
          inputReservoir.check_phases === "one" &&
          value !== "",
        updates: [false, true, true, true],
      },
      {
        condition:
          inputReservoir.check_phases !== "three" &&
          state === "undersaturated" &&
          inputReservoir.test_pressure_1_us === "" &&
          inputReservoir.test_flow_rate_1_us === "" &&
          inputReservoir.test_pressure_2_us === "" &&
          inputReservoir.test_flow_rate_2_us === "" &&
          inputReservoir.check_phases === "two" &&
          value !== "",
        updates: [true, false, true, true],
      },
      {
        condition:
          inputReservoir.check_phases !== "three" &&
          state === "undersaturated" &&
          inputReservoir.productivity_index_j_us === "" &&
          (inputReservoir.test_pressure_1_us !== "" ||
            inputReservoir.test_flow_rate_1_us !== "") &&
          inputReservoir.test_pressure_2_us === "" &&
          inputReservoir.test_flow_rate_2_us === "" &&
          inputReservoir.check_phases === "one" &&
          value !== "",
        updates: [false, true, true, true],
      },
      {
        condition:
          inputReservoir.check_phases !== "three" &&
          state === "undersaturated" &&
          inputReservoir.productivity_index_j_us === "" &&
          inputReservoir.test_pressure_1_us !== "" &&
          inputReservoir.test_flow_rate_1_us !== "" &&
          (inputReservoir.test_pressure_2_us === "" ||
            inputReservoir.test_flow_rate_2_us === "") &&
          inputReservoir.check_phases === "two" &&
          value === "",
        updates: [true, false, true, true],
      },
      {
        condition:
          inputReservoir.check_phases !== "three" &&
          state === "undersaturated" &&
          inputReservoir.productivity_index_j_us === "" &&
          inputReservoir.test_pressure_1_us !== "" &&
          inputReservoir.test_flow_rate_1_us !== "" &&
          (inputReservoir.test_pressure_2_us !== "" ||
            inputReservoir.test_flow_rate_2_us !== "") &&
          inputReservoir.check_phases === "two" &&
          value !== "",
        updates: [true, false, false, true],
      },
      {
        condition:
          inputReservoir.check_phases === "three" &&
          state === "undersaturated" &&
          inputReservoir.test_pressure_1_us === "" &&
          inputReservoir.test_flow_rate_1_us === "" &&
          inputReservoir.test_pressure_2_us === "" &&
          inputReservoir.test_flow_rate_2_us === "" &&
          value !== "",
        updates: [true, true, true, false],
      },
      {
        condition:
          inputReservoir.check_phases === "three" &&
          state === "undersaturated" &&
          inputReservoir.productivity_index_j_us === "" &&
          inputReservoir.test_flow_rate_1_us === "" &&
          inputReservoir.test_pressure_2_us === "" &&
          inputReservoir.test_flow_rate_2_us === "" &&
          value !== "",
        updates: [true, true, true, false],
      },
    ];
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].condition) {
        setDisabledCheck((prevArray) => {
          const newArray = [...prevArray];
          newArray[1] = conditions[i].updates[0];
          newArray[2] = conditions[i].updates[1];
          newArray[3] = conditions[i].updates[2];
          newArray[4] = conditions[i].updates[3];
          return newArray;
        });
        break;
      }
    }
  };
  const validateWarningfields = (name, value) => {
    if (inputEmpty) {
      switch (name) {
        case "bubblePointPressure":
          return (
            value === "" || value === null || value < 14.65 || value > 25000
          );
        case "md_perf_depth":
          return value === "" || value < 100 || value > 50000;
        case "check_phases":
          return value === null;
        case "layer_temperature":
          return value === "" || value < 70 || value > 450;
        case "water_cut_fraction":
          return value === "" || value < 0 || value > 100;
        default:
          break;
      }
      if (inputReservoir.state_reservoir === "saturated") {
    
        switch (name) {
          case "layer_pressure":
            return (
              value === "" ||
              value > inputReservoir.bubblePointPressure ||
              value < 14.65 ||
              value > 25000
            );
          case "productivity_index_j_s":
            return (
              inputReservoir.test_pressure_1_s == "" &&
              inputReservoir.test_flow_rate_1_s == "" &&
              (value === "" || value < 0.001 || value > 100)
            );
          case "test_flow_rate_1_s":
            return (
              inputReservoir.productivity_index_j_s === "" &&
              (value === "" ||
                value === null ||
                value < 0.01 ||
                value > 30000) &&
              inputReservoir.check_phases !== "three"
            );
          case "test_flow_rate_2_s":
            return (
              (value === null ||
                (value !== "" && (value < 0.01 || value > 30000))) &&
              inputReservoir.check_phases !== "three"
            );
          case "test_pressure_1_s":
            return (
              inputReservoir.productivity_index_j_s === "" &&
              (value === "" || value === null || value < 14.65 || value > 25000)
            );
          case "test_pressure_2_s":
            return (
              (value === null ||
                (value !== "" && (value < 14.65 || value > 25000))) &&
              inputReservoir.check_phases !== "three"
            );
          case "liquid_flow_rate_s":
            return (
              inputReservoir.productivity_index_j_s === "" &&
              (value === "" || value < 0.01 || value > 30000) &&
              inputReservoir.check_phases === "three"
            );
          default:
            break;
        }
      } else if (inputReservoir.state_reservoir === "undersaturated") {
        switch (name) {
          case "layer_pressure":
            return (
              value === "" ||
              value < inputReservoir.bubblePointPressure ||
              value < 14.65 ||
              value > 25000
            );
          case "productivity_index_j_us":
            return (
              inputReservoir.test_pressure_1_us === "" &&
              inputReservoir.test_flow_rate_1_us === "" &&
              (value === "" || value < 0.001 || value > 100)
            );
          case "test_flow_rate_1_us":
            return (
              inputReservoir.productivity_index_j_us === "" &&
              (value === "" ||
                value === null ||
                value < 0.01 ||
                value > 30000) &&
              inputReservoir.check_phases !== "three"
            );
          case "test_flow_rate_2_us":
            return (
              (value === null ||
                (value !== "" && (value < 0.01 || value > 30000))) &&
              inputReservoir.check_phases !== "three"
            );
          case "test_pressure_1_us":
            return (
              inputReservoir.productivity_index_j_us === "" &&
              (value === "" || value === null || value < 14.65 || value > 25000)
            );
          case "test_pressure_2_us":
            return (
              (value === null ||
                (value !== "" && (value < 14.65 || value > 25000))) &&
              inputReservoir.check_phases !== "three"
            );
          case "liquid_flow_rate_us":
            return (
              inputReservoir.productivity_index_j_us === "" &&
              (value === "" || value < 0.01 || value > 30000) &&
              inputReservoir.check_phases === "three"
            );
          default:
            break;
        }
      }
    } else {
      return false;
    }
  };
  const handleChangeOptionOpen = () => {
    if (optionOpen) {
      setOptionOpen(false);
    } else {
      setOptionOpen(true);
    }
  };
  const handleOpenModalData = () => {
    if (!!dataTableIPRPL) {
      setOpen(true);
    } else {
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert("There is not scenario to save. Please, run a model.");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert the string in a date object
    const day = String(date.getDate()).padStart(2, "0"); // obtain the day and secure two digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // obtain the month
    const year = date.getFullYear(); // obtain the year
    return `${day}/${month}/${year}`; // Format DD/MM/YYYY
  };

  const handleClickUploadData = () => {
    const data = {
      ...listqIPR?.data,
      date: formatDate(inputReservoir?.dateDataGraphic),
      name: inputReservoir?.nameDataGraphic,
      phase: inputReservoir?.check_phases,
      reservoir_type: inputReservoir.state_reservoir,
    };

    // const nameDataExist = !!listScenarios
    //   ? listScenarios.data?.some(
    //       (obj) => obj.name === inputReservoir?.nameDataGraphic
    //     )
    //   : null;

    if (!!data && !!listqIPR?.data /* && !nameDataExist */) {
      setTimeout(() => {
        mutationUploadJsonDataGraph.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserPL.county,
          field: dataFilterUserPL.field,
          well: dataFilterUserPL.well_id,
          username: User.user_name,
          data: data,
        });
      }, 500);
      setOpen(false);
      setInputReservoir((prevState) => ({
        ...prevState,
        nameDataGraphic: "",
      }));
    } /* else if (nameDataExist) {
      message.info("Please, use a different name for this scenario");
    } */ else {
      message.info("First run the process");
    }
  };

  const handleDeleteScenario = (name) => {
 
    setTimeout(() => {
      mutationDeleteDataGraphicIPR.mutate({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: dataFilterUserPL.county,
        field: dataFilterUserPL.field,
        well: dataFilterUserPL.well_id,
        username: User.user_name,
        nameIPRPL: name,
      });
    }, 500);

    if (inputReservoir?.selectDataIPR?.includes(name)) {
      const updatedSelections = inputReservoir.selectDataIPR.filter(
        (selected) => selected !== name
      );
      handleChangeSelect2("selectDataIPR", updatedSelections, true);
    }
  };
  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex h-full abosolute"></Sidebar>
      </div>
      <div className="flex w-full h-full">
        <AlertModal
          showAlert={showAlert}
          handleCloseModal={handleCloseModal}
          messageAlert={messageAlert}
          typeModal={typeAlert}
        ></AlertModal>
        <Modal
        title='Saving Scenarios'
        centered
        open={open}
        onOk={() => handleClickUploadData()}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
        width={720}
      >
        <div className="flex flex-col w-full h-full mt-[45px] ">
            <div className=" w-[550px] h-[300px] p-2 ml-[20px] ">
              <label
                htmlFor=""
                className="flex w-[450px] max-w-[550px] text-start font bold text-[15px] mb-[15px] mt-[15px] "
              >
                Name 
                <span className="text-[12px] font-normal ml-1 align-baseline">(Please, do not use special characters like %, =, /, etc.)</span>
              </label>
              <input
                type="text" style={{ WebkitAppearance: 'none' }}
                name="nameDataGraphic"
                value={inputReservoir?.nameDataGraphic}
                onChange={handleChangeDataReservoir}
                onKeyDown={handleKeyDownScenario}
                className={`w-full max-w-[450px] h-[47px] rounded-[4px] px-3 border border-solid  mb-[17px] `}
              />
              <label
                htmlFor=""
                className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mb-[15px]"
              >
                Date
              </label>
              <input
                type="date" style={{ WebkitAppearance: 'none' }}
                name="dateDataGraphic"
                value={inputReservoir?.dateDataGraphic}
                className={`w-full max-w-[450px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]`}
              />
            </div>
        </div>
      </Modal>
      </div>
      <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center mt-[30px]">
        <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px]">
          <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">
              Layer Information
            </h1>
          </div>
        </div>
        <div className="flex flex-row gap-10">
          <div className="flex flex-col items-end w-1/2">
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Layer pressure (psia)
              <Tooltip title={"The value must be between 14.65 and 25000"}>
                <InfoCircleOutlined
                  className="ml-2"
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                />
              </Tooltip>
            </label>
            <input
              type="number"
              name="layer_pressure"
              min="0"
              disabled={
                inputReservoir.bubblePointPressure === null ||
                inputReservoir.bubblePointPressure === ""
              }
              onKeyDown={handleKeyDown}
              value={inputReservoir.layer_pressure}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleBlurDataReservoir(e)}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                validateWarningfields(
                  "layer_pressure",
                  inputReservoir.layer_pressure
                )
                  ? " border-red-700 bg-yellow-100"
                  : " border-[#BCBCCC]"
              } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Bubble point pressure (psia)
              <Tooltip title={"The value must be between 14.65 and 25000"}>
                <InfoCircleOutlined
                  className="ml-2"
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                />
              </Tooltip>
            </label>
            <input
              type="number"
              name="bubblePointPressure"
              min="0"
              onKeyDown={handleKeyDown}
              value={inputReservoir.bubblePointPressure}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleBlurDataReservoir(e)}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                validateWarningfields(
                  "bubblePointPressure",
                  inputReservoir.bubblePointPressure
                )
                  ? " border-red-700 bg-yellow-100"
                  : " border-[#BCBCCC]"
              } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Mid-perf Measured Depth (feet)
              <Tooltip title={"The value must be between 100 and 50000"}>
                <InfoCircleOutlined
                  className="ml-2"
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                />
              </Tooltip>
            </label>
            <input
              type="number"
              name="md_perf_depth"
              min="0"
              onKeyDown={handleKeyDown}
              value={inputReservoir.md_perf_depth}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleBlurDataReservoir(e)}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                validateWarningfields(
                  "md_perf_depth",
                  inputReservoir.md_perf_depth
                )
                  ? " border-red-700 bg-yellow-100"
                  : " border-[#BCBCCC]"
              } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] font bold text-[15px] mb-[5px] justify-left"
            >
              Phase
            </label>
            <Select
              className="flex w-full h-[47px] max-w-[592px] text-start mb-[7px]"
              name="check_phases"
              value={inputReservoir.check_phases || "Selected option"}
              onChange={(e) => handleChangeSelect2("check_phases", e,false)}
            >
              <Option
                value="one"
                disabled={inputReservoir.state_reservoir === "saturated"}
              >
                One (Oil)
              </Option>
              <Option value="two">Two (Oil + Gas)</Option>
              <Option value="three">Three (Oil + Gas + Water)</Option>
            </Select>
          </div>
          <div className="flex flex-col items-start justify-start w-1/2 text-left">
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Layer temperature (deg F)
              <Tooltip title={"The value must be between 70 and 450"}>
                <InfoCircleOutlined
                  className="ml-2"
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                />
              </Tooltip>
            </label>
            <input
              type="number"
              name="layer_temperature"
              min="0"
              onKeyDown={handleKeyDown}
              value={inputReservoir.layer_temperature}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleBlurDataReservoir(e)}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                validateWarningfields(
                  "layer_temperature",
                  inputReservoir.layer_temperature
                )
                  ? " border-red-700 bg-yellow-100"
                  : " border-[#BCBCCC]"
              } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Water cut (%)
              <Tooltip title={"The value must be between 0 and 100"}>
                <InfoCircleOutlined
                  className="ml-2"
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                />
              </Tooltip>
            </label>
            <input
              type="number"
              name="water_cut_fraction"
              min="0"
              max={100}
              onKeyDown={handleKeyDown}
              value={inputReservoir.water_cut_fraction}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleBlurDataReservoir(e)}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                validateWarningfields(
                  "water_cut_fraction",
                  inputReservoir.water_cut_fraction
                )
                  ? " border-red-700 bg-yellow-100"
                  : " border-[#BCBCCC]"
              } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir State
            </label>
            <Select
              className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
              name="state_reservoir"
              value={inputReservoir.state_reservoir || "Selected Option"}
              onChange={(e) => handleChangeSelect("state_reservoir", e)}
            >
              <Option value="saturated">Saturated</Option>
              <Option value="undersaturated">Undersaturated</Option>
            </Select>
            {selectedValue === "saturated" && (
              <>
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Productivity index J (STB/D/psi)
                  <Tooltip title={"The value must be between 0.001 and 100"}>
                    <InfoCircleOutlined
                      className="ml-2"
                      style={{ color: "rgba(0, 0, 0, 0.45)" }}
                    />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="productivity_index_j_s"
                  min="0"
                  onKeyDown={handleKeyDown}
                  value={inputReservoir.productivity_index_j_s}
                  onChange={handleChangeDataReservoir}
                  onBlur={(e) => handleBlurDataReservoir(e)}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                    validateWarningfields(
                      "productivity_index_j_s",
                      inputReservoir.productivity_index_j_s
                    )
                      ? "border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                  } `}
                  disabled={
                    inputReservoir.test_pressure_1_s !== "" ||
                    inputReservoir.test_pressure_2_s !== "" ||
                    inputReservoir.test_flow_rate_1_s !== "" ||
                    inputReservoir.test_flow_rate_2_s !== ""
                  } // Deshabilita si cualquier campo de test tiene un valor
                />
              </>
            )}
            {selectedValue === "undersaturated" && (
              <>
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Productivity Index J
                  <Tooltip title={"The value must be between 0.001 and 100"}>
                    <InfoCircleOutlined
                      className="ml-2"
                      style={{ color: "rgba(0, 0, 0, 0.45)" }}
                    />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="productivity_index_j_us"
                  min="0"
                  onKeyDown={handleKeyDown}
                  value={inputReservoir.productivity_index_j_us}
                  onChange={handleChangeDataReservoir}
                  onBlur={(e) => handleBlurDataReservoir(e)}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                    validateWarningfields(
                      "productivity_index_j_us",
                      inputReservoir.productivity_index_j_us
                    )
                      ? " border-red-700 bg-yellow-100"
                      : " border-[#BCBCCC]"
                  } `}
                  disabled={
                    inputReservoir.test_pressure_1_us !== "" ||
                    inputReservoir.test_pressure_2_us !== "" ||
                    inputReservoir.test_flow_rate_1_us !== "" ||
                    inputReservoir.test_flow_rate_2_us !== ""
                  } // Deshabilita si cualquier campo de test tiene un valor
                />
              </>
            )}
          </div>
        </div>
        {selectedValue === "saturated" && (
          <>
            <div className="flex flex-row gap-10 mt-[10px]">
              <div className="flex flex-col items-end w-1/2">
                <label
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                  htmlFor=""
                >
                  Oil Flow Rate (STB/D)
                </label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                      <Tooltip
                        title={"The value must be between 0.01 and 30000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_1_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_1_s}
                      onChange={handleChangeDataReservoir}
                      onBlur={(e) => handleBlurDataReservoir(e)}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                        validateWarningfields(
                          "test_flow_rate_1_s",
                          inputReservoir.test_flow_rate_1_s
                        )
                          ? " border-red-700 bg-yellow-100"
                          : " border-[#BCBCCC]"
                      } `}
                      disabled={
                        inputReservoir.productivity_index_j_s !== "" ||
                        inputReservoir.check_phases === "three"
                      }
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                      <Tooltip
                        title={"The value must be between 0.01 and 30000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_2_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_2_s}
                      onChange={handleChangeDataReservoir}
                      onBlur={(e) => handleBlurDataReservoir(e)}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                        validateWarningfields(
                          "test_flow_rate_2_s",
                          inputReservoir.test_flow_rate_2_s
                        )
                          ? " border-red-700 bg-yellow-100"
                          : " border-[#BCBCCC]"
                      } `}
                      disabled={
                        inputReservoir.productivity_index_j_s !== "" ||
                        inputReservoir.test_flow_rate_1_s === "" ||
                        inputReservoir.check_phases === "three"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <label
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                  htmlFor=""
                >
                  Bottomhole Pressure (psia)
                  <Tooltip
                    title={`To use Fetkovich model pressure test 1 and pressure test 2 must be below bubble point pressure`}
                  >
                    <InfoCircleOutlined
                      className="ml-2"
                      style={{ color: "rgba(0, 0, 0, 0.45)" }}
                    />
                  </Tooltip>
                </label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                      <Tooltip
                        title={"The value must be between 14.65 and 25000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_pressure_1_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_pressure_1_s}
                      onChange={handleChangeDataReservoir}
                      onBlur={(e) => handleBlurDataReservoir(e)}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                        validateWarningfields(
                          "test_pressure_1_s",
                          inputReservoir.test_pressure_1_s
                        )
                          ? "border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                      }${
                        inputReservoir.productivity_index_j_s !== ""
                          ? " disabled"
                          : ""
                      } `}
                      disabled={inputReservoir.productivity_index_j_s !== ""}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                      <Tooltip
                        title={"The value must be between 14.65 and 25000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_pressure_2_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_pressure_2_s}
                      onChange={handleChangeDataReservoir}
                      onBlur={(e) => handleBlurDataReservoir(e)}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                        validateWarningfields(
                          "test_pressure_2_s",
                          inputReservoir.test_pressure_2_s
                        )
                          ? " border-red-700 bg-yellow-100"
                          : " border-[#BCBCCC]"
                      } `}
                      disabled={
                        inputReservoir.productivity_index_j_s !== "" ||
                        inputReservoir.test_pressure_1_s === "" ||
                        inputReservoir.check_phases === "three"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {inputReservoir.check_phases === "three" && (
              <>
                <div className="flex flex-row w-full gap-10">
                  <div className="flex flex-col items-end w-1/2">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Liquid Flow Rate (STB/D)
                    </label>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] mt-[6px]"
                    >
                      Test 1
                      <Tooltip
                        title={"The value must be between 0.01 and 30000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="liquid_flow_rate_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.liquid_flow_rate_s}
                      onChange={handleChangeDataReservoir}
                      onBlur={handleBlurDataReservoir}
                      disabled={inputReservoir.productivity_index_j_s !== ""}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                        validateWarningfields(
                          "liquid_flow_rate_s",
                          inputReservoir.liquid_flow_rate_s
                        )
                          ? " border-red-700 bg-yellow-100"
                          : " border-[#BCBCCC]"
                      } `}
                    />
                  </div>
                  <div className="flex flex-col items-start w-1/2"></div>
                </div>
              </>
            )}
            <div className="flex flex-row w-full gap-10 ">
              <div className="flex flex-col items-end w-1/2">
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  IPR model
                </label>
                <Select
                  style={{
                    backgroundColor:
                      inputEmpty && inputReservoir.ipr_model === ""
                        ? "#FEF9C3"
                        : "",
                    border:
                      inputEmpty && inputReservoir.ipr_model === ""
                        ? "1px solid #B91C1C"
                        : "",
                    borderRadius: "8px",
                    textAlignalign: "left",
                  }}
                  className="w-full h-[47px] max-w-[592px] text-start mb-[7px] "
                  name="ipr_model"
                  value={inputReservoir.ipr_model || "Selected option"}
                  onChange={(e) => handleChangeSelect2("ipr_model", e,false)}
                >
                  <Option value="straight" disabled={disabledCheck[1]}>
                    Straight line
                  </Option>
                  <Option value="vogel" disabled={disabledCheck[2]}>
                    Vogel
                  </Option>
                  <Option value="fetko" disabled={disabledCheck[3]}>
                    Fetkovich
                  </Option>
                  <Option value="composite" disabled={disabledCheck[4]}>
                    Composite
                  </Option>
                </Select>
              </div>
              <div className="flex flex-col items-start w-1/2"></div>
            </div>
            <div className="flex flex-col w-full text-center items-center justify-start gap-y-[2em] mt-[17px] mb-[17px]">
              <button
                className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                onClick={handleCalculate}
              >
                Run
              </button>
            </div>
            <div className="flex items-center justify-center w-full h-full text-center">
              <div
                className="flex flex-col w-[1220px] h-full text-center items-center justify-center border-solid"
                style={{
                  border: "1px solid #BCBCCC",
                }}
              >
                <div className="flex w-[1150px] h-6 text-center items-center justify-end gap-y-[2em] mt-[17px]">
                  {optionOpen && (
                    <Select
                      style={{
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      className="min-w-[200px] min-h-[24px] mr-[15px] text-left overflow-x-auto"
                      name="selct_modelIPR"
                      placeholder={"Select scenario"}
                      value={
                        inputReservoir?.selectDataIPR?.length
                          ? inputReservoir?.selectDataIPR
                          : undefined
                      }
                      onChange={(e) => {
                        const maxSelections =
                          inputReservoir?.check_phases === "three" ? 2 : 4;
                        if (e.length <= maxSelections) {
                          handleChangeSelect2("selectDataIPR", e, true);
                        }
                      }}
                      mode="multiple"
                      tagRender={tagRender}
                      disabled={!!dataTableIPRPL ? false : true}
                    >
                      {queryListDataGraphic?.data
                        ?.filter(
                          (item) =>
                            item.phase === inputReservoir?.check_phases &&
                            item.reservoir_type ===
                              inputReservoir.state_reservoir
                        )
                        ?.map((item, index) => (
                          <Option key={index} value={item.name}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                {item.name} ({item.date})
                              </span>
                              <DeleteOutlined
                                onClick={(e) => {
                                  e.stopPropagation(); // Detener la propagación para evitar seleccionar la opción
                                  handleDeleteScenario(item.name);
                                }}
                                style={{ marginRight: 8, cursor: "pointer" }}
                              />
                            </div>
                          </Option>
                        ))}
                    </Select>
                  )}
                  {!optionOpen && (
                    <Tooltip
                      placement="bottom"
                      title={"expand options"}
                      color={"#662D91"}
                    >
                      <CaretDownOutlined
                        onClick={() => handleChangeOptionOpen()}
                        className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                      />
                    </Tooltip>
                  )}
                  {optionOpen && (
                    <Tooltip
                      placement="bottom"
                      title={"hide options"}
                      color={"#662D91"}
                    >
                      <CaretLeftOutlined
                        onClick={() => handleChangeOptionOpen()}
                        className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    placement="bottom"
                    title={"save calculations"}
                    color={"#662D91"}
                  >
                    <SaveData
                      onClick={handleOpenModalData}
                      className="w-6 h-7 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple justify-end"
                    />
                  </Tooltip>
                </div>
                {inputReservoir.check_phases !== "three" && (
                  <ChartVogelPL
                    dataResults={dataTableIPRPL}
                    optionDataIPR={inputReservoir.selectDataIPR}
                    dataIPR={queryDataGraphic?.data[0]}
                  ></ChartVogelPL>
                )}
                {inputReservoir.check_phases === "three" && (
                  <ChartCompositePL
                    dataResults={dataTableIPRPL}
                    optionDataIPR={inputReservoir.selectDataIPR}
                    dataIPR={queryDataGraphic?.data[0]}
                  ></ChartCompositePL>
                )}
              </div>
            </div>
            <div className="flex w-full text-center justify-center items-center mb-[90px]">
              <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                <button
                  className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                  onClick={() => handleClickDone()}
                >
                  Done
                </button>
              </div>
            </div>
          </>
        )}
        {selectedValue === "undersaturated" && (
          <>
            <div className="flex flex-row gap-10 mt-[10px]">
              <div className="flex flex-col items-end w-1/2">
                <label
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                  htmlFor=""
                >
                  Oil Flow Rate (STB/D)
                </label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                      <Tooltip
                        title={"The value must be between 0.01 and 30000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_1_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_1_us}
                      onChange={handleChangeDataReservoir}
                      onBlur={(e) => handleBlurDataReservoir(e)}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                        validateWarningfields(
                          "test_flow_rate_1_us",
                          inputReservoir.test_flow_rate_1_us
                        )
                          ? " border-red-700 bg-yellow-100"
                          : " border-[#BCBCCC]"
                      } `}
                      disabled={
                        inputReservoir.productivity_index_j_us !== "" ||
                        inputReservoir.check_phases === "three"
                      }
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                      <Tooltip
                        title={"The value must be between 0.01 and 30000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_2_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_2_us}
                      onChange={handleChangeDataReservoir}
                      onBlur={(e) => handleBlurDataReservoir(e)}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                        validateWarningfields(
                          "test_flow_rate_2_us",
                          inputReservoir.test_flow_rate_2_us
                        )
                          ? " border-red-700 bg-yellow-100"
                          : " border-[#BCBCCC]"
                      } `}
                      disabled={
                        inputReservoir.productivity_index_j_us !== "" ||
                        inputReservoir.test_flow_rate_1_us === "" ||
                        (inputReservoir.test_flow_rate_1_us !== "" &&
                          inputReservoir.check_phases !== "two") ||
                        inputReservoir.check_phases === "three"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <label
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                  htmlFor=""
                >
                  Bottomhole Pressure (psia)
                  <Tooltip
                    title={`To use Fetkovich model pressure test 1 and pressure test 2 must be below bubble point pressure`}
                  >
                    <InfoCircleOutlined
                      className="ml-2"
                      style={{ color: "rgba(0, 0, 0, 0.45)" }}
                    />
                  </Tooltip>
                </label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold textt-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                      <Tooltip
                        title={"The value must be between 14.65 and 25000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      min="0"
                      onKeyDown={handleKeyDown}
                      name="test_pressure_1_us"
                      value={inputReservoir.test_pressure_1_us}
                      onChange={handleChangeDataReservoir}
                      onBlur={(e) => handleBlurDataReservoir(e)}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                        validateWarningfields(
                          "test_pressure_1_us",
                          inputReservoir.test_pressure_1_us
                        )
                          ? "border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                      }${
                        inputReservoir.productivity_index_j_s !== ""
                          ? " disabled"
                          : ""
                      } `}
                      disabled={inputReservoir.productivity_index_j_us !== ""}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                      <Tooltip
                        title={"The value must be between 14.65 and 25000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_pressure_2_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_pressure_2_us}
                      onChange={handleChangeDataReservoir}
                      onBlur={(e) => handleBlurDataReservoir(e)}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                        validateWarningfields(
                          "test_pressure_2_us",
                          inputReservoir.test_pressure_2_us
                        )
                          ? " border-red-700 bg-yellow-100"
                          : " border-[#BCBCCC]"
                      } `}
                      disabled={
                        inputReservoir.productivity_index_j_us !== "" ||
                        inputReservoir.test_pressure_1_us === "" ||
                        (inputReservoir.test_flow_rate_1_us !== "" &&
                          inputReservoir.check_phases !== "two") ||
                        inputReservoir.check_phases === "three"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {inputReservoir.check_phases === "three" && (
              <>
                <div className="flex flex-row w-full gap-10">
                  <div className="flex flex-col items-end w-1/2">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Liquid Flow Rate (STB/D)
                    </label>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] mt-[6px]"
                    >
                      Test 1
                      <Tooltip
                        title={"The value must be between 0.01 and 30000"}
                      >
                        <InfoCircleOutlined
                          className="ml-2"
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="liquid_flow_rate_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.liquid_flow_rate_us}
                      disabled={inputReservoir.productivity_index_j_us !== ""}
                      onChange={handleChangeDataReservoir}
                      onBlur={handleBlurDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${
                        validateWarningfields(
                          "liquid_flow_rate_us",
                          inputReservoir.liquid_flow_rate_us
                        )
                          ? " border-red-700 bg-yellow-100"
                          : " border-[#BCBCCC]"
                      } `}
                    />
                  </div>
                  <div className="flex flex-col items-start w-1/2"></div>
                </div>
              </>
            )}
            <div className="flex flex-row w-full gap-10">
              <div className="flex flex-col items-end w-1/2">
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  IPR model
                </label>
                <Select
                  style={{
                    backgroundColor:
                      inputEmpty && inputReservoir.ipr_model === ""
                        ? "#FEF9C3"
                        : "",
                    border:
                      inputEmpty && inputReservoir.ipr_model === ""
                        ? "1px solid #B91C1C"
                        : "",
                    borderRadius: "8px",
                    textAlignalign: "left",
                  }}
                  className="w-full h-[47px] max-w-[592px] text-start mb-[7px]"
                  name="ipr_model"
                  value={inputReservoir.ipr_model || "Selected option"}
                  onChange={(e) => handleChangeSelect2("ipr_model", e,false)}
                >
                  <Option value="straight" disabled={disabledCheck[1]}>
                    Straight line
                  </Option>
                  <Option value="vogel" disabled={disabledCheck[2]}>
                    Vogel
                  </Option>
                  <Option value="fetko" disabled={disabledCheck[3]}>
                    Fetkovich
                  </Option>
                  <Option value="composite" disabled={disabledCheck[4]}>
                    Composite
                  </Option>
                </Select>
              </div>
              <div className="flex flex-col items-start w-1/2"></div>
            </div>
            <div className="flex flex-col w-full text-center items-center justify-start gap-y-[2em] mt-[17px] mb-[17px]">
              <button
                className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                onClick={handleCalculate}
              >
                Run
              </button>
            </div>
            <div className="flex items-center justify-center w-full h-full text-center">
              <div
                className="flex flex-col w-[1220px] h-full text-center items-center justify-center border-solid"
                style={{
                  border: "1px solid #BCBCCC",
                }}
              >
                <div className="flex w-[1150px] h-6 text-center items-center justify-end gap-y-[2em] mt-[17px]">
                  {optionOpen && (
                    <Select
                      style={{
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      className="min-w-[200px] min-h-[24px] mr-[15px] text-left overflow-x-auto"
                      name="selct_modelIPR"
                      placeholder={"Select scenario"}
                      value={
                        inputReservoir?.selectDataIPR?.length
                          ? inputReservoir?.selectDataIPR
                          : undefined
                      }
                      onChange={(e) => {
                        const maxSelections =
                          inputReservoir?.check_phases === "three" ? 2 : 4;
                        if (e.length <= maxSelections) {
                          handleChangeSelect2("selectDataIPR", e, true);
                        }
                      }}
                      mode="multiple"
                      tagRender={tagRender}
                      disabled={!!dataTableIPRPL ? false : true}
                    >
                      {queryListDataGraphic?.data
                        ?.filter(
                          (item) =>
                            item.phase === inputReservoir?.check_phases &&
                            item.reservoir_type ===
                              inputReservoir.state_reservoir
                        )
                        ?.map((item, index) => (
                          <Option key={index} value={item.name}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                {item.name} ({item.date})
                              </span>
                              <DeleteOutlined
                                onClick={(e) => {
                                  e.stopPropagation(); // Detener la propagación para evitar seleccionar la opción
                                  handleDeleteScenario(item.name);
                                }}
                                style={{ marginRight: 8, cursor: "pointer" }}
                              />
                            </div>
                          </Option>
                        ))}
                    </Select>
                  )}
                  {!optionOpen && (
                    <Tooltip
                      placement="bottom"
                      title={"expand options"}
                      color={"#662D91"}
                    >
                      <CaretDownOutlined
                        onClick={() => handleChangeOptionOpen()}
                        className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                      />
                    </Tooltip>
                  )}
                  {optionOpen && (
                    <Tooltip
                      placement="bottom"
                      title={"hide options"}
                      color={"#662D91"}
                    >
                      <CaretLeftOutlined
                        onClick={() => handleChangeOptionOpen()}
                        className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    placement="bottom"
                    title={"save calculations"}
                    color={"#662D91"}
                  >
                    <SaveData
                      onClick={handleOpenModalData}
                      className="w-6 h-7 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple justify-end"
                    />
                  </Tooltip>
                </div>
                {inputReservoir.check_phases !== "three" && (
                  <ChartVogelPL
                    dataResults={dataTableIPRPL}
                    optionDataIPR={inputReservoir.selectDataIPR}
                    dataIPR={queryDataGraphic?.data[0]}
                  ></ChartVogelPL>
                )}
                {inputReservoir.check_phases === "three" && (
                  <ChartCompositePL
                    dataResults={dataTableIPRPL}
                    optionDataIPR={inputReservoir.selectDataIPR}
                    dataIPR={queryDataGraphic?.data[0]}
                  ></ChartCompositePL>
                )}
              </div>
            </div>
            <div className="flex w-full text-center justify-center items-center mb-[90px]">
              <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                <button
                  className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                  onClick={() => handleClickDone()}
                >
                  Done
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
