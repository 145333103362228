import { serviceEndPoints } from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_IPR = serviceEndPoints.IPR.BASE_URL_IPR + "/IPR";

export const uploadJsonIPR = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/upload_json/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem uploading JSON for IPR");
  }
};

export const uploadCsvIPR = async (operator, lease, region, country, field, well, dataFile) => {
  if (!validateParams(operator, lease, region, country, field, well)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/upload_csv/${operator}/${lease}/${region}/${country}/${field}/${well}`, {
      method: "POST",
      headers: {
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
      body: dataFile,
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem uploading CSV for IPR");
  }
};

export const calculateQIPR = async (operator, lease, region, country, field, well, username) => {
  if (!validateParams(operator, lease, region, country, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/calculate_q/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: {
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem calculating Q for IPR");
  }
};

export const calculatePwfIPR = async (operator, lease, region, country, field, well, dataFile) => {
  if (!validateParams(operator, lease, region, country, field, well)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/calculate_pwf/${operator}/${lease}/${region}/${country}/${field}/${well}`, {
      method: "GET",
      headers: {
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem calculating Pwf for IPR");
  }
};

export const uploadaDataGraphic = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/upload_json_graphic/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem uploading data graphic for IPR");
  }
};

export const dataGraphic = async (operator, lease, region, country, field, well, username, nameIPR) => {
  if (!validateParams(operator, lease, region, country, field, well, username, nameIPR)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/ipr_graph/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/${nameIPR}`, {
      method: "GET",
      headers: {
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data graphic for IPR");
  }
};

export const listDataGraphic = async (operator, lease, region, country, field, well, username) => {
  if (!validateParams(operator, lease, region, country, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/list_ipr/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: {
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem listing data graphic for IPR");
  }
};

export const deleteDataGraphic = async (dataJson) => {
  const { operator, lease, region, country, field, well, username, nameIPR } = dataJson;
  if (!validateParams(operator, lease, region, country, field, well, username, nameIPR)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/deleted_graphic_ipr/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/${nameIPR}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem deleting data graphic for IPR");
  }
};

export const dataReservoirDryGas = async (operator, lease, region, county, field, well, username) => {
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/calc_ipr_gas/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: {
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting reservoir data for dry gas");
  }
};

export const uploadaDataReservoirDryGas = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/upload_ipr_gas/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem uploading reservoir data for dry gas");
  }
};

export const deleteScenariosReservoir = async (dataJson) => {
  const { operator, lease, region, country, field, well, username } = dataJson;
  if (!validateParams(operator, lease, region, country, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/deleted_scenarios_ipr/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem deleting scenarios reservoir for dry gas");
  }
};

export const getListDataChartIPRGas = async (operator, lease, region, county, field, wellid, username) => {
  if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/list_ipr_gas/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
      method: "GET",
      headers: {
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem listing chart data for IPR gas");
  }
};

export const getDataChartIPRGas = async (operator, lease, region, county, field, wellid, username, nameChart) => {
  if (!validateParams(operator, lease, region, county, field, wellid, username, nameChart)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/ipr_graph_gas/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/${nameChart}`, {
      method: "GET",
      headers: {
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting chart data for IPR gas");
  }
};

export const postDataChartIPRGas = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/upload_json_graphic_gas/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem posting chart data for IPR gas");
  }
};

export const deleteScenarioChartIPRGas = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, name } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username, name)) return;

  try {
    const response = await fetch(`${BASE_URL_IPR}/deleted_graphic_ipr_gas/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/${name}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem deleting chart scenario for IPR gas");
  }
};
