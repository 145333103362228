export const DATA_STANDARD_RT = {
    GENERAL: {
        PARAMETER_000:"Date (MM/DD/YYYY)",//"date",
        PARAMETER_001:"Static Pressure",//"pressure_tubing",
        PARAMETER_002:"Flowing Temperature",//"temperature_tubing",
        PARAMETER_003:"oil_rate",
        PARAMETER_004:"gas_rate",
        PARAMETER_005:"water_rate",
        PARAMETER_006:"gas_current_day_volume",
        PARAMETER_007:"gas_previous_day_volume",
        PARAMETER_008:"oil_current_day_volume",
        PARAMETER_009:"oil_previous_day_volume"
    },
    DCA : {
        PARAMETER_010:"Corrected Accumulated Volume",//"gas_cumulative",
        PARAMETER_011:"Corrected Accumulated Volume"//"oil_cumulative"
    },
    GASLIFT : {
        PARAMETER_012:"Static Pressure",//"pressure_injection",
        PARAMETER_013:"Gas Rate"//"gas_rate_injection"
    },
    PLUNGER_LIFT : {
        PARAMETER_014:"pressure_pipeline",
        PARAMETER_015:"pressure_casing",
        PARAMETER_016:"liquid_rate",
        PARAMETER_021:"pressure_tubing"
    },

    CALCULATIONS: {
        PARAMETER_017:"gas_rate_total",
        PARAMETER_018:"liquid_rate_total"
    },
    AUX: {
        PARAMETER_019:"pressure_casing",
        PARAMETER_020:"temperature_casing"
    },
  };