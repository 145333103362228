import React, { useEffect, useState } from 'react';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';

const ChartReservoir = ({dataGraph}) => {

  const [data, setData] = useState([]);

  useEffect(() => {    
    if(dataGraph?.delta_pseudopress_FAF_test && dataGraph?.gas_rate_test_m){
      const validateDataGraph = transformedData(dataGraph, "delta_pseudopress_FAF_test", "gas_rate_test_m");
      setData(validateDataGraph);
    }else{
      setData([]);
    }
  }, [dataGraph]);

  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      const dataTooltip = payload[0].payload;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
          <p style={{ color: '#000000' }}>{`∆Pp/q: ` + (dataTooltip?.delta_pseudopress).toFixed(2) + ` (psia²/cp/(Mscf/d))`}</p>
          <p style={{ color: '#d4362c' }}>{`Gas rate: ` + (dataTooltip?.gas_m).toFixed(2) + ` (Mscf/d)`}</p>
        </div>
      )
    }
  }

  function transformedData(data, value1, value2) {
    if(data && value1 && value2){
      const transform = data[value1]?.map((_, index) => ({
        delta_pseudopress: data[value1][index],
        gas_m: data[value2][index]
      }));
  
      return transform ? transform : [];
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 35,
          bottom: 25,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="gas_m" 
          label={{ 
            value: "Gas rate (Mscf/d)", 
            position: "insideBottom", 
            offset: -5,
            dx: -5,
            dy: 10,
          }}
          reversed={true}
        />
        <YAxis 
          type="number"
          tickFormatter={(tick) => tick.toExponential(2)}
          label={{ 
            value: "∆Pp/q (psia²/cp/(Mscf/d))", 
            angle: -90, 
            position: "insideLeft", 
            offset: -5,
            dx: -15,
            dy: 100,
          }}
          domain={['auto', 'auto']}
        />
        <Tooltip content={<CustomTooltip/>}/>
        <Line type="monotone" dataKey="delta_pseudopress" stroke="#d4362c" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}
export default ChartReservoir;

