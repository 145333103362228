import { message, Modal, Select, Switch, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/icon/arrow-repeat.svg";
import { ReactComponent as ExpandIcon } from "../../assets/icon/expand1.svg";
import { ReactComponent as SaveAlert } from "../../assets/img/icons/save.svg";
import {
  DATA_ALERTS,
  INITIAL_TYPE_GRAPH,
  WELL_SENSOR_DEVICE,
} from "../../components/elements/types/type_dashboard";
import {
  DATA_DOMAIN_GRAPH,
  INITIAL_DATA_ALERTS_GASLIFT,
  STATE_ALERTS,
} from "../../components/elements/types/type_gas_lift";
import {
  STATUS_CODE_UNTUNED_VLP,
  STATUS_CODE_VLP,
  STATUS_DATA_GAS_LIFT,
  STATUS_DATA_HISTORY,
  STATUS_DATA_VLP,
} from "../../components/elements/types/type_response_API";
import {
  ErrorLoadingDashboard,
  ErrorLoadingTypeGraph,
} from "../../components/ErrorPage";
import { CardGraph } from "../../components/GasLift/Complements/CardGraph";
import { ImportFile } from "../../components/GasLift/Complements/ImportFile";
import { ChartProdHistory } from "../../components/GasLift/GraphicsDashboard/ChartProdHistory/ChartProdHistory";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { Loading, LoadingDashboard } from "../../components/Loading";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useFilters } from "../../hooks/useFilters";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabIOT } from "../../hooks/useTabIOT";
import { useTabTuning } from "../../hooks/useTabTuning";
import { useTabFlow } from "../../hooks/useTabFlow";
import { ChartInflowOutflow } from "../../components/GasLift/GraphicsFlow/ChartInflowOutflow";
import ChartHole from "../../components/GasLift/GraphicsDashboard/ChartHole/ChartHole";
import ChartWellCond from "../../components/GasLift/GraphicsDashboard/ChartWellCond/ChartWellCond";
import ChartVLP from "../../components/GasLift/GraphicsTuning/ChartVLP";
import Carrusel from "../../components/GasLift/Complements/Carrusel/Carrusel";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import { DataGasLiftContext } from "../../context/DataGasLift/DataGasLiftContext";
import { AlertModal } from "../../components/AlertModal";
import { validateJsonEmptyAlert } from "../../helpers/helpersValidate";

const dataWell = {
  well_name: "",
  company: "",
  country: "",
  region: "",
  field: "",
  well_orientation: "",
};

const initialInputVolumeTracker = {
  hole: {
    separator_type: "",
    platform: ""
  },
  well_cond: {
    separator_type: "",
    platform: ""
  },
  production_history: {
    fcat: null,
    fcat_gas: null,
    water_cut: null,
    separator_type: "",
    platform: ""
  }

};


const initialDataAverage = {
  fcat: null,
  fcat_gas: null,
  water_cut: null,
  separator_type: null,
};

const initialSelectedDeviceGraph = {
  chartHole: "",
  chartWellCond: "",
  chartProdHistory: ""
}

const numberPreGraph = {
  chartHole: 0,
  chartWellCond: 1,
  chartProdHistory: 2
}

export const DashboardGasLift = () => {
  const { Option } = Select;
  const [selectedOption, setSelectedOption] = useState("Line");
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const {
    viewLoadingGasLift,
    validateAlertGasLift,
    getValidateAlertGasLift
  } = useContext(DataGasLiftContext);
  const {
    getDataPage,
    selectedWell,
    dataDomainGraph,
    getDataDomainGraph,
    getDataAlertGasLift,
    dataAlertGasLift,
    someAlert,
    getSomeAlert,
    checkTypeGraph,
    getCheckTypeGraph,
    deviceRealTime,
    getDeviceRealTime,
    selectIntervalVLP,
    isOpen2,
    getSelectIntervalVLP,
    getIsOpenModalAllocattion,
    slideGraph,
    getSlideGraph
  } = useContext(DashboardContext);

  const {
    dataFilterUserGasLift,
    dataWellGasLift,
    queryDataHistory,
    refetchDataWellGasLift,
    refetchDataHistory,
    loadDataWellGasLift,
    mutationUpdateDataGasLift,
    mutationUploadJsonVolumeTracker,
    dataVolumeTracker,
    refetchVolumeTracker,
    dataWellGL,
    setDataWellGL,
    dataIOTAllPlatforms,
    dataChartGL_well_head,
    refetchDataIOTAllPlatforms,
    refetchDataChartGL_well_head,
    loadDataIOTAllPlatforms,
    dataVolumeTrackerRT,
    refetchVolumeTrackerRT,
    dataChartGL_well_head_inj,
    refetchChartGL_well_head_inj,
    refetchChartProductionData,
    chartProductionData,
    listWellsGasLift,
    getDataFilterUserGaslift,
    refetchListWellsGasLift
  } = useTabGaslift();
  const {
    queryDataTuning,
    refetchTuning,
    queryCalculateTuningVLP,
    queryCalculateUntunedVLP,
    refetchCalculateTuningVLP,
    refetchCalculateUntunedVLP,
    mutationUpdateDataTuning,
    maxDepth,
    minDepth,
    maxPressure,
    minPressure,
    calculateRangesGraphVLP,
    refetchPressDrowDown,
    queryPressDrowDown,
  } = useTabTuning();
  const { dataListDevicesGaslift } = useTabIOT();
  const { queryDataFlowGraph, refetchDataFlowGraph } = useTabFlow();
  const { dataFilter, User } = useFilters();
  const { userDataInfo } = useTabGaslift();

  const [domainGraph, setDomainGraph] = useState(DATA_DOMAIN_GRAPH);
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [inputInformation, setInputInformation] = useState(dataWell);
  const [arrayDataHistory, setArrayDataHistory] = useState([]);
  const [dataCardDashboard, setDataCardDashboard] = useState([]);
  const [switchVLP, setSwitchVLP] = useState(false);
  const [dataGrapFlow, setDataGrapFlow] = useState([]);
  const [typeGraphVLP, setTypeGraphVLP] = useState("vlp");
  const [loadVolumeT, setLoadVolumeT] = useState(false);
  const [dataGraphProduction, setDataGraphProduction] = useState([]);
  let [dataTableGraphUntVLP, setDataTableGraphUntVLP] = useState();
  let [activeChecks, setActiveChecks] = useState();
  let [dataTableGauge, setDataTableGauge] = useState();
  let [dataTableGraphVLP, setDataTableGraphVLP] = useState();
  const seletedFilter = dataFilter ? dataFilter?.data : null;
  const [inputVolumeTracker, setInputVolumeTracker] = useState(initialInputVolumeTracker);
  const [selectedDeviceGraph, setSelectedDeviceGraph] = useState(initialSelectedDeviceGraph);
  const [validAllocation, setValidAllocation] = useState(false);
  const [inputVolumeTrackerWP, setInputVolumeTrackerWP] =
    useState(initialDataAverage);
  const [inputVolumeTrackerWIC, setInputVolumeTrackerWIC] =
    useState(initialDataAverage);
  const [inputVolumeTrackerPH, setInputVolumeTrackerPH] =
    useState(initialDataAverage);

  useEffect(() => {
    getDataPage(NAME_PAGES.gas_lift.dashboard);
  }, []);

  useEffect(() => {
    if (listWellsGasLift?.data && !!selectedWell) {
      const dataWellFind = listWellsGasLift?.data.find((item) => item.well_id === selectedWell);
      if (dataWellFind) {
        const dataFilterNew = {
          county: dataWellFind?.county,
          field: dataWellFind?.field,
          well_id: dataWellFind?.well_id,
          well_platforms: dataWellFind?.well_platforms,
          well_name: dataWellFind?.well_name,
        };
        getDataFilterUserGaslift(dataFilterNew);
      }
    } else{
      refetchListWellsGasLift();
    }
  }, [listWellsGasLift, selectedWell]);

  useEffect(() => {
    if (
      dataFilterUserGasLift?.county &&
      dataFilterUserGasLift?.field &&
      dataFilterUserGasLift?.well_id
    ) {
      refetchDataWellGasLift();
      refetchTuning();
      refetchDataHistory();
      refetchVolumeTracker();
    }
  }, [dataFilterUserGasLift]);

  useEffect(() => {
    if (chartProductionData) setDataGraphProduction(chartProductionData.data);
  }, [chartProductionData])

  useEffect(() => {
    if (
      !!dataFilterUserGasLift &&
      dataWellGasLift?.statusCode === STATUS_DATA_GAS_LIFT.SUCCESS
    ) {
      const {
        well_name,
        company,
        country,
        region,
        field,
        well_orientation,
        well_id,
        data_alert,
        data_check,
        type_graph_dashboard,
        data_domain_graph,
        volumeTracker,
        volumeTrackerWP,
        volumeTrackerWIC,
        selected_platform_graph,
        selected_device_graph,
        selectTimeRTDashboard,
        slide_graph_gaslift
      } = dataWellGasLift?.data || {};

      const getDataTuning = queryDataTuning?.data;

      setInputInformation({
        ...inputInformation,
        well_name: well_name,
        company: company,
        country: country,
        region: region,
        field: field,
        well_orientation: well_orientation,
        well_id: well_id,
        selected_device_graph: selected_device_graph
      });

      if (!!volumeTracker) {
        setInputVolumeTrackerPH(volumeTracker);
      } else {
        setInputVolumeTrackerPH({
          ...inputVolumeTrackerPH,
          water_cut: getDataTuning?.water_cut,
        });
      }

      if (!!volumeTrackerWP) {
        setInputVolumeTrackerWP(volumeTrackerWP);
      } else {
        setInputVolumeTrackerWP({
          ...inputVolumeTrackerPH,
          water_cut: getDataTuning?.water_cut,
        });
      }

      if (!!volumeTrackerWIC) {
        setInputVolumeTrackerWIC(volumeTrackerWIC);
      } else {
        setInputVolumeTrackerWIC({
          ...inputVolumeTrackerPH,
          water_cut: getDataTuning?.water_cut,
        });
      }

      if (!!selected_device_graph) {
        setSelectedDeviceGraph(selected_device_graph)
      }

      if (volumeTracker) {
        setInputVolumeTracker({ ...inputVolumeTracker, production_history: volumeTracker.production_history });
      }
      if (slide_graph_gaslift !== null || slide_graph_gaslift !== undefined) {
        getSlideGraph({
          ...slideGraph,
          gas_lift: slide_graph_gaslift
        })
      }

      getDataAlertGasLift(
        data_alert && !!Object.keys(data_alert).length
          ? data_alert
          : INITIAL_DATA_ALERTS_GASLIFT
      );

      getSomeAlert({
        ...someAlert,
        messageChange: false,
        gas_lift:
          data_check && Object.keys(data_check).length
            ? data_check
            : DATA_ALERTS.gas_lift,
      });

      getCheckTypeGraph({
        ...checkTypeGraph,
        gas_lift:
          type_graph_dashboard && Object.keys(type_graph_dashboard).length
            ? type_graph_dashboard
            : INITIAL_TYPE_GRAPH.gas_lift,
      });

      const domainGraph = Array.isArray(data_domain_graph) && data_domain_graph.length  === 0 ? DATA_DOMAIN_GRAPH : data_domain_graph;
      setDomainGraph(domainGraph);
      getDataDomainGraph(domainGraph);

      setDataWellGL(dataWellGasLift?.data);

      setInputVolumeTrackerPH((prevState) => ({
        ...prevState,
        separator_type: selected_device_graph?.chartProdHistory,
      }));
      setInputVolumeTrackerWIC((prevState) => ({
        ...prevState,
        separator_type: selected_device_graph?.chartWellCond,
      }));
      setInputVolumeTrackerWP((prevState) => ({
        ...prevState,
        separator_type: selected_device_graph?.chartHole,
      }));

      getDeviceRealTime({
        ...deviceRealTime,
        device_gaslift: {
          ...deviceRealTime.device_gaslift,
          selected_platform_graph: {
            chartHole: selected_platform_graph?.chartHole,
            chartWellCond: selected_platform_graph?.chartWellCond,
            chartProdHistory: selected_platform_graph?.chartProdHistory,
          },
          selected_device_graph: {
            chartHole: selected_device_graph?.chartHole,
            chartWellCond: selected_device_graph?.chartWellCond,
            chartProdHistory: selected_device_graph?.chartProdHistory,
          },
        },
      });

      if (!!selectTimeRTDashboard) {
        getSelectIntervalVLP(selectTimeRTDashboard);
      }
      setTimeout(() => {
        sequentialUpdateVLPWHP();
        refetchDataFlowGraph();
        refetchPressDrowDown();
        refetchVolumeTrackerRT();
        refetchDataIOTAllPlatforms();
        // refetchDataChartGL_well_head();
        // refetchChartGL_well_head_inj();
        refetchChartProductionData();
      }, 400);
    }
  }, [dataWellGasLift,dataFilterUserGasLift]);

  useEffect(() => {
    if (dataIOTAllPlatforms?.requestData) {
      let dataFilterProductionHistory = [];
      let dataFilterWellHeadProduction = [];
      let dataFilterWellHeadInjectionConditions = [];
      let dataFilterWellHeadProduction_WellHeadPressure = [];
      const plataformaWhp = selectedDeviceGraph.hasOwnProperty("chartHole") ? selectedDeviceGraph?.chartHole.split(" ")[0] : ""; // Plataforma segun el select Wellhead Production Conditions
      const plataformaPP = selectedDeviceGraph.hasOwnProperty("chartProdHistory") ? selectedDeviceGraph?.chartProdHistory.split(" ")[0] : ""; // Plataforma para Product parameters Production History
      const platformWIC = selectedDeviceGraph.hasOwnProperty("chartWellCond") ? selectedDeviceGraph?.chartWellCond.split(" ")[0] : "";
      const selectDevicePP = selectedDeviceGraph?.chartProdHistory;
      const selectDeviceWP = selectedDeviceGraph?.chartHole;
      const selectDeviceWIC = selectedDeviceGraph.chartWellCond;
      const requestData = dataIOTAllPlatforms?.requestData;

      if (
        (plataformaWhp !== "" && selectDeviceWP !== "") ||
        (plataformaPP !== "" && selectDevicePP !== "") ||
        (platformWIC !== "" && selectDeviceWIC !== "")
      ) {
        if (
          requestData &&
          Array.isArray(requestData["SMI-27"]) &&
          Array.isArray(requestData["V-191"])
        ) {
          const platformKeys = Object.keys(requestData);

          platformKeys?.forEach((key) => {
            const platformData = requestData[key];
            platformData?.forEach((entry) => {
              if (plataformaPP === key && entry.LSD === selectDevicePP) {
                dataFilterProductionHistory?.push(entry);
              }
              if (
                plataformaWhp === key &&
                entry.LSD === selectDeviceWP &&
                selectDeviceWP !== "V-191 Well Head Pressure"
              ) {
                dataFilterWellHeadProduction?.push(entry);
              }
              if (
                plataformaWhp === key &&
                selectDeviceWP === "V-191 Well Head Pressure"
              ) {
                dataFilterWellHeadProduction_WellHeadPressure?.push(entry);
              }
              if (platformWIC === key && entry.LSD === selectDeviceWIC) {
                dataFilterWellHeadInjectionConditions?.push(entry);
              }
            });
          });

          const lastDataIOTPP = !!dataFilterProductionHistory.length
            ? [
              dataFilterProductionHistory[
              dataFilterProductionHistory?.length - 1
              ],
            ]
            : null;

          const lastDataIOTWhp = !!dataFilterWellHeadProduction.length
            ? [
              dataFilterWellHeadProduction[
              dataFilterWellHeadProduction.length - 1
              ],
            ]
            : null;

          const lastDataIOTWIC = !!dataFilterWellHeadInjectionConditions.length
            ? [
              dataFilterWellHeadInjectionConditions[
              dataFilterWellHeadInjectionConditions.length - 1
              ],
            ]
            : null;

          const lastDataIOTWellHeadProduction_WellHeadPressure =
            !!dataFilterWellHeadProduction_WellHeadPressure.length
              ? [
                dataFilterWellHeadProduction_WellHeadPressure[
                dataFilterWellHeadProduction_WellHeadPressure.length - 1
                ],
              ]
              : null;
          if (
            Array.isArray(lastDataIOTPP) &&
            lastDataIOTPP.length > 0 &&
            lastDataIOTPP[0] !== undefined
          ) {
            setDataCardDashboard((prevState) => ({
              ...prevState,
              correlatedFlowRate:
                lastDataIOTPP[0]["Gas Rate"] !== undefined &&
                  lastDataIOTPP[0]["Gas Rate"] !== null &&
                  lastDataIOTPP[0]["Gas Rate"] !== -999.25
                  ? lastDataIOTPP[0]["Gas Rate"].toFixed(2)
                  : "--",
              oilRate:
                lastDataIOTPP[0]["Oil Rate"] !== undefined &&
                  lastDataIOTPP[0]["Oil Rate"] !== null &&
                  lastDataIOTPP[0]["Gas Rate"] !== -999.25
                  ? lastDataIOTPP[0]["Oil Rate"].toFixed(2)
                  : "--",
              waterRate:
                lastDataIOTPP[0]["Water Rate"] !== undefined &&
                  lastDataIOTPP[0]["Water Rate"] !== null &&
                  lastDataIOTPP[0]["Gas Rate"] !== -999.25
                  ? lastDataIOTPP[0]["Water Rate"].toFixed(2)
                  : "--",
            }));
          } else {
            setDataCardDashboard((prevState) => ({
              ...prevState,
              correlatedFlowRate: "--",
              oilRate: "--",
              waterRate: "--",
            }));
          }

          if (
            Array.isArray(lastDataIOTWhp) &&
            lastDataIOTWhp.length > 0 &&
            lastDataIOTWhp[0] !== undefined
          ) {
            const namePozoWellHeadPressure =
              inputInformation?.well_name + " Well Head Pressure";
            setDataCardDashboard((prevState) => ({
              ...prevState,
              staticPressure:
                lastDataIOTWhp[0][namePozoWellHeadPressure] !== undefined &&
                  lastDataIOTWhp[0][namePozoWellHeadPressure] !== null &&
                  lastDataIOTWhp[0][namePozoWellHeadPressure] !== -999.25 &&
                  dataWellGasLift?.data?.discretized_well
                  ? lastDataIOTWhp[0][namePozoWellHeadPressure]
                  : lastDataIOTWhp[0]["Static Pressure"] !== undefined &&
                    lastDataIOTWhp[0]["Static Pressure"] !== null &&
                    lastDataIOTWhp[0]["Static Pressure"] !== -999.25
                    ? lastDataIOTWhp[0]["Static Pressure"]
                    : "--",

              flowingTemp:
                lastDataIOTWhp[0]["Flowing Temperature"] !== undefined &&
                  lastDataIOTWhp[0]["Flowing Temperature"] !== null &&
                  lastDataIOTWhp[0]["Flowing Temperature"] !== -999.25
                  ? lastDataIOTWhp[0]["Flowing Temperature"].toFixed(2)
                  : "--",
            }));
          } else {
            setDataCardDashboard((prevState) => ({
              ...prevState,
              staticPressure: "--",
              flowingTemp: "--",
            }));
          }

          if (
            Array.isArray(lastDataIOTWIC) &&
            lastDataIOTWIC.length > 0 &&
            lastDataIOTWIC[0] !== undefined
          ) {
            setDataCardDashboard((prevState) => ({
              ...prevState,
              injectionPressure:
                lastDataIOTWIC[0]["Static Pressure"] !== undefined &&
                  lastDataIOTWIC[0]["Static Pressure"] !== null &&
                  lastDataIOTWIC[0]["Static Pressure"] !== -999.25
                  ? lastDataIOTWIC[0]["Static Pressure"]
                  : "--",

              injectionGasRate:
                lastDataIOTWIC[0]["Gas Rate"] !== undefined &&
                  lastDataIOTWIC[0]["Gas Rate"] !== null &&
                  lastDataIOTWIC[0]["Gas Rate"] !== -999.25
                  ? lastDataIOTWIC[0]["Gas Rate"].toFixed(2)
                  : "--",

              annulusWellheadPress:
                lastDataIOTWIC[0]["Static Pressure"] !== undefined &&
                  lastDataIOTWIC[0]["Static Pressure"] !== null &&
                  lastDataIOTWIC[0]["Static Pressure"] !== -999.25
                  ? lastDataIOTWIC[0]["Static Pressure"]
                  : "--",

              annulusWellheadTemp:
                lastDataIOTWIC[0]["Flowing Temperature"] !== undefined &&
                  lastDataIOTWIC[0]["Flowing Temperature"] !== null &&
                  lastDataIOTWIC[0]["Flowing Temperature"] !== -999.25
                  ? lastDataIOTWIC[0]["Flowing Temperature"]
                  : "--",
            }));
          } else {
            setDataCardDashboard((prevState) => ({
              ...prevState,
              injectionPressure: "--",
              injectionGasRate: "--",
            }));
          }

          if (
            Array.isArray(lastDataIOTWellHeadProduction_WellHeadPressure) &&
            lastDataIOTWellHeadProduction_WellHeadPressure.length > 0 &&
            lastDataIOTWellHeadProduction_WellHeadPressure[0] !== undefined
          ) {
            const namePozoWellHeadPressure =
              inputInformation?.well_name + " Well Head Pressure";
            setDataCardDashboard((prevState) => ({
              ...prevState,
              staticPressure:
                lastDataIOTWellHeadProduction_WellHeadPressure[0][
                  namePozoWellHeadPressure
                ] !== undefined &&
                  lastDataIOTWellHeadProduction_WellHeadPressure[0][
                  namePozoWellHeadPressure
                  ] !== null &&
                  lastDataIOTWellHeadProduction_WellHeadPressure[0][
                  namePozoWellHeadPressure
                  ] !== -999.25 &&
                  dataWellGasLift?.data?.discretized_well
                  ? lastDataIOTWellHeadProduction_WellHeadPressure[0][
                  namePozoWellHeadPressure
                  ]
                  : lastDataIOTWellHeadProduction_WellHeadPressure[0][
                    "Static Pressure"
                  ] !== undefined &&
                    lastDataIOTWellHeadProduction_WellHeadPressure[0][
                    "Static Pressure"
                    ] !== null &&
                    lastDataIOTWellHeadProduction_WellHeadPressure[0][
                    "Static Pressure"
                    ] !== -999.25
                    ? lastDataIOTWellHeadProduction_WellHeadPressure[0][
                    "Static Pressure"
                    ]
                    : "--",

              flowingTemp:
                lastDataIOTWellHeadProduction_WellHeadPressure[0][
                  "Flowing Temperature"
                ] !== undefined &&
                  lastDataIOTWellHeadProduction_WellHeadPressure[0][
                  "Flowing Temperature"
                  ] !== null &&
                  lastDataIOTWellHeadProduction_WellHeadPressure[0][
                  "Flowing Temperature"
                  ] !== -999.25
                  ? lastDataIOTWellHeadProduction_WellHeadPressure[0][
                    "Flowing Temperature"
                  ].toFixed(2)
                  : "--",
            }));
          }
        } else {
          console.log(
            "Error: The requestData format is not as expected",
            requestData
          );
        }
      } else {
        setDataCardDashboard((prevState) => ({
          ...prevState,
          staticPressure: "--",
          flowingTemp: "--",
          correlatedFlowRate: "--",
          oilRate: "--",
          waterRate: "--",
        }));
        console.log(
          "Error: No data selected for Wellhead Production Conditions,Product parameters Production History."
        );
      }
    }
  }, [
    dataIOTAllPlatforms,
    inputInformation,
    checkTypeGraph.gas_lift,
    inputVolumeTrackerWP?.separator_type,
    inputVolumeTrackerPH.separator_type,
  ]);

  useEffect(() => {
    if (queryDataHistory?.statusType === STATUS_DATA_HISTORY.SUCCESS) {
      const dataHistory = queryDataHistory?.data.data;

      setArrayDataHistory(dataHistory);
    }
  }, [queryDataHistory])

  useEffect(() => {
    const dataTuning = queryDataTuning?.data;
    const dataIOT = dataIOTAllPlatforms?.requestData;
    if (!!selectIntervalVLP && switchVLP) {
      const intervalId = setInterval(() => {
        if (
          !!dataIOT &&
          dataTuning?.checksRealTime?.dashboard
        ) {
          const chartHole = dataWellGL?.selected_platform_graph?.chartHole;
          const chartProdHistory = dataWellGL?.selected_platform_graph?.chartProdHistory;
          const chartWellCond = dataWellGL?.selected_platform_graph?.chartWellCond;

          const dataIOTChartHole = dataIOT?.[chartHole]?.filter((item) => {
            return item["LSD"] === dataWellGL?.selected_device_graph?.chartHole;
          });

          const dataIOTChartProdHistory = dataIOT?.[chartProdHistory]?.filter(
            (item) => {
              return item["LSD"] === dataWellGL?.selected_device_graph?.chartProdHistory;
            }
          );

          const dataIOTChartWellCond = dataIOT?.[chartWellCond]?.filter(
            (item) => {
              return  item["LSD"] === dataWellGL?.selected_device_graph?.chartWellCond;
            }
          );

          const lastDataIOTChartHole = !!dataIOTChartHole
            ? [dataIOTChartHole[dataIOTChartHole?.length - 1]]
            : null;
          const lastDataIOTChartProdHistory = !!dataIOTChartProdHistory
            ? [dataIOTChartProdHistory[dataIOTChartProdHistory?.length - 1]]
            : null;
          const lastDataIOTWellCond = !!dataIOTChartWellCond
            ? [dataIOTChartWellCond[dataIOTChartWellCond?.length - 1]]
            : null;

          const isValidArray = (array) =>
            Array.isArray(array) && array.length > 0 && array[0] !== undefined;

          const formatDate = (dateStr) => {
            const [month, day, year] = dateStr?.split("/");
            return `${year}-${month?.padStart(2, "0")}-${day?.padStart(
              2,
              "0"
            )}`;
          };

          let newDataVLPChartHole = {}
          let newDataVLPChartProdHist = {}
          let newDataVLPChartWellCond = {}
          if(isValidArray(lastDataIOTChartHole)){
            const dateIOT = lastDataIOTChartHole[0]["Date (MM/DD/YYYY)"]?.split(" ");
            newDataVLPChartHole = {
              test_date_whp: formatDate(dateIOT[0]),
              wellhead_temperature: lastDataIOTChartHole[0]["Flowing Temperature"],
              wellhead_pressure: lastDataIOTChartHole[0][
                  deviceRealTime?.device_gaslift?.discretized
                    ? dataTuning.well_name + " Well Head Pressure"
                    : "Static Pressure"
                ],
            }
          }
          if(isValidArray(lastDataIOTChartProdHistory)){
            const dateIOTProdHistory= lastDataIOTChartProdHistory[0]["Date (MM/DD/YYYY)"]?.split(" ");
            const valFcat = dataWellGasLift?.data?.["volumeTracker"]?.["fcat"] / 100 ?? 1;
            const valWaterCut = dataWellGasLift?.data?.["volumeTracker"]?.["water_cut"] ?? 0;
            const calculateOilRate = ((lastDataIOTChartProdHistory[0]["Liquid Flow Rate"] * valFcat * (100 - valWaterCut)) /100 ).toFixed(1);
            const formattedOilRate = calculateOilRate === "0.0" ? "0" : calculateOilRate;
            const calculateGasRate = (lastDataIOTChartProdHistory[0]["Corrected Flow Rate"] * valFcat).toFixed(3);
            newDataVLPChartProdHist = {
              test_date_pp: formatDate(dateIOTProdHistory[0]),
              oil_rate: formattedOilRate,
              gas_rate: calculateGasRate,
              water_cut: dataWellGL?.volumeTracker?.water_cut,
            };
          }
          if ( isValidArray(lastDataIOTWellCond)) {
            const dateIOTWellCond = lastDataIOTWellCond[0]["Date (MM/DD/YYYY)"]?.split(" ");
            newDataVLPChartWellCond = {
              test_date_glp: formatDate(dateIOTWellCond[0]),
              injection_gas_rate: lastDataIOTWellCond[0][
                deviceRealTime?.device_gaslift?.discretized
                  ? dataTuning.well_name + " Well Head Pressure"
                  : "Static Pressure"
              ],
              injection_pressure: lastDataIOTWellCond[0]["Flowing Temperature"],
            }
          }
          const newDataVLP = {
            ...newDataVLPChartHole,
            ...newDataVLPChartWellCond,
            ...newDataVLPChartProdHist
          }
          async function executeFunction() {
            await sequentialUpdateVLPWHP(newDataVLP);
          }
          executeFunction();
        }
      }, selectIntervalVLP);
      return () => clearInterval(intervalId);
    }
  }, [selectIntervalVLP, switchVLP, dataIOTAllPlatforms, refetchDataIOTAllPlatforms]);

  useEffect(() => {
    //Se encarga se realizar el auto escalizado inicial de las graficas
    if (!!inputInformation && (Array.isArray(dataWellGasLift?.data?.data_domain_graph) && dataWellGasLift?.data.data_domain_graph.length === 0)) {
      Object.entries(selectedDeviceGraph).forEach(([key, value]) => {
        if (selectedDeviceGraph[key]) {
          resetDomain(numberPreGraph[key], key);
        }
      });
    }
  }, [selectedDeviceGraph, dataWellGasLift, inputInformation]);

  useEffect(() => {
    if (queryDataTuning && !!selectedWell) {
      if (
        queryDataTuning?.statusCode === STATUS_DATA_VLP.SUCCESS &&
        queryCalculateTuningVLP
      ) {
        const getDataTuning = queryDataTuning?.data;

        setSwitchVLP(getDataTuning?.checksRealTime?.dashboard);

        if (
          queryCalculateTuningVLP?.statusCode === STATUS_CODE_VLP?.SUCCESS &&
          queryCalculateUntunedVLP?.statusCode ===
          STATUS_CODE_UNTUNED_VLP?.SUCCESS &&
          !!queryCalculateTuningVLP?.data
        ) {
          const getDataCalculatedVLP =
            queryCalculateTuningVLP?.data != null
              ? queryCalculateTuningVLP?.data[0]
              : [];
          const getDataCalculatedUntVLP =
            queryCalculateUntunedVLP?.data !== null
              ? queryCalculateUntunedVLP?.data[0]
              : [];
          setActiveChecks(getDataTuning.active_checks);
          setDataTableGauge(getDataTuning.gauge_data);
          setDataTableGraphVLP(getDataCalculatedVLP);
          setDataTableGraphUntVLP(getDataCalculatedUntVLP);

          const pressureArrayTuned = [
            ...getDataCalculatedVLP.pressure,
            getDataTuning?.reservoir_pressure + 200,
          ];
          const depthArrayTuned = [
            ...getDataCalculatedVLP?.depth_md,
            getDataTuning?.mid_perf_tvd_depth + 200,
          ];
          const pressureArrayUntuned = [
            ...getDataCalculatedUntVLP?.pressure,
            getDataTuning?.reservoir_pressure + 200,
          ];
          const depthArrayUntuned = [
            ...getDataCalculatedUntVLP?.depth_md,
            getDataTuning?.mid_perf_tvd_depth + 200,
          ];

          calculateRangesGraphVLP(
            pressureArrayTuned,
            depthArrayTuned,
            pressureArrayUntuned,
            depthArrayUntuned
          );

          const dataTuning = queryCalculateTuningVLP?.data[0];
          const endPressure = dataTuning?.pressure?.length - 1;
          setDataCardDashboard({
            ...dataCardDashboard,
            waterCut: getDataTuning?.water_cut ?? "--",
            pressureBottomHole: Number(
              dataTuning?.pressure[endPressure]?.toFixed(2)
            ),
            injectionGLR: Number(dataTuning.glr_inj?.toFixed(2)),
            injectionFormation: Number(dataTuning.glr_form?.toFixed(2)),
            wor: Math.round(dataTuning.wor),
            gor: Number(dataTuning.gor?.toFixed(2)),
            temperature: Number(dataTuning?.temperature?.toFixed(2)),
          });
        }
      } else {
        setActiveChecks([]);
        setDataTableGauge([]);
        setDataTableGraphVLP([]);
        setDataTableGraphUntVLP([]);
      }
    } else {
      setDataCardDashboard({
        ...dataCardDashboard,
        waterCut: "--",
        pressureBottomHole: "--",
        injectionGLR: "--",
        injectionFormation: "--",
        wor: "--",
        temperature: "--",
      });
    }
  }, [queryDataTuning, queryCalculateTuningVLP]);

  useEffect(() => {
    //MODIFICAR ESTOS VALORES SI SE DESEO INCLUIR UN NUEVO
    const validateAlert = {
      ...someAlert.gas_lift.valuesAlert,
      [STATE_ALERTS.alert_twc_temp]: validateTrueCount(
        "twc_temp",
        dataCardDashboard?.flowingTemp,
        dataAlertGasLift.twc_temp_max,
        dataAlertGasLift.twc_temp_min
      ),
      [STATE_ALERTS.alert_twc_pressure]: validateTrueCount(
        "twc_pressure",
        dataCardDashboard?.staticPressure,
        dataAlertGasLift.twc_pressure_max,
        dataAlertGasLift.twc_pressure_min
      ),
      [STATE_ALERTS.alert_pc_gas_rate]: validateTrueCount(
        "pc_gas_rate",
        dataCardDashboard?.correlatedFlowRate,
        dataAlertGasLift.pc_gas_rate_max,
        dataAlertGasLift.pc_gas_rate_min
      ),
      [STATE_ALERTS.alert_pc_oil_rate]: validateTrueCount(
        "pc_oil_rate",
        dataCardDashboard?.oilRate,
        dataAlertGasLift.pc_oil_rate_max,
        dataAlertGasLift.pc_oil_rate_min
      ),
      [STATE_ALERTS.alert_pc_water_rate]: validateTrueCount(
        "pc_water_rate",
        dataCardDashboard?.waterRate,
        dataAlertGasLift.pc_water_rate_max,
        dataAlertGasLift.pc_water_rate_min
      ),
      [STATE_ALERTS.alert_awc_pressure]: validateTrueCount(
        "awc_pressure",
        dataCardDashboard?.annulusWellheadPress,
        dataAlertGasLift.awc_pressure_max,
        dataAlertGasLift.awc_pressure_min
      ),
      [STATE_ALERTS.alert_awc_temp]: validateTrueCount(
        "awc_temp",
        dataCardDashboard?.annulusWellheadTemp,
        dataAlertGasLift.awc_temp_max,
        dataAlertGasLift.awc_temp_min
      ),
      [STATE_ALERTS.alert_alm_injection]: validateTrueCount(
        "alm_injection",
        dataCardDashboard?.injectionPressure,
        dataAlertGasLift.alm_injection_max,
        dataAlertGasLift.alm_injection_min
      ),
      [STATE_ALERTS.alert_alm_inj_gas]: validateTrueCount(
        "alm_inj_gas",
        dataCardDashboard?.injectionGasRate,
        dataAlertGasLift.alm_inj_gas_max,
        dataAlertGasLift.alm_inj_gas_min
      ),
      [STATE_ALERTS.alert_alm_water_cut]: validateTrueCount(
        "alm_water_cut",
        dataCardDashboard?.waterCut,
        dataAlertGasLift.alm_water_cut_max,
        dataAlertGasLift.alm_water_cut_min
      ),
      [STATE_ALERTS.alert_bhc_pressure]: validateTrueCount(
        "bhc_pressure",
        dataCardDashboard?.pressureBottomHole,
        dataAlertGasLift.bhc_pressure_max,
        dataAlertGasLift.bhc_pressure_min
      ),
      [STATE_ALERTS.alert_alm_inj_GLR]: validateTrueCount(
        "alm_inj_GLR",
        dataCardDashboard?.injectionGLR,
        dataAlertGasLift.alm_inj_GLR_max,
        dataAlertGasLift.alm_inj_GLR_min
      ),
      [STATE_ALERTS.alert_alm_formation]: validateTrueCount(
        "alm_formation",
        dataCardDashboard?.injectionFormation,
        dataAlertGasLift.alm_formation_max,
        dataAlertGasLift.alm_formation_min
      ),
      [STATE_ALERTS.alert_pc_wor]: validateTrueCount(
        "pc_wor",
        dataCardDashboard?.wor,
        dataAlertGasLift.pc_wor_max,
        dataAlertGasLift.pc_wor_min
      ),
      [STATE_ALERTS.alert_pc_gor]: validateTrueCount(
        "pc_gor",
        dataCardDashboard?.gor,
        dataAlertGasLift.pc_gor_max,
        dataAlertGasLift.pc_gor_min
      ),
    };

    const trueCount = Object.values(validateAlert).filter(
      (value) => value
    ).length;

    if (trueCount >= 0 && someAlert.gas_lift.countCheck !== undefined) {
      const realValueTrueCount =
        trueCount <= 0 ? 0 : trueCount - someAlert.gas_lift.countCheck;
      getSomeAlert({
        ...someAlert,
        gas_lift: {
          ...someAlert.gas_lift,
          countAlert: realValueTrueCount,
          valuesAlert: validateAlert,
        },
      });
    }
  }, [dataAlertGasLift, dataCardDashboard, someAlert.gas_lift.valuesCheck]);

  useEffect(() => {
    if (queryDataFlowGraph?.statusCode === 200044 && queryDataFlowGraph?.data) {
      setDataGrapFlow(queryDataFlowGraph?.data?.formatted_data);
    } else if (
      queryDataFlowGraph?.statusCode === 500201 &&
      !queryDataFlowGraph?.data
    ) {
      setDataGrapFlow([]);
    }
  }, [queryDataFlowGraph]);

  const sequentialUpdateVLPWHP = async (newDataVLP) => {
    let newDataTuning = {};
    if (switchVLP) {
      newDataTuning = {
        ...queryDataTuning?.data,
        ...newDataVLP
      };
    } else {
      newDataTuning = { ...queryDataTuning?.data };
    }
    await mutationUpdateDataTuning.mutateAsync({
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      county: dataFilterUserGasLift.county,
      field: dataFilterUserGasLift.field,
      wellid: dataFilterUserGasLift.well_id,
      username: User.user_name,
      data: newDataTuning,
    });

    await new Promise((resolve) => setTimeout(resolve, 1000));

    await Promise.all([
      refetchTuning(),
      refetchCalculateTuningVLP(),
      refetchCalculateUntunedVLP(),
    ]);
  };

  const validateTrueCount = (name, valueCard, valueMax, valueMin) => {
    if (valueCard !== null) {
      if (someAlert?.gas_lift?.valuesCheck[name] === false) {
        const validateTrue = someAlert?.gas_lift.valuesAlert[name];
        return validateTrue;
      } else if (someAlert?.gas_lift?.valuesCheck[name]) {
        const validateTrue =
          valueCard > valueMax || valueCard < valueMin ? true : false;
        return validateTrue;
      }
    }
  };

  const calculateDomain = (value, contextDomain, domain, reset) => {
    switch (domain) {
      case "max":
        const valueMax = Math.max(
          ...value.filter((values) => values !== undefined)
        );
        const roundedMax = Math.ceil(Math.round(valueMax * 10) / 100) * 10;
        const validateDomain =
          !!contextDomain && !reset ? contextDomain : roundedMax;
        return validateDomain;

      case "min":
        const valueMin = Math.min(
          ...value.filter((values) => values !== undefined)
        );
        const roundedMin = Math.floor(Math.round(valueMin * 10) / 100) * 10;
        const validateDomainMin =
          !!contextDomain && !reset ? contextDomain : roundedMin;
        return validateDomainMin;

      default:
        return null;
    }
  };

  const resetDomain = (resetNumber, graph) => {

    let newDataDomain = "";
    //DATA REAL TIME
    const dataGraphicRT = dataIOTAllPlatforms?.requestData[selectedDeviceGraph[graph]?.split(" ")[0]];
    //Este proceso se realiza por que el well head pressure no esta como LSD dentro de la data si no como parametro
    const selectedDeviceGraphReplace = selectedDeviceGraph[graph] === WELL_SENSOR_DEVICE?.data[6].sensor ? WELL_SENSOR_DEVICE?.data[5].sensor : selectedDeviceGraph[graph];
    const filteredDataRT = dataGraphicRT?.filter(item => item.LSD === selectedDeviceGraphReplace);
    //Variables Grafica 1
    const flowingTemperatureValuesRT = filteredDataRT?.map((obj) => obj["Flowing Temperature"]).filter((value) => value !== -999.25);
    const staticPressureValuesRT = filteredDataRT?.map((obj) => obj[selectedDeviceGraph[graph] === WELL_SENSOR_DEVICE?.data[6].sensor ? dataFilterUserGasLift.well_name + ' Well Head Pressure' : 'Static Pressure']).filter((value) => value !== -999.25);
    //Variables Grafica 2
    const injectionPressValuesRT = filteredDataRT?.map((obj) => obj[selectedDeviceGraph[graph] === WELL_SENSOR_DEVICE?.data[6].sensor ? dataFilterUserGasLift.well_name + ' Well Head Pressure' : 'Static Pressure']).filter((value) => value !== -999.25);

    const injectionGasValuesRT = filteredDataRT?.map((obj) => obj["Gas Rate"]).filter((value) => value !== -999.25);
    //Variables Grafica 3
    const oilRateValuesRT = dataGraphProduction?.map((obj) => obj["oil_rate"]).filter((value) => value !== -999.25);
    const waterRateValuesRT = dataGraphProduction?.map((obj) => obj["water_rate"]).filter((value) => value !== -999.25);
    const liquidValuesRT = waterRateValuesRT ? oilRateValuesRT.concat(waterRateValuesRT) : [];;
    const gastRateValuesRT = dataGraphProduction?.map((obj) => obj["gas_rate"]).filter((value) => value !== -999.25);
    //DATA HISTORIC
    const dataGraphicHT = queryDataHistory?.data?.data;
    //Variables Grafica 1
    const flowingTemperatureValuesHT = dataGraphicHT?.map((obj) => obj["wellhead_tem"]).filter((value) => value !== -999.25) || [];
    const staticPressureValuesHT = dataGraphicHT?.map((obj) => obj["wellhead_pressure"]).filter((value) => value !== -999.25) || [];
    //Variables Grafica 2
    const injectionPressValuesHT = dataGraphicHT ? dataGraphicHT?.map((obj) => obj["injection_pressure"]) : [];
    const injectionGasValuesHT = dataGraphicHT ? dataGraphicHT?.map((obj) => obj["injection_gas"]).filter((value) => value !== -999.25) : [];
    //Variables Grafica 3
    const oilRateValuesHT = dataGraphicHT?.map((obj) => obj["oil_rate"]).filter((value) => value !== -999.25);
    const waterRateValuesHT = dataGraphicHT?.map((obj) => obj["water_rate"]).filter((value) => value !== -999.25);
    const liquidValuesHT = waterRateValuesHT ? waterRateValuesHT.concat(oilRateValuesHT) : [];
    const gastRateValuesHT = dataGraphicHT ? dataGraphicHT?.map((obj) => obj["gas_rate"]).filter((value) => value !== -999.25) : [];

    //DATA CONCAT
    const flowingTemperatureValues = !checkTypeGraph.gas_lift.wellhead_production ? [...flowingTemperatureValuesHT, ...flowingTemperatureValuesRT] : flowingTemperatureValuesRT;
    const staticPressureValues = !checkTypeGraph.gas_lift.wellhead_production ? [...staticPressureValuesHT, ...staticPressureValuesRT] : staticPressureValuesRT;
    const injectionPressValues = !checkTypeGraph.gas_lift.wellhead_injection ? [...injectionPressValuesHT, ...injectionPressValuesRT] : injectionPressValuesRT;
    const injectionGasValues = !checkTypeGraph.gas_lift.wellhead_injection ? [...injectionGasValuesHT, ...injectionGasValuesRT] : injectionGasValuesRT;
    const liquidValues = !checkTypeGraph.gas_lift.production_history ? [...liquidValuesHT] : liquidValuesRT;
    const gastRateValues = !checkTypeGraph.gas_lift.production_history ? [...gastRateValuesHT] : gastRateValuesRT;

    switch (resetNumber) {
      case 0:
        if (flowingTemperatureValues?.length && staticPressureValues?.length) {
          newDataDomain = {
            press_max_wpc: calculateDomain(
              staticPressureValues,
              dataDomainGraph?.press_max_wpc,
              "max",
              true
            ),
            press_min_wpc: calculateDomain(
              staticPressureValues,
              dataDomainGraph?.press_min_wpc,
              "min",
              true
            ),
            temp_max_wpc: calculateDomain(
              flowingTemperatureValues,
              dataDomainGraph?.temp_max_wpc,
              "max",
              true
            ),
            temp_min_wpc: calculateDomain(
              flowingTemperatureValues,
              dataDomainGraph?.temp_min_wpc,
              "min",
              true
            ),
          };
        } else {
          newDataDomain = {
            press_max_wpc: 0,
            temp_max_wpc: 0,
            press_min_wpc: 0,
            temp_min_wpc: 0,
          };
        }
        break;

      case 1:
        if (injectionGasValues?.length) {
          newDataDomain = {
            injrate_max_wic: calculateDomain(
              injectionGasValues,
              dataDomainGraph?.injrate_max_wic,
              "max",
              true
            ),
            injpress_max_wic: calculateDomain(
              injectionPressValues,
              dataDomainGraph?.injpress_max_wic,
              "max",
              true
            ),
            injrate_min_wic: calculateDomain(
              injectionGasValues,
              dataDomainGraph?.injrate_min_wic,
              "min",
              true
            ),
            injpress_min_wic: calculateDomain(
              injectionPressValues,
              dataDomainGraph?.injpress_min_wic,
              "min",
              true
            ),
          };
        } else {
          newDataDomain = {
            injrate_max_wic: 0,
            injpress_max_wic: 0,
            injrate_min_wic: 0,
            injpress_min_wic: 0,
          };
        }
        break;

      case 2:
        if (gastRateValues?.length && liquidValues?.length) {
          newDataDomain = {
            rate_max_ph: calculateDomain(
              liquidValues,
              dataDomainGraph?.rate_max_ph,
              "max",
              true
            ),
            rate_min_ph: 0,
            gas_max_ph: calculateDomain(
              gastRateValues,
              dataDomainGraph?.gas_min_ph,
              "max",
              true
            ),
            gas_min_ph: 0,
          };
        } else {
          newDataDomain = {
            rate_max_ph: 0,
            rate_min_ph: 0,
            gas_max_ph: 0,
            gas_min_ph: 0,
          };
        }
        break;

      default:
        break;
    }

    setDomainGraph((prevDomain) => {
      const mergedDomain = { ...prevDomain, ...newDataDomain };
      getDataDomainGraph(mergedDomain);
      return mergedDomain;
    });
  };

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleUploadFile = (dataFile) => {
    if (selectedWell !== "null") {
      const dataFilePre = dataFile.map((obj, index) => {
        return { id: index + 1, ...obj };
      });

      const finalDataAlert = {};

      dataFilePre.map((item) => {
        const nameMax = `${item.item}_max`;
        const nameMin = `${item.item}_min`;

        finalDataAlert[nameMin] = item.min >= item.max ? 0 : item.min;
        finalDataAlert[nameMax] = item.max;
      });

      const dataConcatAlert = { ...dataWellGL, data_alert: finalDataAlert };
      setTimeout(() => {
        mutationUpdateDataGasLift.mutate({
          operator: seletedFilter?.operator,
          lease: seletedFilter?.lease,
          region: seletedFilter?.region,
          county: dataWellGL?.county,
          field: dataWellGL?.field,
          well: dataWellGL?.well_id,
          username: User?.user_name,
          data: dataConcatAlert,
        });

        getDataAlertGasLift(finalDataAlert);
        getSomeAlert({ ...someAlert, messageChange: false });
      }, 500);
    } else {
      message.info("Please seleted well for save domain alert");
    }
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    if (
      name === "press_max_wpc" ||
      name === "press_min_wpc" ||
      name === "temp_max_wpc" ||
      name === "temp_min_wpc" ||
      name === "injrate_max_wic" ||
      name === "injrate_min_wic" ||
      name === "injpress_max_wic" ||
      name === "injpress_min_wic" ||
      name === "rate_max_ph" ||
      name === "rate_min_ph" ||
      name === "gas_max_ph" ||
      name === "gas_min_ph"
    ) {
      const dataNewDomain = {
        ...dataDomainGraph,
        [name]: Number(value),
      };
      getDataDomainGraph(dataNewDomain);
    }
    setDomainGraph((prevState) => ({
      ...prevState,
      [name]: value !== "" ? Number(value) : null,
    }));
  };

  const handleInputBlur = (e) => {
    const { value, name } = e.target;
    const validateAndSetDomain = (field, comparisonField, condition, errorMessage) => {
      if (condition) {
        message.warning(errorMessage);
        getDataDomainGraph({
          ...dataDomainGraph,
          [field]: "",
        });
        setDomainGraph((prevState) => ({
          ...prevState,
          [field]: "",
        }));
      }
    };

    if ([
      "press_max_wpc",
      "press_min_wpc",
      "temp_max_wpc",
      "temp_min_wpc",
      "injrate_max_wic",
      "injrate_min_wic",
      "injpress_max_wic",
      "injpress_min_wic",
      "rate_max_ph",
      "rate_min_ph",
      "gas_max_ph",
      "gas_min_ph"
    ].includes(name)) {
      const conditions = {
        temp_max_wpc: {
          comparisonField: "temp_min_wpc",
          condition: value < domainGraph.temp_min_wpc,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        temp_min_wpc: {
          comparisonField: "temp_max_wpc",
          condition: value > domainGraph.temp_max_wpc,
          errorMessage: "The minimum value must be less than the maximum",
        },
        press_max_wpc: {
          comparisonField: "press_min_wpc",
          condition: value < domainGraph.press_min_wpc,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        press_min_wpc: {
          comparisonField: "press_max_wpc",
          condition: value > domainGraph.press_max_wpc,
          errorMessage: "The minimum value must be less than the maximum",
        },
        injrate_max_wic: {
          comparisonField: "injrate_min_wic",
          condition: value < domainGraph.injrate_min_wic,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        injrate_min_wic: {
          comparisonField: "injrate_max_wic",
          condition: value > domainGraph.injrate_max_wic,
          errorMessage: "The minimum value must be less than the maximum",
        },
        injpress_max_wic: {
          comparisonField: "injpress_min_wic",
          condition: value < domainGraph.injpress_min_wic,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        injpress_min_wic: {
          comparisonField: "injpress_max_wic",
          condition: value > domainGraph.injpress_max_wic,
          errorMessage: "The minimum value must be less than the maximum",
        },
        rate_max_ph: {
          comparisonField: "rate_min_ph",
          condition: value < domainGraph.rate_min_ph,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        rate_min_ph: {
          comparisonField: "rate_max_ph",
          condition: value > domainGraph.rate_max_ph,
          errorMessage: "The minimum value must be less than the maximum",
        },
        gas_max_ph: {
          comparisonField: "gas_min_ph",
          condition: value < domainGraph.gas_min_ph,
          errorMessage: "The maximum value must be greater than the minimum",
        },
        gas_min_ph: {
          comparisonField: "gas_max_ph",
          condition: value > domainGraph.gas_max_ph,
          errorMessage: "The minimum value must be less than the maximum",
        },
      };

      const { comparisonField, condition, errorMessage } = conditions[name];
      validateAndSetDomain(name, comparisonField, condition, errorMessage);
    }
  }

  const handleSaveAlert = () => {
    if (dataAlertGasLift && selectedWell !== "null") {
      const data_alert = dataAlertGasLift;
      const data_check = someAlert.gas_lift;
      const dataConcatAlert = {
        ...dataWellGL,
        data_alert: data_alert,
        data_check: data_check,
      };
      const invalidKeys = data_alert && data_check ? validateJsonEmptyAlert(data_check,data_alert) : [];
      if(invalidKeys.length > 0){
        getValidateAlertGasLift({
          ...validateAlertGasLift,
          alertCard: true
        });
        setShowAlert(true);
        setTypeAlert("Warning");
        setMessageAlert("There are empty alerts please complete the boxes of the ranges");
      }
      else {
        getValidateAlertGasLift({
          ...validateAlertGasLift,
          alertCard: false
        });
        setTimeout(() => {
          mutationUpdateDataGasLift.mutate({
            operator: seletedFilter?.operator,
            lease: seletedFilter?.lease,
            region: seletedFilter?.region,
            county: dataWellGL?.county,
            field: dataWellGL?.field,
            well: dataWellGL?.well_id,
            username: User?.user_name,
            data: dataConcatAlert,
          });
        }, 500);
        getDataAlertGasLift(dataConcatAlert?.data_alert);
        getSomeAlert({ ...someAlert, messageChange: false });
      }
    } else {
      message.info("Please seleted well for save domain alert");
    }
  };

  const handleResetAlert = () => {
    if (!!selectedWell && !!Object.keys(dataWellGL).length) {
      const dataConcatAlert = {
        ...dataWellGL,
        data_alert: [],
        data_check: [],
      };

      async function executeFunction() {
        await new Promise((resolve) => setTimeout(resolve, 100));

        await mutationUpdateDataGasLift.mutateAsync({
          operator: seletedFilter?.operator,
          lease: seletedFilter?.lease,
          region: seletedFilter?.region,
          county: dataWellGL?.county,
          field: dataWellGL?.field,
          well: dataWellGL?.well_id,
          username: User?.user_name,
          data: dataConcatAlert,
        });

        await new Promise((resolve) => setTimeout(resolve, 100));

        window.location.reload();
      }

      executeFunction();
    }
  };

  const handleOptionChange = (name, option) => {
    setValidAllocation(false);
    switch (name) {
      case "chartType":
        setSelectedOption(option);
        break;
      case "graph_WPC":
        if (option === "" || option === null) {
          const dataInitialDomain = {
            ...dataDomainGraph,
            "press_max_wpc": "",
            "press_min_wpc": "",
            "temp_max_wpc": "",
            "temp_min_wpc": ""
          }
          getDataDomainGraph(dataInitialDomain);
          setDomainGraph(dataInitialDomain);
        }
        getDeviceRealTime({
          ...deviceRealTime,
          device_gaslift: {
            ...deviceRealTime.device_gaslift,
            selected_platform_graph: {
              ...deviceRealTime.device_gaslift.selected_platform_graph,
              chartHole: option?.split(" ")[0],
            },
            selected_device_graph: {
              ...deviceRealTime.device_gaslift.selected_device_graph,
              chartHole: option,
            },
          },
        });
        setSelectedDeviceGraph((prevData) => ({
          ...prevData,
          chartHole: option
        }));
        setInputVolumeTrackerWP((prevState) => ({
          ...prevState,
          separator_type: option,
        }));
        break;
      case "graph_WIC":
        if (option === "" || option === null) {
          const dataInitialDomain = {
            ...dataDomainGraph,
            "injrate_max_wic": "",
            "injrate_min_wic": "",
            "injpress_max_wic": "",
            "injpress_min_wic": ""
          }
          getDataDomainGraph(dataInitialDomain);
          setDomainGraph(dataInitialDomain);
        }
        getDeviceRealTime({
          ...deviceRealTime,
          device_gaslift: {
            ...deviceRealTime.device_gaslift,
            selected_platform_graph: {
              ...deviceRealTime.device_gaslift.selected_platform_graph,
              chartWellCond: option?.split(" ")[0],
            },
            selected_device_graph: {
              ...deviceRealTime.device_gaslift.selected_device_graph,
              chartWellCond: option,
            },
          },
        });
        setSelectedDeviceGraph((prevData) => ({
          ...prevData,
          chartWellCond: option
        }));
        setInputVolumeTrackerWIC((prevState) => ({
          ...prevState,
          separator_type: option,
        }));
        break;
      case "graph_PH":
        if (option === "" || option === null) {
          const dataInitialDomain = {
            ...dataDomainGraph,
            "rate_max_ph": "",
            "rate_min_ph": "",
            "temp_max_wpc": "",
            "temp_min_wpc": ""

          }
          getDataDomainGraph(dataInitialDomain);
          setDomainGraph(dataInitialDomain);
        }
        getDeviceRealTime({
          ...deviceRealTime,
          device_gaslift: {
            ...deviceRealTime.device_gaslift,
            selected_platform_graph: {
              ...deviceRealTime.device_gaslift.selected_platform_graph,
              chartProdHistory: option?.split(" ")[0],
            },
            selected_device_graph: {
              ...deviceRealTime.device_gaslift.selected_device_graph,
              chartProdHistory: option,
            },
          },
        });
        setSelectedDeviceGraph((prevData) => ({
          ...prevData,
          chartProdHistory: option
        }));
        setInputVolumeTrackerPH((prevState) => ({
          ...prevState,
          separator_type: option,
        }));
        break;
      case "separator_type_PH":
        setInputVolumeTrackerPH((prevState) => ({
          ...prevState,
          separator_type: option,
        }));
        break;
      default:
        break;
    }
  };

  const handleOpenModal = (cardId) => {
    setSelectedCardId(cardId);
    switch (cardId) {
      case 1:
      case 2:
      case 3:
      case 4:
        setOpen(true);
        break;
      case 5:
      case 6:
      case 7:
        getIsOpenModalAllocattion(true);
      default:
        break;
    }
  };

  const handleResetLimits = (numberGraph, graph) => {
    if (selectedDeviceGraph[graph]) {
      resetDomain(numberGraph, graph);
    } else {
      message.info("Select a device to reset the domains in this chart")
    }
  };

  const handleTypeGraph = (event, name) => {
    if (checkTypeGraph.gas_lift[name] === true) {
      getCheckTypeGraph({
        ...checkTypeGraph,
        gas_lift: { ...checkTypeGraph.gas_lift, [name]: false },
      });
    } else {
      getCheckTypeGraph({
        ...checkTypeGraph,
        gas_lift: { ...checkTypeGraph.gas_lift, [name]: true },
      });
    }
  };

  const handleSwitchVLP = (checked) => {
    const dataTuning = queryDataTuning?.data;
    if (!!dataTuning) {
      setSwitchVLP(checked);
      async function executeFunction() {
        await sequentialUpdateSwitchVLP(dataTuning, checked);
      }
      executeFunction();
    }
  };

  const sequentialUpdateSwitchVLP = async (dataTuning, checked) => {
    await mutationUpdateDataTuning.mutateAsync({
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      county: dataFilterUserGasLift.county,
      field: dataFilterUserGasLift.field,
      wellid: dataFilterUserGasLift.well_id,
      username: User.user_name,
      data: {
        ...dataTuning,
        checksRealTime: { ...dataTuning.checksRealTime, dashboard: checked },
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await Promise.all([refetchTuning()]);
  };

  const handleSelect = (name, option) => {
    const dataTuning = queryDataTuning?.data;
    switch (name) {
      case "selectTypeGraphVLP":
        setTypeGraphVLP(option);
        break;
      case "SelectTimeRunVLP":
        getSelectIntervalVLP(option);
        mutationUpdateDataTuning.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: { ...dataTuning, selectTimeRTDashboard: option },
        });
        break;
      default:
        break;
    }
  };

  const handleChangeFluidInformation = (e, graph) => {
    const { name, value } = e.target;
    setValidAllocation(false);
    if ((name === "fcat" || name === "fcat_gas" || name === "water_cut") && (value < 0 || value > 100)) {
      message.warning("The maximum value can not exceed 100");
      return
    }
    switch (graph) {
      case "WPC":
        if (value !== "") {
          setInputVolumeTrackerWP((prevState) => ({
            ...prevState,
            [name]: Number(value),
          }));
        }
        else {
          setInputVolumeTrackerWP((prevState) => ({
            ...prevState,
            [name]: "",
          }));
        }
        break;
      case "PH":
        if (value !== "") {
          setInputVolumeTrackerPH((prevState) => ({
            ...prevState,
            [name]: Number(value),
          }));
        }
        else {
          setInputVolumeTrackerPH((prevState) => ({
            ...prevState,
            [name]: "",
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleClickAverages = () => {
    let bodyVT = { wells: [] };
    let dataConcatAverage = null;
    let isPHInputEmpty = false;
    let isWPInputEmpty = false;
    let isWICInputEmpty = false;

    if (
      !!inputVolumeTrackerPH.fcat &&
      !!inputVolumeTrackerPH.fcat_gas &&
      !!inputVolumeTrackerPH.water_cut &&
      !!inputVolumeTrackerPH.separator_type &&
      selectedCardId === 6
    ) {
      bodyVT.wells = [
        {
          well_id: dataWellGL?.well_id,
          fcat: inputVolumeTrackerPH?.fcat,
          fcat_gas: inputVolumeTrackerPH.fcat_gas,
          water_cut: inputVolumeTrackerPH?.water_cut,
          separator_type: inputVolumeTrackerPH?.separator_type.replace(/^\w+-\d+\s*/, ""), // Eliminar prefijo si es necesario
        },
      ];
      isPHInputEmpty = true;
    }

    if (
      !!inputVolumeTrackerWP.fcat &&
      !!inputVolumeTrackerWP.water_cut &&
      !!inputVolumeTrackerWP.separator_type &&
      selectedCardId === 5
    ) {
      bodyVT.wells = [
        {
          well_id: dataWellGL?.well_id,
          fcat: inputVolumeTrackerWP.fcat,
          water_cut: inputVolumeTrackerWP.water_cut,
          separator_type: inputVolumeTrackerWP.separator_type.replace(
            /^\w+-\d+\s*/,
            ""
          ), // Eliminar prefijo si es necesario
        },
      ];
      isWPInputEmpty = true;
    }
    if (
      !!inputVolumeTrackerWIC.fcat &&
      !!inputVolumeTrackerWIC.water_cut &&
      !!inputVolumeTrackerWIC.separator_type &&
      selectedCardId === 7
    ) {
      bodyVT.wells = [
        {
          well_id: dataWellGL?.well_id,
          fcat: inputVolumeTrackerWIC.fcat,
          water_cut: inputVolumeTrackerWIC.water_cut,
          separator_type: inputVolumeTrackerWIC.separator_type.replace(
            /^\w+-\d+\s*/,
            ""
          ), // Eliminar prefijo si es necesario
        },
      ];
      isWICInputEmpty = true;
    }

    if (selectedCardId === 5) {
      dataConcatAverage = {
        ...dataWellGL,
        volumeTrackerWP: {
          ...bodyVT.wells[0],
          separator_type: inputVolumeTrackerWP.separator_type,
        },
      };
    } else if (selectedCardId === 6) {
      dataConcatAverage = {
        ...dataWellGL,
        volumeTracker: {
          ...bodyVT.wells[0],
          separator_type: inputVolumeTrackerPH.separator_type,
        },
      };
    } else if (selectedCardId === 7) {
      dataConcatAverage = {
        ...dataWellGL,
        volumeTrackerWIC: {
          ...bodyVT.wells[0],
          separator_type: inputVolumeTrackerWIC.separator_type,
        },
      };
    }

    if (!isWPInputEmpty && selectedCardId === 5) {
      setValidAllocation(true);
      message.warning("Please validate that the information is complete");
    }
    if (!isPHInputEmpty && selectedCardId === 6) {
      setValidAllocation(true);
      message.warning("Please validate that the information is complete");
    }

    if (!isWICInputEmpty && selectedCardId === 7) {
      setValidAllocation(true);
      message.warning("Please validate that the information is complete");
    }

    if (isPHInputEmpty || isWPInputEmpty || isWICInputEmpty) {
      async function executeFunctionVT() {
        setLoadVolumeT(true);
        await mutationUploadJsonVolumeTracker.mutateAsync({
          operator: seletedFilter?.operator,
          lease: seletedFilter?.lease,
          region: seletedFilter?.region,
          county: dataWellGL?.county,
          field: dataWellGL?.field,
          well: dataWellGL?.well_id,
          userName: User?.user_name,
          data: bodyVT,
        });

        await new Promise((resolve) => setTimeout(resolve, 500));

        await mutationUpdateDataGasLift.mutateAsync({
          operator: seletedFilter?.operator,
          lease: seletedFilter?.lease,
          region: seletedFilter?.region,
          county: dataWellGL?.county,
          field: dataWellGL?.field,
          well: dataWellGL?.well_id,
          username: User?.user_name,
          data: dataConcatAverage,
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));

        refetchDataIOTAllPlatforms();
        // refetchDataChartGL_well_head()

        getIsOpenModalAllocattion(false);
        setLoadVolumeT(false);
      }
      executeFunctionVT();
    }
  };


  const handleCloseModalAlert = () => {
    setShowAlert(false);
  };

  const handleCloseModalGraph = () => {
    const invalidKeysGraph = domainGraph ? validateGraphDomain("graph" + selectedCardId) : false;
    if (invalidKeysGraph && selectedCardId !== 3) {
      getValidateAlertGasLift({
        ...validateAlertGasLift,
        alertDomain: true
      });
      setShowAlert(true);
      setTypeAlert("Warning");
      setMessageAlert("There are empty domains please complete the boxes of the ranges");
    }
    else {
      getValidateAlertGasLift({
        ...validateAlertGasLift,
        alertDomain: false
      });
      setOpen(false);
    }
  };

  const validateGraphDomain = (name) => {
    const graphValidationMap = {
      graph1: [
        "press_max_wpc",
        "press_min_wpc",
        "temp_max_wpc",
        "temp_min_wpc",
      ],
      graph2: [
        "injrate_max_wic",
        "injrate_min_wic",
        "injpress_max_wic",
        "injpress_min_wic",
      ],
      graph4: [
        "rate_max_ph",
        "rate_min_ph",
        "gas_max_ph",
        "gas_min_ph",
      ],
    };
    const fieldsToValidate = graphValidationMap[name] || [];

    const validateResult = fieldsToValidate.some(
      (field) =>
        dataDomainGraph[field] === null ||
        dataDomainGraph[field] === "" ||
        dataDomainGraph[field] === undefined
    );
    return validateResult
  };


  return (
    <>
      <div className="flex flex-col w-full h-screen bg-pc-grey4 mb-100">
        <div className="flex">
          <Navbar wellboreOption={wellboreOption}></Navbar>
          <Sidebar className="flex h-full abosolute"></Sidebar>
        </div>
        {(loadDataIOTAllPlatforms || loadDataWellGasLift || viewLoadingGasLift.loadSaveParameters) && !!selectedWell ? (
          <div className="flex w-full h-full">
            <LoadingDashboard />
          </div>
        ) : !selectedWell ||
          selectedWell === "null" ||
          seletedFilter?.checkedWellGaslift === null ||
          seletedFilter?.checkedWellGaslift === "" ? (
          <ErrorLoadingDashboard />
        ) : (
          <div className="grid grid-cols-3 grid-rows-10 w-full h-full gap-x-6 pr-2 pl-[90px]">
            <div className="w-full h-full grid grid-cols-4 grid-rows-10 gap-x-[1em] gap-y-[1em] row-span-6 overflow-y-auto ml-[1em] mt-[1em] pb-[2em]">
              {/* Tarjeta 1: Form */}
              <div className="card w-full col-span-2 bg-white p-[0.5em]">
                <div className="grid w-full h-full grid-cols-2 grid-rows-6">
                  <div className="flex  flex-col col-span-2 w-full h-1/6 max-h-[45px]">
                    <label className="w-full text-pc-grey2 font-bold text-[14px] p-1">
                      Well
                    </label>
                    <input
                      className="w-full pl-1"
                      value={inputInformation.well_name}
                      readOnly
                    />
                  </div>
                  <div className="flex  flex-col w-full col-span-2  h-1/6 max-h-[45px]">
                    <label className="w-full text-pc-grey2 font-bold text-[14px] p-1">
                      Company
                    </label>
                    <input
                      className="w-full pl-1"
                      value={inputInformation.company}
                      readOnly
                    />
                  </div>
                  <div className="flex  flex-col w-full col-span-2  h-1/6 max-h-[45px]">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Country
                    </label>
                    <input
                      className="w-full pl-1"
                      value={inputInformation.country}
                      readOnly
                    />
                  </div>
                  <div className="flex  flex-col w-full col-span-2  h-1/6 max-h-[45px]">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Region
                    </label>
                    <input
                      className="w-full pl-1"
                      value={inputInformation.region}
                      readOnly
                    />
                  </div>
                  <div className="flex  flex-col w-full  col-span-2 h-1/6 max-h-[45px]">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Field
                    </label>
                    <input
                      className="w-full pl-1"
                      value={inputInformation.field}
                      readOnly
                    />
                  </div>
                  <div className="flex  flex-col w-full  col-span-2 h-1/6 max-h-[45px]">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Well orientation
                    </label>
                    <input
                      className="w-full pl-1"
                      value={inputInformation.well_orientation}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col w-[157px] h-[42px] ml-[8px] justify-start text-start items-start">
                    <label className="w-full text-pc-grey2 font-bold text-[14px]">
                      Domain Alert
                    </label>
                    <div className="flex flex-row w-full gap-4">
                      <div className="flex items-center justify-center text-center">

                        <ImportFile uploadfile={handleUploadFile} tableType={"data_domain_alerts"} messageTooltip={"Load the template to enter the alerts"}></ImportFile>
                      </div>
                      <Tooltip title={'This button saves only the alerts'}>
                        <SaveAlert
                          onClick={() => handleSaveAlert()}
                          className="w-4 h-5 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                        />
                      </Tooltip>
                      {User?.role !== "Simple User" && (
                        <Tooltip title={'This button reset all the alerts'}>
                          <ArrowIcon
                            onClick={() => handleResetAlert()}
                            className="w-4 h-5 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple "
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Tarjeta 5: ARTIFICIAL LIFT METRICS */}
              <div className="card w-full col-span-2  bg-white p-[0.5em]">
                <div className="grid w-full h-full grid-cols-2 grid-rows-5">
                  <div className="flex flex-col w-full col-span-2">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Artificial Lift Metrics
                    </label>
                    <CardGraph
                      title={"Injection Pressure "}
                      value={
                        dataCardDashboard?.injectionPressure !== 0
                          ? dataCardDashboard?.injectionPressure
                          : 0
                      }
                      unit={"(psig)"}
                      name={"alm_injection"}
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full col-span-2">
                    <CardGraph
                      title={"Injection Gas Rate "}
                      value={
                        dataCardDashboard?.injectionGasRate !== 0
                          ? dataCardDashboard?.injectionGasRate
                          : 0
                      }
                      unit={"(MSCF/D)"}
                      name={"alm_inj_gas"}
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full col-span-2">
                    <CardGraph
                      title={"GLR Injection "}
                      value={
                        dataCardDashboard?.injectionGLR !== 0
                          ? dataCardDashboard?.injectionGLR
                          : 0
                      }
                      unit={"(SCF/STB)"}
                      name={"alm_inj_GLR"}
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full col-span-2">
                    <CardGraph
                      title={"GLR Formation "}
                      value={
                        dataCardDashboard?.injectionFormation !== 0
                          ? dataCardDashboard?.injectionFormation
                          : 0
                      }
                      unit={"(SCF/STB)"}
                      name="alm_formation"
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full col-span-2">
                    <CardGraph
                      title={"Water Cut "}
                      value={
                        dataCardDashboard?.waterCut !== 0
                          ? dataCardDashboard?.waterCut
                          : 0
                      }
                      unit={"(%)"}
                      name="alm_water_cut"
                    ></CardGraph>
                  </div>
                </div>
              </div>
              {/* Tarjeta 2: WELLHEAD CONDITIONS */}
              <div className="card w-full col-span-2 bg-white p-[0.5em]">
                <div className="grid w-full h-full grid-cols-2 grid-rows-2">
                  <div className="w-full col-span-2">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Tubing Wellhead Conditions
                    </label>
                    <CardGraph
                      title={"Pressure "}
                      unit={"(psig)"}
                      name="twc_pressure"
                      value={
                        dataCardDashboard.staticPressure !== 0
                          ? dataCardDashboard.staticPressure
                          : ""
                      }
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full col-span-2">
                    <CardGraph
                      title={"Temperature "}
                      unit={"(deg F)"}
                      name={"twc_temp"}
                      value={
                        dataCardDashboard.flowingTemp !== 0
                          ? dataCardDashboard.flowingTemp
                          : 0
                      }
                    ></CardGraph>
                  </div>
                </div>
              </div>
              {/* Tarjeta 6: Economics Metrics*/}
              <div className="card w-full  col-span-2 bg-white p-[0.5em]">
                <div className="grid w-full h-full grid-cols-2 grid-rows-2">
                  <div className="w-full col-span-2">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Economics Metrics
                    </label>
                    <CardGraph
                      title={"EUR "}
                      value={"--"}
                      unit={"(MMSTB)"}
                      name={"em_eur"}
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full col-span-2">
                    <CardGraph
                      title={"NPV "}
                      value={"--"}
                      unit={"(MM$)"}
                      name={"em_npv"}
                    ></CardGraph>
                  </div>
                </div>
              </div>
              {/* Tarjeta 3: Annulus Wellhead CONDITIONS */}
              <div className="card w-full  col-span-2 bg-white p-[0.5em]">
                <div className="grid w-full h-full grid-cols-2 grid-rows-2">
                  <div className="w-full col-span-2">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Annulus Wellhead Conditions
                    </label>
                    <CardGraph
                      title={"Pressure "}
                      value={
                        dataCardDashboard.annulusWellheadPress !== 0
                          ? dataCardDashboard.annulusWellheadPress
                          : 0
                      }
                      unit={"(psig)"}
                      name={"awc_pressure"}
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full col-span-2">
                    <CardGraph
                      title={"Temperature "}
                      value={
                        dataCardDashboard.annulusWellheadTemp !== 0
                          ? dataCardDashboard.annulusWellheadTemp
                          : 0
                      }
                      unit={"(deg F)"}
                      name={"awc_temp"}
                    ></CardGraph>
                  </div>
                </div>
              </div>
              {/* Tarjeta 7: Bottom Hole CONDITIONS*/}
              <div className="card w-full col-span-2  bg-white p-[0.5em]">
                <div className="grid w-full h-full grid-cols-2 grid-rows-2">
                  <div className="w-full col-span-2">
                    <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                      Bottom Hole Conditions
                    </label>
                    <CardGraph
                      title={"Pressure "}
                      value={
                        dataCardDashboard.pressureBottomHole !== 0
                          ? dataCardDashboard.pressureBottomHole
                          : 0
                      }
                      unit={"(psig)"}
                      name={"bhc_pressure"}
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full col-span-2">
                    <CardGraph
                      title={"Temperature "}
                      value={
                        dataCardDashboard?.temperature !== 0
                          ? dataCardDashboard?.temperature
                          : 0
                      }
                      unit={"(deg F)"}
                      name={"bhc_temp"}
                    ></CardGraph>
                  </div>
                </div>
              </div>
              {/* Tarjeta 4: BH CONDITIONS */}
              <div className="card w-full bg-white grid-rows-1 p-[0.5em] col-span-4 ">
                <div className="grid w-full h-full grid-cols-2">
                  <div className="w-full">
                    <label className="w-full text-pc-grey2 font-bold text-[14px] p-1">
                      Production Conditions
                    </label>
                    <CardGraph
                      title={"Oil rate "}
                      unit={"(STB/D)"}
                      name={"pc_oil_rate"}
                      value={
                        dataCardDashboard.oilRate !== 0
                          ? dataCardDashboard.oilRate
                          : 0
                      }
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full">
                    <CardGraph
                      title={"GOR "}
                      value={
                        dataCardDashboard.gor !== 0 ? dataCardDashboard.gor : 0
                      }
                      unit={"(SCF/STB)"}
                      name={"pc_gor"}
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-fulFlowingTempl">
                    <CardGraph
                      title={"Gas rate "}
                      unit={"(MSCFD)"}
                      name={"pc_gas_rate"}
                      value={
                        dataCardDashboard.correlatedFlowRate !== 0
                          ? dataCardDashboard.correlatedFlowRate
                          : 0
                      }
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full">
                    <CardGraph
                      title={"WOR "}
                      value={
                        dataCardDashboard.wor != 0 ? dataCardDashboard.wor : 0
                      }
                      unit={"(Dimensionless)"}
                      name={"pc_wor"}
                    ></CardGraph>
                  </div>
                  <div className="flex flex-col justify-center w-full">
                    <CardGraph
                      title={"Water rate "}
                      unit={"(STB/D)"}
                      name={"pc_water_rate"}
                      value={
                        dataCardDashboard.waterRate !== 0
                          ? dataCardDashboard.waterRate
                          : 0
                      }
                    ></CardGraph>
                  </div>
                </div>
              </div>
            </div>
            {/*Gráficas*/}
            {wellboreOptionState && (
              <div className="w-full h-full grid grid-cols-2 gap-x-[1em] gap-y-[1em] grid-rows-6  col-span-2 row-span-6 overflow-y-auto ml-[1em] mt-[1em]">
                <div className="card w-full row-span-3  bg-white p-[0.5em] ">
                  {/* Gráfica 1 */}
                </div>
                <div className="card w-full row-span-3  bg-white p-[0.5em]">
                  {/* Gráfica 21 */}
                </div>
                <div className="card w-full row-span-3  bg-white p-[0.5em]">
                  {/* Gráfica 3 */}
                </div>
                <div className="card w-full row-span-3  bg-white p-[0.5em]  ">
                  {/* Gráfica 4 */}
                </div>
              </div>
            )}
            {!wellboreOptionState && (
              <div className="w-full h-full grid grid-cols-2 gap-x-[1em] gap-y-[1em] grid-rows-6  col-span-2 row-span-6 overflow-y-auto mt-[1em]">
                <div className={`card w-full row-span-3  bg-white p-[0.5em] ${validateAlertGasLift.alertDomain && validateGraphDomain("graph1") ? "border-2 border-solid border-red-600" : ""}`}>
                  <div className="flex flex-row w-full">
                    <ExpandIcon
                      className="w-5 h-5 hover:bg-pc-grey3"
                      onClick={() => handleOpenModal(1)}
                    />
                    <ArrowIcon
                      className="w-6 h-6 hover:bg-pc-grey3 ml-[10px]"
                      onClick={() => handleResetLimits(0, "chartHole")}
                    />
                    <Select
                      style={{
                        backgroundColor: !selectedDeviceGraph.chartHole
                          ? "#FEF9C3"
                          : "",
                        border: !selectedDeviceGraph.chartHole
                          ? "1px solid #B91C1C"
                          : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      className="w-[180px] h-[18px] ml-[10px] text-left"
                      name={"chartHole"}
                      value={selectedDeviceGraph.chartHole}
                      onChange={(value) =>
                        handleOptionChange("graph_WPC", value)
                      }
                    >
                      <Option value="">Select option</Option>
                      {dataListDevicesGaslift?.data?.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    {/* <ExpandInputHistory className="w-5 h-5 hover:bg-pc-grey3 ml-[10px] " onClick={() => handleOpenModal(5)} /> */}
                  </div>
                  <div className="flex flex-row w-full h-[5%] pl-[1em] max-h-[10%] mt-[5px] ">
                    <label className="text-pc-grey2 font-bold text-[14px] p-1 mr-6">
                      Wellhead Production Conditions
                    </label>
                    <div className="flex flex-row items-center justify-end h-full gap-2 text-right w-2/8">
                      <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.gas_lift.wellhead_production
                            ? "selectedInput"
                            : "unselectedInput"
                          }`}
                        type="checkbox"
                        checked={checkTypeGraph.gas_lift.wellhead_production}
                        onChange={(event) =>
                          handleTypeGraph(event, "wellhead_production")
                        }
                        disabled={
                          !selectedWell || selectedWell === "null"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="" className="text-bc-gray text-[14px]">
                        {" "}
                        Historical Data + RT avg{" "}
                      </label>
                    </div>
                    <div className="flex flex-row w-[25%] h-full items-center text-right justify-end gap-2">
                      <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.gas_lift.wellhead_production
                            ? "selectedInput"
                            : "unselectedInput"
                          }`}
                        type="checkbox"
                        checked={checkTypeGraph.gas_lift.wellhead_production}
                        onChange={(event) =>
                          handleTypeGraph(event, "wellhead_production")
                        }
                        disabled={
                          !selectedWell || selectedWell === "null"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="" className="text-bc-gray text-[14px]">
                        Real Time Data
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col h-[90%]">
                    <div className="w-full h-[55px]">
                      <div className="flex flex-row items-center justify-center w-full h-full gap-2">
                        <div className="rounded-full w-3 h-3 bg-[#16a34a]"></div>
                        <p className="text-bc-grey text-[13px]">
                          Pressure
                        </p>
                        <div className="rounded-full w-3 h-3 bg-[#dc2626]"></div>
                        <p className="text-bc-grey text-[13px]">Temperature</p>
                      </div>
                    </div>
                    <ChartHole
                      className="h-[80%]"
                      dataGrapIOT={
                        dataIOTAllPlatforms?.requestData !== null
                          ? dataIOTAllPlatforms?.requestData[
                          selectedDeviceGraph.hasOwnProperty("chartHole") ? selectedDeviceGraph?.chartHole.split(" ")[0] : ""
                          ]
                          : []
                      }
                      dataHistory={arrayDataHistory}
                      typeDeviceWP={inputVolumeTrackerWP?.separator_type}
                      dataDomainGraph={domainGraph}
                      statusGraph={
                        checkTypeGraph?.gas_lift?.wellhead_production
                      }
                      dataAverage={dataVolumeTracker?.requestData}
                      // dataAverageRT={dataChartGL_well_head?.data}
                      wellId={dataWellGL?.well_id}
                    >
                      {" "}
                    </ChartHole>
                  </div>
                </div>
                <div className={`card w-full row-span-3  bg-white p-[0.5em] ${validateAlertGasLift.alertDomain && validateGraphDomain("graph2") ? "border-2 border-solid border-red-600" : ""}`}>
                  <div className="flex flex-row w-full">
                    <ExpandIcon
                      className="w-5 h-5 hover:bg-pc-grey3"
                      onClick={() => handleOpenModal(2)}
                    />
                    <ArrowIcon
                      className="w-6 h-6 hover:bg-pc-grey3 ml-[10px]"
                      onClick={() => handleResetLimits(1, "chartWellCond")}
                    />
                    <Select
                      style={{
                        backgroundColor: !selectedDeviceGraph.chartWellCond
                          ? "#FEF9C3"
                          : "",
                        border: !selectedDeviceGraph.chartWellCond
                          ? "1px solid #B91C1C"
                          : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      className="w-[180px] h-[18px] ml-[10px] text-left"
                      name={"chartWellCond"}
                      value={selectedDeviceGraph.chartWellCond}
                      onChange={(value) =>
                        handleOptionChange("graph_WIC", value)
                      }
                    >
                      <Option value="">Select option</Option>
                      {dataListDevicesGaslift?.data?.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    {/* <ExpandInputHistory className="w-5 h-5 hover:bg-pc-grey3 ml-[10px] " onClick={() => handleOpenModal(7)} /> */}
                  </div>
                  <div className="flex flex-row w-full h-[5%] pl-[2em] max-h-[10%] mt-[5px] ">
                    <label className="text-pc-grey2 font-bold text-[14px] p-1 mr-6">
                      Wellhead Injection Conditions
                    </label>
                    <div className="flex flex-row items-center justify-end h-full gap-2 text-right w-2/8">
                      <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.gas_lift.wellhead_injection
                            ? "selectedInput"
                            : "unselectedInput"
                          }`}
                        type="checkbox"
                        checked={checkTypeGraph.gas_lift.wellhead_injection}
                        onChange={(event) =>
                          handleTypeGraph(event, "wellhead_injection")
                        }
                        disabled={
                          !selectedWell || selectedWell === "null"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="" className="text-bc-gray text-[14px]">
                        {" "}
                        Historical Data + RT avg{" "}
                      </label>
                    </div>
                    <div className="flex flex-row w-[25%] h-full items-center text-right justify-end gap-2">
                      <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.gas_lift.wellhead_injection
                            ? "selectedInput"
                            : "unselectedInput"
                          }`}
                        type="checkbox"
                        checked={checkTypeGraph.gas_lift.wellhead_injection}
                        onChange={(event) =>
                          handleTypeGraph(event, "wellhead_injection")
                        }
                        disabled={
                          !selectedWell || selectedWell === "null"
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="" className="text-bc-gray text-[14px]">
                        Real Time Data
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col h-[90%]">
                    <div className="w-full h-[40px]">
                      <div className="flex flex-row items-center justify-center w-full h-full gap-2">
                        <div className="rounded-full w-3 h-3 bg-[#16a34a]"></div>
                        <p className="text-bc-grey text-[13px]">Pressure</p>
                        <div className="rounded-full w-3 h-3 bg-[#dc2626]"></div>
                        <p className="text-bc-grey text-[13px]">Gas</p>
                      </div>
                    </div>
                    <ChartWellCond
                      className="h-[80%]"
                      dataGrapIOT={
                        dataIOTAllPlatforms?.requestData !== null
                          ? dataIOTAllPlatforms?.requestData[
                          selectedDeviceGraph.hasOwnProperty("chartWellCond") ? selectedDeviceGraph?.chartWellCond.split(" ")[0] : ""
                          ]
                          : []
                      }
                      dataHistory={arrayDataHistory}
                      typeDeviceWIC={inputVolumeTrackerWIC.separator_type}
                      dataDomainGraph={domainGraph}
                      dataAverage={dataVolumeTracker?.requestData}
                      // dataAverageRT={dataChartGL_well_head_inj?.data}
                      wellId={dataWellGL?.well_id}
                      statusGraph={checkTypeGraph.gas_lift.wellhead_injection}
                    ></ChartWellCond>
                  </div>
                </div>
                <div className="flex w-full h-full col-span-2 row-span-3 overflow-hidden">
                  <Carrusel tooltips={["VLP", "Production History"]}>
                    <div className={`flex flex-col card w-full h-full row-span-3 bg-white p-[0.5em]`}>
                      <div className="flex flex-row w-full">
                        <ExpandIcon
                          className="w-5 h-5 hover:bg-pc-grey3"
                          onClick={() => handleOpenModal(3)}
                        />
                        <label
                          htmlFor=""
                          className="text-bc-gray text-[14px] ml-[15px]"
                        >
                          User Run
                        </label>
                        <Switch
                          className="ml-[10px]"
                          style={{
                            backgroundColor: switchVLP ? "#662D91" : "#707070",
                            borderColor: switchVLP ? "#662D91" : "#707070",
                          }}
                          checked={switchVLP}
                          onChange={handleSwitchVLP}
                          size="small"
                          disabled={
                            queryDataTuning?.statusCode ===
                              STATUS_DATA_VLP.SUCCESS
                              ? false
                              : true
                          }
                        ></Switch>
                        <label
                          htmlFor=""
                          className="text-bc-gray text-[14px] ml-[15px]"
                        >
                          Automatic Run
                        </label>
                        <Select
                          id="SelectTimeRunVLP"
                          value={selectIntervalVLP || "Selected option"}
                          onChange={(value) =>
                            handleSelect("SelectTimeRunVLP", value)
                          }
                          className="w-[130px] h-[22px] ml-[10px]"
                          disabled={
                            queryDataTuning?.statusCode ===
                              STATUS_DATA_VLP.SUCCESS && switchVLP
                              ? false
                              : true
                          }
                        >
                          <Option value={900000}>15 minutes</Option>
                          <Option value={1800000}>30 minutes</Option>
                          <Option value={3600000}>1 hour</Option>
                        </Select>
                        <label
                          htmlFor=""
                          className="text-bc-gray text-[14px] ml-[15px]"
                        >
                          Graph Type
                        </label>
                        <Select
                          id="selectTypeGraphVLP"
                          value={typeGraphVLP}
                          onChange={(value) =>
                            handleSelect("selectTypeGraphVLP", value)
                          }
                          className="w-[130px] h-[22px] ml-[10px]"
                        >
                          <Option value={"vlp"}>VLP</Option>
                          <Option value={"flow"}>Inflow / Outflow</Option>
                        </Select>
                      </div>
                      <div className="flex  flex-col col-span-2 w-full h-[5%] pl-[75px] max-h-[10%] ">
                        <label className="w-full  text-pc-grey2 font-bold text-[14px] p-1">
                          {typeGraphVLP === "vlp"
                            ? "VLP"
                            : "Inflow / Outflow curve"}
                        </label>
                      </div>
                      {typeGraphVLP === "vlp" ? (
                        <div className="flex flex-col w-full h-full">
                          <div className="flex w-full h-[40px">
                            <div className="flex flex-row items-center justify-center w-full h-full gap-1 ml-2">
                              <div className="w-3 h-1 bg-[#FE3F40]"></div>
                              <p className="text-bc-grey text-[13px]">
                                Tuned FBHP
                              </p>
                              <div className="w-3 h-1 bg-[#253fce]"></div>
                              <p className="text-bc-grey text-[13px]">
                                Untuned FBHP
                              </p>
                              {(!!dataTableGauge && queryDataTuning?.data?.enable_gauge_parameters) && (
                                <>
                                  <div className="rounded-full w-3 h-3 bg-[#1e1b4b]"></div>
                                  <p className="text-bc-grey text-[13px]">
                                    Gauge Pressure
                                  </p>
                                </>
                              )}
                              {queryDataTuning?.data?.mid_perf_tvd_depth &&
                                queryDataTuning?.data?.reservoir_pressure && (
                                  <>
                                    <div className="rounded-full w-3 h-3 bg-[#684200]"></div>
                                    <p className="text-bc-grey text-[13px]">
                                      Reservoir Pressure
                                    </p>
                                  </>
                                )}
                              {queryDataTuning?.data?.mid_perf_tvd_depth &&
                                queryPressDrowDown?.data?.pwf_user && (
                                  <>
                                    <div className="rounded-full w-3 h-3 bg-[#009130]"></div>
                                    <p className="text-bc-grey text-[13px]">
                                      FBHP at Perforations
                                    </p>
                                  </>
                                )}
                            </div>
                          </div>
                          <div className="flex w-full h-full">
                            {queryDataTuning?.data !== null ? (
                              <ChartVLP
                                activeChecks={activeChecks}
                                dataPoints={dataTableGauge}
                                dataPoint2={queryDataTuning?.data}
                                dataPoint3={queryPressDrowDown?.data}
                                dataTableLine={dataTableGraphVLP}
                                dataTableLine2={dataTableGraphUntVLP}
                                domainMaxDepth={maxDepth}
                                domainMinDepth={minDepth}
                                domainMaxPressure={maxPressure}
                                domainMinPressure={minPressure}
                                tuningnNav={true}
                              ></ChartVLP>
                            )
                              : (
                                <ErrorLoadingTypeGraph messagePage={"vlp"} />
                              )}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col w-full h-full">
                          <div className="flex items-center justify-center w-full h-full">
                            {!!Object.keys(dataGrapFlow).length ? (
                              <ChartInflowOutflow
                                uniqueQJ={[]}
                                dashboardMode={true}
                                dataTableGraphFlow={dataGrapFlow}
                                limits={[]}
                              ></ChartInflowOutflow>
                            ) : (
                              <ErrorLoadingTypeGraph messagePage={"flow"} />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={`card w-full h-full row-span-3 bg-white p-[0.5em] ${validateAlertGasLift.alertDomain && validateGraphDomain("graph4") ? "border-2 border-solid border-red-600" : ""}`}>
                      <div className="flex flex-row w-full">
                        <ExpandIcon
                          className="w-5 h-5 hover:bg-pc-grey3"
                          onClick={() => handleOpenModal(4)}
                        />
                        <ArrowIcon
                          className="w-6 h-6 hover:bg-pc-grey3 ml-[10px] mr-[10px]"
                          onClick={() => handleResetLimits(2, "chartProdHistory")}
                        />
                        <label
                          htmlFor="chartType"
                          className="mr-2 text-[12px] place-content-center"
                        >
                          Chart Type:
                        </label>
                        <Select
                          id="chartType"
                          value={selectedOption}
                          onChange={(value) =>
                            handleOptionChange("chartType", value)
                          }
                          className="w-[70px] h-[20px]"
                        >
                          <Option value="Line">Line</Option>
                          <Option value="Bar">Bar</Option>
                        </Select>
                        <Select
                          style={{
                            backgroundColor:
                              !selectedDeviceGraph.chartProdHistory
                                ? "#FEF9C3"
                                : "",
                            border: !selectedDeviceGraph.chartProdHistory
                              ? "1px solid #B91C1C"
                              : "",
                            borderRadius: "8px",
                            textAlignalign: "left",
                          }}
                          className="w-[180px] h-[18px] ml-[10px] text-left"
                          name={"chartProdHistory"}
                          value={selectedDeviceGraph.chartProdHistory}
                          onChange={(value) =>
                            handleOptionChange("graph_PH", value)
                          }
                        >
                          <Option value="">Select option</Option>
                          {dataListDevicesGaslift?.data
                            ?.filter((item) => item.includes("Separator"))
                            .map((item, index) => (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            ))}
                        </Select>
                        <button
                          className="w-[75px] h-5 hover:bg-pc-grey3 ml-[10px] bg-pa-purple text-white rounded-md text-[12px]"
                          onClick={() => handleOpenModal(6)}
                        >
                          Allocation
                        </button>
                      </div>
                      <div className="flex flex-col h-[90%] mt-[5px] ">
                        <div className="flex flex-row w-full h-[5%] pl-[2em] max-h-[10%] mt-[5px] ">
                          <label className="text-pc-grey2 font-bold text-[14px] p-1 mr-6">
                            Production History
                          </label>
                          <div className="flex flex-row items-center justify-end h-full gap-2 text-right w-2/8">
                            <input
                              className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.gas_lift.production_history
                                  ? "selectedInput"
                                  : "unselectedInput"
                                }`}
                              type="checkbox"
                              checked={
                                checkTypeGraph.gas_lift.production_history
                              }
                              onChange={(event) =>
                                handleTypeGraph(event, "production_history")
                              }
                              disabled={
                                !selectedWell || selectedWell === "null"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor=""
                              className="text-bc-gray text-[14px]"
                            >
                              {" "}
                              Historical Data + RT avg{" "}
                            </label>
                          </div>
                          <div className="flex flex-row w-[25%] h-full items-center text-right justify-end gap-2">
                            <input
                              className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.gas_lift.production_history
                                  ? "selectedInput"
                                  : "unselectedInput"
                                }`}
                              type="checkbox"
                              checked={
                                checkTypeGraph.gas_lift.production_history
                              }
                              onChange={(event) =>
                                handleTypeGraph(event, "production_history")
                              }
                              disabled={
                                !selectedWell || selectedWell === "null"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor=""
                              className="text-bc-gray text-[14px]"
                            >
                              Real Time Data
                            </label>
                          </div>
                        </div>
                        <div className="w-full h-[45px] mt-[2px]">
                          <div className="flex flex-row items-center justify-center w-full h-full gap-2">
                            <div className="rounded-full w-3 h-3 bg-[#194bff]"></div>
                            <p className="text-bc-grey text-[13px]">Water</p>
                            <div className="rounded-full w-3 h-3 bg-[#2bca41]"></div>
                            <p className="text-bc-grey text-[13px]">Oil</p>
                            <div className="rounded-full w-3 h-3 bg-[#ce0f41]"></div>
                            <p className="text-bc-grey text-[13px]">Gas</p>
                          </div>
                        </div>
                        <ChartProdHistory
                          className="h-[100%]"
                          typeGraph={selectedOption}
                          y={275}
                          enableModal={false}
                          type={"tab"}
                          dataGrapIOT={ dataGraphProduction.length ? dataGraphProduction : [] }
                          typeDevicePH={inputVolumeTrackerPH.separator_type}
                          dataHistory={arrayDataHistory}
                          dataDomainGraph={domainGraph}
                          dataAverage={dataVolumeTracker?.requestData}
                          dataAverageRT={dataVolumeTrackerRT?.requestData}
                          wellId={dataWellGL?.well_id}
                          statusGraph={
                            checkTypeGraph.gas_lift.production_history
                          }
                        ></ChartProdHistory>
                      </div>
                    </div>
                  </Carrusel>
                </div>
              </div>
            )}

            <AlertModal
              showAlert={showAlert}
              handleCloseModal={handleCloseModalAlert}
              messageAlert={messageAlert}
              typeModal={typeAlert}
            ></AlertModal>

            <Modal
              title={`${selectedCardId === 1
                  ? "Wellhead production conditions"
                  : selectedCardId === 2
                    ? "Wellhead injection conditions"
                    : selectedCardId === 3
                      ? typeGraphVLP === "vlp"
                        ? "VLP"
                        : typeGraphVLP === "flow"
                          ? "Inflow / Outflow curve"
                          : ""
                      : selectedCardId === 4
                        ? "Production History"
                        : "Upload data for monthly average"
                } `}
              centered
              open={isOpen}
              onOk={handleCloseModalGraph}
              onCancel={() => setOpen(false)}
              okButtonProps={{
                className: "bg-[#662D91]",
              }}
              width="80%"
            >
              <div className="h-[800px]">
                {/* Filtrar y mostrar los datos correspondientes a la card seleccionada */}
                {selectedCardId === 1 && (
                  <div className="flex flex-col h-[100%]">
                    <div className="grid grid-cols-2 ">
                      <div className="flex justify-start w-full">
                        <div className="flex flex-col items-center">
                          <label htmlFor="domainY1" className="text-xs">
                            Press max
                          </label>
                          <input
                            type="number"
                            name="press_max_wpc"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.press_max_wpc === "" || domainGraph.press_max_wpc === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.press_max_wpc}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end w-full">
                        <div className="flex flex-col items-center">
                          <label htmlFor="domainY2" className="text-xs">
                            Temp max
                          </label>
                          <input
                            type="number"
                            name="temp_max_wpc"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.temp_max_wpc === "" || domainGraph.temp_max_wpc === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.temp_max_wpc}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-full h-[40px] justify-center">
                      <div className="flex flex-row items-center h-full gap-2 w-2/8">
                        <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.gas_lift.wellhead_production
                              ? "selectedInput"
                              : "unselectedInput"
                            }`}
                          type="checkbox"
                          checked={checkTypeGraph.gas_lift.wellhead_production}
                          onChange={(event) =>
                            handleTypeGraph(event, "wellhead_production")
                          }
                        />
                        <label htmlFor="" className="text-bc-gray text-[14px]">
                          Historical Data + RT avg
                        </label>
                      </div>
                      <div className="flex flex-row w-[20%] h-full items-center justify-center gap-2">
                        <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.gas_lift.wellhead_production
                              ? "selectedInput"
                              : "unselectedInput"
                            }`}
                          type="checkbox"
                          checked={checkTypeGraph.gas_lift.wellhead_production}
                          onChange={(event) =>
                            handleTypeGraph(event, "wellhead_production")
                          }
                        />
                        <label htmlFor="" className="text-bc-gray text-[14px]">
                          Real Time Data
                        </label>
                      </div>
                    </div>
                    <div className="w-full h-[40px]">
                      <div className="flex flex-row items-center justify-center w-full h-full gap-2">
                        <div className="rounded-full w-3 h-3 bg-[#16a34a]"></div>
                        <p className="text-bc-grey text-[13px]">
                          Pressure
                        </p>
                        <div className="rounded-full w-3 h-3 bg-[#dc2626]"></div>
                        <p className="text-bc-grey text-[13px]">Temperature</p>
                      </div>
                    </div>
                    <ChartHole
                      className="h-[80%]"
                      dataGrapIOT={
                        dataIOTAllPlatforms?.requestData !== null
                          ? dataIOTAllPlatforms?.requestData[
                          selectedDeviceGraph.hasOwnProperty("chartHole") ? selectedDeviceGraph?.chartHole.split(" ")[0] : ""
                          ]
                          : []
                      }
                      dataHistory={arrayDataHistory}
                      typeDeviceWP={inputVolumeTrackerWP.separator_type}
                      dataDomainGraph={domainGraph}
                      dataAverage={dataVolumeTracker?.requestData}
                      // dataAverageRT={dataChartGL_well_head?.data}
                      statusGraph={checkTypeGraph.gas_lift.wellhead_production}
                    ></ChartHole>
                    <div className="grid grid-cols-2">
                      <div className="flex justify-start w-full">
                        <div className="flex flex-col items-center">
                          <input
                            type="number"
                            name="press_min_wpc"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.press_min_wpc === "" || domainGraph.press_min_wpc === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.press_min_wpc}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                          <label htmlFor="domainY1" className="text-xs">
                            Press min
                          </label>
                        </div>
                      </div>
                      <div className="flex justify-end w-full">
                        <div className="flex flex-col items-center">
                          <input
                            type="number"
                            name="temp_min_wpc"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.temp_min_wpc === "" || domainGraph.temp_min_wpc === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.temp_min_wpc}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                          <label htmlFor="domainY1" className="text-xs">
                            Temp min
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedCardId === 2 && (
                  <div className="flex flex-col h-[100%]">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-start w-full">
                        <div className="flex flex-col items-center">
                          <label htmlFor="domainY1" className="text-xs">
                            Injection rate max
                          </label>
                          <input
                            type="number"
                            name="injrate_max_wic"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.injrate_max_wic === "" || domainGraph.injrate_max_wic === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.injrate_max_wic}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end w-full">
                        <div className="flex flex-col items-center">
                          <label htmlFor="domainY1" className="text-xs">
                            Injection press max
                          </label>
                          <input
                            type="number"
                            name="injpress_max_wic"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.injpress_max_wic === "" || domainGraph.injpress_max_wic === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.injpress_max_wic}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-full h-[40px] justify-center">
                      <div className="flex flex-row items-center h-full gap-2 w-2/8">
                        <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.gas_lift.wellhead_injection
                              ? "selectedInput"
                              : "unselectedInput"
                            }`}
                          type="checkbox"
                          checked={checkTypeGraph.gas_lift.wellhead_injection}
                          onChange={(event) =>
                            handleTypeGraph(event, "wellhead_injection")
                          }
                          disabled={
                            !selectedWell || selectedWell === "null"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="" className="text-bc-gray text-[14px]">
                          {" "}
                          Historical Data + RT avg{" "}
                        </label>
                      </div>
                      <div className="flex flex-row w-[20%] h-full items-center justify-center gap-2">
                        <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.gas_lift.wellhead_injection
                              ? "selectedInput"
                              : "unselectedInput"
                            }`}
                          type="checkbox"
                          checked={checkTypeGraph.gas_lift.wellhead_injection}
                          onChange={(event) =>
                            handleTypeGraph(event, "wellhead_injection")
                          }
                          disabled={
                            !selectedWell || selectedWell === "null"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="" className="text-bc-gray text-[14px]">
                          Real Time Data
                        </label>
                      </div>
                    </div>
                    <div className="w-full h-[50px]">
                      <div className="flex flex-row items-center justify-center w-full h-full gap-2">
                        <div className="rounded-full w-3 h-3 bg-[#16a34a]"></div>
                        <p className="text-bc-grey text-[13px]">Pressure</p>
                        <div className="rounded-full w-3 h-3 bg-[#dc2626]"></div>
                        <p className="text-bc-grey text-[13px]">Gas</p>
                      </div>
                    </div>
                    <ChartWellCond
                      className="h-[100%]"
                      dataGrapIOT={
                        dataIOTAllPlatforms?.requestData !== null
                          ? dataIOTAllPlatforms?.requestData[
                          selectedDeviceGraph.hasOwnProperty("chartWellCond") ? selectedDeviceGraph?.chartWellCond.split(" ")[0] : ""
                          ]
                          : []
                      }
                      dataHistory={arrayDataHistory}
                      typeDeviceWIC={inputVolumeTrackerWIC.separator_type}
                      dataDomainGraph={domainGraph}
                      dataAverage={dataVolumeTracker?.requestData}
                      dataAverageRT={dataVolumeTrackerRT?.requestData}
                      wellId={dataWellGL?.well_id}
                      statusGraph={checkTypeGraph.gas_lift.wellhead_injection}
                    ></ChartWellCond>
                    <div className="grid grid-cols-2">
                      <div className="flex justify-start w-full">
                        <div className="flex flex-col items-center">
                          <input
                            type="number"
                            name="injrate_min_wic"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.injrate_min_wic === "" || domainGraph.injrate_min_wic === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.injrate_min_wic}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                          <label htmlFor="domainY1" className="text-xs">
                            Injection rate min
                          </label>
                        </div>
                      </div>
                      <div className="flex justify-end w-full">
                        <div className="flex flex-col items-center">
                          <input
                            type="number"
                            name="injpress_min_wic"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.injpress_min_wic === "" || domainGraph.injpress_min_wic === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.injpress_min_wic}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                          <label htmlFor="domainY1" className="text-xs">
                            Injection press min
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedCardId === 3 && (
                  <>
                    {typeGraphVLP === "vlp" ? (
                      <div className="flex flex-col w-full h-full">
                        <div className="flex w-full p-2">
                          <div className="flex flex-row items-center justify-center w-full h-full gap-2">
                            <div className="w-3 h-1 bg-[#FE3F40]"></div>
                            <p className="text-bc-grey text-[13px]">
                              Tuned FBHP
                            </p>
                            <div className="w-3 h-1 bg-[#253fce]"></div>
                            <p className="text-bc-grey text-[13px]">
                              Untuned FBHP
                            </p>
                            {(!!dataTableGauge && queryDataTuning?.enable_gauge_parameters) && (
                              <>
                                <div className="rounded-full w-3 h-3 bg-[#1e1b4b]"></div>
                                <p className="text-bc-grey text-[13px]">
                                  Gauge Pressure
                                </p>
                              </>
                            )}
                            {queryDataTuning?.data?.mid_perf_tvd_depth &&
                              queryDataTuning?.data?.reservoir_pressure && (
                                <>
                                  <div className="rounded-full w-3 h-3 bg-[#684200]"></div>
                                  <p className="text-bc-grey text-[13px]">
                                    Reservoir Pressure
                                  </p>
                                </>
                              )}
                            {queryPressDrowDown?.data?.mid_perf_tvd_depth &&
                              queryPressDrowDown?.data?.pwf_user && (
                                <>
                                  <div className="rounded-full w-3 h-3 bg-[#009130]"></div>
                                  <p className="text-bc-grey text-[13px]">
                                    FBHP at Perforations
                                  </p>
                                </>
                              )}
                          </div>
                        </div>
                        <div className="flex w-full h-full p-2">
                          <ChartVLP
                            activeChecks={activeChecks}
                            dataPoints={dataTableGauge}
                            dataTableLine={dataTableGraphVLP}
                            dataTableLine2={dataTableGraphUntVLP}
                            domainMaxDepth={maxDepth}
                            domainMinDepth={minDepth}
                            domainMaxPressure={maxPressure}
                            domainMinPressure={minPressure}
                            dataDomainGraph={domainGraph}
                            tuningnNav={true}
                          ></ChartVLP>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col w-full h-full">
                        <div className="flex items-center justify-center w-full h-full">
                          {!!Object.keys(dataGrapFlow).length ? (
                            <ChartInflowOutflow
                              uniqueQJ={[]}
                              dashboardMode={true}
                              dataTableGraphFlow={dataGrapFlow}
                              limits={[]}
                            ></ChartInflowOutflow>
                          ) : (
                            <ErrorLoadingTypeGraph messagePage={"flow"} />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {selectedCardId === 4 && (
                  <div className="flex flex-col h-full">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-start w-full">
                        <div className="flex flex-col items-center">
                          <label htmlFor="domainY1" className="text-xs">
                            Liquid Rate max
                          </label>
                          <input
                            type="number"
                            name="rate_max_ph"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.rate_max_ph === "" || domainGraph.rate_max_ph === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.rate_max_ph}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end w-full">
                        <div className="flex flex-col items-center">
                          <label htmlFor="domainY1" className="text-xs">
                            Gas Rate max
                          </label>
                          <input
                            type="number"
                            name="gas_max_ph"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.gas_max_ph === "" || domainGraph.gas_max_ph === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.gas_max_ph}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-full h-[40px] justify-center">
                      <div className="flex flex-row items-center justify-center w-2/6 h-full gap-2 text-right">
                        <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${!checkTypeGraph.gas_lift.production_history
                              ? "selectedInput"
                              : "unselectedInput"
                            }`}
                          type="checkbox"
                          checked={checkTypeGraph.gas_lift.production_history}
                          onChange={(event) =>
                            handleTypeGraph(event, "production_history")
                          }
                        />
                        <label htmlFor="" className="text-bc-gray text-[14px]">
                          Historical Data + RT avg
                        </label>
                      </div>
                      <div className="flex flex-row w-[15%] h-full items-center text-right justify-end gap-2">
                        <input
                          className={`ml-1 w-5 h-5 appearance-none rounded-full ${checkTypeGraph.gas_lift.production_history
                              ? "selectedInput"
                              : "unselectedInput"
                            }`}
                          type="checkbox"
                          checked={checkTypeGraph.gas_lift.production_history}
                          onChange={(event) =>
                            handleTypeGraph(event, "production_history")
                          }
                        />
                        <label htmlFor="" className="text-bc-gray text-[14px]">
                          Real Time Data
                        </label>
                      </div>
                      <div className="flex flex-row w-[20%] h-full items-center text-right justify-end gap-2">
                        <label htmlFor="chartType">Chart Type:</label>
                        <select
                          id="chartType"
                          value={selectedOption}
                          onChange={(e) =>
                            handleOptionChange("chartType", e.target.value)
                          }
                          className="border border-gray-300 rounded-md "
                        >
                          <option value="Line">Line</option>
                          <option value="Bar">Bar</option>
                        </select>
                      </div>
                    </div>
                    <div className="w-full h-[50px]">
                      <div className="flex flex-row items-center justify-center w-full h-full gap-2">
                        <div className="rounded-full w-3 h-3 bg-[#194bff]"></div>
                        <p className="text-bc-grey text-[13px]">Water</p>
                        <div className="rounded-full w-3 h-3 bg-[#2bca41]"></div>
                        <p className="text-bc-grey text-[13px]">Oil</p>
                        <div className="rounded-full w-3 h-3 bg-[#ce0f41]"></div>
                        <p className="text-bc-grey text-[13px]">Gas</p>
                      </div>
                    </div>
                    <ChartProdHistory
                      className="h-[90%]"
                      typeGraph={selectedOption}
                      dataGrapIOT={ dataGraphProduction.length ? dataGraphProduction : [] }
                      typeDevicePH={inputVolumeTrackerPH.separator_type}
                      dataHistory={arrayDataHistory}
                      dataDomainGraph={domainGraph}
                      dataAverage={dataVolumeTracker?.requestData}
                      dataAverageRT={dataVolumeTrackerRT?.requestData}
                      wellId={dataWellGL?.well_id}
                      type={"modal"}
                      y={420}
                      enableModal={true}
                      statusGraph={checkTypeGraph.gas_lift.production_history}
                    ></ChartProdHistory>
                    <div className="grid grid-cols-2">
                      <div className="flex justify-start w-full">
                        <div className="flex flex-col items-center">
                          <input
                            type="number"
                            name="rate_min_ph"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.rate_min_ph === "" || domainGraph.rate_min_ph === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.rate_min_ph}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                          <label htmlFor="domainY1" className="text-xs">
                            Liquid Rate min
                          </label>
                        </div>
                      </div>
                      <div className="flex justify-end w-full">
                        <div className="flex flex-col items-center">
                          <input
                            type="number"
                            name="gas_min_ph"
                            className={`w-[22%] domain ${validateAlertGasLift.alertDomain && (domainGraph.gas_min_ph === "" || domainGraph.gas_min_ph === null) ? "bg-warning-200" : "bg-white"}`}
                            min="0"
                            style={{
                              border: "solid 1px",
                              WebkitAppearance: "none",
                              textAlign: "center",
                            }}
                            value={domainGraph.gas_min_ph}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                          <label htmlFor="domainY1" className="text-xs">
                            Gas Rate min
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
            <Modal
              title="Allocation Information"
              centered
              open={isOpen2}
              onOk={() => {
                if (!loadVolumeT) {
                  handleClickAverages();
                }
              }}
              onCancel={() => {
                if (!loadVolumeT) {
                  getIsOpenModalAllocattion(false);
                }
              }}
              okButtonProps={{
                className: "bg-[#662D91]",
              }}
              width={720}
              cancelButtonProps={{ disabled: loadVolumeT }}
            >
              {loadVolumeT ? (
                <div className="flex flex-col w-full text-center justify-center mt-8 h-[320px]">
                  <Loading />
                </div>
              ) : (
                <div className="flex flex-col w-full h-full mt-[45px] ">
                  <div className="grid grid-cols-2 w-[550px] h-[320px] p-2 ml-[20px] ">
                    <label
                      htmlFor=""
                      className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mb-[7px] mt-[15px] "
                    >
                      Measuring Fluid Device Location
                    </label>
                    <Select
                      style={{
                        backgroundColor: !inputVolumeTrackerPH.separator_type
                          ? "#FEF9C3"
                          : "",
                        border: !inputVolumeTrackerPH.separator_type
                          ? "3px solid #B91C1C"
                          : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      className="w-full max-w-[260px] h-[47px] text-left mt-[15px] mb-[17px]"
                      name={"separator_type"}
                      value={inputVolumeTrackerPH.separator_type}
                      onChange={(value) =>
                        handleOptionChange("separator_type_PH", value)
                      }
                    >
                      <Option value="">Select option</Option>
                      {dataListDevicesGaslift?.data
                        ?.filter((item) => item.includes("Separator"))
                        .map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                    </Select>
                    <label
                      htmlFor=""
                      className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mb-[7px] mt-[15px] "
                    >
                      Well Liquid Allocation Factor (%)
                    </label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      style={{ WebkitAppearance: "none" }}
                      name="fcat"
                      value={inputVolumeTrackerPH.fcat}
                      onChange={(e) => handleChangeFluidInformation(e, "PH")}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[260px] h-[47px] rounded-[4px] px-3 border border-solid  mb-[17px] ${validAllocation && inputVolumeTrackerPH.fcat === "" ? " border-red-700 bg-yellow-100" : "border-[#BCBCCC]"}`}
                    />
                    <label
                      htmlFor=""
                      className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mb-[7px] mt-[15px] "
                    >
                      Well Gas Allocation Factor (%)
                    </label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      style={{ WebkitAppearance: "none" }}
                      name="fcat_gas"
                      value={inputVolumeTrackerPH?.fcat_gas}
                      onChange={(e) => handleChangeFluidInformation(e, "PH")}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[260px] h-[47px] rounded-[4px] px-3 border border-solid  mb-[17px] ${validAllocation && inputVolumeTrackerPH.fcat_gas === "" ? " border-red-700 bg-yellow-100" : "border-[#BCBCCC]"}`}
                    />
                    <label
                      htmlFor=""
                      className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mt-[15px] mb-[7px]"
                    >
                      Well Water Cut (%)
                    </label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      style={{ WebkitAppearance: "none" }}
                      name="water_cut"
                      value={inputVolumeTrackerPH.water_cut}
                      onChange={(e) => handleChangeFluidInformation(e, "PH")}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[260px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${validAllocation && inputVolumeTrackerPH.water_cut === "" ? " border-red-700 bg-yellow-100" : "border-[#BCBCCC]"}`}
                    />
                  </div>
                </div>
              )}
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};
