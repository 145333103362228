import { Modal } from 'antd';
import React from 'react';

export const AlertModal = ({showAlert,handleCloseModal,messageAlert,typeModal}) => {

  return (
    <>
      <Modal
        zIndex={10000}
        title={typeModal}
        open={showAlert}
        onOk={handleCloseModal}
        okText="Ok"
        className="bg-[#662D91] rounded-md"
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
        footer={[
          <button
            key="ok"
            onClick={handleCloseModal}
            className="bg-[#662D91] text-white px-4 py-2 rounded-md"
          >
            Ok
          </button>,
        ]}
      >
        <p>{messageAlert}</p>
      </Modal>
    </>
  )
}