import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { DashboardContext } from '../context/Dashboard/DashboardContentx';
import {
  createParametersPlotting,
  deleteParametersPlotting,
  getJsonPlottingIOT,
  getListDevicesPlotting,
  getListParametersPlotting,
  getListPlatformsPlotting,
  getParametersPlotting,
  updateParametersPlotting,
  getPlatformsListPlotting,
  getDevicesListPlotting,
  getParametersListPlotting,
  getIOTWellData
} from '../services/api/plotting';
import { DashboardPlottingContext } from '../context/DashboardPlotting/DashboardPlottingContext';
import { INITIAL_DATA_PLOTTING } from '../components/elements/types/type_plotting';
import { AuthContext } from '../auth/context/AuthContext';
import { message } from 'antd';

export const useTabPlotting = () => {
  const { userDataInfo } = useContext(DashboardContext);
  const { dataPlotting, getDataPlotting, getViewLoadingPlotting, viewLoadingPlotting } = useContext(DashboardPlottingContext);
  const { User } = useContext(AuthContext);
  const [concatenatedData, setConcatenatedData] = useState([]);
  const [inputDataPlot, setInputDataPlot] = useState(INITIAL_DATA_PLOTTING);
  const [optionsAllIOT, setOptionsAllIOT] = useState([]);
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [startDateIOT, setStartDateIOT] = useState();
  const [endDateIOT, setEndDateIOT] = useState();
  
  const { data: dataListPlatforms, isLoading: loadDataListPlatforms, isError: ErrorDataListPlatforms, refetch: refetchDataListPlatforms, } = useQuery({
    queryKey: ['dataListPlatforms'],
    queryFn: () => getPlatformsListPlotting(userDataInfo.operator, userDataInfo.lease, userDataInfo.region),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    },
  });

  const { data: dataListDevices, isLoading: loadDataListDevices, isError: ErrorDataListDevices, refetch: refetchDataListDevices, isSuccess } = useQuery({
    queryKey: ['dataListDevices', inputDataPlot.selected_platform],
    queryFn: () => getDevicesListPlotting(
      userDataInfo.operator,
      userDataInfo.lease,
      userDataInfo.region,
      dataPlotting.selected_platform.length > 0 ? dataPlotting.selected_platform : []
    ),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    },
  });

  const { data: dataListParameters, isLoading: loadDataListParameters, refetch: refetchDataListParameters } = useQuery({
    queryKey: ["dataListParameters"],
    queryFn: () => getParametersListPlotting(
      userDataInfo.operator,
      userDataInfo.lease,
      userDataInfo.region,
      dataPlotting?.selected_device.length > 0 ? dataPlotting?.selected_device : []
    ),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      const optionsSelect = data?.data.map((item, index) => ({
        label: item,
        value: item,
      })) || [];
      setOptionsAllIOT(optionsSelect?.sort((a, b) => a.label.localeCompare(b.label)));
    },
    onError: (error) => {
      console.error("Error al obtener parámetros:", error);
    },
  });

  const { data: dataPlottingIOT, isLoading: loadDataPlottingIOT, isError: ErrorDataPlottingIOT, refetch: refetchDataPlottingIOT } = useQuery({
    queryKey: ['dataIOTDCA'],
    queryFn: () => getJsonPlottingIOT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, 'all', 'all'),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    },
  })

  const { data: dataParamsPlotting, isLoading: loadDataParamsPlotting, isError: ErrorDataParamsPlotting, refetch: refetchParamsPlotting } = useQuery({
    queryKey: ['dataParamsPlotting'],
    queryFn: () => getParametersPlotting(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
      // Verifica si data?.data[0] es null
      if (data?.data[0] === null) {
        getDataPlotting(INITIAL_DATA_PLOTTING)
      } else {
        // Solo ejecuta getDataPlotting si data?.data[0] es diferente de null
        getDataPlotting({
          selected_platform: data?.data[0].data.selected_platform,
          selected_device: data?.data[0].data.selected_device,
          selected_parameter: data?.data[0].data.selected_parameter,
          dataPlotting: data?.data[0].data.dataPlotting
        });
      }
    },
    onError: (error) => {
      // Manejo de errores si es necesario
    },
  })

  const addParameters = useMutation({
    mutationFn: createParametersPlotting,
    retry: 1,
    onMutate: () => {
      getViewLoadingPlotting({ ...viewLoadingPlotting, loadSaveParameters: true });
    },
    onSuccess: () => {
      message.success("Data save successfull");
      getViewLoadingPlotting({ ...viewLoadingPlotting, loadSaveParameters: false });
    },
    onError: () => {
      getViewLoadingPlotting({ ...viewLoadingPlotting, loadSaveParameters: false });
    }
  })

  const cleanParameters = useMutation({
    mutationFn: deleteParametersPlotting,
    onSuccess: () => {
    },
    onError: () => {
    }
  })

  const modifyParameters = useMutation({
    mutationFn: updateParametersPlotting,
    onMutate: () => {
      getViewLoadingPlotting({ ...viewLoadingPlotting, loadSaveParameters: true });
    },
    onSuccess: () => {
      getViewLoadingPlotting({ ...viewLoadingPlotting, loadSaveParameters: false });
      message.success("Data update successfull");
    },
    onError: () => {
      getViewLoadingPlotting({ ...viewLoadingPlotting, loadSaveParameters: false });
    }
  })

  // Función para obtener el color específico desde el archivo de configuración
  const getSpecificColor = (parameter) => {
    // Mapeo de colores basado en el archivo cargado, ejemplo:
    const colorMap = {
      "SMI-27 LACT Meter 1 Previous Day Volume": "#006400", // Dark Green
      "SMI-27 Fuel Gas Meter Flowing Temperature": "#f5dc00", //Yellow
      "SMI-27 LP Separator Flowing Temperature": "#f5dc00", //Yellow
      "V-191 Test Separator Flowing Temperature": "#f5dc00", //Yellow
      "SMI-27 Sales Gas Meter Flowing Temperature": "#f5dc00", //Yellow
      "SMI-27 LP Separator Differential Pressure": "#A349A4", // Pink
      "SMI-27 Sales Gas Meter Differential Pressure": "#A349A4", // Pink
      "SMI-27 Sales Gas Meter Static Pressure": "#8a8980", //Gray
      "SMI-27 LACT Meter 1 Flow Rate": "#90EE90", // Light Green
      "V-191 Well Head Pressure C6": "#000000", // Black
      "SMI-27 IP Separator Corrected Previous Day Volume": "#8B0000", // Dark Red
      "SMI-27 Fuel Gas Meter Corrected Flow Rate": "#FF0202", // Light Red
      "SMI-27 IP Separator Corrected Flow Rate": "#FF0202", // Light Red
      "SMI-27 Sales Gas Meter Corrected Current Day Volume": "#8B0000", // Dark Red
      "SMI-27 IP Separator Static Pressure": "#800080", // Purple
      "SMI-27 LACT Meter 2 Flow Rate": "#90EE90", // Light Green
      "SMI-27 IP Separator Corrected Accumulated Volume": "#8B0000", // Dark Red
      "V-191 Test Separator Corrected Current Day Volume": "#8B0000", // Dark Red
      "V-191 Test Separator Corrected Flow Rate": "#FF0202", // Light Red
      "SMI-27 LP Separator Liquid Flow Rate": "#BF6B21", // Light Brown
      "SMI-27 IP Separator Liquid Flow Rate": "#BF6B21", // Light Brown
      "SMI-27 Fuel Gas Meter Corrected Accumulated Volume": "#8B0000", // Dark Red
      "SMI-27 Fuel Gas Meter Corrected Current Day Volume": "#8B0000", // Dark Red
      "SMI-27 Sales Gas Meter Corrected Flow Rate": "#FF0202", // Light Red
      "V-191 Test Separator Static Pressure": "#800080", // Purple
      "SMI-27 LACT Meter 2 Previous Day Volume": "#006400", // Dark Green
      "SMI-27 LP Separator Static Pressure": "#800080", // Purple
      "SMI-27 LACT Meter 1 Current Day Volume": "#006400", // Dark Green
      "SMI-27 LACT Meter 2 Net Accumulated Volume": "#006400", // Dark Green
      "SMI-27 Sales Gas Meter Gas Flow Rate": "#FF0202", // Light Red
      "V-191 Test Separator Liquid Flow Rate": "#BF6B21", // Light Brown
      "SMI-27 LACT Meter 2 Current Day Volume": "#006400", // Dark Green
      "V-191 Test Separator Corrected Previous Day Volume": "#8B0000", // Dark Red
      "V-191 Test Separator Liquid Previous Day Total": "#8B4513", // Dark Brown
      "SMI-27 LP Separator Liquid Previous Day Total": "#8B4513", // Dark Brown
      "SMI-27 IP Separator Liquid Previous Day Total": "#8B4513", // Dark Brown
      "SMI-27 LACT Meter 1 Net Accumulated Volume": "#006400", // Dark Green
      "SMI-27 IP Separator Separator Pressure": "#800080", // Purple
      "V-191 Well Head Pressure C9": "#000000", // Black
      "SMI-27 Sales Gas Meter Corrected Previous Day Volume": "#8B0000", // Dark Red
      "SMI-27 LP Separator Corrected Flow Rate": "#FF0202", // Light Red
      "V-191 Test Separator Corrected Accumulated Volume": "#8B0000", // Dark Red
      "V-191 Test Separator Separator Pressure": "#800080", // Purple
      "Water rate": "#ADD8E6", // Light Blue
      "Water volume": "#00008B", // Dark Blue
    };

    return colorMap[parameter] || "#e8b7e4"; //light pink
  };

  const { data: dataIOT, isFetching: loadDataIOT, isError: ErrorDataIOT, refetch: refetchDataIOT } = useQuery({
    queryKey: ['DataIOT'],
    queryFn: () => getIOTWellData(
      userDataInfo.operator,
      userDataInfo.lease,
      userDataInfo.region,
      dataPlotting?.selected_parameter.length > 0 ? dataPlotting?.selected_parameter : [],
      startDateIOT,
      endDateIOT
    ),
    retry: 1,
    enabled: false,
    onSuccess: (response) => {
    },
    onError: (error) => {
    },
  })
  return {
    dataListPlatforms,
    refetchDataListPlatforms,
    dataListDevices,
    refetchDataListDevices,
    dataPlottingIOT,
    refetchDataPlottingIOT,
    concatenatedData,
    setConcatenatedData,
    inputDataPlot,
    setInputDataPlot,
    optionsAllIOT,
    setOptionsAllIOT,
    dataParamsPlotting,
    loadDataParamsPlotting,
    ErrorDataParamsPlotting,
    refetchParamsPlotting,
    addParameters,
    cleanParameters,
    modifyParameters,
    loadDataPlottingIOT,
    getSpecificColor,
    loadDataListParameters,
    refetchDataListParameters,
    setStartDateIOT,
    setEndDateIOT,
    refetchDataIOT,
    dataIOT,
    loadDataIOT
  }
}