import { message } from 'antd';
import { useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../auth/context/AuthContext';
import { DashboardDCAContext } from '../context/DasboardDCA/DashboardDCAContext';
import { DashboardContext } from '../context/Dashboard/DashboardContentx';
import { deleteDataHistory, deleteJsonWellDCA, getChart_ai_dcaData, getChartDCAPerformanceData, getChartDCAProductionData, getDataHistory, getJsonADDWellDCA, getJsonIOTAllPlatforms, getJsonIOTVolumeTracker, getJsonVolumeTracker, getJsonWellDCA, getListDataHistory, updateDataHistory, updateJsonWellDCA, uploadDataHistory, uploadJsonVolumeTracker, uploadJsonWellDCA } from '../services/api/dcaAPI';
import { getConfirmUploadFile, getForecast, postForecast, postForecastSemi, postForecastFull, uploadForecast } from '../services/api/forecastAPI';
import { VALUE_SENSIBILTY } from '../components/elements/types/type_value_sensitives';
import { WELL_SENSOR_DEVICE } from '../components/elements/types/type_dashboard';
import { STATUS_DATA_DCA } from '../components/elements/types/type_response_API';
import { NAME_PAGES } from '../components/elements/types/type_pages';
import { useFilters } from "./useFilters";

const initialInputPropiertiesOut = {
  well_id: "",
  fluid_type: "",
  region: "",
  county: "",
  field: "",
  latitude: "",
  longitude: "",
  productForescast: "",
  connectionWellPress: true,
  iprModel: "",
  generateForeOil: true,
  generateForeGas: true,
  generateForeOilWater: true,
  forecastTime: 0.0,
  oilRateLow: 0.0,
  asRateLow: 0.0,
  WaterRateLow: 0.0,
  staticResPress: 0.0,
  productionForeCastModel: "",
  fluidFlowModel: "",
  vTable: "",
  apiGravity: 0.0,
  oilPVTCorrelation: 0.0,
  oilViscosityCorrelation: 0.0,
  gasSpecificGravity: 0.0,
  co2Fraction: 0.0,
  n2Fraction: 0.0,
  numberInteraction: 0.0,
  prodMonthsModel: 0,
  srvPermeability: 0.0,
  fractureHalfLength: 0.0,
  stimulationEfficency: 0
}

export const useTabDCA = (table = []) => {
  const navigate = useNavigate();
  const { User } = useContext(AuthContext);
  const {
    getUserDataInfo,
    userDataInfo,
    deviceRealTime,
    getDeviceRealTime,
  } = useContext(DashboardContext);
  const { getDataFilterUserDCA, dataFilterUserDCA, getViewLoadingDCA, viewLoadingDCA } = useContext(DashboardDCAContext);
  const [bodyAddWell, setBodyAddWell] = useState([]);
  const [viewLoading, setViewLoading] = useState(true);
  const [generateTable, setGenerateTable] = useState(false)
  const [typeTable, setTypeTable] = useState(false);
  const [lastIndexSelected, setLastIndexSelected] = useState();
  const [inputPropertiesOut, setInputPropertiesOut] = useState(initialInputPropiertiesOut);
  const [userMultiselect, setUserMultiselect] = useState();
  const { dataFilter, mutationUpdateFilter } = useFilters();


  const tableData = table.length ? table : typeTable;

  //LLAMADOS API
  const { data: dataIOTAllPlatforms, isLoading: loadDataIOTAllPlatforms, isError: ErrorDataIOTAllPlatforms, refetch: refetchDataIOTAllPlatforms } = useQuery({
    queryKey: ['dataIOTAllPlatforms'],
    queryFn: () => getJsonIOTAllPlatforms(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserDCA.county, dataFilterUserDCA.field, dataFilterUserDCA.well_id, User.user_name),
    retry: 1,
    enabled: false,
    refetchInterval: 15 * 60 * 1000,
    onSuccess: (data) => {
    },
    onError: (error) => {
    },
  })

  const mutationUploadJsonWellDCA = useMutation({
    mutationFn: uploadJsonWellDCA,
    onMutate: () => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: true });
    },
    onSuccess: (data, dataMutation) => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: false });
      if (data?.statusCode === STATUS_DATA_DCA.SUCCESS) {
        async function executeFunction() {
          await mutationUpdateFilter.mutateAsync({
            user: User.user_name,
            data: {
              ...dataFilter?.data,
              checkedWellDCA: dataMutation.wellid
            }
          });

          await new Promise(resolve => setTimeout(resolve, 100));

          await refetchDataHistoryDCA();
          message.success(`Data Well DCA Saved Successfull`);
        }
        executeFunction();
      } else {
        message.error(`An error has occurred when loading the data Well DCA`);
      }
    },
    onError: (error) => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: false });
      message.error(`An error has occurred when loading the information of well`)
    }
  })

  const mutationUploadJsonWellDCAParcial = useMutation({
    mutationFn: uploadJsonWellDCA,
    onMutate: () => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: true });
    },
    onSuccess: (data, dataMutation) => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: false });
      if (data?.statusCode === STATUS_DATA_DCA.SUCCESS) {
        async function executeFunction() {
          await mutationUpdateFilter.mutateAsync({
            user: User.user_name,
            data: {
              ...dataFilter?.data,
              checkedWellDCA: dataMutation.wellid
            }
          });

          await new Promise(resolve => setTimeout(resolve, 100));

          await refetchDataHistoryDCA();
          message.success(`Data Well DCA Saved Successfull`);
        }
        navigate(`/${NAME_PAGES.DCA.list_well}`);
        message.success("Processing complete!");
        executeFunction();
      } else {
        message.error(`An error has occurred when loading the data Well DCA`);
      }
    },
    onError: (error) => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: false });
      message.error(`An error has occurred when loading the information of well`)
    }
  })

  const mutationUpdateJsonWellDCA = useMutation({
    mutationFn: updateJsonWellDCA,
    onMutate: () => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: true });
    },
    onSuccess: (data) => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: false });
      if (data?.statusCode === STATUS_DATA_DCA.SUCCESS) {
        navigate(`/${NAME_PAGES.DCA.list_well}`);
        message.success("Processing complete!");
      } else {
        message.info("An error has occurred when updated the information of well");
        console.info(data?.statusMessage);
      }
    },
    onError: (error) => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: false });
      message.error(`An error has occurred when loading the information of well`)
    }
  })

  const mutationDeleteJsonWellDCA = useMutation({
    mutationFn: deleteJsonWellDCA,
    onMutate: () => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: true });
    },
    onSuccess: () => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: false });
      message.success(`Data the well Successfull`)
    },
    onError: (error) => {
      getViewLoadingDCA({ ...viewLoadingDCA, loadSaveParameters: false });
      message.error(`An error has occurred when delete the information of well`)
    }
  })

  const { data: dataWellDCA, isLoading: loadDataWellDCA, isError: ErrorDataWellDCA, refetch: refetchDataWellDCA } = useQuery({
    queryKey: ['dataWellDCA'],
    queryFn: () => getJsonWellDCA(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserDCA.county, dataFilterUserDCA.field, dataFilterUserDCA.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      setViewLoading(false)
      if (data.statusType != "ERROR") {
        const filtered = WELL_SENSOR_DEVICE?.data.filter(item => item.name === data?.data?.well_platforms);
        getDeviceRealTime({
          ...deviceRealTime,
          list_device_dca: filtered,
          device_dca: {
            ...deviceRealTime.device_dca,
            platform: data?.data?.well_platforms,
            discretized: data?.data?.discretized_well
          }
        });
      }
    },
    onError: (error) => {
    }
  })

  const { data: listWellDCA, isLoading: loadListWellDCA, isError: ErrorListWellDCA, refetch: refetchListWellDCA } = useQuery({
    queryKey: ['dataADDWellDCA'],
    queryFn: () => getJsonADDWellDCA(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      setViewLoading(false)
    },
    onError: (error) => {
      setViewLoading(false)
    }
  });

  const { data: wellsUserDCA, isLoading: loadWellsUserDCA, isError: ErrorWellsUserDCA, refetch: refetchWellsUserDCA } = useQuery({
    queryKey: ['dataADDWellDCA'],
    queryFn: () => getJsonADDWellDCA(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, userMultiselect),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      setViewLoading(false)
    },
    onError: (error) => {
      setViewLoading(false)
    }
  })

  const { data: dataVolumeTracker, isLoading: loadVolumeTracker, isError: ErrorloadVolumeTracker, refetch: refetchVolumeTracker } = useQuery({
    queryKey: ['get_VolumTracker'],
    queryFn: () => getJsonVolumeTracker(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserDCA.county, dataFilterUserDCA.field, dataFilterUserDCA.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  });

  const { data: dataVolumeTrackerRT, isLoading: loadDataVolumeTrackerRT, isError: ErrorDataVolumeTrackerRT, refetch: refetchVolumeTrackerRT } = useQuery({
    queryKey: ['get_VolumTracker_RT'],
    queryFn: () => getJsonIOTVolumeTracker(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataWellDCA.data.county, dataWellDCA.data.field, dataWellDCA.data.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    },
  })

  const mutationUploadJsonVolumeTracker = useMutation({
    mutationFn: uploadJsonVolumeTracker,
    onSuccess: () => {
      message.success(`Uploaded Data Successfull`);
      setTimeout(() => {
        refetchVolumeTracker();
        refetchVolumeTrackerRT()
      }, 500);
    },
    onError: (error) => {
      message.error(`An error has occurred when loading the PVT data`)
    }
  })

  const mutationUploadDataHistory = useMutation({
    mutationFn: uploadDataHistory,
    onSuccess: () => {
      refetchListDataHistory()
      message.success(`The charge of data history was successful`)
    },
    onError: (error) => {
      message.error(`An error has occurred when loading the information of the data history`)
    }
  })

  const mutationUpdateDataHistory = useMutation({
    mutationFn: updateDataHistory,
    onSuccess: () => {
      refetchListDataHistory()
      message.success(`The charge of data history was successful`)
    },
    onError: (error) => {
      message.error(`An error has occurred when loading the information of the data history`)
    }
  })

  const mutationDeleteDataHistory = useMutation({
    mutationFn: deleteDataHistory,
    onSuccess: () => {
      refetchListDataHistory()
      message.success(`The elimination of the Data history was successful`)
    },
    onError: (error) => {
      message.error(`An error has occurred when delete the information of the data history`)
    }
  })

  const { data: dataHistoryDCA, isLoading: loadDataHistoryDCA, isError: ErrorDataHistoryDCA, refetch: refetchDataHistoryDCA } = useQuery({
    queryKey: ['dataHistoryDCA'],
    queryFn: () => getDataHistory(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserDCA.county, dataFilterUserDCA.field, dataFilterUserDCA.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      setViewLoading(false)
    },
    onError: (error) => {
    }
  })

  const { data: dataListDataHistory, isLoading: loadListDataHistory, isError: ErrorListDataHistory, refetch: refetchListDataHistory } = useQuery({
    queryKey: ['dataHistoryList'],
    queryFn: () => getListDataHistory(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      setViewLoading(false)
    },
    onError: (error) => {
      setViewLoading(false)
    }
  })

  const { data: dataForecast, isLoading: loadDataForecast, isError: ErrorDataForecast, refetch: refetchDataForecast } = useQuery({
    queryKey: ['dataForecast'],
    queryFn: () => getForecast(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserDCA.well_id, User.user_name, tableData),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      setGenerateTable(false)
    },
    onError: (error) => {
    }
  })

  const mutationUploadForecast = useMutation({
    mutationFn: uploadForecast,
    onSuccess: () => {
      refetchConfirmUploadFile();
      message.success(`The file upload successful`)
    },
    onError: (error) => {
      message.error(`An error has occurred when uplaod file`)
    }
  })

  const mutationPostForecast = useMutation({
    mutationFn: postForecast,
    onSuccess: () => {
      setGenerateTable(true);
      message.success(`The code for forecast successful`)
    },
    onError: (error) => {
      message.error(`An error has occurred when run code`)
    }
  })

  const mutationPostForecastSemi = useMutation({
    mutationFn: postForecastSemi,
    onSuccess: () => {
      message.success(`The code for forecast successful`)
      setGenerateTable(true);
    },
    onError: (error) => {
      message.error(`An error has occurred when run code`)
    }
  })

  const mutationPostForecastFull = useMutation({
    mutationFn: postForecastFull,
    onSuccess: () => {
      message.success(`The code for forecast successful`)
      setGenerateTable(true);
    },
    onError: (error) => {
      message.error(`An error has occurred when run code`)
    }
  })


  const { data: confirmUploadFile, isLoading: loadConfirmUploadFile, isError: ErrorConfirmUploadFile, refetch: refetchConfirmUploadFile } = useQuery({
    queryKey: ['confirmUploadFile'],
    queryFn: () => getConfirmUploadFile(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserDCA.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  const generateRandomID = () => {
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseLettersAndNumbers = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';

    // Generar los primeros tres caracteres con letras mayúsculas
    for (let i = 0; i < 3; i++) {
      const randomUppercaseLetter = uppercaseLetters.charAt(Math.floor(Math.random() * uppercaseLetters.length));
      id += randomUppercaseLetter;
    }

    // Generar el resto de caracteres con letras minúsculas y números
    const remainingLength = 10 - id.length;
    for (let i = 0; i < remainingLength; i++) {
      const randomChar = lowercaseLettersAndNumbers.charAt(Math.floor(Math.random() * lowercaseLettersAndNumbers.length));
      id += randomChar;
    }

    return id;
  }

  const { data: chartDCAProductionData, isLoading: loadChartProductionData, isError: errorChartProductionData, refetch: refetchChartDCAProductionData } = useQuery({
    queryKey: ['chartDCAProductionData'],
    queryFn: () => getChartDCAProductionData(
      userDataInfo.operator,
      userDataInfo.lease,
      userDataInfo.region,
      dataFilterUserDCA.county,
      dataFilterUserDCA.field,
      dataFilterUserDCA.well_id,
      User.user_name,
      dataFilterUserDCA.well_name),
    retry: 3,
    enabled: false,
    onSuccess: (data) => { },
    onError: (error) => { },
  })

  const { data: chart_ai_dca, isLoading: loadChart_ai_dca, isError: errorChart_ai_dca, refetch: refetchChart_ai_dca } = useQuery({
    queryKey: ['chart_ai_dca'],
    queryFn: () => getChart_ai_dcaData(
      userDataInfo.operator,
      userDataInfo.lease,
      userDataInfo.region,
      dataFilterUserDCA.county,
      dataFilterUserDCA.field,
      dataFilterUserDCA.well_id,
      User.user_name,
      dataFilterUserDCA.well_name),
    retry: 3,
    enabled: false,
    onSuccess: (data) => { },
    onError: (error) => { },
  })

  const { data: chartDCAPerformanceData, isLoading: loadChartDCAPerformanceData, isError: errorChartDCAPerformanceData, refetch: refetchChartDCAPerformanceData } = useQuery({
    queryKey: ['chartDCAPerformanceData'],
    queryFn: () => getChartDCAPerformanceData(
      userDataInfo.operator,
      userDataInfo.lease,
      userDataInfo.region,
      dataFilterUserDCA.county,
      dataFilterUserDCA.field,
      dataFilterUserDCA.well_id,
      User.user_name,
      dataFilterUserDCA.well_name),
    retry: 3,
    enabled: false,
    onSuccess: (data) => { },
    onError: (error) => { },
  })

  const extractTitle = (table) => {
    const matches = table.match(/(\d+)/g);
    if (matches && matches.length >= 2) {
      const sensibility = VALUE_SENSIBILTY[matches[0]] || "N/A";
      const period = matches[1];
      return { sensibility, period };
    }
    return { sensibility: "N/A", period: "N/A" };
  }

  return {
    bodyAddWell,
    mutationUploadJsonWellDCA,
    mutationUploadJsonWellDCAParcial,
    mutationUpdateJsonWellDCA,
    mutationDeleteJsonWellDCA,
    mutationUploadDataHistory,
    mutationUpdateDataHistory,
    mutationDeleteDataHistory,
    mutationPostForecast,
    mutationPostForecastSemi,
    mutationPostForecastFull,
    mutationUploadForecast,
    dataWellDCA,
    listWellDCA,
    dataHistoryDCA,
    dataListDataHistory,
    getUserDataInfo,
    userDataInfo,
    inputPropertiesOut,
    dataFilterUserDCA,
    getDataFilterUserDCA,
    setBodyAddWell,
    refetchDataWellDCA,
    refetchListWellDCA,
    refetchDataHistoryDCA,
    refetchListDataHistory,
    setInputPropertiesOut,
    generateRandomID,
    viewLoading,
    setViewLoading,
    ErrorDataHistoryDCA,
    ErrorDataWellDCA,
    ErrorListWellDCA,
    dataForecast,
    refetchDataForecast,
    loadDataForecast,
    generateTable,
    setGenerateTable,
    typeTable,
    setTypeTable,
    setLastIndexSelected,
    lastIndexSelected,
    extractTitle,
    refetchConfirmUploadFile,
    confirmUploadFile,
    setUserMultiselect,
    refetchWellsUserDCA,
    wellsUserDCA,
    dataIOTAllPlatforms,
    refetchDataIOTAllPlatforms,
    loadDataIOTAllPlatforms,
    dataVolumeTracker,
    refetchVolumeTracker,
    dataVolumeTrackerRT,
    refetchVolumeTrackerRT,
    mutationUploadJsonVolumeTracker,
    refetchChartDCAProductionData,
    chartDCAProductionData,
    chart_ai_dca,
    loadChart_ai_dca,
    errorChart_ai_dca,
    refetchChart_ai_dca,
    refetchChartDCAPerformanceData,
    chartDCAPerformanceData
  }
}
