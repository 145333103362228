import { Checkbox, Table } from 'antd';
import React, { useState } from 'react';

const dataTable = [
	{
		well_name: "C-6",
		well_id: "12345",
		status_well: true,
		test_date: "18/01/2024",
		test_duration: 4,
		oil_rate: 63,
		water_rate: 142,
		gas_rate: 1040,
		ft_pressure: 2500,
		si_t_pressure: 3850
	},
	{
		well_name: "C-9",
		well_id: "1234",
		status_well: true,
		test_date: "18/01/2024",
		test_duration: 4,
		oil_rate: 16,
		water_rate: 308,
		gas_rate: 385,
		ft_pressure: 1900,
		si_t_pressure: 3350
	},

]

export const TableProductionTest = () => {

	const [dataMultiWell, setDataMultiWell] = useState(dataTable);
	const [filteredInfo, setFilteredInfo] = useState({});
	
	const handleChangeData = (state, name, id) => {
		setDataMultiWell(prevState =>
			prevState.map(item =>
				item.well_id === id ? { ...item, [name]: state } : item
			)
		);
	};

	const handleChange = ( filters) => {
		setFilteredInfo(filters);
  };

	const getData = dataMultiWell.map((item, index) =>
		createRowData(item, index, handleChangeData)
	);

	const filtersColumns = {
		well_name: getUniqueFilters("well_name"),
		test_date: getUniqueFilters("test_date"),
		test_duration: getUniqueFilters("test_duration"),
		oil_rate: getUniqueFilters("oil_rate"),
		water_rate: getUniqueFilters("water_rate"),
		gas_rate: getUniqueFilters("gas_rate"),
		ft_pressure: getUniqueFilters("ft_pressure"),
		si_t_pressure: getUniqueFilters("si_t_pressure")
	}

	const columns = [
		constructColumns("Well Name", "well_name", true, "string"),
		constructColumns("Test Date", "test_date", true, "string"),
		constructColumns("Test Duration", "test_duration", true, "number"),
		constructColumns("Oil Rate (STB/D)", "oil_rate", true, "number"),
		constructColumns("Water Rate (bbt/d)", "water_rate", true, "number"),
		constructColumns("Gas Rate (Mscf/d)", "gas_rate", true, "number"),
		constructColumns("FT Pressure (psia)", "ft_pressure", true, "number"),
		constructColumns("SI T Pressure (psia)", "si_t_pressure", true, "number"),
  ];

	function createRowData(item, index, handleChangeData) {
		return {
			key: index + item.well_id,
			well_id: item.well_id,
			well_name: createComponent("checkbox", item, handleChangeData),
			status_well: item.status_well,
			test_date: createComponent("date", item, handleChangeData),
			test_duration: createComponent("input", item, handleChangeData, "test_duration"),
			oil_rate: createComponent("input", item, handleChangeData, "oil_rate"),
			water_rate: createComponent("input", item, handleChangeData, "water_rate"),
			gas_rate: createComponent("input", item, handleChangeData, "gas_rate"),
			ft_pressure: createComponent("input", item, handleChangeData, "ft_pressure"),
			si_t_pressure: createComponent("input", item, handleChangeData, "si_t_pressure")
		};
	}

	function createComponent(type, item, handle, nameInput){
		switch (type) {
			case "checkbox":
				return (
					<>
						<Checkbox
							className="custom-checkbox"
							checked={item.status_well}
							onChange={(e) => handle(e.target.checked, "status_well", item.well_id)}
						/>
						<span className="ml-1">{item.well_name}</span>
					</>
				);
			case "date":
				return (
					<input 
						type="date" 
						name="test_date"
						value={item.test_date}
						onChange={(e) => handleChangeData(e.target.value, "test_date", item.well_id)} 
						className={`w-full max-w-[300px] h-[23px] rounded-[4px] px-3 border border-solid border border-solid border-[#BCBCCC]`} 
					/>
				);
			case "input":
				return (
					<input 
						type="number" 
						name={nameInput}
						value={item[nameInput]}
						onChange={(e) => handleChangeData(e.target.value, nameInput, item.well_id)} 
						className={`w-full max-w-[300px] h-[23px] rounded-[5px] px-3`} 
					/>
				);
			default:
				break;
		}
	}
	
	function constructColumns(name, value, sort, typeSort, filter){
		
		const dataColumn = {
			title: name,
      dataIndex: value,
      key: value,
			className: `text-xs whitespace-normal break-words`,
			ellipsis: false,
			align: 'center'
		};
		
		let addProp = {};
		
		if (sort) {
			switch (typeSort) {
				case "string":
					addProp = {
						...addProp,
						sorter: value === "well_name" ? (a, b) => a.well_name.props.children[1].props.children.localeCompare(b.well_name.props.children[1].props.children) : (a, b) => a[value].props.value.localeCompare(b[value].props.value),
					}
					break;
				case "number":
					addProp = {
						...addProp,
						sorter: (a, b) => parseFloat(a[value].props.value) - parseFloat(b[value].props.value),
					}
					break;
				default:
					break;
			}
		}

		if (filter) {
			addProp = {
				...addProp,
				filters: filtersColumns[value],
				filteredValue: filteredInfo[value]|| null,
				onFilter: (values, record) => record[value].includes(values),
			}
		}
		
		return sort || filter ? {...dataColumn, ...addProp} : dataColumn;
	}

	function getUniqueFilters(key){

    const filteredData = getData.filter(item => {
      return Object.entries(filteredInfo).every(([filterKey, filterValue]) => {

        if (filterValue) {
          return filterValue.includes(item[filterKey]);
        }
        return true;
      });
    });
  
    return [...new Set(filteredData.map(item => item[key]))].map(value => ({
      text: value,
      value: value,
    }));
  };

	return (

		<div className="min-w-full divide-y divide-gray-200 rounded-[4px] border border-[#f5f5f5]">
			<Table 
				columns={columns}
				dataSource={getData}
				onChange={handleChange} 
				pagination={false}
				rowClassName="border-b transition duration-300 ease-in-out "
			/>
		</div>
	)
}
