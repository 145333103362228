import React from 'react'
import { DashboardPLContext } from './DashboardPLContext';
import { useDashboardPL } from '../../hooks/useDashboardPlungerLift';

export const DashboardPLProvider = ({children}) => {
  const {
    dataCreated,
    dataFilterUserPL,
    dataWellTables,
    dataWell,
    dataFluid,
    datReservoir,
    enableCheckLab,
    selectDashboardPL,
    lastSelectDataIPRPL,
    validateAlertPlungerLift,
    viewLoadingPlungerLift,
    getDataCreated,
    getDataFilterPL,
    getDataWellTables,
    getDataWell,
    getDataFluid,
    getDataReservoir,
    getEnableCheckLab,
    getSelectDashboardPL,
    handleSelectWellGaslift,
    getSelectDataIPRPL,
    getValidateAlertPlungerLift,
    getViewLoadingPlungerLift
  } = useDashboardPL();
  return (
    <DashboardPLContext.Provider value={{   
      dataCreated,
      dataFilterUserPL,
      dataWellTables,
      dataWell,
      dataFluid,
      datReservoir,
      enableCheckLab,
      selectDashboardPL,
      lastSelectDataIPRPL,
      validateAlertPlungerLift,
      viewLoadingPlungerLift,
      getDataCreated,
      getDataFilterPL,
      getDataWellTables,
      getDataWell,
      getDataFluid,
      getDataReservoir,
      getEnableCheckLab,
      getSelectDashboardPL,
      handleSelectWellGaslift,
      getSelectDataIPRPL,
      getValidateAlertPlungerLift,
      getViewLoadingPlungerLift
    }}>
        {children}
    </DashboardPLContext.Provider>
  )
}
