
export const DATA_SCREENING_TOOL = {
  initialDataScreeningTool : {
    reservoir_id:"",
    reservoir_name:"",
    reservoir_type:"",
    country:"",
    state:"",
    field:"",
    gor: 75,
    water_cut: 75,
    oil_rate: 75,
    depth: 75,
    selection_dashboard: []
  }
};

export const INITIAL_ITEMS = [
  {
    label: 'Statistical Analytics',
    key: 'tab1',
  },
  {
    label: 'Analysis report',
    key: 'tab3',
  }
];

export const INITIAL_DATA_SLIDER = {
  percentage: {
    slider_gor_percentage: 75,
    slider_water_cut_percentage: 75,
    slider_oil_rate_percentage: 75
  },
  value: {
    slider_gor: 0,
    slider_water_cut: 0,
    slider_oil_rate: 0
  }
}

export const PARAMETER_PERCENTAGE = {
  slider_gor: { min: 0, max: 0 },
  slider_water_cut: { min: 0, max: 0 },
  slider_oil_rate: { min: 0, max: 0 },
};

export const COLORS_PIE_STATUS = [
  '#8884d8', 
  '#82ca9d', 
  '#ffc658', 
  '#ff8042', 
  '#00c49f'
];

export const COLORS_PIE_STAGE = [
  '#47b452', 
  '#f32d19', 
  '#1937f3', 
  '#ffc658'
];

export const SPECIFIC_VALUE_MULTIPLE_SCATTER = {
  oil_rate:{
    value: "oil_rate", 
    name: "Oil rate", 
    unit: "(STB/D)"
  },
  gor:{
    value: "gor", 
    name: "GOR", 
    unit: "(SCF/STB)"
  },
  water_cut:{
    value: "water_cut", 
    name: "Water cut", 
    unit: "(%)"
  }
}

export const REQUIRED_COLUMNS_DOCUMENT = [
  'Well Name',
  'Oil Rate (STB/D)',
  'Gas rate (Mscf/D)',
  'Water rate (STB/D)',
  'Depth (ft)'
];