import React, { useEffect, useRef, useState } from "react";
import { read, utils } from "xlsx";
import { message } from "antd";
import { ReactComponent as IconUpload } from "../../../assets/img/icons/upload.svg";
import { REQUIRED_COLUMNS_TABLE_DEVIATION,REQUIRED_COLUMNS_TABLE_EQUIPMENT } from "../../elements/types/type_plunger_Lift";
import { AlertModal } from "../../AlertModal";
export const ImportFile = ({ uploadfile, tableType }) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState([]);
  const [ showAlert, setShowAlert ] = useState(false);
  const [ messageAlert, setMessageAlert ] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };
  const handleImport = (event) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      const validExtensions = [".xls", ".xlsx", ".csv"];
      const fileType = file.name.split(".").pop();
      const mimeType = file.type;

      if (
        validExtensions.includes(`.${fileType}`) ||
        mimeType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        mimeType === "application/vnd.ms-excel"
      ) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const wb = read(e.target.result);
          const sheetName = wb.SheetNames[0];
          const sheets = wb.Sheets[sheetName];
          if (sheets) {
            const jsonData = utils.sheet_to_json(sheets);
            switch (tableType) {
              case "well_deviation":
                const hasRequiredColumns = REQUIRED_COLUMNS_TABLE_DEVIATION.every((col) =>
                  Object.keys(jsonData[0]).includes(col)
                );
  
                if (!hasRequiredColumns) {

                  handleOpenModal();
                  setTypeAlert("Warning")
                  setMessageAlert('The uploaded file does not contain the corresponding columns.')
                  return;
                }else if(jsonData.length > 3){
                  message.error(
                    'The file must contain only 3 rows'
                  );
                  return;
                }
                
                break;
              
              case "well_equipment": 
                const hasRequiredColumns2 = REQUIRED_COLUMNS_TABLE_EQUIPMENT.every((col) =>
                  Object.keys(jsonData[0]).includes(col)
                );
  
                if (!hasRequiredColumns2) {
                  message.error(
                    'The uploaded file does not contain the corresponding columns.'
                  );
                  return;
                }
                break;
              default:
                break;
            }

            const noID = jsonData.map((item) => {
              const { id, ...excludeID } = item;
              return excludeID;
            });
            setFile(noID);
            uploadfile(noID);
            message.info("The file data has been loaded successfully.");
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        handleOpenModal();
        setTypeAlert("Warning")
        setMessageAlert('Please upload a valid Excel file.')
      }

      event.target.value = "";
    }
  };
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <div className="flex w-full h-full">
        <AlertModal showAlert={showAlert} handleCloseModal={handleCloseModal} messageAlert={messageAlert} typeModal={typeAlert}></AlertModal>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        name="file"
        id="inputGroupFile"
        onChange={handleImport}
        style={{ display: "none" }}
      />
      {/*This is the load icon */}
      <label htmlFor="fileInput">
        <IconUpload
          size={32}
          className="flex w-4 h-4 bg-opacity-50 cursor-pointer fill-neutral-500 hover:fill-pa-purple"
          onClick={handleImageClick}
        />
      </label>
    </div>
  );
};
