import React, { useEffect, useState } from 'react';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';
import { TYPE_LINE_TUNNING_GRAPH } from '../../../elements/types/type_gas_lift';

const ChartReservoirIPR = ({dataGraph, typeGraph, dataScenario, selectedScenario}) => {
  
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [renderCombinedData, setRenderCombinedData] = useState(false);
  
  useEffect(() => {
    let validateDataGraph = [];

    if (typeGraph === "pseudo_press_single_rate") {
      validateDataGraph = transformedData(dataGraph, "gas_rate_estimated", "press_decrease");
      setData(validateDataGraph);
    }else if (typeGraph === "forchheimer_pseudo_press_prop") {
      validateDataGraph = transformedData(dataGraph, "gas_rate_estimated_a_b", "press_decrease");
      setData(validateDataGraph);
    }else if (typeGraph === "pseudo_press_multi_rate"){
      validateDataGraph = transformedData(dataGraph, "gas_rate_estimated_faf", "press_decrease");
      setData(validateDataGraph);
    }else if (typeGraph === "forchheimer_pseudo_press_multi_rate"){
      validateDataGraph = transformedData(dataGraph, "gas_rate_estimated", "press_decrease");
      setData(validateDataGraph);
    }else{
      setData([]);
    }

    const lastValidPress = validateDataGraph ? [...validateDataGraph]
    .reverse()
    .find(item => item.press != null) : [];

    setDataOriginal(lastValidPress);
    
    if (selectedScenario?.length) {
      setRenderCombinedData(true);
    }
  }, [dataGraph, typeGraph]);

  useEffect(() => {
    function buildScenarioMap(gasRates, presses) {
      const map = {};
      gasRates.forEach((gasRate, i) => {
        const pressValue = presses[i] !== undefined ? presses[i] : null;
        map[gasRate] = pressValue;
      });
      return map;
    }

    function mergeOriginalWithAllScenarios(originalData, scenariosData) {
      const mergedMap = {};

      for (const item of originalData) {
        const { gas_rate, press } = item;
        mergedMap[gas_rate] = {
          gas_rate,
          press: press ?? null
        };
      }

      for (const [scenarioKey, scenarioDataObj] of Object.entries(scenariosData)) {
        const scenarioName = scenarioKey;
        
        const data = scenarioDataObj.data || {};

        const gasRateKey = Object.keys(data).find((key) => 
          key.startsWith("gas_rate_estimated")
        );

        const gas_rate_estimated = gasRateKey ? data[gasRateKey] : [];

        const press_decrease = data.press_decrease ?? [];

        const scenarioMap = buildScenarioMap(
          gas_rate_estimated,
          press_decrease
        );

        for (const [gasRateStr, pressValue] of Object.entries(scenarioMap)) {
          const gasRateNum = Number(gasRateStr);
          if (!mergedMap[gasRateNum]) {
            mergedMap[gasRateNum] = {
              gas_rate: gasRateNum,
              press: null
            };
          }
          mergedMap[gasRateNum][`press_${scenarioName}`] = pressValue;
        }
      }

      const mergedArray = Object.values(mergedMap).sort(
        (a, b) => a.gas_rate - b.gas_rate
      );

      return mergedArray;
    }

    const finalMergedArray = mergeOriginalWithAllScenarios(data ? data : [], dataScenario);
    
    setData(finalMergedArray);
    setRenderCombinedData(false);

  }, [dataScenario, selectedScenario, renderCombinedData]);
  
  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      const dataTooltip = payload[0].payload;
      const scenarioData = payload.filter((data) =>
        data.name.toLowerCase().includes("press_")
      );
      
      return (
        <div 
          className="custom-tooltip" 
          style={{ 
            backgroundColor: 'white', 
            padding: '5px',
            textAlign: "left",
          }}
        >
          {!!dataTooltip?.gas_rate && (
            <p className="text-[15px]">
              {`Gas rate: `}
              <strong>{(dataTooltip?.gas_rate).toFixed(2)} (Mscf/d)</strong>
            </p>
          )}

          {!!dataTooltip?.press && (
            <>
              <p className="text-[15px] mt-[2px]">{`Case Base: `}</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "2px",
                }}
              >
                <p
                  className="text-[15px] mr-[5px]"
                  style={{ color: "#d4362c" }}
                >
                  {`Pressure: `}
                  <strong>{(dataTooltip?.press).toFixed(2)} (psia)</strong>
                </p>
              </div>
              <p className="text-[15px]">
                {`AOFP: `}
                <strong>{dataOriginal?.press ? (dataOriginal?.press).toFixed(2) : ""} (Mscf/d)</strong>
              </p>
            </>
          )}
          {scenarioData?.map((scenario, index) => (
            <>
              <p className="text-[15px] mt-1">
                {`${scenario.name.replace("press_", "")}` +
                  ":"}
              </p>
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "2px",
                }}
              >
                <div className="mr-[5px]">
                  <p className="text-[15px]" style={{ color: "#d4362c" }}>
                    {`Pressure: `}
                    <strong>{(scenario.value).toFixed(2)} (Mscf/d)</strong>
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      )
    }
  }
  
  function transformedData(data, value1, value2) {
    if(data && value1 && value2){
      const transform = data[value1]?.map((_, index) => ({
        gas_rate: data[value1][index],
        press: data[value2][index]
      }));
  
      return transform ? transform : [];
    }
  }
  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 25,
          bottom: 35,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="gas_rate" 
          label={{ 
            value: "Gas rate (Mscf/d)", 
            position: "insideBottom", 
            offset: -5,
            dx: -5,
            dy: 10,
          }}
          type="number"
          tickCount={11}
        />
        <YAxis
          type="number"
          label={{ 
            value: "Bottom hole flowing pressure (psia)", 
            angle: -90, 
            position: "insideLeft", 
            offset: -5,
            dx: -5,
            dy: 100,
          }}
          tickCount={11}
        />
        <Tooltip content={<CustomTooltip/>}/>
        <Line 
          type="monotone" 
          dataKey="press" 
          stroke="#d4362c" 
          activeDot={{ r: 8 }}
          connectNulls={true}
        />

        {selectedScenario?.map((key, index) => (
          <Line
            key={`scenario_${key}`}
            type="monotone"
            dataKey={`press_${key}`}
            stroke="#d4362c"
            name={`press_${key}`}
            dot={false}
            connectNulls={true}
            strokeWidth={
              TYPE_LINE_TUNNING_GRAPH[2][
                index % TYPE_LINE_TUNNING_GRAPH[2].length
              ]
            }
            strokeDasharray={
              TYPE_LINE_TUNNING_GRAPH[0][
                index % TYPE_LINE_TUNNING_GRAPH[0].length
              ]
            }
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
export default ChartReservoirIPR;

