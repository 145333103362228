import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from "recharts";
import moment from 'moment';
import { formatDateHist, formatDateIOT } from '../../../utils/utils';
import { REQUIRED_COLUMNS_DOCUMENT_HISTORY } from '../../../elements/types/type_dca';

// custom tooltip component
function CustomTooltip({ active, payload, option }) {
  if (active && payload && payload.length) {
    const dataPoint = payload[0]?.payload;

    // format the date and time
    const formattedDate = new Date(dataPoint?.date).toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });

    const productionRate =
      dataPoint?.[option === 'Gas' ? 'gas_rate' : 'oil_rate'] !== null
        ? dataPoint?.[option === 'Gas' ? 'gas_rate' : 'oil_rate']
        : 'No data';

    const cumulativeProduction =
      dataPoint?.[option === 'Gas' ? 'gas_cumulative' : 'oil_cumulative'] !== null
        ? dataPoint?.[option === 'Gas' ? 'gas_cumulative' : 'oil_cumulative']
        : 'No data';

    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
        <p>{`Date: ${formattedDate}`}</p>
        <p style={{ color: option === 'Gas' ? '#ed567e' : '#2bca41' }}>{
          `Production Rate: ${productionRate} ${option === 'Gas' ? 'MSCF/D' : 'STB/D'}`
        }</p>
        <p style={{ color: option === 'Gas' ? '#986a46' : '#4b6f50' }}>{
          `Cumulative Production: ${cumulativeProduction} ${option === 'Gas' ? 'MSCF' : 'STB'}`
        }</p>
      </div>
    );
  }

  return null;
}

export default function ChartAIDCA(props) {
  const {
    option,
    dataHistory,
    typeGraph,
    dataRt,
  } = props;

  const chartRef = useRef();
  const [dataFinalGraph, setDataFinalGraph] = useState([]);
  const [yAxisDomain1, setYAxisDomain1] = useState([0, 0]);
  const [yAxisDomain2, setYAxisDomain2] = useState([0, 0]);
  const [bottomMargin, setBottomMargin] = useState(50);
  const [valueOffset, setValueOffset] = useState();

  useEffect(() => {
    // Dynamic calculation of the lower margin
    const calculateBottomMargin = () => {
      if (chartRef.current) {
        const chartHeight = chartRef.current.clientHeight;
        const newBottomMargin = Math.max(30, Math.min(80, chartHeight * 0.1));
        setBottomMargin(newBottomMargin);
      }
    };

    calculateBottomMargin();

    const handleResize = () => {
      if (chartRef.current) {
        setValueOffset(chartRef.current.clientHeight - 17);
      }
    };

    window.addEventListener('resize', calculateBottomMargin);
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', calculateBottomMargin);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let formattedDataRT = [];
    let formattedDataHist = [];
    let dataFinalHistory = [];

    if (typeGraph === true && Array.isArray(dataRt?.data_chart) && dataRt?.data_chart.length > 0) {
      dataFinalHistory = dataRt?.data_chart
        ?.map((item) => ({
          date: new Date(formatDateIOT(item.date)).getTime(),
          gas_rate: item.gas_rate || null,
          gas_cumulative: item.gas_cumulative || null,
          oil_rate: item.oil_rate || null,
          oil_cumulative: item.oil_cumulative || null,
        }))
        .filter((item) =>
          item.gas_rate !== null ||
          item.gas_cumulative !== null ||
          item.oil_rate !== null ||
          item.oil_cumulative !== null
        )
        .sort((a, b) => a.date - b.date);
    } else if (typeGraph === false && Array.isArray(dataHistory) && dataHistory.length > 0) {
      formattedDataRT = dataRt?.data_chart
        ?.map((item) => ({
          date: new Date(formatDateIOT(item.date)).getTime(),
          gas_rate: item.gas_rate || null,
          gas_cumulative: item.gas_cumulative || null,
          oil_rate: item.oil_rate || null,
          oil_cumulative: item.oil_cumulative || null,
        }))
        .filter((item) =>
          item.gas_rate !== null ||
          item.gas_cumulative !== null ||
          item.oil_rate !== null ||
          item.oil_cumulative !== null
        )
        .sort((a, b) => a.date - b.date) || [];
        formattedDataHist = dataHistory
          ?.map((item) => ({
          date: new Date(formatDateHist(item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[0]])).getTime(),
          oil_rate: typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]] === "number" ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[1]]?.toFixed(2) : null,
          oil_cumulative: typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]] === "number" ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[4]]?.toFixed(2) : null,
          gas_rate: typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]] === "number" ?item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[2]]?.toFixed(2) : null,
          gas_cumulative: typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]] === "number" ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]]?.toFixed(2) : null
        })).filter(
          (item) =>
            item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[1]] !== null &&
            item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[2]] !== null && 
            item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[4]] !== null &&
            item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]] !== null
        )
        .sort((a, b) => a.date - b.date) || [];
        dataFinalHistory = [...formattedDataHist,...formattedDataRT];
    }
    setDataFinalGraph(dataFinalHistory);
  }, [dataRt?.data_chart, dataHistory, typeGraph]);

  useEffect(() => {
    if (dataFinalGraph.length > 0) {
      let dataFinalGraphFilter = dataFinalGraph.filter(item => item !== undefined);
      
      const gasRates = dataFinalGraphFilter?.map((d) => d.gas_rate || 0);
      const gasCumulative = dataFinalGraphFilter?.map((d) => d.gas_cumulative || 0);
      const oilRates = dataFinalGraphFilter?.map((d) => d.oil_rate || 0);
      const oilCumulative = dataFinalGraphFilter?.map((d) => d.oil_cumulative || 0);

      const minGasRate = Math.min(...gasRates);
      const maxGasRate = Math.max(...gasRates);
      const minGasCumulative = Math.min(...gasCumulative);
      const maxGasCumulative = Math.max(...gasCumulative);
      const minOilRate = Math.min(...oilRates);
      const maxOilRate = Math.max(...oilRates);
      const minOilCumulative = Math.min(...oilCumulative);
      const maxOilCumulative = Math.max(...oilCumulative);

      if (option === 'Gas') {
        setYAxisDomain1([
          minGasRate === Infinity ? 0 : Math.floor(minGasRate),
          maxGasRate === -Infinity ? 0 : Math.ceil(maxGasRate),
        ]);
        setYAxisDomain2([
          minGasCumulative === Infinity ? 0 : Math.floor(minGasCumulative),
          maxGasCumulative === -Infinity ? 0 : Math.ceil(maxGasCumulative),
        ]);
      } else {
        setYAxisDomain1([
          minOilRate === Infinity ? 0 : Math.floor(minOilRate),
          maxOilRate === -Infinity ? 0 : Math.ceil(maxOilRate),
        ]);
        setYAxisDomain2([
          minOilCumulative === Infinity ? 0 : Math.floor(minOilCumulative),
          maxOilCumulative === -Infinity ? 0 : Math.ceil(maxOilCumulative),
        ]);
      }
    } else {
      setYAxisDomain1([0, 0]);
      setYAxisDomain2([0, 0]);
    }
  }, [dataFinalGraph, option]);

  return (
    <div className="w-full h-full" ref={chartRef}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={1000}
          height={300}
          data={dataFinalGraph}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: bottomMargin,
          }}
        >
          <Brush
            dataKey="date"
            height={10}
            stroke="#8884d8"
            y={valueOffset}
            tickFormatter={(tick) =>
              new Date(tick).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
              })
            }
          />
          <XAxis
            dataKey="date"
            type="number"
            domain={['dataMin', 'dataMax']}
            label={{
              value: "Date",
              position: "bottom",
              fill: "black",
            }}
            tickFormatter={(tick) =>
              moment(tick).format("MM/DD/YYYY")
            }
            tick={{ fill: "black" }}
          />
          <YAxis
            yAxisId={0}
            dataKey={
              option === "Gas" ? "gas_cumulative" : "oil_rate"
            }
            label={{
              value:
                option === "Gas"
                  ? "Production Rate (MSCF/D)"
                  : "Production Rate (STB/D)",
              angle: -90,
              position: "insideLeft",
              fill: "black",
              style: { textAnchor: "middle" },
              fontSize: 15,
            }}
            width={103}
            domain={yAxisDomain1}
            allowDataOverflow={true}
            tick={{ fill: "black" }}
          />
          <YAxis
            orientation="right"
            yAxisId={1}
            dataKey={
              option === "Gas" ? "gas_rate" : "oil_cumulative"
            }
            label={{
              value:
                option === "Gas"
                  ? "Cumulative Production (MSCF)"
                  : "Cumulative Production (STB)",
              angle: -90,
              position: "insideRight",
              fill: "black",
              style: { textAnchor: "middle" },
              fontSize: 15,
            }}
            width={103}
            domain={yAxisDomain2}
            allowDataOverflow={true}
            tick={{ fill: "black" }}
          />
          <Tooltip content={<CustomTooltip option={option} />} />
          {option === "Gas" && (
            <>
              <Line
                type="monotone"
                dataKey="gas_cumulative"
                stroke="#986a46"
                yAxisId={1}
                dot={{ r: 2, fill: "#986a46" }}
              />
              <Line
                type="monotone"
                dataKey="gas_rate"
                stroke="#ed567e"
                yAxisId={0}
                dot={{ r: 2, fill: "#ed567e" }}
              />
            </>
          )}
          {option === "Oil" && (
            <>
              <Line
                type="monotone"
                dataKey="oil_rate"
                stroke="#2bca41"
                yAxisId={0}
                dot={{ r: 2, fill: "#2bca41" }}
              />
              <Line
                type="monotone"
                dataKey="oil_cumulative"
                stroke="#4b6f50"
                yAxisId={1}
                dot={{ r: 2, fill: "#4b6f50" }}
              />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
