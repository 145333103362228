import React, { PureComponent, useState, useEffect, useContext, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { DashboardContext } from "../../../../context/Dashboard/DashboardContentx";
import { DataGasLiftContext } from "../../../../context/DataGasLift/DataGasLiftContext";
import { WELL_SENSOR_DEVICE } from "../../../elements/types/type_dashboard";
import { DATA_STANDARD_RT } from "../../../elements/types/type_standard_parameters";
import { REQUIRED_COLUMNS_DOCUMENT_HISTORY } from "../../../elements/types/type_gas_lift";
import moment from "moment";
import { formatDateHist, formatDateIOT } from '../../../utils/utils';

const ChartWellCond = (props) => {
  const { dataFilterUserGasLift } = useContext(DataGasLiftContext);
  const { deviceRealTime } = useContext(DashboardContext);
  const [dataIOT, setDataIOT] = useState();
  const chartRefHoleInj = useRef();
  const { dataHistory, dataGrapIOT, dataDomainGraph, statusGraph, typeDeviceWIC, dataAverage, dataAverageRT, wellId } = props;
  const averageData = !!dataAverage ? dataAverage : null
  const averageDataRT = !!dataAverageRT ? dataAverageRT : null
  const pressInjectionMax = dataDomainGraph.injpress_max_wic;
  const pressInjectionMin = dataDomainGraph.injpress_min_wic;
  const gasInjectionMax = dataDomainGraph.injrate_max_wic;
  const gasInjectioMin = dataDomainGraph.injrate_min_wic;
  const [bottomMargin, setBottomMargin] = useState(50);
  const [valueOffset, setValueOffset] = useState();

  const [yAxisDomain2, setYAxisDomain2] = useState([pressInjectionMin, pressInjectionMax]);
  const [yAxisDomain1, setYAxisDomain1] = useState([gasInjectioMin, gasInjectionMax]);
  const [dataFinalGraph, setDataFinalGraph] = useState([])
  const dataDeviceReplace = typeDeviceWIC === WELL_SENSOR_DEVICE?.data[6].sensor ? WELL_SENSOR_DEVICE?.data[5].sensor : typeDeviceWIC;

  useEffect(() => {
    // Cálculo dinámico del margen inferior
    const calculateBottomMargin = () => {
      if (chartRefHoleInj?.current) {
        const chartHeight = chartRefHoleInj?.current?.clientHeight;
        // Ajustar el margen inferior según la altura del gráfico y espacio necesario para el `Brush`
        const newBottomMargin = Math.max(30, Math.min(80, chartHeight * 0.1));
        setBottomMargin(newBottomMargin);
      }
    };

    // Calcular al montar el componente
    calculateBottomMargin();
    const handleResize = () => {
      if (chartRefHoleInj?.current) {
        setValueOffset(chartRefHoleInj?.current?.clientHeight - 17);
      }
    };
    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);
    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();
    // Volver a calcular cuando se redimensiona la ventana
    window.addEventListener('resize', calculateBottomMargin);
    return () => {
      window.removeEventListener('resize', calculateBottomMargin);
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (!!dataGrapIOT) {

      const dataIOT = dataGrapIOT?.filter((item) => {
        return item["LSD"] === dataDeviceReplace &&
          item[DATA_STANDARD_RT.GENERAL.PARAMETER_001] !== -999.25 &&
          item[DATA_STANDARD_RT.GENERAL.PARAMETER_004] !== -999.25
      });
      setDataIOT(dataIOT);
    }
  }, [dataGrapIOT, typeDeviceWIC]);

  useEffect(() => {
    setYAxisDomain2([pressInjectionMin, pressInjectionMax]);
    setYAxisDomain1([gasInjectioMin, gasInjectionMax]);
  }, [pressInjectionMax, gasInjectionMax, pressInjectionMin, gasInjectioMin])

  // useEffect(() => {
  //   let formattedData = [];

  //   if (statusGraph === true) {
  //     // Lógica para RT usando dataAverageRT
  //     formattedData = dataAverageRT
  //       ?.map((item) => ({
  //         date: new Date(item.date).getTime(),
  //         pressure_injection: parseFloat(item.pressure_injection),
  //         gas_rate_injection: parseFloat(item.gas_rate_injection),
  //       }))
  //       ?.sort((a, b) => b.date - a.date) || []; // Ordenar de fecha mayor a fecha menor
  //   } else if (statusGraph === false) {
  //     // Lógica para datos históricos usando dataIOT y dataHistory
  //     const dataIOTMod = dataIOT
  //       ?.map((item) => ({
  //         date: new Date(formatDateIOT(item[DATA_STANDARD_RT.GENERAL.PARAMETER_000])).getTime(),
  //         pressure_injection: parseFloat(
  //           item[
  //             typeDeviceWIC === WELL_SENSOR_DEVICE?.data[6].sensor
  //               ? `${dataFilterUserGasLift.well_name} Well Head Pressure`
  //               : DATA_STANDARD_RT.GENERAL.PARAMETER_001
  //           ]
  //         ),
  //         gas_rate_injection: parseFloat(item[DATA_STANDARD_RT.GENERAL.PARAMETER_004]),
  //       })) || [];

  //     const dataHistoryMod = dataHistory
  //       ?.map((item) => ({
  //         date: new Date(item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[0]]).getTime(),
  //         pressure_injection:
  //           typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[7]] === "number"
  //             ? parseFloat(item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[7]])
  //             : null,
  //         gas_rate_injection:
  //           typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[3]] === "number"
  //             ? parseFloat(item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[3]])
  //             : null,
  //       }))
  //       .filter(
  //         (item) =>
  //           item.pressure_injection !== null && item.gas_rate_injection !== null
  //       ) || [];

  //     formattedData = [...dataHistoryMod, ...dataIOTMod]?.sort((a, b) => b.date - a.date); // Ordenar de fecha mayor a fecha menor
  //   }

  //   setDataFinalGraph(formattedData);


  // }, [dataIOT, dataHistory, statusGraph, typeDeviceWIC, dataFilterUserGasLift])

  useEffect(() => {
    // Cálculo dinámico del margen inferior
    const calculateBottomMargin = () => {
      if (chartRefHoleInj?.current) {
        const chartHeight = chartRefHoleInj?.current?.clientHeight;
        // Ajustar el margen inferior según la altura del gráfico y espacio necesario para el `Brush`
        const newBottomMargin = Math.max(30, Math.min(80, chartHeight * 0.1));
        setBottomMargin(newBottomMargin);
      }
    };

    // Calcular al montar el componente
    calculateBottomMargin();

    // Volver a calcular cuando se redimensiona la ventana
    window.addEventListener('resize', calculateBottomMargin);
    const handleResize = () => {
      if (chartRefHoleInj?.current) {
        setValueOffset(chartRefHoleInj?.current?.clientHeight - 17);
      }
    };
    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);
    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();
    // Limpiar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', calculateBottomMargin);
      window.removeEventListener('resize', handleResize);
    };
  }, [chartRefHoleInj]);
  useEffect(() => {

    const formattedData = dataIOT?.map((item) => ({
      date: new Date(formatDateIOT(item[DATA_STANDARD_RT.GENERAL.PARAMETER_000])).getTime(),
      pressure_injection: parseFloat(
        item[
        typeDeviceWIC === WELL_SENSOR_DEVICE?.data[6].sensor
          ? `${dataFilterUserGasLift.well_name} Well Head Pressure`
          : DATA_STANDARD_RT.GASLIFT.PARAMETER_012
        ]
      ),
      gas_rate_injection: parseFloat(item[DATA_STANDARD_RT.GASLIFT.PARAMETER_013])?.toFixed(2),
    })).filter(item => item.pressure_injection !== null || item.gas_rate_injection !== null) || []; // Filtrar nulos

    if (!!dataHistory && statusGraph === false) {
      const dataHistoryMod = dataHistory
        ?.map((item) => ({
          date: new Date(formatDateHist(item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[0]])).getTime(),
          pressure_injection:
            typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[7]] === "number"
              ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[7]]?.toFixed(2)
              : null,
          gas_rate_injection:
            typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[3]] === "number"
              ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[3]]?.toFixed(2)
              : null,
        }))
        .filter(
          (item) =>
            item.pressure_injection !== null || item.gas_rate_injection !== null
        );

      const combinedData = [...dataHistoryMod, ...formattedData]?.sort(
        (a, b) => a.date - b.date // Ordenar de fecha menor a fecha mayor
      );
      setDataFinalGraph(combinedData);
    } else if (statusGraph === true) {
      setDataFinalGraph(
        formattedData?.sort((a, b) => a.date - b.date) // Ordenar de fecha menor a fecha mayor
      );
    }
  }, [dataIOT, dataHistory, statusGraph]);
  // useEffect(() => {
  //   if (dataFinalGraph?.length > 0) {
  //     const pressures = dataFinalGraph.map((d) => d?.pressure_injection || 0);
  //     const gasRates = dataFinalGraph.map((d) => d?.gas_rate_injection || 0);

  //     const minPressure = Math.min(...pressures);
  //     const maxPressure = Math.max(...pressures);
  //     const minGasRate = Math.min(...gasRates);
  //     const maxGasRate = Math.max(...gasRates);

  //     setYAxisDomain2([Math.floor(minPressure), Math.ceil(maxPressure)]);
  //     setYAxisDomain1([Math.floor(minGasRate), Math.ceil(maxGasRate)]);
  //   }
  // }, [dataFinalGraph]);

  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 5000
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };

  function customTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      // Obtén los valores de gas y presión
      const gasData = payload.find(p => p.dataKey === "gas_rate_injection");
      const pressureData = payload.find(p => p.dataKey === "pressure_injection");

      // Formatea la fecha dependiendo del estado de statusGraph
      const formattedDate = new Date(payload[0]?.payload?.date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        ...(statusGraph
          ? { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }
          : {}),
      });

      // Extrae los valores y maneja nulos o "NaN"
      const gasRateText = gasData?.value && !isNaN(gasData.value)
        ? `${gasData.value} MSCF/D`
        : 'No data';

      const pressureText = pressureData?.value && !isNaN(pressureData.value)
        ? `${pressureData.value} psig`
        : 'No data';

      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
          <p>{`Date: ${formattedDate}`}</p>
          <p style={{ color: '#dc2626' }}>{`Injection Gas Rate: ${gasRateText}`}</p>
          <p style={{ color: '#16a34a' }}>{`Injection Pressure: ${pressureText}`}</p>
        </div>
      );
    }
    return null;
  }
  return (
    <div className="w-full h-full" ref={chartRefHoleInj}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={dataFinalGraph}
          margin={{
            top: 0,
            right: 30,
            left: 30,
            bottom: bottomMargin,
          }}
        >
          <CartesianGrid />
          <Brush
            dataKey="date"
            height={10}
            y={valueOffset}
            stroke="#8884d8"
            tickFormatter={(tick) =>
              new Date(tick).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
              })
            }
          />
          <XAxis
            dataKey="date"
            type="number"
            domain={["dataMin", "dataMax"]}
            tickFormatter={(tick) =>
              new Date(tick).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
              })
            }
            label={{
              value: "Date",
              position: "bottom",
              fill: "black",
            }}
            tickCount={10}
          />
          <YAxis
            yAxisId="left"
            domain={yAxisDomain1}
            allowDataOverflow={true}
            label={{
              value: `Injection Gas Rate (MSCF/D)`,
              style: { textAnchor: "middle" },
              angle: -90,
              position: "left",
              offset: 0,
            }}
            padding={{ bottom: 10 }}

          />
          <YAxis
            yAxisId="right"
            orientation="right"
            allowDataOverflow={true}
            domain={yAxisDomain2}
            label={{
              value: `Injection Pressure (psig)`,
              style: { textAnchor: "middle" },
              angle: -90,
              position: "left",
              offset: -60,
            }}
            padding={{ bottom: 10 }}
          />
          <Tooltip content={customTooltip} />
          {/* <Legend align="center" verticalAlign="top" /> */}
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="gas_rate_injection"
            name="Gas Rate"
            stroke="#dc2626" // Rojo
            dot={<CustomDot color="#dc2626" />}
            activeDot={{ r: 8 }}
          />
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="pressure_injection"
            name="Pressure"
            stroke="#16a34a" // Verde
            dot={<CustomDot color="#16a34a" />}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
export default ChartWellCond;
