import {
  serviceEndPoints
} from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_WELL = serviceEndPoints.GASLIFT.BASE_URL_WELL;
const BASE_ULR_DATA_HISTORY = serviceEndPoints.GASLIFT.BASE_URL_DATA_HISTORY;
const DB_URL_VOLUMETRACKER = serviceEndPoints.GASLIFT.BASE_URL_VOLUMETRACKER;
const BASE_URL_VOLUMETRACKER_RT = serviceEndPoints.GASLIFT.BASE_URL_VOLUMETRACKER_RT;

export const getJsonIOTAllPlatforms = async (operator, lease, region, county, field, wellid, user) => {
  if (!validateParams(operator, lease, region, county, field, wellid, user)) return;

  try {
    const response = await fetch(`${BASE_URL_VOLUMETRACKER_RT}/data_devices/get_all_devices/200/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}`, {
      method: "GET",
      headers: {
        Authorization: "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting the information");
  }
};

export const createParametersGasLift = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    console.error("Problem creating parameters gas lift");
  }
};

export const updateParametersGasLift = async (dataJson) => {
  const { operator, lease, region, county, field, well, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    console.error("Problem updating parameters gas lift");
  }
};

export const deleteParametersGasLift = async (dataJson) => {
  const { operator, lease, region, county, field, well, username } = dataJson;
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
      method: "DELETE",
      headers: {
        Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem deleting parameters gas lift");
  }
};

export const getParametersGasLift = async (operator, lease, region, county, field, well, username) => {
  if (!validateParams(operator, lease, region, county, field, well, username)) return;

  try {
    const response = await fetch(`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
      method: "GET",
      headers: {
        Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting parameters gas lift");
  }
};

export const getListWellGasLift = async (operator, lease, region, username) => {
  if (!validateParams(operator, lease, region, username)) return;

  try {
    const response = await fetch(`${BASE_URL_WELL}/parameterslist/${operator}/${lease}/${region}/${username}/parametersgaslift`, {
      method: "GET",
      headers: {
        Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting well gas lift list");
  }
};

export const getWellsUserGasLift = async (operator, lease, region, username) => {
  if (!validateParams(operator, lease, region, username)) return;

  try {
    const response = await fetch(`${BASE_URL_WELL}/parameterslist/${operator}/${lease}/${region}/${username}/parametersgaslift`, {
      method: "GET",
      headers: {
        "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"
      }
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting wells user gas lift");
  }
};

export const getDataHistory = async (operator, lease, region, county, field, wellid, username) => {
  if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

  try {
    const response = await fetch(`${BASE_ULR_DATA_HISTORY}/get-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
      method: "GET",
      headers: {
        Authorization: "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting data history");
  }
};

export const createDataHistory = async (dataJson) => {
  const { operator, lease, region, county, field, wellid, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

  try {
    const response = await fetch(`${BASE_ULR_DATA_HISTORY}/upload-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh",
      },
      body: JSON.stringify({ data }),
    });
    return response.json();
  } catch (error) {
    console.error("Problem creating data history");
  }
};

export const updateDataHistory = async (dataJson) => {
  const { operator, lease, region, county, field, wellid, username, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

  try {
    const response = await fetch(`${BASE_ULR_DATA_HISTORY}/update-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh",
      },
      body: JSON.stringify({ data }),
    });
    return response.json();
  } catch (error) {
    console.error("Problem updating data history");
  }
};

export const deleteDataHistory = async (dataJson) => {
  const { operator, lease, region, county, field, wellid, username } = dataJson;
  if (!validateParams(operator, lease, region, county, field, wellid, username)) return;

  try {
    const response = await fetch(`${BASE_ULR_DATA_HISTORY}/delete-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
      method: "DELETE",
      headers: {
        Authorization: "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem deleting data history");
  }
};

export const getListDataHistory = async (operator, lease, region, username) => {
  if (!validateParams(operator, lease, region, username)) return;

  try {
    const response = await fetch(`${BASE_ULR_DATA_HISTORY}/list-wells/${operator}/${lease}/${region}/${username}`, {
      method: "GET",
      headers: {
        Authorization: "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting data history list");
  }
};

export const uploadJsonVolumeTracker = async (dataJson) => {
  const { operator, lease, region, county, field, userName, data } = dataJson;
  if (!validateParams(operator, lease, region, county, field, userName)) return;

  try {
    const response = await fetch(`${DB_URL_VOLUMETRACKER}/calculate_averages_volume/${operator}/${lease}/${region}/${county}/${field}/${userName}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    console.error("Problem uploading volume tracker data");
  }
};

export const getJsonVolumeTracker = async (operator, lease, region, county, field, userName) => {
  if (!validateParams(operator, lease, region, county, field, userName)) return;

  try {
    const response = await fetch(`${DB_URL_VOLUMETRACKER}/results_averages_volume/${operator}/${lease}/${region}/${county}/${field}/${userName}`, {
      method: "GET",
      headers: {
        Authorization: "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting volume tracker data");
  }
};

export const getJsonIOTVolumeTracker = async (operator, lease, region, county, field, wellid, user) => {
  if (!validateParams(operator, lease, region, county, field, wellid, user)) return;

  try {
    const response = await fetch(`${BASE_URL_VOLUMETRACKER_RT}/data_devices/get_last_devices/200/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}`, {
      method: "GET",
      headers: {
        Authorization: "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting IOT volume tracker data");
  }
};

export const getChartProductionData = async (operator, lease, region, county, field, wellid, user) => {
  if (!validateParams(operator, lease, region, county, field, wellid, user)) return;

  try {
    const response = await fetch(`${BASE_URL_VOLUMETRACKER_RT}/chart_gl_production/200/${operator}/${field}/${lease}/${county}/${region}/${wellid}/${user}`, {
      method: "GET",
      headers: {
        Authorization: "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting chart production data");
  }
};

export const getJsonChartGL_well_head = async (operator, lease, region, county, field, wellid, user, wellName) => {
  if (!validateParams(operator, lease, region, county, field, wellid, user, wellName)) return;

  try {
    const response = await fetch(`${BASE_URL_VOLUMETRACKER_RT}/chart_gl_well_head/10/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}/${wellName}`, {
      method: "GET",
      headers: {
        Authorization: "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting chart GL well head data");
  }
};

export const getJsonChartGL_well_head_inj = async (operator, lease, region, county, field, wellid, user, wellName) => {
  if (!validateParams(operator, lease, region, county, field, wellid, user, wellName)) return;

  try {
    const response = await fetch(`${BASE_URL_VOLUMETRACKER_RT}/chart_gl_well_head/10/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}/${wellName}`, {
      method: "GET",
      headers: {
        Authorization: "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9",
      },
    });
    return response.json();
  } catch (error) {
    console.error("Problem getting chart GL well head injection data");
  }
};
