import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { read, write, utils, SSF } from "xlsx";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, message } from "antd";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";
import { REQUIRED_COLUMNS_DOCUMENT_HISTORY } from "../../elements/types/type_dca";

const { confirm } = Modal;

export const ImportFileHistory = ({ uploadfile }) => {
  const fileInputRef = useRef(null);
  const { selectedWellDCA, userDataInfo } = useContext(DashboardContext);

  const handleImport = (event) => {
      const files = event.target.files;
      const dataFile = event.target.files[0];

      confirm({
        title: "You are sure you want upload?",
        icon: <ExclamationCircleFilled />,
        content: "Remember that if you eliminate the historical data",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          if (files) {
            const file = files[0];
            const validExtensions = ['.xls', '.xlsx', '.csv'];
            const fileType = file.name.split('.').pop();
            const mimeType = file.type;

            if (
              (validExtensions.includes(`.${fileType}`)) || 
              (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
              mimeType === 'application/vnd.ms-excel')
            ) {
              const reader = new FileReader();
          
              reader.onload = (e) => {
                const wb = read(e.target.result);
                const sheetName = wb.SheetNames[0];
                const sheets = wb.Sheets[sheetName];
                if (sheets) {
                  const jsonData = utils.sheet_to_json(sheets, { header: 1 });
                  const fileColumns = jsonData[0].map(key => key.toLowerCase().split(' ')[0]) || [];
                  const hasAllRequiredColumns = REQUIRED_COLUMNS_DOCUMENT_HISTORY.every(column => fileColumns.includes(column));

                  if (!hasAllRequiredColumns) {
                    message.error("The uploaded file is missing one or more required columns.");
                    uploadfile([], []);
                  } else {
                    const data = utils.sheet_to_json(sheets).map((item) => {
                      const formattedItem = {};
                      Object.keys(item).forEach((key) => {
                        // Convertir la clave a minúsculas y eliminar todo después del espacio
                        const formattedKey = key.toLowerCase().split(' ')[0];
                        formattedItem[formattedKey] = item[key];
                      });
                      return formattedItem;
                    });
                    uploadfile(data, dataFile);
                    message.success("Upload successfully");
                  }
                }
              };
              reader.readAsArrayBuffer(file);
            }else{
              message.info('Please upload a valid Excel file');
            }
            event.target.value = '';
          }
        },
        onCancel() {
          console.log("Cancel");
          event.target.value = '';
        },
      });
  };
  const handleImageClick = () => {
    if (selectedWellDCA !== null && selectedWellDCA !== undefined && selectedWellDCA !== "null") {
        fileInputRef.current.click();
    }else{
        Modal.info({
          title: "Warning",
          content: (
            <div>
              {(userDataInfo === undefined || userDataInfo === null) && (
                <>
                  <p>Please select a filter in the navigation bar</p>
                </>
              )}
              {(selectedWellDCA === undefined || selectedWellDCA === null || selectedWellDCA === "null") && (
                <>
                  <p>Please select a well first</p>
                </>
              )}
            </div>
          ),
          onOk(){},
        });
      }
  };
  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        name="file"
        id="inputGroupFile"
        onChange={handleImport}
        style={{ display: "none" }}
      />
      <label htmlFor="fileInput">
        <button
          className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
          onClick={handleImageClick}
        >
          Add Historical data
        </button>
      </label>
    </div>
  );
};
