import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

export const ChartAdjusment = ({ dataPress, dataCalculate, stateCheck }) => {

  const [dataGraphic, setDataGraphic] = useState([]);
  
  useEffect(() => {
    if(dataCalculate && dataPress){
      if (dataCalculate?.bo && dataCalculate?.rs && dataPress) {
        const combinedData = dataPress?.map((pressure, index) => {
          return {
            pressure,
            bo_combined: dataCalculate?.bo[index],
            rs_combined: dataCalculate?.rs[index],
          };
        });
  
        setDataGraphic(combinedData);
      }
    }
  }, [dataPress, dataCalculate]);

  const linesAxis = Object.keys(stateCheck).filter(key => stateCheck[key]);
 
  const colorsByType = {
    bo_combined: "#dc2626",
    rs_combined: "#5D7EC6",
  };

  const namData = {
    bo_combined: {
      state: stateCheck["Oil FVF"] || false,
      name: "Oil FVF (RB/STB)",
    },
    rs_combined: {
      state: stateCheck["Solution GOR"] || false,
      name: "Solution GOR (SCF/STB)",
    },
  };

  const DataAxisY = Object.keys(namData).filter((key) => namData[key].state);
  // const nameAxisY = DataAxisY.map((key) => namData[key].name).join(", ");
  // const dy = -10 - (DataAxisY.length - 1) * -65;

  const getColorByType = (type) => colorsByType[type] || "#4c0519";

  function CustomTooltip({ active, payload, stateContent }) {
    if (active && payload && payload.length) {
  
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
          <p style={{ color: '#000000' }}>{`Pressure: ` + (data?.pressure).toFixed(2) + ` (psia)`}</p>
          <p style={{ color: '#5D7EC6' }}>{stateCheck["Solution GOR"] ? `Solution GOR: ` + (data?.rs_combined).toFixed(2)+ ` (SCF/STB)` : ``}</p>
          <p style={{ color: '#dc2626' }}>{stateCheck["Oil FVF"] ? `Oil FVF: ` + (data?.bo_combined).toFixed(2) + ` (RB/STB)` : ``}</p>
        </div>
      )
    }
  }

  const DomainY = () => {
    if (DataAxisY.length === 1) {
      const key = DataAxisY[0];
      const values = dataGraphic.map(item => parseFloat(item[key])).filter(value => !isNaN(value)); 
      const min = Math.min(...values);
      const max = Math.max(...values);
      return [min, max];
    }
    return ['auto', 'auto'];
  };

  return (
    <div className="flex w-[1420px] h-[700px] mb-[20px]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={dataGraphic}
          margin={{
            top: 20,
            right: 100,
            left: 45,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="pressure"
            label={{
              value: "Pressure (psia)",
              position: "insideBottom",
              offset: -15,
            }}
            type='number'
            tickCount={10}
          />
          {linesAxis.map((lineKey, index) => (
            <YAxis
              key={`yAxis-${lineKey}`}
              type="number" 
              label={{
                value: lineKey, // Etiqueta del eje Y
                angle: index % 2 === 0 ? -90 : 90,
                position: index % 2 === 0 ? 'outsideLeft' : 'outsideRight', // Coloca las etiquetas fuera
                fill: colorsByType[lineKey === "Solution GOR" ? "rs_combined" : "bo_combined"],
                style: { textAnchor: "middle" }, // Mover el label hacia arriba
                fontSize: 14,
                dx: index % 2 === 0 ? -20 : 10,
              }}
              tick={{ fill: colorsByType[lineKey === "Solution GOR" ? "rs_combined" : "bo_combined"], 
              fontSize: 12}} // Color de las marcas
              stroke={colorsByType[lineKey === "Solution GOR" ? "rs_combined" : "bo_combined"]} // Color del eje, igual al de la línea
              yAxisId={index} // Cada eje tiene un ID único
              orientation={index % 2 === 0 ? 'left' : 'right'} // Alterna la orientación
              interval={0} // Muestra todos los ticks
              width={80} 
              tickCount={10} 
              domain={DomainY()}
              allowDataOverflow={true}
            />
          ))}
          <Tooltip content={<CustomTooltip stateContent={stateCheck}/>}/>
          {DataAxisY.map((key, index) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              yAxisId={index}
              stroke={getColorByType(key)}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
