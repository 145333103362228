import React, { useEffect, useState } from 'react'
import { SELECT_DASHBOARD_PL } from '../components/elements/types/type_plunger_Lift'

const initialDataCreated = [{
  id: 0,
  wellConfig: {
    fluidConfig: false,
    reservoir: false,
    tuning: false
  },
  wellId: "",
  wellName: "",
  wellRegion: "",
  wellFiled: "",
  wellCountry: "",
}]

const initialDataWellTables = {
  wellDeviation: [],
  wellEquipment: [],
}

const initialDataFilterUserGasLift = {
  county: "",
  field: "",
  well_id: "",
}

const initialViewLoadingPlungerLift= {
  loadSaveParameters:false
}

const initialValidateAlertPlungerLift = {
  alertCard: false,
  alertDomain: false,
};

export const useDashboardPL = () => {
  const [dataCreated, setDataCreated] = useState([]);
  const [dataFilterUserPL, setDataFilterUserPL] = useState(initialDataFilterUserGasLift);
  const [dataWell, setDataWell] = useState([]);
  const [dataWellTables, setDataWellTables] = useState(initialDataWellTables);
  const [dataFluid, setDataFluid] = useState([]);
  const [datReservoir, setReservoir] = useState([]);
  const [enableCheckLab, setEnableCheckLab] = useState(false);
  const [selectDashboardPL, setSelectDashboardPL] = useState(SELECT_DASHBOARD_PL);
  const [lastSelectDataIPRPL, setLastSelectDataIPRPL] = useState();
  const [viewLoadingPlungerLift, setViewLoadingPlungerLift] = useState(initialViewLoadingPlungerLift);
  const [validateAlertPlungerLift, setValidateAlertPlungerLift] = useState(initialValidateAlertPlungerLift);
  useEffect(() => {
    const getDataCreated = JSON.parse(sessionStorage.getItem('dataCreated'));
    const getDataFilterPL = JSON.parse(sessionStorage.getItem('dataFilterUserPL'));
    const getDataWellTables = JSON.parse(sessionStorage.getItem('dataWellTables'));
    const getDataWell = JSON.parse(sessionStorage.getItem('dataWell'));
    const getDataFluid = JSON.parse(sessionStorage.getItem('dataFluid'));
    const getDataReservoir = JSON.parse(sessionStorage.getItem('dataReservoir'));
    const getEnableCheckLab = sessionStorage.getItem('enableCheckLab');
    const getSelectDashboardPL = JSON.parse(sessionStorage.getItem('selectDashboardPL'));
    const getSelectDataIPRPL = sessionStorage.getItem('lastSelectDataIPRPL');
    if (getDataCreated) {
      setDataCreated(getDataCreated);
    }
    if (getDataFilterPL) {
      setDataFilterUserPL(getDataFilterPL);
    }
    if (getDataWellTables) {
      setDataWellTables(getDataWellTables);
    }
    if (getDataWell) {
      setDataWell(getDataWell);
    }
    if (getDataFluid) {
      setDataFluid(getDataFluid);
    }
    if (getDataReservoir) {
      setReservoir(getDataReservoir);
    }
    if (getEnableCheckLab) {
      setEnableCheckLab(getEnableCheckLab)
    }
    if (getSelectDashboardPL) {
      setSelectDashboardPL(getSelectDashboardPL)
    }
    if (getSelectDataIPRPL) {
      setLastSelectDataIPRPL(getSelectDataIPRPL)
    }
  }, [])

  const getDataCreated = (options) => {
    setDataCreated(options);
    sessionStorage.setItem('dataCreated', JSON.stringify(options));
  }
  const getDataFilterPL = (options) => {
    setDataFilterUserPL(options);
    sessionStorage.setItem('dataFilterUserPL', JSON.stringify(options));
  }
  const getDataWellTables = (options) => {
    setDataWellTables(options);
    sessionStorage.setItem('dataWellTables', JSON.stringify(options));
  }
  const getDataWell = (options) => {
    setDataWell(options);
    sessionStorage.setItem('dataWell', JSON.stringify(options));
  }
  const getDataFluid = (options) => {
    setDataFluid(options);
    sessionStorage.setItem('dataFluid', JSON.stringify(options));
  }
  const getDataReservoir = (options) => {
    setReservoir(options);
    sessionStorage.setItem('dataReservoir', JSON.stringify(options));
  }
  const handleSelectWellGaslift = (wellId) => {
    setDataFilterUserPL(wellId);
    sessionStorage.setItem('dataFilterUserGaslift', wellId);
  }

  const getEnableCheckLab = (options) => {
    setEnableCheckLab(options);
    sessionStorage.setItem('enableCheckLab', options);
  }
  const getSelectDashboardPL = (options) => {
    setSelectDashboardPL(options);
    sessionStorage.setItem('selectDashboardPL', JSON.stringify(options));
  }
  const getSelectDataIPRPL = (options) => {
    setLastSelectDataIPRPL(options);
    sessionStorage.setItem('lastSelectDataIPRPL', JSON.stringify(options))
  }
  const getViewLoadingPlungerLift = (options) => {
    setViewLoadingPlungerLift(options);
  }
  const getValidateAlertPlungerLift = (options) => {
    setValidateAlertPlungerLift(options);
  };


  return {
    dataCreated,
    dataFilterUserPL,
    dataWellTables,
    dataWell,
    dataFluid,
    datReservoir,
    lastSelectDataIPRPL,
    validateAlertPlungerLift,
    viewLoadingPlungerLift,
    getDataCreated,
    getDataFilterPL,
    getDataWellTables,
    getDataWell,
    getDataFluid,
    getDataReservoir,
    handleSelectWellGaslift,
    getEnableCheckLab,
    getSelectDashboardPL,
    getSelectDataIPRPL,
    selectDashboardPL,
    enableCheckLab,
    getValidateAlertPlungerLift,
    getViewLoadingPlungerLift
  }
}
