import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { DashboardDCAContext } from '../../../../context/DasboardDCA/DashboardDCAContext';
import { DashboardContext } from '../../../../context/Dashboard/DashboardContentx';
import { WELL_SENSOR_DEVICE } from '../../../elements/types/type_dashboard';
import { DATA_STANDARD_RT } from '../../../elements/types/type_standard_parameters';
import { formatDateIOT } from '../../../utils/utils';

// Componente de tooltip personalizado
function CustomTooltip({ active, payload, label }) {

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const titleColor = "#ce0f41"; // Color del título dependiendo de Gas o Oil
    const valueColor = "black"; // Color del valor
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
        <p style={{ color: titleColor }}>{`Date: `}<span style={{ color: valueColor }}>{data.Dates}</span></p>
        <p style={{ color: 'blue' }}>{`Wellhead Pressure: `}<span style={{ color: valueColor }}>{data['Static Pressure']}{` (psig)`}</span></p>
        <p style={{ color: 'red' }}>{`Flowing Temperature: `}<span style={{ color: valueColor }}>{data['Flowing Temperature']}{` (deg F)`}</span></p>
      </div>
    );
  }

  return null;
}

export const ChartPerformance = (props) => {
  const { enableModal, dataIOT, bottom, dataHistory, typeDevice } = props;

  const chartRef = useRef();
  const { dataFilterUserDCA, dataCardsDCA, getDataCardsDCA } = useContext(DashboardDCAContext);
  const { deviceRealTime } = useContext(DashboardContext);
  let [valueOffset, setValueOffset] = useState();
  const dataDeviceReplace = typeDevice === WELL_SENSOR_DEVICE?.data[6].sensor ? WELL_SENSOR_DEVICE?.data[5].sensor : typeDevice;

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        if (chartRef.current) {
          const chartNode = chartRef.current;
          const offsetTop = chartNode.offsetTop;
          // Obtener el tamaño de la pantalla
          const screenHeight = window.innerHeight;
          setValueOffset(enableModal ? offsetTop + 435 : (screenHeight / 1.93) - offsetTop);
        }
      }, 500);

    };

    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);

    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();

    // Limpiar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [enableModal]);

  const dataFilter = dataIOT?.filter(item => 
    item['LSD'] === dataDeviceReplace &&
    item[DATA_STANDARD_RT.GENERAL.PARAMETER_001] !== -999.25 && 
    item[DATA_STANDARD_RT.GENERAL.PARAMETER_002] !== -999.25 
  );

  // const dataFilter = dataIOT?.filter((item) => {
  //   return item.lsd === dataDeviceReplace &&
  //     item.pressure_tubing !== -999.25 &&
  //     item.temperature_tubing !== -999.25 &&
  //     item.pressure_casing !== -999.25 &&
  //     item.temperature_casing !== -999.25 &&
  //     item.oil_rate != null &&
  //     item.water_rate != null &&
  //     item.gas_rate != null &&
  //     item.temperature_casing
  // });

  const formattedData = dataFilter?.map((item, index) => ({
    ...item,
    CumulativeDate: index + 1,
    'date': String(item[DATA_STANDARD_RT.GENERAL.PARAMETER_000]).split(" ")[0],
    'pressure_tubing': parseFloat(item[deviceRealTime?.device_dca?.discretized && typeDevice === WELL_SENSOR_DEVICE?.data[6].sensor ? dataFilterUserDCA.well_name + ' Well Head Pressure' : DATA_STANDARD_RT.GENERAL.PARAMETER_001]),
    'temperature_tubing': parseFloat(item[DATA_STANDARD_RT.GENERAL.PARAMETER_002]),
  })) || [];

  // const formattedData = dataFilter
  //   ?.map((item) => ({
  //     ...item,
  //     date: new Date(formatDateIOT(item.date)).getTime(),
  //   }))
  //   .filter((item) => !isNaN(item.date))
  //   .sort((a, b) => a.date - b.date);

  useEffect(() => {
    const lastData = formattedData?.[formattedData.length - 1] || {};

    getDataCardsDCA({
      ...dataCardsDCA,
      wellhead_conditions_press: lastData.pressure_tubing,
      wellhead_conditions_temp: lastData.temperature_tubing,
    })
  }, [typeDevice, dataIOT])


  const maxDataValue1 = formattedData ? Math.max(
    ...formattedData?.map(item => parseFloat(item.pressure_tubing))
  ) : 0;

  const roundedMaxValue = Math.ceil(maxDataValue1 / 100) * 100;

  // Encontrar el máximo valor de Gas-Oil ratio u Oil rate y redondearlo al siguiente número de mil más cercano
  const maxDataValue2 = formattedData ? Math.max(
    ...formattedData.map(item => parseFloat(item.temperature_tubing))
  ) : 0;

  const roundedMaxValue2 = Math.ceil(maxDataValue2 / 100) * 100;
  const yAxisDomain = roundedMaxValue;
  const yAxisDomain1 = roundedMaxValue2;
  // Ancho fijo del eje Y
  const yAxisWidth = 85;

  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 5000
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };

  const dataLength = formattedData?.length;
  const tickInterval = Math.ceil(dataLength / 6);

  return (
    <div className="w-full h-full" ref={chartRef}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={1000}
          height={300}
          data={formattedData} // dataFinalHistory
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: bottom,
          }}
        >
          <Brush dataKey="date" height={10} stroke="#8884d8" y={valueOffset} />
          <XAxis
            dataKey="date"
            // domain={['auto', 'auto']}
            allowDecimals={false}
            label={{
              value: "Date",
              position: "bottom",
              fill: "black",
            }}
            tick={{ fill: "black" }}
            tickCount={tickInterval}
          />

          <YAxis
            yAxisId={0}
            dataKey={'pressure_tubing'}
            label={{
              value: 'Wellhead Pressure (psig)',
              angle: -90,
              position: 'insideLeft',
              fill: 'black',
              style: { textAnchor: "middle" }, // Mover el label hacia abajo
            }}
            width={yAxisWidth}
            domain={yAxisDomain} // Establecer el dominio basado en el máximo valor
            tickFormatter={(value) => Math.round(value)}
            padding={{ bottom: 10 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <YAxis
            orientation="right"
            yAxisId={1}
            dataKey={'temperature_tubing'}
            label={{
              value:
                'Flowing Temperature (deg F)',
              angle: -90,
              position: 'insideRight',
              fill: 'black',
              style: { textAnchor: "middle" },
            }}
            width={yAxisWidth}
            domain={yAxisDomain1} // Establecer el dominio redondeado en el eje Y
            tickFormatter={(value) => Math.round(value)}
            padding={{ bottom: 10 }}
            tick={{ fill: "black" }}
          />
          {/* <ReferenceLine x={3} stroke="red" label="Max PV PAGE" /> */}
          {/* <ReferenceLine y={9800} label="Max" stroke="red" /> */}
          <Line type="monotone" dataKey="pressure_tubing" fill='blue' stroke="blue"
            dot={<CustomDot color='blue' />} activeDot={{ r: 4 }} yAxisId={0}
          />
          <Line type="monotone" dataKey="temperature_tubing" fill='red' stroke="#ce0f41"
            dot={<CustomDot color='red' />} activeDot={{ r: 4 }} yAxisId={1} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
