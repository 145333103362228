import { Checkbox, Select, Modal, Table, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Calendar } from "../../../assets/icon/calendar.svg"
import { ShopOutlined } from '@ant-design/icons';

// {
// 	well_name: "A-14",
// 	well_id: "123",
// 	status_well: true,
// 	data_range: "",
// 	oil_meter_device: "",
// 	gas_meter_device: "",
// 	liquid_meter_device: "",
// 	water_meter_device: "",
// 	well_status: "Flowing",
// 	fluid_type: "Oil + Water",
// 	highest_tubing: 705,
// 	current_tubing: 125,
// 	gas_rate: 128,
// 	liquid_rate: 578,
// 	pressure_rate: 580,
// 	prod_index: 0.996,
// 	date_start: "02/01/2024",
// 	date_end: "",
// },
const dataTable = [
	{
		well_name: "C-6",
		well_id: "1234",
		status_well: true,
		data_range: "",
		oil_meter_device: "",
		gas_meter_device: "",
		liquid_meter_device: "",
		water_meter_device: "",
		well_status: "Flowing",
		fluid_type: "Gas",
		highest_tubing: 3915.64,
		current_tubing: 2199.73,
		gas_rate: 1229.59,
		liquid_rate: 264.33,
		pressure_rate: 1715.91,
		prod_index: 0.782,
		date_start: "",
		date_end: "",
	},
	{
		well_name: "C-9",
		well_id: "1234658",
		status_well: true,
		data_range: "",
		oil_meter_device: "",
		gas_meter_device: "",
		liquid_meter_device: "",
		water_meter_device: "",
		well_status: "Flowing",
		fluid_type: "Gas",
		highest_tubing: 2950.01,
		current_tubing: 1509.91,
		gas_rate: 341.56,
		liquid_rate: 287.44,
		pressure_rate: 1443.11,
		prod_index: 0.237,
		date_start: "",
		date_end: "",
	},
];

export const TableMultiWell = () => {
	
	const { Option } = Select;
	const [dataMultiWell, setDataMultiWell] = useState(dataTable);
	const [openModalDataRange, setOpenModalDataRange] = useState(false);
	const [filteredInfo, setFilteredInfo] = useState({});
	const [currentId, setCurrentID] = useState();

	const handleChangeData = (state, name, id) => {
		setDataMultiWell(prevState =>
			prevState.map(item =>
				item.well_id === id ? { ...item, [name]: state } : item
			)
		);
	};
	
	const handleOpenModal = (id) => {
		setOpenModalDataRange(!openModalDataRange);
		setCurrentID(id);
  };
	
	const handleChange = ( filters) => {
		setFilteredInfo(filters);
  };
	
	const getData = dataMultiWell.map((item, index) =>
		createRowData(item, index, handleChangeData, handleOpenModal)
	);

	const filtersColumns = {
		well_name: getUniqueFilters("well_name"),
		well_status: getUniqueFilters("well_status"),
		fluid_type: getUniqueFilters("fluid_type"),
		highest_tubing: getUniqueFilters("highest_tubing"),
		current_tubing: getUniqueFilters("current_tubing"),
		gas_rate: getUniqueFilters("gas_rate"),
		liquid_rate: getUniqueFilters("liquid_rate"),
		pressure_rate: getUniqueFilters("pressure_rate"),
		prod_index: getUniqueFilters("prod_index"),
	}	

	const columns = [
		constructColumns("Well Name", "well_name", true, "string" ),
		constructColumns("Date Range", "data_range"),
		constructColumns("Oil Meter Device", "oil_meter_device"),
		constructColumns("Gas Meter  Device", "gas_meter_device"),
		constructColumns("Water Meter Device", "water_meter_device"),
		constructColumns("Liquid Meter Device", "liquid_meter_device"),
		constructColumns("Well Status", "well_status", true, "string"),
		constructColumns("Fluid Type", "fluid_type", true, "string", true),
		constructColumns("Highest Tubing Pwh*", "highest_tubing", true, "number"),
		constructColumns("Current Tubing Pwh*", "current_tubing", true, "number"),
		constructColumns("Gas Rate (Mscf/d)", "gas_rate", true, "number"),
		constructColumns("Liquid Rate (bbt/d)", "liquid_rate", true, "number"),
		constructColumns("Pressure Drawdown (psi)", "pressure_rate", true, "number"),
		constructColumns("Productivity Index (Mscf/d/psi or bbl/d/psi)", "prod_index", true, "number"),
  ];
	
	function createRowData(item, index, handleChangeData, handleOpenModal) {
		return {
			key: index + item.well_id,
			well_id: item.well_id,
			well_name: createComponent("checkbox", item, handleChangeData),
			status_well: item.status_well,
			data_range: createComponent("calendar", item, handleOpenModal),
	
			oil_meter_device: createComponent("select", null, null, {
				name: "oil_meter_device",
				value: item.oil_meter_device || "Selected option",
				onChange: (e) => handleChangeData(e, "oil_meter_device", item.well_id),
				options: [
					{ value: "", label: "Selected option" },
					{ value: "1", label: "Oil Meter 1" },
					{ value: "2", label: "Oil Meter 2" },
				],
			}),
	
			gas_meter_device: createComponent("select", null, null, {
				name: "gas_meter_device",
				value: item.gas_meter_device || "Selected option",
				onChange: (e) => handleChangeData(e, "gas_meter_device", item.well_id),
				options: [{ value: "", label: "Selected option" }],
			}),
	
			water_meter_device: createComponent("select", null, null, {
				name: "water_meter_device",
				value: item.water_meter_device || "Selected option",
				onChange: (e) => handleChangeData(e, "water_meter_device", item.well_id),
				options: [{ value: "", label: "Selected option" }]
			}),
	
			liquid_meter_device: createComponent("select", null, null, {
				name: "liquid_meter_device",
				value: item.liquid_meter_device || "Selected option",
				onChange: (e) => handleChangeData(e, "liquid_meter_device", item.well_id),
				options: [{ value: "", label: "Selected option" }]
			}),
	
			well_status: createComponent("alert", item.well_status),
			fluid_type: item.fluid_type,
			highest_tubing: item.highest_tubing,
			current_tubing: item.current_tubing,
			gas_rate: item.gas_rate,
			liquid_rate: item.liquid_rate,
			pressure_rate: item.pressure_rate,
			prod_index: item.prod_index,
			date_start: item.date_start,
			date_end: item.date_end,
		};
	}

	function createComponent(type, item, handle, dataOption){
		switch (type) {
			case "checkbox":
				return (
					<>
						<Checkbox
							className="custom-checkbox"
							checked={item.status_well}
							onChange={(e) => handle(e.target.checked, "status_well", item.well_id)}
						/>
						<span className="ml-1">{item.well_name}</span>
					</>
				);
			case "select":
				return (
					<Select
						className="flex w-full h-[24px] max-w-[300px] text-start"
						name={dataOption.name}
						value={dataOption.value}
						onChange={dataOption.onChange}
					>
						{dataOption.options.map((opt) => (
							<Option key={opt.value} value={opt.value}>
								{opt.label}
							</Option>
						))}
					</Select>
				);
			case "calendar":
				return (
					<Calendar
						className="w-5 h-5 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
						onClick={() => handle(item.well_id)}
					/>
				);
			case "alert":
				const colorAlert = {
					"Flowing": ["#0958d9", "#1e40af"],
					"Shut-in": ["#991b1b", "#fee2e2"],
				}
								
				return(
					<div className='items-center justify-center'>
						<input
							className={`flex w-[78%] h-[23px] text-center text-[15px] rounded-[5px] text-[${colorAlert[item][0]}] bg-[${colorAlert[item][1]}] border border-[${colorAlert[item][0]}]`}
							value={item}
							readOnly
						/>
					</div>
				)
			default:
				break;
		}
	}
	
	function constructColumns(name, value, sort, typeSort, filter){
		
		const dataColumn = {
			title: name,
      dataIndex: value,
      key: value,
			className: `text-xs whitespace-normal break-words`,
			ellipsis: false,
			align: 'center'
		};
		
		let addProp = {};
		
		if (sort) {
			switch (typeSort) {
				case "string":
					addProp = {
						...addProp,
						sorter: value === "well_name" ? (a, b) => a.well_name.props.children[1].props.children.localeCompare(b.well_name.props.children[1].props.children) : value === "well_status" ? (a, b) => a.well_status.props.children.props.value.localeCompare(b.well_status.props.children.props.value)  : (a, b) => a[value].localeCompare(b[value]),
					}
					break;
				case "number":
					addProp = {
						...addProp,
						sorter: (a, b) => parseFloat(a[value]) - parseFloat(b[value]),
					}
					break;
				default:
					break;
			}
		}

		if (filter) {
			addProp = {
				...addProp,
				filters: filtersColumns[value],
				filteredValue: filteredInfo[value]|| null,
				onFilter: (values, record) => record[value].includes(values),
			}
		}
		
		return sort || filter ? {...dataColumn, ...addProp} : dataColumn;
	}

	function getUniqueFilters(key){

    const filteredData = getData.filter(item => {
      return Object.entries(filteredInfo).every(([filterKey, filterValue]) => {

        if (filterValue) {
          return filterValue.includes(item[filterKey]);
        }
        return true;
      });
    });
  
    return [...new Set(filteredData.map(item => item[key]))].map(value => ({
      text: value,
      value: value,
    }));
  };
	
	return (
		<>
			<Modal
				title={"Date Range"}
				centered
				open={openModalDataRange}
				onOk={() => handleOpenModal()}
				onCancel={() => handleOpenModal()}
				okButtonProps={{
					className: "bg-[#662D91]",
				}}
				width="30%"
			>
				<div className="flex w-full h-full justify-end items-center mt-[20px] mb-5 mr-5">
					<div className="grid grid-cols-2 w-full h-[100px] gap-x-[43px]">
						<div className="flex flex-col text-right">
							<label 
								htmlFor="" 
								className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
							>
								Date start
							</label>
							<input 
								type="date" 
								name="date_start" 
								value={dataMultiWell.filter((item) => item.well_id === currentId)[0]?.date_start} 
								onChange={(e) => handleChangeData(e.target.value, "date_start", currentId)}
								className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid border border-solid border-[#BCBCCC]`}
							/>
						</div>
						<div className="flex flex-col text-right">
							<label 
								htmlFor="" 
								className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
							>
								Date end
							</label>
							<input 
								type="date" 
								name="date_end" 
								value={dataMultiWell.filter((item) => item.well_id === currentId)[0]?.date_end} 
								onChange={(e) => handleChangeData(e.target.value, "date_end", currentId)}
								className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid border border-solid border-[#BCBCCC]`}
							/>
						</div>
					</div>
				</div>
			</Modal>

			<div className="flex flex-col border rounded-[4px] border-[#f5f5f5]">
				<Table 
					columns={columns}
					dataSource={getData}
					onChange={handleChange} 
					pagination={false}
					rowClassName="border-b transition duration-300 ease-in-out "
				/>
			</div>
		</>
	)
}
