import { logDOM } from "@testing-library/react";
import React, { PureComponent, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";

import moment from "moment";
import { DataGasLiftContext } from "../../../../context/DataGasLift/DataGasLiftContext";
import { DashboardContext } from "../../../../context/Dashboard/DashboardContentx";
import { WELL_SENSOR_DEVICE } from "../../../elements/types/type_dashboard";
import { DATA_STANDARD_RT } from "../../../elements/types/type_standard_parameters";
import { REQUIRED_COLUMNS_DOCUMENT_HISTORY } from "../../../elements/types/type_gas_lift";
import { formatDateIOT } from '../../../utils/utils';

const ChartHole = (props) => {
  const {
    dataGrapIOT,
    dataHistory,
    dataDomainGraph,
    statusGraph,
    typeDeviceWP,
    dataAverage,
    dataAverageRT,
    wellId
  } = props;

  const { dataFilterUserGasLift } = useContext(DataGasLiftContext);
  const { deviceRealTime } = useContext(DashboardContext);
  const [dataIOT, setDataIOT] = useState();
  const chartRefHole = useRef();
  const validateDataProp = !!dataGrapIOT || !!dataHistory;
  const averageData = !!dataAverage ? dataAverage : null
  const [valueOffset, setValueOffset] = useState();
  const pressureMax = !!dataDomainGraph.press_max_wpc && validateDataProp ? dataDomainGraph.press_max_wpc : 0
  const pressureMin = !!dataDomainGraph.press_min_wpc && validateDataProp ? dataDomainGraph.press_min_wpc : 0
  const temperatureMax = !!dataDomainGraph.temp_max_wpc && validateDataProp ? dataDomainGraph.temp_max_wpc : 0
  const temperatureMin = !!dataDomainGraph.temp_min_wpc && validateDataProp ? dataDomainGraph.temp_min_wpc : 0
  const [dataFinalGraph, setDataFinalGraph] = useState([])
  const [yAxisDomain1, setYAxisDomain1] = useState([pressureMin, pressureMax]);
  const [yAxisDomain2, setYAxisDomain2] = useState([temperatureMin, temperatureMax,]);
  const [bottomMargin, setBottomMargin] = useState(50);
  const [modalData, setModalData] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const dataDeviceReplace = typeDeviceWP === WELL_SENSOR_DEVICE?.data[6].sensor ? WELL_SENSOR_DEVICE?.data[5].sensor : typeDeviceWP;

  useEffect(() => {
    // Cálculo dinámico del margen inferior
    const calculateBottomMargin = () => {
      if (chartRefHole?.current) {
        const chartHeight = chartRefHole?.current?.clientHeight;
        // Ajustar el margen inferior según la altura del gráfico y espacio necesario para el `Brush`
        const newBottomMargin = Math.max(30, Math.min(80, chartHeight * 0.1));
        setBottomMargin(newBottomMargin);
      }
    };

    // Calcular al montar el componente
    calculateBottomMargin();
    const handleResize = () => {
      if (chartRefHole?.current) {
        setValueOffset(chartRefHole?.current?.clientHeight - 17);
      }
    };
    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);
    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();
    // Volver a calcular cuando se redimensiona la ventana
    window.addEventListener('resize', calculateBottomMargin);
    return () => {
      window.removeEventListener('resize', calculateBottomMargin);
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {

    if (!!dataGrapIOT) {

      const dataFilter = dataGrapIOT?.filter((item) => {
        return item["LSD"] === dataDeviceReplace &&
          item[DATA_STANDARD_RT.GENERAL.PARAMETER_001] !== -999.25 &&
          item[DATA_STANDARD_RT.GENERAL.PARAMETER_002] !== -999.25
      });
      setDataIOT(dataFilter);
    }
  }, [dataGrapIOT, typeDeviceWP]);

  useEffect(() => {
    setYAxisDomain1([pressureMin, pressureMax]);
    setYAxisDomain2([temperatureMin, temperatureMax]);
  }, [pressureMax, temperatureMax, pressureMin, temperatureMin]);

  useEffect(() => {
    const formattedData = dataIOT?.map((item, index) => ({
      date: new Date(formatDateIOT(item[DATA_STANDARD_RT.GENERAL.PARAMETER_000])).getTime(),
      pressure_tubing: parseFloat(item[typeDeviceWP === WELL_SENSOR_DEVICE?.data[6].sensor ? dataFilterUserGasLift.well_name + ' Well Head Pressure' : DATA_STANDARD_RT.GENERAL.PARAMETER_001]),
      temperature_tubing: parseFloat(item[DATA_STANDARD_RT.GENERAL.PARAMETER_002]),
    })) || [];

    if (dataHistory != null && statusGraph === false) {
      const dataHistoryMod = dataHistory?.map((item, index) => ({
        date: new Date(formatDateIOT(item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[0]])).getTime(),
        pressure_tubing: typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[4]] === "number"
          ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[4]].toFixed(2)
          : null,
        temperature_tubing: typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]] === "number"
          ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]].toFixed(2)
          : null,
      })).filter(item =>
        item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[4]] !== null &&
        item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]] !== null
      );

      const dataFinalGraphVar = dataHistoryMod.concat(formattedData)
      setDataFinalGraph(dataFinalGraphVar?.sort(
        (a, b) => a.date - b.date // Ordenar de fecha menor a fecha mayor
      ))
    } else if (statusGraph === true) {
      setDataFinalGraph(
        formattedData?.sort((a, b) => a.date - b.date) // Ordenar de fecha menor a fecha mayor
      );
    }
  }, [dataIOT, dataHistory, statusGraph, dataIOT])
  //   useEffect(() => {
  //     let formattedData = [];

  //     const formatDateIOT = (dateString) => {
  //       const [datePart, timePart] = dateString.split(' ');
  //       const [month, day, year] = datePart.split('/');
  //       return `${parseInt(month)}/${parseInt(day)}/${year} ${timePart}`;
  //     };

  //     if (statusGraph === true) {
  //       // Datos en tiempo real
  //       formattedData = dataAverageRT?.map((item) => ({
  //         date: new Date(item.date).getTime(),
  //         pressure_tubing: parseFloat(item.pressure_tubing),
  //         temperature_tubing: parseFloat(item.temperature_tubing),
  //       })).reverse() || [];
  //     } else {
  //       // Datos históricos

  // const dataIOTMod = dataIOT?.map((item, index) => ({
  //   date: new Date(formatDateIOT(item[DATA_STANDARD_RT.GENERAL.PARAMETER_000])).getTime(),
  //   'pressure_tubing': parseFloat(item[typeDeviceWP === WELL_SENSOR_DEVICE?.data[6].sensor ? dataFilterUserGasLift.well_name + ' Well Head Pressure' : DATA_STANDARD_RT.GENERAL.PARAMETER_001]),
  //   "temperature_tubing": parseFloat(item[DATA_STANDARD_RT.GENERAL.PARAMETER_002]),
  // })) || [];

  // const dataHistoryMod = dataHistory?.map((item, index) => ({
  //   date: new Date(item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[0]]).getTime(),
  //   "pressure_tubing": typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[4]] === "number"
  //     ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[4]].toFixed(2)
  //     : null,
  //   "temperature_tubing": typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]] === "number"
  //     ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]].toFixed(2)
  //     : null,
  // })).filter(item =>
  //   item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[4]] !== null &&
  //   item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[5]] !== null
  // );

  //       formattedData = [...dataHistoryMod, ...dataIOTMod];
  //     }
  //     // Ordenar por fecha (de menor a mayor)
  //     const sortedData = formattedData
  //       ?.filter((item) => !isNaN(item.date))
  //       ?.sort((a, b) => a.date - b.date);

  //     setDataFinalGraph(sortedData);
  //   }, [dataAverageRT, dataHistory, dataIOT, statusGraph]);

  useEffect(() => {
    if (dataFinalGraph?.length > 0) {
      const pressures = dataFinalGraph
        .filter((d) => d.pressure_tubing !== null)
        .map((d) => d.pressure_tubing);

      const temperatures = dataFinalGraph
        .filter((d) => d.temperature_tubing !== null)
        .map((d) => d.temperature_tubing);

      const minPressure = pressures.length > 0 ? Math.min(...pressures) : 0;
      const maxPressure = pressures.length > 0 ? Math.max(...pressures) : 0;

      const minTemperature = temperatures.length > 0 ? Math.min(...temperatures) : 0;
      const maxTemperature = temperatures.length > 0 ? Math.max(...temperatures) : 0;

      setYAxisDomain1([Math.floor(minPressure), Math.ceil(maxPressure)]);
      setYAxisDomain2([Math.floor(minTemperature), Math.ceil(maxTemperature)]);
    }
  }, [dataFinalGraph]);

  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 5000
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };

  function obtenerFechasUnicas(data) {
    const fechas = data?.map(item => item?.date); // Obtener todas las fechas
    const fechasUnicas = fechas?.filter((fecha, index) => fechas.indexOf(fecha) === index);
    return fechasUnicas;
  }

  // Obtener fechas únicas
  const fechasUnicas = obtenerFechasUnicas(dataFinalGraph);

  function customTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      const valuesDate = payload[0]?.payload;
      const formattedDate = new Date(valuesDate?.date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      });

      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
          <p>{`Date: ${formattedDate}`}</p>
          <p style={{ color: '#16a34a' }}>{`Wellhead Pressure: ${valuesDate.pressure_tubing !== null ? valuesDate.pressure_tubing : 'No data'} psig`}</p>
          <p style={{ color: '#dc2626' }}>{`Wellhead Temperature: ${valuesDate.temperature_tubing !== null ? valuesDate.temperature_tubing : 'No data'} °F`}</p>
        </div>
      );
    }
    return null;
  }

  const handleMouseMove = (event) => {
    if (!event.isTooltipActive) {
      setModalData(null); // Oculta el modal si no hay tooltip activo
      return;
    }

    const activePoint = event.activePayload?.[0]?.payload;
    if (
      activePoint &&
      activePoint.pressure_tubing === null &&
      activePoint.temperature_tubing === null
    ) {
      // Si ambos valores son null, muestra el modal personalizado
      const chartRect = chartRefHole.current.getBoundingClientRect(); // Obtiene el área del gráfico

      setModalData({
        date: new Date(activePoint.date).toLocaleString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        }),
        position: {
          x: event.chartX + chartRect.left, // Posición relativa al área del gráfico
          y: event.chartY + chartRect.top,
        },
      });
    } else {
      setModalData(null); // Oculta el modal si hay datos válidos
    }
  };

  return (
    <div className="w-full h-full" ref={chartRefHole}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={200}
          data={dataFinalGraph}
          margin={{
            top: 0,
            right: 30,
            left: 30,
            bottom: bottomMargin,
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => setModalData(null)}
        >
          <Brush
            dataKey="date"
            height={10}
            // startIndex={dataFinalGraph.findIndex(d => d.pressure_tubing !== null || d.temperature_tubing !== null)}
            // endIndex={dataFinalGraph.length - 1}
            y={valueOffset}
            stroke="#8884d8"
            tickFormatter={(tick) =>
              new Date(tick).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
              })
            }
          />
          <CartesianGrid />
          <XAxis
            dataKey="date"
            type="number"
            domain={['dataMin', 'dataMax']}
            tickFormatter={(tick) =>
              new Date(tick).toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })
            }
            label={{
              value: 'Date',
              position: 'bottom',
              fill: 'black',
            }}
            tickCount={10}
          />
          <YAxis
            yAxisId="left"
            domain={yAxisDomain1}
            allowDataOverflow={true}
            label={{
              value: `Wellhead Pressure (psig)`,
              style: { textAnchor: "middle" },
              angle: -90,
              position: "left",
              offset: 0,
            }}
            // tickCount={8}
            padding={{ bottom: 10 }}
          />
          <YAxis
            yAxisId="right"
            domain={yAxisDomain2}
            orientation="right"
            allowDataOverflow={true}
            label={{
              value: `Wellhead Temperature (deg F)`,
              style: { textAnchor: "middle" },
              angle: -90,
              position: "left",
              offset: -60,
            }}
            // tickCount={7}
            padding={{ bottom: 10 }}
          />
          <Tooltip content={customTooltip} />
          {/* <Legend align="center" verticalAlign="top" /> */}
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="pressure_tubing"
            stroke="#16a34a"
            dot={<CustomDot color="#16a34a"
            />}
            activeDot={{ r: 8 }}
            connectNulls={true} // mostrar que se conecten los puntos sin valor
          />
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="temperature_tubing"
            stroke="#dc2626"
            dot={<CustomDot color="#dc2626" />}
            activeDot={{ r: 8 }}
            connectNulls={true} // mostrar que se conecten los puntos sin valor
          />
        </LineChart>
      </ResponsiveContainer>
      {modalData && (
        <div
          style={{
            position: "absolute",
            top: modalData.position.y,
            left: modalData.position.x,
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            pointerEvents: "none",
            transform: "translate(-50%, -100%)", // Ajusta la posición para centrar el modal
          }}
        >
          <p>{`Date: ${modalData.date}`}</p>
          <p style={{ color: "#16a34a" }}>Wellhead Pressure: No data</p>
          <p style={{ color: "#dc2626" }}>Wellhead Temperature: No data</p>
        </div>
      )}
    </div>
  );
};
export default ChartHole;

