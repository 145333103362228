import { InfoCircleOutlined } from '@ant-design/icons';
import { message, Select, Steps, Switch, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as IconAdd } from '../../assets/img/icons/add.svg';
import { ReactComponent as IconDelete } from '../../assets/img/icons/delete.svg';
import Img_Wellhead from '../../assets/img/wellhead-img.png';
import { AlertModal } from "../../components/AlertModal";
import { DATA_CREATE_WELL_GAS_LIFT, DATA_END_PARAMETERS, INITIAL_DATA_WELL_DEVIATION, INITIAL_DATA_WELL_EQUIPMENT, VALUES_FO_CALCULATE } from "../../components/elements/types/type_gas_lift";
import { ImportFile } from "../../components/GasLift/Complements/ImportFile";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { DataGasLiftContext } from "../../context/DataGasLift/DataGasLiftContext";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabIOT } from "../../hooks/useTabIOT";
import { removeEmptyRows } from '../../helpers/helpersProcess';
import { LoadingDashboard } from '../../components/Loading';

const steps = [
  {
    title: "Well",
    content: "First-content",
  },
  {
    title: "Second",
    content: "Second-content",
  },
  {
    title: "Last",
    content: "Last-content",
  },
];

const initialCoordinates = {
  latitudeDegrees: '',
  latitudeMinutes: '',
  latitudeSeconds: '',
  latitudeDirection: '',
  longitudeDegrees: '',
  longitudeMinutes: '',
  longitudeSeconds: '',
  longitudeDirection: ''
}
const initialValidatioTable = {
  tableDeviation: false,
  tableEquipment: false
}

export const StepMenuWellConfig = () => {
  const { Option } = Select;
  const {
    User,
    userDataInfo,
    mutationUploadJsonPVT,
    mutationUploadJsonDataPVT,
    mutationUploadFoPVT,
    getDataWell,
    getDataWellTables,
    setDataDeviationApi,
    setDataEquipmentApi,
    setBodyAddWell,
    getSelectedWell,
    generateRadomId,
    getDataFilterUserGaslift,
    dataFilterUserGasLift,
    mutationSaveDataGasLift,
    listWellsGasLift,
    refetchListWellsGasLift
  } = useTabGaslift();

  const {
    dataListPlatforms,
    refetchDataListPlatforms,
    dataListPlatformsWells,
    refetchDataListPlatformsWells,
    setTypeProcessPlatform
  } = useTabIOT();
  const { dataCreated, getDataCreated, viewLoadingGasLift } = useContext(DataGasLiftContext);
  const [dataDeviation, setDataDeviation] = useState(INITIAL_DATA_WELL_DEVIATION);
  const { getDeviceRealTime, deviceRealTime } = useContext(DashboardContext);
  const [dataEquipment, setDataEquipment] = useState(INITIAL_DATA_WELL_EQUIPMENT);
  const [inputWellInformation, setInputWellInformation] = useState(DATA_CREATE_WELL_GAS_LIFT);
  const [wellOrientation, setWellOrientation] = useState()
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [fileDeviation, setFileDeviation] = useState([]);
  const [fileEquipment, setFileEquipment] = useState([]);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [coordinate, setCoordinate] = useState(initialCoordinates);
  const [validationTable, setValidationTable] = useState(initialValidatioTable);
  const dataListGasLift = listWellsGasLift != null ? listWellsGasLift?.data : null;

  useEffect(() => {
    refetchDataListPlatforms();
    refetchListWellsGasLift();
    setInputWellInformation({
      ...inputWellInformation,
      region: userDataInfo.region
    })
    getDeviceRealTime({
      ...deviceRealTime,
      device_gaslift: {
        ...deviceRealTime.device_gaslift,
        platform: "",
        discretized: false
      }
    });
  }, []);

  useEffect(() => {
    setInputWellInformation(prevState => ({
      ...prevState,
      latitude: `${coordinate.latitudeDegrees}${'°'}${coordinate.latitudeMinutes}${'\''}${coordinate.latitudeSeconds}${'"'}${coordinate.latitudeDirection}`
    }));

    setInputWellInformation(prevState => ({
      ...prevState,
      longitude: `${coordinate.longitudeDegrees}${'°'}${coordinate.longitudeMinutes}${'\''}${coordinate.longitudeSeconds}${'"'}${coordinate.longitudeDirection}`
    }));
  }, [coordinate]);

  const handleClickGenerateId = () => {

    const idGenerated = generateRadomId()
    setInputWellInformation({
      ...inputWellInformation,
      well_id: idGenerated
    })
  }

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  const onChange = (value) => {
    setCurrent(value);
  };

  const handleClickNext = () => {
    if (current === 0) {
      if (inputWellInformation.well_id === '' || inputWellInformation.well_id === null || inputWellInformation.well_platforms === '' || inputWellInformation.well_platforms === null || inputWellInformation.region === '' || inputWellInformation.field === '' || inputWellInformation.latitude === '' || inputWellInformation.company === ''
        || inputWellInformation.well_type === '' || inputWellInformation.artificial_method === '' || inputWellInformation.flow_type === '' || inputWellInformation.well_name === '' || inputWellInformation.well_name === null ||
        inputWellInformation.country === '' || inputWellInformation.county === '' || coordinate.longitudeDegrees === '' || coordinate.longitudeMinutes === '' || coordinate.longitudeSeconds === '' || coordinate.longitudeDirection === '' || inputWellInformation.objective === '' || inputWellInformation.well_orientation === '' ||
        inputWellInformation.well_reference === '' || inputWellInformation.fluid_type === ''
      ) {
        handleOpenModal();
        setTypeAlert("Warning")
        setMessageAlert('Please, complete the missing information in empty fields.')
        setInputEmpty(true)
        handleStatusChange();
      } else {
        getDataFilterUserGaslift({
          ...dataFilterUserGasLift,
          county: inputWellInformation.county,
          field: inputWellInformation.field,
          well_id: inputWellInformation.well_id,
          well_platforms: inputWellInformation.well_platforms,
          well_name: inputWellInformation.well_name,
          fluid_type: inputWellInformation.fluid_type
        })
        setInputEmpty(false)
        setCurrent(current + 1);
      }
    }
    if (current === 1) {
      const validateDataNull = VerifyingNulls(dataDeviation)
      if (validateDataNull || validationTable.tableDeviation) {
        setValidationTable({ ...validationTable, tableDeviation: true });
        handleOpenModal();
        setTypeAlert("Warning")
        setMessageAlert('Please, complete the missing information or upload a file')
      }
      else {
        setCurrent(current + 1);
      }
    }
  };

  const handleClickPrev = () => {
    setCurrent(current - 1);
  };

  const handleClickDone = () => {
    const validateDataNull = VerifyingNulls(dataEquipment)
    const validateMD = sumMeasureDepth();
    if (validateDataNull || validateMD || validationTable.tableEquipment) {
      setValidationTable({ ...validationTable, tableEquipment: true });
      handleOpenModal();
      setTypeAlert("Warning")
      setMessageAlert('Please, complete the missing information or upload a file')
    }
    else {
      setBodyAddWell({ inputWellInformation, dataDeviation: [...dataDeviation], dataEquipment: [...dataEquipment] });
      let maxId = 0
      if (dataCreated.length > 0) {
        maxId = Math.max(...dataCreated.map(item => item.id));
      }
      getDataCreated([...dataCreated,
      {
        id: maxId + 1,
        wellConfig: {
          fluidConfig: false,
          reservoir: false,
          tuning: false
        },
        wellId: inputWellInformation.well_id,
        wellName: inputWellInformation.well_name,
        wellRegion: inputWellInformation.region,
        wellFiled: inputWellInformation.field,
        wellCountry: inputWellInformation.country,
      }
      ]);

      const dataCreateWellTables = { dataDeviation: [...dataDeviation], dataEquipment: [...dataEquipment] };
      const dataCreateWell = Object.assign({}, inputWellInformation, dataCreateWellTables, DATA_END_PARAMETERS);

      mutationSaveDataGasLift.mutate({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: dataFilterUserGasLift.county,
        field: dataFilterUserGasLift.field,
        well: dataFilterUserGasLift.well_id,
        username: User.user_name,
        data: dataCreateWell
      });

      getSelectedWell(inputWellInformation.well_id ? inputWellInformation.well_id : null);
    }
  };

  const handleAddDeviation = () => {
    const uniqueIds = [...new Set(dataDeviation.map(obj => obj.id))];
    const maxId = Math.max(...uniqueIds);
    const newDeviation = {
      id: maxId + 1,
      measured_depth: null,
      tvd: null,
      inc: null,
    };

    setDataDeviation([...dataDeviation, newDeviation]);
    setValidationTable({ ...validationTable, tableDeviation: false });
  };

  const handleUploadFile = (dataFile) => {
    const dataTableProcessed = removeEmptyRows(dataDeviation);
    const uniqueIds = [...new Set(dataDeviation.map(obj => obj.id))];
    const maxId = (Math.max(...uniqueIds)) + 1;
    const dataFileEnd = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };
    });


    const previousIndex = (dataDeviation.length - 1);
    const lastValue = dataDeviation[dataDeviation.length - 1].measured_depth;

    const calculateMaxIndex = (array, currentIndex) => {
      let max = -Infinity;
      for (let i = 0; i < currentIndex; i++) {
        if (array[i].measured_depth > max) {
          max = array[i].measured_depth;
        }
      }
      return max;
    };

    const dataFileUp = dataFileEnd.map((item, index) => {

      const previousItem = dataFileEnd[index - 1];

      const previousMeasuredDepth = previousItem ? previousItem.measured_depth : null;
      const lastMax = calculateMaxIndex(dataFileEnd, index);

      const newMeasuredDepth = (
        (item.measured_depth < 0 || item.measured_depth > 60000) ||
        (item.measured_depth <= lastValue && (index + previousIndex) === previousIndex) ||
        (previousMeasuredDepth >= item.measured_depth) || 
        ((previousMeasuredDepth >= item.measured_depth  ? previousMeasuredDepth : lastMax) >= (item.measured_depth)) || 
        (item.measured_depth === lastMax) ) 
        ? null : item.measured_depth;

      return {
          ...item,
          measured_depth: newMeasuredDepth,
          tvd: (0 <= item.tvd  && item.tvd <= 60000 && item.tvd <= item.measured_depth) ? item.tvd : null
      };
    });
    const dataConcat = dataTableProcessed.length ? [...dataTableProcessed, ...dataFileUp] : dataFileUp;
    setDataDeviation(dataConcat);
    setFileDeviation(dataFile);

  };

  const handleDeleteDeviation = (id) => {
    const newData = dataDeviation.filter((item) => item.id !== id);
    setDataDeviation(newData);
  };

  const handleAddEquipment = () => {
    const uniqueIds = [...new Set(dataEquipment.map(obj => obj.id))];
    const maxId = Math.max(...uniqueIds);

    const newDeviation = {
      id: maxId + 1,
      name: "",
      type: "",
      measured_depth: null,
      internal_diameter: null,
      external_diameter: null,
      absolute_roughness: null,
    };
    setDataEquipment([...dataEquipment, newDeviation]);
    setValidationTable({ ...validationTable, tableEquipment: false });
  };

  const handleUploadFile2 = (dataFile) => {
    const dataTableProcessed = removeEmptyRows(dataEquipment);
    const uniqueIds = [...new Set(dataEquipment.map(obj => obj.id))];
    const maxId = (Math.max(...uniqueIds)) + 1;
    const dataFileEnd = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };
    });

    const dataFileUp = dataFileEnd.map(item => ({
      ...item,
      type: (item.type !== 'tubing' && item.type !== 'casing') ? null : item.type,
      measured_depth: item.measured_depth < 0 ? null : item.measured_depth,
      internal_diameter: (item.internal_diameter <= 0 || item.internal_diameter > 4) ? null : item.internal_diameter,
      external_diameter: (item.external_diameter <= 0 || item.external_diameter > 4) ? null : item.external_diameter,
      internal_diameter: item.internal_diameter >= item.external_diameter ? null : item.internal_diameter,
      absolute_roughness: (item.absolute_roughness > 0.1 || item.absolute_roughness < 0) ? null : item.absolute_roughness
    }));

    const dataConcat = dataTableProcessed.length ? [...dataTableProcessed, ...dataFileUp] : dataFileUp;
    setDataEquipment(dataConcat);
    setFileEquipment(dataFile);
  };

  const handleDeleteEquipment = (id) => {
    const newData = dataEquipment.filter((item) => item.id !== id);
    setDataEquipment(newData);
  };


  const handleChangeInformation = (e) => {
    const { name, value } = e.target;
    if (name === "well_platforms") {
      getDeviceRealTime({
        ...deviceRealTime,
        device_gaslift: {
          ...deviceRealTime.device_gaslift,
          platform: value
        }
      })
      setTypeProcessPlatform(value);
      setTimeout(() => {
        refetchDataListPlatformsWells();
      }, 500);
    }
    else if (name === "discretized_well") {
      getDeviceRealTime({
        ...deviceRealTime,
        device_gaslift: {
          ...deviceRealTime.device_gaslift,
          discretized: value
        }
      })
    }

    const wellIdExists = name === "well_id" ? dataListGasLift?.some(obj => obj.well_id === value) : null
    const wellNameExists = name === "well_name" ? dataListGasLift?.some(obj => obj.well_name === value) : null
    if (wellIdExists) {
      message.error("There is already a well with the same ID, please generate a new ID");
      setInputWellInformation((prevState) => ({
        ...prevState,
        well_id: null,
      }));
    }
    else if (wellNameExists) {
      message.error("There is already a well with the same name, please select a new name");
      setInputWellInformation((prevState) => ({
        ...prevState,
        well_name: "",
      }));
    }
    else {
      setInputWellInformation(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleChangeDeviation = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? parseFloat(value) : "";
    setValidationTable({ ...validationTable, tableDeviation: false });

    if (name === "measured_depth" && (dataValue < 0.0 || dataValue > 60000.0)) {
      message.error("Measured Depth value can't be less than to 0 or greater than 60000");
      setValidationTable({ ...validationTable, tableDeviation: true });
    } else if (name === "tvd" && (dataValue < 0.0 || dataValue > 60000.0)) {
      message.error("TVD value can't be less than to 0 or greater than 60000");
      setValidationTable({ ...validationTable, tableDeviation: true });
    }
    else if (name === "inc" && (dataValue < -25.0 || dataValue > 125.0)) {
      message.error("INC value can't be less than to -25 or greater than 125");
      setValidationTable({ ...validationTable, tableDeviation: true });
    }
    else {
      setDataDeviation(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: dataValue } : item
        )
      );
    }
  };

  const handleBlurDeviation = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : "";

    const selectedIndex = dataDeviation.findIndex(
      (data) => data.id === id
    );
    const lastIndex = selectedIndex !== 0 ? (selectedIndex - 1) : selectedIndex;
    if (name === 'tvd' && dataValue > dataDeviation[selectedIndex].measured_depth) {
      message.error("The value can't be greater than measured depth value");
      setValidationTable({ ...validationTable, tableDeviation: true });
    }
    if (name === 'measured_depth' && (dataValue <= dataDeviation[lastIndex].measured_depth && selectedIndex !== lastIndex)) {
      message.error("Measured depth value can't be less than previous row value");
      setValidationTable({ ...validationTable, tableDeviation: true });
    }
  }

  const handleChangeEquipment = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" && !isNaN(parseFloat(value)) ? parseFloat(value) : "";
    setValidationTable({ ...validationTable, tableEquipment: false });


    if (name === "measured_depth" && (dataValue < 0 || dataValue > 50000)) {
      message.error("Length (ft) value can't be less than 0  and greater than 50000");
      setValidationTable({ ...validationTable, tableEquipment: true });
    } else if (name === "internal_diameter" && (dataValue < 0 || dataValue > 36)) {
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setValidationTable({ ...validationTable, tableEquipment: true });
    }
    else if (name === "external_diameter" && (dataValue < 0 || dataValue > 36)) {
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setValidationTable({ ...validationTable, tableEquipment: true });
    }
    else if (name === "absolute_roughness" && (dataValue >= 0.1 || dataValue < 0)) {
      message.error("Absolute roughness value can't be greater than 0.1 and less than 0");
      setValidationTable({ ...validationTable, tableEquipment: true });
    } else {
      if (name === "name" || name === "type") {
        setDataEquipment(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: value } : item
          )
        );
      } else {
        setDataEquipment(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: dataValue } : item
          )
        );

      }
    }
  };

  const handleBlurEquipment = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" && !isNaN(parseFloat(value)) ? parseFloat(value) : "";

    const selectedIndex = dataEquipment.findIndex(
      (data) => data.id === id
    );
    const externalDiameter = !isNaN(parseFloat(dataEquipment[selectedIndex].external_diameter))
      ? parseFloat(dataEquipment[selectedIndex].external_diameter)
      : "";
    const internalDiameter = !isNaN(parseFloat(dataEquipment[selectedIndex].internal_diameter))
      ? parseFloat(dataEquipment[selectedIndex].internal_diameter)
      : "";

    if ((name === "internal_diameter" || name === "external_diameter") && dataValue <= 0) {
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: "" } : item
        )
      );
    } else if (name === "measured_depth") {
      const valMeasuredDepth = sumMeasureDepth()
      if (valMeasuredDepth) {
        message.error("the sum of MD Length in the table cannot exceed the maximum value of Measured Depth in Well Orientation");
        setDataEquipment(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: "" } : item
          )
        );
      }
    }
    else if (name === "internal_diameter" && dataValue >= externalDiameter && dataValue !== "") {
      message.error("Internal diamater value can't be greater than or equal External diamater value");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: "" } : item
        )
      );
    }
    else if (name === "external_diameter" && dataValue <= internalDiameter && dataValue !== "") {
      message.error("Internal diamater value can't be greater than or equal External diamater value");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: "" } : item
        )
      );
    }
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleChangeSelect = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInformation(customEvent);

    if (nameData === "well_orientation") {
      setWellOrientation(valueData)
    }
  };

  const handleChangeSelectCurrent2 = (nameData, valueData, id) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeEquipment(customEvent, id);
  };

  const handleChangeCoor = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "latitudeDegrees" ||
      name === "latitudeMinutes" ||
      name === "longitudeDegrees" ||
      name === "longitudeMinutes"
    ) {
      newValue = value.replace(/[^\d]/g, '');
    } else if (name === "latitudeSeconds" || name === "longitudeSeconds") {
      newValue = value.replace(/[^\d.]/g, '');
      if (value.length === 3 && value.indexOf(".") === -1) {
        newValue = value.slice(0, 2) + "." + value.slice(2);
      }
      const decimalIndex = newValue.indexOf('.');
      if (decimalIndex !== -1) {
        const integerPart = newValue.slice(0, decimalIndex);
        const decimalPart = newValue.slice(decimalIndex + 1, decimalIndex + 3);
        newValue = `${integerPart}.${decimalPart}`;
        if (value.indexOf(".") !== -1 && decimalPart === '') {
          newValue = value.slice(0, -1);
        }
      }
    }

    setCoordinate((prevState) => ({
      ...prevState,
      [name]: newValue,

    }));
  };

  const handleChangeSelectCoor = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeCoor(customEvent);
  };

  const handleKeyDown = (event) => {
    if (event.key === '-' && event.target.name !== "inc") {
      event.preventDefault();
    }
  };

  const handleSwitch = (enabled) => {
    const customEvent = {
      target: {
        name: "discretized_well",
        value: enabled,
      },
    };
    handleChangeInformation(customEvent);
  }

  function VerifyingNulls(array) {
    let validateValuesNull = false;
    if (wellOrientation === "vertical") {
      array.forEach(obj => {
        Object.entries(obj).forEach(([key, value]) => {
          if (key !== 'inc' && (value === null || value === '')) {
            validateValuesNull = true;
          }
        });
      });
    } else {
      array.forEach(obj => {
        Object.values(obj).forEach(value => {
          if (value === null || value === '') {
            validateValuesNull = true;
          }
        });
      });
    }

    return validateValuesNull;
  }

  function sumMeasureDepth() {
    const valuesDeviation = dataDeviation.length ? dataDeviation.length : null;
    const lastDeviation = dataDeviation[valuesDeviation - 1].measured_depth;

    const value = dataEquipment.reduce((total, item) => {
      return total + parseFloat(item.measured_depth);
    }, 0);
    return (value > lastDeviation);
  }

  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="absolute flex h-full "></Sidebar>
      </div>

      {viewLoadingGasLift.loadSaveParameters ?
        (
          <div className="flex w-full h-[1000px]">
            <LoadingDashboard />
          </div>
        )
        :
        (
          <>
            <div className="flex w-full h-full">
              <AlertModal showAlert={showAlert} handleCloseModal={handleCloseModal} messageAlert={messageAlert} typeModal={typeAlert}></AlertModal>
            </div>
            <div className="flex w-full pl-[88px] text-center justify-center mt-[30px]">
              <Steps
                size="default"
                current={current}
                onChange={onChange}
                className="site-navigation-steps flex w-[600px] custom-steps"
                items={[
                  {
                    title: "Well",
                    disabled: { status },
                    description: "Detailed well information",
                  },
                  {
                    title: 'Well deviation',
                    disabled: { status },
                    description: "Detailed well deviation",
                  },
                  {
                    title: "Well equipment",
                    disabled: { status },
                    description: "Detailed well equipment",
                  },
                ]}
              />
            </div>
            <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center">
              {current === 0 &&
                <>
                  <div className="flex w-full h-[24px] justify-center text-center items-center mb-[35px]">
                    <div className="flex flex-row w-[1230px] h-[24px] gap-5 justify-start text-center items-center">
                      <h1 className="flex flex-row font-bold text-[20px]">Well Information</h1>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 w-full h-[850px]  gap-x-[43px]">
                    <div className="flex flex-col items-end text-right">
                      <label htmlFor="" className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well API Number/Well ID</label>
                      <div className="flex flex-row w-full max-w-[592px] gap-5">
                        <input type="text" name="well_id" value={inputWellInformation.well_id} onChange={handleChangeInformation} className={`w-2/3 h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${(inputEmpty && inputWellInformation.well_id === '') || inputWellInformation.well_id === null ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                        <button onClick={handleClickGenerateId} className="w-1/3 h-[47px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1">Generate Id</button>
                      </div>
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well Name</label>
                      {
                        inputWellInformation.discretized_well ?

                          <Select
                            style={{ backgroundColor: inputEmpty && inputWellInformation.well_name === "" ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_name === "" ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                            className="w-full max-w-[592px] h-[47px] text-left mb-[15px]"
                            name="well_name"
                            value={inputWellInformation.well_name || ""}
                            onChange={(value) => handleChangeSelect("well_name", value)}
                          >
                            <Option value="">Selected option</Option>
                            {dataListPlatformsWells?.data?.map((item, index) => (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                          :
                          <input type="text" name="well_name" value={inputWellInformation.well_name} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.well_name === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                      }
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Region</label>
                      <input type="text" disabled={true} name="region" value={inputWellInformation.region} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.region === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Field</label>
                      <input type="text" name="field" value={inputWellInformation.field} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.field === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Latitude
                        <Tooltip title={'Example latitude value: 29°13\'47.9"N'}>
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                      </label>
                      <div className="flex flex-row max-w-[592px] h-[65px] gap-[15px]">
                        <div className="flex 1/4">
                          <input placeholder={'29°'} type="text" name="latitudeDegrees" value={coordinate.latitudeDegrees} onChange={handleChangeCoor} maxLength={2} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.latitudeDegrees === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            °
                          </div>
                        </div>
                        <div className="flex 1/4">
                          <input placeholder={'13\''} type="text" name="latitudeMinutes" value={coordinate.latitudeMinutes} onChange={handleChangeCoor} maxLength={2} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.latitudeMinutes === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            '
                          </div>
                        </div>
                        <div className="flex 1/4">
                          <input placeholder={'47.9"'} type="text" name="latitudeSeconds" value={coordinate.latitudeSeconds} onChange={handleChangeCoor} maxLength={5} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.latitudeSeconds === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            "
                          </div>
                        </div>
                        <Select
                          style={{ backgroundColor: inputEmpty && coordinate.latitudeDirection === '' ? '#FEF9C3' : '', border: inputEmpty && coordinate.latitudeDirection === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                          name="latitudeDirection"
                          value={coordinate.latitudeDirection || "Direction"}
                          className="flex w-1/4  h-[47px] text-left mb-[15px]"
                          onChange={(value) => handleChangeSelectCoor('latitudeDirection', value)}>
                          <Option value="N">N</Option>
                          <Option value="S">S</Option>
                        </Select>
                      </div>
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Company</label>
                      <input type="text" name="company" value={inputWellInformation.company} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.company === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well type</label>
                      <Select
                        style={{ backgroundColor: inputEmpty && inputWellInformation.well_type === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                        className="w-full max-w-[592px] h-[47px] text-left mb-[15px]"
                        name="well_type"
                        value={inputWellInformation.well_type || "Selected option"}
                        onChange={(value) => handleChangeSelect("well_type", value)}
                      >
                        <Option value="producer">Producer</Option>
                        <Option value="injector">Injector</Option>
                      </Select>
                      {/* <input type="text"  name="well_type" value={inputWellInformation.well_type} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.well_type === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Artificial Lift Method</label>
                      {/* <input type="text"  name="artificial_method" value={inputWellInformation.artificial_method} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.artificial_method === ''? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                      <Select
                        style={{ backgroundColor: inputEmpty && inputWellInformation.artificial_method === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.artificial_method === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                        className="w-full max-w-[592px] h-[47px] text-left mb-[15px]"
                        name="artificial_method"
                        value={inputWellInformation.artificial_method || "Selected option"}
                        onChange={(value) => handleChangeSelect("artificial_method", value)}
                      >
                        <Option value="none">None</Option>
                        <Option value="continuous_gas_lift">Continuous Gas Lift</Option>
                        <Option value="esp" disabled>Esp</Option>
                      </Select>
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Flow type</label>
                      {/* <input type="text"  name="flow_type" value={inputWellInformation.flow_type} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.flow_type === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                      <Select
                        style={{ backgroundColor: inputEmpty && inputWellInformation.flow_type === '' || inputWellInformation.flow_type === null ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.flow_type === '' || inputWellInformation.flow_type === null ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                        className="w-full max-w-[592px] h-[47px] text-left mb-[15px]"
                        name="flow_type"
                        value={inputWellInformation.flow_type || "Selected option"}
                        onChange={(value) => handleChangeSelect("flow_type", value)}
                      >
                        <Option value="tubing">Tubing</Option>
                        <Option value="annular">Annular</Option>
                        <Option value="tubing_annular">Tubing Annular</Option>
                      </Select>
                    </div>
                    <div className="flex flex-col items-start text-left">
                      <div className="flex flex-row w-full max-w-[592px] mb-[7px] gap-5">
                        <label htmlFor="" className="flex w-[80%] text-start font bold text-[15px]">
                          Platform/Tank Battery
                          <Tooltip title="This option will become available once real-time data streaming begins">
                            <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                          </Tooltip>
                        </label>
                      <label htmlFor="" className="flex w-[20%] text-start font bold text-[15px]">Discretized IoT well
                      <Tooltip title="Enables IoT well discretization.">
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                      </label>
                      </div>
                      <div className="flex flex-row w-full max-w-[592px] h-[47px] mb-[15px] items-center justify-center text-center gap-5">
                        <Select
                          style={{ backgroundColor: inputEmpty && inputWellInformation.well_platforms === "" ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_platforms === "" ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                          className="w-[80%] max-w-[592px] h-[47px] text-left "
                          name="well_platforms"
                          value={inputWellInformation.well_platforms || ""}
                          onChange={(value) => handleChangeSelect("well_platforms", value)}
                        >
                          <Option value="">Selected option</Option>
                          <Option value="LATER">Select Later</Option>
                          {dataListPlatforms?.data?.map((item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      <div className="flex w-[20%] h-full justify-start text-left items-center">
                  <Tooltip title={inputWellInformation.discretized_well ? "Disable discretization" : "Enable discretization"} color={"#662D91"}>
                          <Switch
                            style={{
                              backgroundColor: inputWellInformation.discretized_well ? "#662D91" : "#707070",
                              borderColor: inputWellInformation.discretized_well ? "#662D91" : "#707070",
                            }}
                            checked={inputWellInformation.discretized_well}
                            onChange={handleSwitch}
                          ></Switch>
                  </Tooltip>
                        </div>
                      </div>
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Country</label>
                      <input type="text" name="country" value={inputWellInformation.country} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.country === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">County (if not available please add NA)</label>
                      <input
                        type="text"
                        name="county"
                        value={inputWellInformation?.county}
                        onChange={handleChangeInformation}
                        onKeyDown={(e) => {
                          if (e.key === '/') {
                            e.preventDefault();
                          }
                        }}
                        className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.county === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}
                      />
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Longitude
                        <Tooltip title={'Example longitude value: 29°13\'47.9"N'}>
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                      </label>
                      <div className="flex flex-row max-w-[592px] h-[65px] gap-[15px]">
                        <div className="flex w-1/4">
                          <input placeholder={'29°'} type="text" name="longitudeDegrees" value={coordinate.longitudeDegrees} onChange={handleChangeCoor} maxLength={3} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeDegrees === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            °
                          </div>
                        </div>
                        <div className="flex w-1/4">
                          <input placeholder={'13\''} type="text" name="longitudeMinutes" value={coordinate.longitudeMinutes} onChange={handleChangeCoor} maxLength={2} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeMinutes === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            '
                          </div>
                        </div>
                        <div className="flex w-1/4">
                          <input placeholder={'47.9"'} type="text" name="longitudeSeconds" value={coordinate.longitudeSeconds} onChange={handleChangeCoor} maxLength={5} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeSeconds === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            "
                          </div>
                        </div>
                        <Select
                          style={{ backgroundColor: inputEmpty && coordinate.longitudeDirection === '' ? '#FEF9C3' : '', border: inputEmpty && coordinate.longitudeDirection === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                          name="longitudeDirection"
                          value={coordinate.longitudeDirection || "Direction"}
                          className="flex w-1/4  h-[47px] text-left mb-[15px]"
                          onChange={(value) => handleChangeSelectCoor('longitudeDirection', value)}>
                          <Option value="W">W</Option>
                          <Option value="E">E</Option>
                        </Select>
                      </div>
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Objective</label>
                      <input type="text" name="objective" value={inputWellInformation.objective} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.objective === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well orientation</label>
                      {/* <input type="text" name="well_orientation" value={inputWellInformation.well_orientation} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.well_orientation === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                      <Select
                        style={{ backgroundColor: inputEmpty && inputWellInformation.well_orientation === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_orientation === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                        className="w-full max-w-[592px] h-[47px] text-left mb-[15px]"
                        name="well_orientation"
                        value={inputWellInformation.well_orientation || "Selected option"}
                        onChange={(value) => handleChangeSelect("well_orientation", value)}
                      >
                        <Option value="vertical">Vertical</Option>
                        <Option value="deviated">Deviated</Option>
                        <Option value="horizontal">Horizontal</Option>
                      </Select>
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well reference</label>
                      {/* <input type="text" name="well_reference" value={inputWellInformation.well_reference} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.well_reference === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                      <Select
                        style={{ backgroundColor: inputEmpty && inputWellInformation.well_reference === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_reference === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                        className="w-full max-w-[592px] h-[47px] text-left mb-[15px]"
                        name="well_reference"
                        value={inputWellInformation.well_reference || "Selected option"}
                        onChange={(value) => handleChangeSelect("well_reference", value)}
                      >
                        <Option value="onshore">Onshore</Option>
                        <Option value="offshore">Offshore</Option>

                      </Select>
                      <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Fluid type</label>
                      {/* <input type="text" name="fluid_type" value={inputWellInformation.fluid_type} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.fluid_type === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                      <Select
                        style={{ backgroundColor: inputEmpty && inputWellInformation.fluid_type === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.fluid_type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                        className="w-full max-w-[592px] h-[47px] text-start mb-[15px]"
                        name="fluid_type"
                        value={inputWellInformation.fluid_type || "Selected option"}
                        onChange={(value) => handleChangeSelect("fluid_type", value)}
                      >
                        <Option value="black_oil">Oil and Water</Option>
                        <Option value="dry_gas">Dry and Wet Gas</Option>
                        <Option value="volatile_oil" disabled>Volatile Oil</Option>
                        <Option value="condensate" disabled>Condensatee</Option>
                      </Select>
                    </div>
                  </div>
                </>
              }
              {current === 1 &&
                <>
                  <div className="flex w-full h-[24px] justify-center text-center items-center mb-[10px] mt-[15px]">
                    <div className="flex flex-row w-[1335px] h-[24px] gap-5 justify-start text-center items-center ">
                      <h1 className="flex flex-row font-bold text-[20px]">Well orientation ({wellOrientation})</h1>
                      <ImportFile uploadfile={handleUploadFile} tableType="well_deviation"></ImportFile>
                    </div>
                  </div>
                  <div className="flex flex-row w-full min-h-[710px] min-w-[1550px] item-center justify-center  overflow-x-auto">
                    <ul className="flex flex-col">
                      <div className="flex w-[1300px] gap-[26px] mb-[7px] ml-[50px] mr-[20px]">
                        <label className="w-full max-w-[392px] text-[15px] item-start justify-start text-left">Measured depth (ft)
                          <Tooltip title={'Value greater than 0 and less of 50000'}>
                            <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                          </Tooltip>
                        </label>
                        <label className="w-full max-w-[392px] text-[15px] item-start justify-start text-left">True vertical depth (ft)
                          <Tooltip
                            title={
                              <ul className="pl-4 list-disc">
                                <li>
                                  Value greater than 0 and less than 50000.
                                </li>
                                <li>
                                  Value less than or equal to Measured depth (ft).
                                </li>
                              </ul>
                            }>
                            <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                          </Tooltip>
                        </label>
                        <label className="w-full max-w-[392px] text-[15px] item-start justify-start text-left">
                          Inclination (°)
                          <Tooltip title={'Value greater than -25 and less than 125'}>
                            <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                          </Tooltip>
                        </label>
                      </div>
                      {dataDeviation.map((item, index) => (
                        <li
                          key={item.id + "deviation" + index}
                          className="flex flex-row w-full overflow-y-auto"
                        >
                          <div className="flex w-full gap-[26px] mb-[10px]">
                            {/* <IconArrowsVertical className="flex w-6 h-[47px] fill-neutral-500 " /> */}
                            <label htmlFor="" className="flex w-[20px] font-bold text-lg text-center items-center justify-center ">{index + 1}</label>
                            <input
                              type="number"
                              min={0}
                              max={50000}
                              onKeyDown={handleKeyDown}
                              style={{ WebkitAppearance: 'none' }}
                              defaultValue={item.measured_depth}
                              name={`measured_depth`}
                              value={item.measured_depth}
                              onChange={(e) => handleChangeDeviation(e, item.id)}
                              onBlur={(e) => handleBlurDeviation(e, item.id)}
                              className={`w-full max-w-[392px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${validationTable.tableDeviation && (item.measured_depth === null || item.measured_depth === "") ? " border-red-700 bg-yellow-100"
                                : "border-[#BCBCCC]"}`} />
                            <input
                              type="number"
                              min={0}
                              max={50000}
                              onKeyDown={handleKeyDown}
                              style={{ WebkitAppearance: 'none' }}
                              defaultValue={item.tvd}
                              name={`tvd`}
                              //value={wellOrientation === "vertical" ? item.measured_depth : dataDeviation.tvd} 
                              value={item.tvd}
                              onChange={(e) => handleChangeDeviation(e, item.id)}
                              onBlur={(e) => handleBlurDeviation(e, item.id)}
                              className={`w-full max-w-[392px] h-[47px] rounded-[4px] border border-solid border-[#BCBCCC] px-3  ${validationTable.tableDeviation && (item.tvd === null || item.tvd === "" || item.tvd > item.measured_depth) ? " border-red-700 bg-yellow-100"
                                : "border-[#BCBCCC]"}`}
                              disabled={item.measured_depth === null || item.measured_depth === ""}
                            />
                            <input type="number" min={-25} max={125}
                              onKeyDown={handleKeyDown} style={{ WebkitAppearance: 'none' }}
                              defaultValue={item.inc}
                              name={`inc`}
                              value={wellOrientation === "vertical" ? null : item.inc}
                              //disabled={wellOrientation === "vertical"} 
                              onChange={(e) => handleChangeDeviation(e, item.id)}
                              className={`w-full max-w-[392px] h-[47px] border border-solid px-3 rounded-sm ${wellOrientation !== "vertical" && validationTable.tableDeviation && (item.inc === null || item.inc === "") ? " border-red-700 bg-yellow-100"
                                : "border-[#BCBCCC]"}`} />

                            {(dataDeviation.length - 1 === index) &&
                              <IconAdd className="w-6 h-[47px] fill-neutral-500 " onClick={() => handleAddDeviation()} />
                            }
                            {/* {(wellOrientation === "vertical" && dataDeviation.length <= 1 ) &&
                          <IconAdd className="w-6 h-[47px] fill-neutral-500 " disabled="true" onClick={() => handleAddDeviation()} />
                        } */}
                            {dataDeviation.length >= 4 &&
                              <IconDelete className="w-6 h-[47px] fill-neutral-500 " onClick={() => handleDeleteDeviation(item.id)} />
                            }
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              }
              {current === 2 &&
                <>
                  <div className="flex w-full h-[24px] justify-center text-center items-center mb-[10px] mt-[15px]">
                    <div className="flex flex-row w-full h-[24px] pl-[85px] gap-5 justify-start text-center items-center ">
                      <h1 className="flex flex-row font-bold text-[20px]">Well equipment</h1>
                      <ImportFile uploadfile={handleUploadFile2} tableType="well_equipment"></ImportFile>
                    </div>
                  </div>
                  <div className="flex w-full h-[24px] justify-center text-center items-center mb-[10px]">
                    <div className="flex flex-row w-full h-[24px] pl-[85px] gap-5 justify-start text-center items-center ">
                      <h1 className="flex flex-row font-bold text-[13px]">Well: {inputWellInformation.well_id}</h1>
                    </div>
                  </div>
                  <div className="flex flex-row w-full min-h-[710px] min-w-[1800px] overflow-x-auto">
                    <div className="flex flex-row justify-center w-5/6 h-full item-center">
                      <ul className="flex flex-col w-full pl-[20px]">
                        <div className="flex w-full gap-[25px] mb-[7px] pl-[70px] pr-[40px]">
                          <label className="flex w-full max-w-[280px] text-[15px] item-center justify-start text-left items-center">Name</label>
                          <label className="flex w-full max-w-[177px] text-[15px] item-start justify-start text-left items-center">Type</label>
                          <div className='flex flex-row w-full max-w-[177px]'>
                            <label className="w-full max-w-[90%] text-[15px] item-start justify-start text-left">Length in contact with fluid flow MD (ft)</label>
                            <Tooltip title={
                              <ul className="pl-4 list-disc">
                                <li>
                                  The value can't be less than 0 and greater than 50000
                                </li>
                                <li>
                                  The value must be less than the sum of MD Length from the well deviation table
                                </li>
                              </ul>
                            }
                              className='max-w-[10%]'>
                              <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Tooltip>
                          </div>
                          <div className='flex flex-row w-full max-w-[177px]'>
                            <label className="w-full max-w-[90%] text-[15px] item-start justify-start text-left">External diameter (in)</label>
                            <Tooltip title={
                              <ul className="pl-4 list-disc">
                                <li>
                                  The value can't be less than 0 and greater than 36
                                </li>
                                <li>
                                  Value can't be less than Internal diameter
                                </li>
                              </ul>
                            }
                              className='max-w-[10%]'>
                              <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Tooltip>
                          </div>
                          <div className='flex flex-row w-full max-w-[177px]'>
                            <label className="w-full max-w-[90%] text-[15px] item-start justify-start text-left">Internal diameter (in)</label>
                            <Tooltip title={
                              <ul className="pl-4 list-disc">
                                <li>
                                  The value can't be less than 0 and greater than 36
                                </li>
                                <li>
                                  Value can't be greater than external diameter
                                </li>
                              </ul>
                            }
                              className='max-w-[10%]'>
                              <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Tooltip>
                          </div>
                          <div className='flex flex-row w-full max-w-[177px]'>
                            <label className="flex w-full max-w-[90%] text-[15px] item-start justify-start text-left ">Absolute roughness (in)</label>
                            <Tooltip title={`Value can't be less than 0 and greater than 0.1`} className='max-w-[10%]'>
                              <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Tooltip>
                          </div>
                        </div>
                        {dataEquipment.map((item, index) => (
                          <li
                            key={item.id + "equipment" + index}
                            className="flex flex-row w-full overflow-y-auto"
                          >
                            <div className={`flex w-full gap-[26px] mb-[10px] pl-[30px]`}>
                              {/* <IconArrowsVertical className="w-6 h-[47px] fill-neutral-500 " /> */}
                              <label
                                min="0"
                                htmlFor=""
                                className="flex w-[10px] font-bold text-lg text-center items-center justify-center ">
                                {index + 1}
                              </label>
                              <input
                                type="text"
                                name="name"
                                value={item.name}
                                onChange={(e) => handleChangeEquipment(e, item.id)}
                                className="w-full max-w-[280px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3"
                              />
                              <Select
                                style={{ backgroundColor: inputEmpty && dataEquipment.type === '' ? '#FEF9C3' : '', border: inputEmpty && dataEquipment.type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                                className="w-[12%] max-w-[12%] h-[47px] text-left"
                                name="type"
                                value={item.type}
                                onChange={(value) => handleChangeSelectCurrent2('type', value, item.id)}
                              >
                                <Option value="tubing">Tubing</Option>
                                <Option value="casing">Casing</Option>
                              </Select>
                              <input
                                type="number"
                                min="0"
                                onKeyDown={handleKeyDown}
                                name="measured_depth"
                                value={item.measured_depth}
                                onChange={(e) => handleChangeEquipment(e, item.id)}
                                onBlur={(e) => handleBlurEquipment(e, item.id)}
                                className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 
                              ${validationTable.tableEquipment && (item.measured_depth === null || item.measured_depth === "")
                                    ? " border-red-700 bg-yellow-100"
                                    : "border-[#BCBCCC]"}`} />
                              <input
                                type="number"
                                min="0"
                                max="36"
                                onKeyDown={handleKeyDown}
                                name="external_diameter"
                                value={item.external_diameter}
                                onChange={(e) => handleChangeEquipment(e, item.id)}
                                onBlur={(e) => handleBlurEquipment(e, item.id)}
                                className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 
                              ${validationTable.tableEquipment && (item.external_diameter < item.internal_diameter || (item.external_diameter === null || item.external_diameter === ""))
                                    ? " border-red-700 bg-yellow-100"
                                    : "border-[#BCBCCC]"}`} />
                              <input
                                type="number"
                                min="0"
                                max={item.external_diameter}
                                onKeyDown={handleKeyDown}
                                name="internal_diameter"
                                disabled={item.external_diameter === "" || item.external_diameter === null || item.external_diameter > 36}
                                value={item.internal_diameter}
                                onChange={(e) => handleChangeEquipment(e, item.id)}
                                onBlur={(e) => handleBlurEquipment(e, item.id)}
                                className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${validationTable.tableEquipment && (item.internal_diameter > item.external_diameter || (item.internal_diameter === null || item.internal_diameter === "")) ? " border-red-700 bg-yellow-100"
                                  : "border-[#BCBCCC]"}`} />
                              <input
                                type="number"
                                min="0"
                                max="1"
                                onKeyDown={handleKeyDown}
                                name="absolute_roughness"
                                value={item.absolute_roughness}
                                onChange={(e) => handleChangeEquipment(e, item.id)}
                                className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${validationTable.tableEquipment && (item.absolute_roughness === null || item.absolute_roughness === "") ? " border-red-700 bg-yellow-100"
                                  : "border-[#BCBCCC]"}`} />
                              {dataEquipment.length - 1 === index &&
                                <IconAdd className="w-6 h-[47px] fill-neutral-500 " onClick={handleAddEquipment} />
                              }
                              {dataEquipment.length >= 2 &&

                                <IconDelete className="w-6 h-[47px] fill-neutral-500 " onClick={() => handleDeleteEquipment(item.id)} />
                              }

                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="flex w-1/6 h-full justify-center items-center text-end mt-[300px]">
                      <img
                        src={Img_Wellhead}
                        className="w-[219px] h-[650px] lg:absolute"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </>
              }
              <div className="flex w-full text-center justify-center items-center mb-[90px]">
                <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                  {current > 0 && (
                    <button
                      className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                      onClick={() => handleClickPrev()}
                    >
                      Previous
                    </button>
                  )}
                  {current === steps.length - 1 && (
                    <button
                      className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                      onClick={() => handleClickDone()}
                    >
                      Done
                    </button>
                  )}
                  {current < steps.length - 1 && (
                    <button
                      className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                      onClick={() => handleClickNext()}
                    >
                      Continue
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};
