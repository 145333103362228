import { Modal, Select, Steps, Switch, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { utils, writeFile } from 'xlsx';
import { ReactComponent as IconCheckFile } from "../../assets/icon/check-circle.svg";
import { ReactComponent as IconCircle } from "../../assets/icon/circle.svg";
import { ReactComponent as IconTableForecast } from "../../assets/img/icons/tableDCA.svg";
import { AlertModal } from "../../components/AlertModal";
import { ChartProduction } from "../../components/DCA/GraphicsForecasting/ChartProduction";
import { ChartResults } from "../../components/DCA/GraphicsForecasting/ChartResults";
import { ModalTable } from "../../components/DCA/complements/ModalTable";
import { TableForecast } from "../../components/DCA/complements/TableForecast";
import { Loading, LoadingForecast, LoadingGrapchicForecast } from "../../components/Loading";
import {
  ALL_TABLES_FORECAST,
  ALL_TABLES_HISTORY,
  DATA_CREATE_WELL_DCA,
  INITIAL_COORDINATES,
  MODELS
} from "../../components/elements/types/type_dca";
import { STATUS_CODE_DCA_TABLES, STATUS_DATA_HISTORY } from "../../components/elements/types/type_response_API";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardDCAContext } from "../../context/DasboardDCA/DashboardDCAContext";
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { useTabDCA } from "../../hooks/useTabDCA";
import { useTabIOT } from "../../hooks/useTabIOT";
import { useUsers } from "../../hooks/useUsers";
import ChartForecasting from "../../components/DCA/GraphicsForecasting/ChartForecasting";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ImportFileHistory } from "../../components/DCA/complements/ImportFileHistory";

const steps = [
  {
    title: "Well",
    content: "First-content",
  },
  {
    title: "Second",
    content: "Second-content",
  },
  {
    title: "Third",
    content: "Third-content",
  },
  {
    title: "Last",
    content: "Last-content",
  },
  {
    title: "fifth",
    content: "fifth-content",
  },
];

export const StepMenuAddWellDCA = () => {
  const { Option } = Select;
  const { checkedValuesLastCol, getCheckedValuesFirstCol, getCheckedValuesLastCol } = useContext(DashboardDCAContext);
  const { getSelectedWellDCA, deviceRealTime, getDeviceRealTime } = useContext(DashboardContext);
  const {
    userDataInfo,
    mutationUpdateJsonWellDCA,
    mutationUploadJsonWellDCAParcial,
    mutationUploadJsonWellDCA,
    mutationUploadDataHistory,
    mutationUpdateDataHistory,
    mutationUploadForecast,
    mutationPostForecast,
    mutationPostForecastSemi,
    mutationPostForecastFull,
    dataFilterUserDCA,
    listWellDCA,
    dataForecast,
    dataHistoryDCA,
    confirmUploadFile,
    refetchListWellDCA,
    refetchDataForecast,
    refetchDataHistoryDCA,
    refetchConfirmUploadFile,
    generateTable,
    setGenerateTable,
    getDataFilterUserDCA,
    generateRandomID,
    setTypeTable,
    extractTitle
  } = useTabDCA();
  const {
    dataListPlatforms,
    refetchDataListPlatforms,
    dataListPlatformsWells,
    refetchDataListPlatformsWells,
    setTypeProcessPlatform
  } = useTabIOT();
  const { User } = useUsers();
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState(false);
  const [activeModal, setActiveModal] = useState(MODELS);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [coordinate, setCoordinate] = useState(INITIAL_COORDINATES);
  const [tables, setTables] = useState([]);
  const [bestScenario, setBestScenario] = useState("");
  const [inputDataDCA, setInputDataDCA] = useState(DATA_CREATE_WELL_DCA);
  const [dataTableDCAResult, setDataTableDCAResult] = useState([]);
  const [countForecast, setCountForecast] = useState(0);
  const totalPeriods = Object.values(ALL_TABLES_HISTORY).reduce((total, sensitivity) => total + sensitivity.length, 0);

  const flagUploadFile = confirmUploadFile && dataHistoryDCA.statusCode !== STATUS_DATA_HISTORY.STATUSCODE ? confirmUploadFile.data : false;
  const mutateLoadFile = mutationUploadForecast.isLoading || mutationUploadJsonWellDCA.isLoading || mutationUploadDataHistory.isLoading
  const mutateLoadRun = mutationPostForecast.isLoading;
  const mutateLoadRunSemi = mutationPostForecastSemi.isLoading;
  const mutateLoadRunFull = mutationPostForecastFull.isLoading;
  const { sensibility, period } = extractTitle(bestScenario);
  const dataListWellDCA = listWellDCA != null ? listWellDCA?.data : null;

  useEffect(() => {
    refetchDataListPlatforms();
    refetchListWellDCA();
    setInputDataDCA((prevState) => ({
      ...prevState,
      region: userDataInfo.region,
    }));
    getCheckedValuesFirstCol('');
    getDeviceRealTime({
      ...deviceRealTime,
      device_dca: {
        name: "",
        platform: "",
        discretized: false
      }
    });
  }, []);

  useEffect(() => {
    if (generateTable) {
      function getValues() {
        const values = [];
        Object.keys(ALL_TABLES_HISTORY).forEach(sensitivity => {
          const sensitivityData = ALL_TABLES_HISTORY[sensitivity];
          sensitivityData.forEach(item => {
            Object.values(item).forEach(value => {
              values.push(value);
            });
          });
        });
        return values;
      }

      function getValuesForecast() {
        const values = [];
        switch (inputDataDCA.customScenario) {
          case "semi_custom":
            ALL_TABLES_FORECAST?.semiCustom.forEach(item => {
              Object.values(item).forEach(value => {
                values.push(value);
              })
            })
            break;
          case "full_custom":
            ALL_TABLES_FORECAST?.fullCustom.forEach(item => {
              Object.values(item).forEach(value => {
                values.push(value);
              })
            })
            break;
          default:
            break;
        }
        return values
      }
      const tableQuery = inputDataDCA.customScenario === "default" || current === 3 ? getValues() : getValuesForecast();
      
      const handleRefetchTable = async () => {
        for (let i = 0; i < tableQuery.length; i++) {
          setTypeTable(tableQuery[i]);
          await new Promise(resolve => setTimeout(resolve, 600));
          await refetchDataForecast();
          if (i === (tableQuery.length -1)){
            setGenerateTable(false);
          }
        }
      };
      handleRefetchTable();
    }
  }, [generateTable]);

  useEffect(() => {
    if (dataForecast) {
      if (dataForecast.statusCode === STATUS_CODE_DCA_TABLES.SUCCESS && current === 3) {
        const elementsData = dataForecast?.data;
        const tableMessage = dataForecast?.statusMessage;
        const parts = tableMessage.split(": ");
        const tableTypeSearch = parts[parts.length - 1];
        const partsData = tableTypeSearch.split(',');
        const sensitivity = partsData[0].match(/\d+/)?.[0];
        const period = partsData[1].match(/\d+/)?.[0];
        const endElements = elementsData[elementsData.length -1]
        if(dataTableDCAResult){
          setDataTableDCAResult(prevData => {
            const newData = {
              sensitivity,
              period,
              initial_rate: endElements.qi_adj,
              b_parameter: endElements.b_adj,
              di_parameter: endElements.di_adj
            };
            const exists = prevData.some(item => item.sensitivity === newData.sensitivity && item.period === newData.period);
            return exists ? prevData : [...prevData, newData];
          });
        }
      }
    }
  }, [dataForecast]);

  useEffect(() => {
    setInputDataDCA((prevState) => ({
      ...prevState,
      latitude: `${coordinate.latitudeDegrees}${"°"}${coordinate.latitudeMinutes
        }${"'"}${coordinate.latitudeSeconds}${'"'}${coordinate.latitudeDirection
        }`,
    }));

    setInputDataDCA((prevState) => ({
      ...prevState,
      longitude: `${coordinate.longitudeDegrees}${"°"}${coordinate.longitudeMinutes
        }${"'"}${coordinate.longitudeSeconds}${'"'}${coordinate.longitudeDirection
        }`,
    }));
  }, [coordinate]);

  useEffect(() => {
    if(current === 3 && dataTableDCAResult.length === 0){
      setGenerateTable(true);
    }
  }, [current]);

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const onChangeStep = (value) => {
    setCurrent(value);
  };

  const handleClickNext = () => {
    switch (current) {
      case 0:
        if (validationForCurrent(current)) {
          getDataFilterUserDCA({
            ...dataFilterUserDCA,
            county: inputDataDCA.county,
            field: inputDataDCA.field,
            well_id: inputDataDCA.well_id,
            well_platforms: inputDataDCA.well_platforms,
            well_name: inputDataDCA.well_name,
          });
          getSelectedWellDCA(inputDataDCA.well_id);

          mutationUploadJsonWellDCA.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            county: inputDataDCA.county,
            field: inputDataDCA.field,
            wellid: inputDataDCA.well_id,
            userName: User.user_name,
            data: inputDataDCA,
          });
          setInputEmpty(false);
          setCurrent(current + 1);
        } else {
          showWarning("Please, complete the missing information in the mandatory fields");
          handleStatusChange();
        }
        break;
      case 1:
        if (validationForCurrent(current)) {
          setInputEmpty(false);
          setCurrent(current + 1);
          refetchConfirmUploadFile();
        } else {
          showWarning("Please, complete the missing information in the mandatory fields");
          handleStatusChange();
        }
        break;
      case 2:
        if (validationForCurrent(current)) {
          mutationPostForecast.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            wellid: dataFilterUserDCA.well_id,
            userName: User.user_name,
            forcastHorizont: inputDataDCA?.forecastHorizont
          });
          setInputEmpty(false);
          setCurrent(current + 1);
          setTypeTable([]);
        } else {
          showWarning("Please, complete the missing information in the mandatory fields");
          handleStatusChange();
        }
        break;
      case 3:
        if (validationForCurrent(current)) {
          setInputEmpty(false);
          setCurrent(current + 1);
        }else{
          showWarning(dataTableDCAResult.length < totalPeriods ? "Please wait for the table to load" : "Please select the best scenario");
          handleStatusChange();
        }
        break;
      default:
        break;
    }
  };

  const handleClickPrev = () => {
    setCurrent(current - 1);
  };

  const handleClickDone = () => {

    const validationForStep = {
      step0: (validationForCurrent(0)),
      step1: (validationForCurrent(1)),
      step2: (validationForCurrent(2)),
      step3: (validationForCurrent(3)),
      step4: (validationForCurrent(4)),
    };
    const messageNullEmpty = "Please, complete the missing information in the mandatory fields";

    if (!validationForStep.step0) {
      showWarning(messageNullEmpty, 0);
    } else if (!validationForStep.step1) {
      showWarning(messageNullEmpty, 1);
    } else if (!validationForStep.step2) {
      showWarning(messageNullEmpty, 2);
    } else if (!validationForStep.step3) {
      showWarning(messageNullEmpty, 3);
    } else if (validationForStep.step4) {
      showWarning(messageNullEmpty, 4);
    } else {
      mutationUpdateJsonWellDCA.mutate({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: dataFilterUserDCA.county,
        field: dataFilterUserDCA.field,
        wellid: dataFilterUserDCA.well_id,
        userName: User.user_name,
        data: inputDataDCA,
      });
      getCheckedValuesFirstCol(false);
      getCheckedValuesLastCol(false);
    }
  };

  const handleClickParcialDone = () => {
    // Validamos en cuál current estamos
    if (!validationForCurrent(0)) {
      showWarning("Please, complete the missing information in the mandatory fields", 0);
      return;
    }

    // Si estamos en el current 0, hacemos el upload
    if (current === 0) {
      mutationUploadJsonWellDCAParcial.mutate({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: inputDataDCA.county,
        field: inputDataDCA.field,
        wellid: inputDataDCA.well_id,
        userName: User.user_name,
        data: inputDataDCA,
      });
      getCheckedValuesFirstCol(false);
      return;
    }
    // Si estamos en el current 3, validamos que 0, 1 y 2 estén bien y permitimos guardar
    if (current === 3) {
      if (validationForCurrent(0) && validationForCurrent(1) && validationForCurrent(2)) {
        mutationUpdateJsonWellDCA.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserDCA.county,
          field: dataFilterUserDCA.field,
          wellid: dataFilterUserDCA.well_id,
          userName: User.user_name,
          data: inputDataDCA,
        });
        getCheckedValuesLastCol(false);
        return;
      } else {
        showWarning("Please, complete the missing information in the mandatory fields for steps 0, 1, and 2", 3);
        return;
      }
    }

    // Si estamos en current > 0, validamos todos los pasos previos
    for (let i = 1; i <= current; i++) {
      if (!validationForCurrent(i)) {
        showWarning("Please, complete the missing information in the mandatory fields", i);
        return;
      }
    }


    // Realizamos el update para los pasos posteriores a 0
    mutationUpdateJsonWellDCA.mutate({
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      county: dataFilterUserDCA.county,
      field: dataFilterUserDCA.field,
      wellid: dataFilterUserDCA.well_id,
      userName: User.user_name,
      data: inputDataDCA,
    });
    getCheckedValuesLastCol(false);
  };

  const handleChangeInputData = (e) => {
    const { name, value } = e.target;
    if (name === "well_platforms") {
      getDeviceRealTime({
        ...deviceRealTime,
        device_dca: {
          ...deviceRealTime.device_dca,
          platform: value
        }
      })
      setTypeProcessPlatform(value);
      setTimeout(() => {
        refetchDataListPlatformsWells();
      }, 500);
    }
    else if (name === "discretized_well") {
      getDeviceRealTime({
        ...deviceRealTime,
        device_dca: {
          ...deviceRealTime.device_dca,
          discretized: value
        }
      })
    }

    let dataValue = value !== "" ? Number(value) : null;
    const wellIdExists =
      name === "well_id"
        ? dataListWellDCA?.some((obj) => obj.well_id === value)
        : null;

    const wellNameExists =
      name === "well_name"
        ? dataListWellDCA?.some((obj) => obj.well_name === value)
        : null;

    if (wellIdExists) {
      message.error(
        "There is already a well with the same ID, please generate a new ID"
      );
      setInputDataDCA((prevState) => ({
        ...prevState,
        well_id: null,
      }));
    }
    else if (wellNameExists) {
      message.error(
        "There is already a well with the same Name, please select a new name"
      );
      setInputDataDCA((prevState) => ({
        ...prevState,
        well_name: null,
      }));
    }
    else if (
      name === "forecastTime" &&
      dataValue <= 0) {
      message.error("The value needs to be greater than 0");
      setInputDataDCA((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    } else {
      setInputDataDCA((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleChangeSelect = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInputData(customEvent);
  };

  const handleChangeSelectWellData = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInputData(customEvent);
  };

  const handleClickGenerateID = () => {
    const idGenerated = generateRandomID();
    setInputDataDCA({ ...inputDataDCA, well_id: idGenerated });
  };

  const handleChangeCoor = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (
      name === "latitudeDegrees" ||
      name === "latitudeMinutes" ||
      name === "longitudeDegrees" ||
      name === "longitudeMinutes"
    ) {
      newValue = value.replace(/[^\d]/g, "");
    } else if (name === "latitudeSeconds" || name === "longitudeSeconds") {
      newValue = value.replace(/[^\d.]/g, "");
      if (value.length === 3 && value.indexOf(".") === -1) {
        newValue = value.slice(0, 2) + "." + value.slice(2);
      }
      const decimalIndex = newValue.indexOf(".");
      if (decimalIndex !== -1) {
        const integerPart = newValue.slice(0, decimalIndex);
        const decimalPart = newValue.slice(decimalIndex + 1, decimalIndex + 3);
        newValue = `${integerPart}.${decimalPart}`;
        if (value.indexOf(".") !== -1 && decimalPart === '') {
          newValue = value.slice(0, -1);
        }
      }
    }

    setCoordinate((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  const handleChangeSelectCoor = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeCoor(customEvent);
  };

  const handleKeyDown = (event) => {
    if (event.key === "-" || event.key === '/') {
      event.preventDefault();
    }
  };

  const convertDate = (fechaNumerica) => {
    const fecha = new Date((fechaNumerica - (25567 + 1)) * 86400 * 1000);
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const anio = fecha.getFullYear();
    return `${mes}/${dia}/${anio}`;
  };

  const handleUploadFile = (dataFile, file) => {
    const dataFilePre = dataFile.map((obj, index) => {
      return { id: index + 1, ...obj };
    });
    const dataFileEnd = dataFilePre.map((item) => ({
      ...item,
      //date: convertDate(item.date) 
    }))
    if (dataFileEnd?.length > 0 && file) {
      setTimeout(() => {
        mutationUploadDataHistory.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserDCA.county,
          field: dataFilterUserDCA.field,
          wellid: dataFilterUserDCA.well_id,
          userName: User.user_name,
          data: dataFileEnd,
        });
      }, 1000);
      const formData = new FormData();
      formData.append('upload', file);
      setTimeout(() => {
        mutationUploadForecast.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          wellid: dataFilterUserDCA.well_id,
          userName: User.user_name,
          data: formData,
        });
      }, 1000);
      setTimeout(() => {
        refetchDataHistoryDCA();
      }, 2000);
    }
  };

  const handleCLickModalTable = (name) => {

    if (activeModal.tableForecast || activeModal.periodSelection) {
      switch (name) {
        case "tableForecast":
          setActiveModal({ ...activeModal, "tableForecast": false })
          break;
        case "periodSelection":
          setActiveModal({ ...activeModal, "periodSelection": false })
          break;
        default:
          break;
      }
    } else {
      switch (name) {
        case "tableForecast":
          setActiveModal({ ...activeModal, "tableForecast": true })
          break;
        case "periodSelection":
          setActiveModal({ ...activeModal, "periodSelection": true })
          setCountForecast(countForecast + 1)
          break;
        default:
          break;
      }
    }
  }

  const handleCheckChange = (checkedCheckboxes) => {
    // Build the names of the  tables based in the checkboxes selected 
    const newTables = checkedCheckboxes.map(checkbox =>
      `df_cp_hist_${checkbox.sensitivity}_${checkbox.period}`
    );
    // Update state of the tables
    setTables(newTables);
  };

  const handleCheckChangeLast = (checkedCheck) => {
    const newTables = `df_cp_forecast_${checkedCheck}`;
    setBestScenario(newTables);
  };

  const handleSwitch = (enabled) => {
    const customEvent = {
      target: {
        name: "discretized_well",
        value: enabled,
      },
    };
    handleChangeInputData(customEvent);
  }

  const handleClickRun = () => {

    const sensitivity = sensibility !== "N/A" ? sensibility : inputDataDCA?.startMonth
    const periodSelected = period !== 'N/A' ? period : inputDataDCA?.initialFluidRate

    if (current === 4) {
      if(inputDataDCA.startMonth && inputDataDCA.initialFluidRate && inputDataDCA.forecastHorizont){
        switch (inputDataDCA.customScenario) {
          case "default":
            setDataTableDCAResult([])
            setTimeout(() => {
              mutationPostForecast.mutate({
                operator: userDataInfo.operator,
                lease: userDataInfo.lease,
                region: userDataInfo.region,
                wellid: dataFilterUserDCA.well_id,
                userName: User.user_name,
                forcastHorizont: inputDataDCA?.forecastHorizont
              });
            }, 500);
          break;
          case "semi_custom":
            setDataTableDCAResult([])
            async function executeFunction() {
              await sequentialMutationForecast("semi_custom", sensitivity, periodSelected);
            }
            executeFunction()
          break;
          case "full_custom":
            setDataTableDCAResult([])
            async function executeFunctionForecastFull() {
              await sequentialMutationForecast("full_custom", sensitivity, periodSelected );
            }
            executeFunctionForecastFull()
          break;
          default:
            break;
        }

      }
      else{
        showWarning("There are empty fields please validate that the information is complete",4);
      }
    } else {
      setTimeout(() => {
        mutationPostForecast.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          wellid: dataFilterUserDCA.well_id,
          userName: User.user_name,
          forcastHorizont: inputDataDCA?.forecastHorizont
        });
      }, 500);
    }
  }

  const sequentialMutationForecast = async (name, sensitivity, periodSelected) => {
    await mutationPostForecast.mutateAsync({
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      wellid: dataFilterUserDCA.well_id,
      userName: User.user_name,
      forcastHorizont: inputDataDCA?.forecastHorizont
    });
    if (name === "semi_custom") {
      await mutationPostForecastSemi.mutateAsync({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        wellid: dataFilterUserDCA.well_id,
        forcastHorizont: inputDataDCA?.forecastHorizont,
        startForecast: inputDataDCA?.startMonth,
        qi_custom: inputDataDCA?.initialFluidRate,
        sensitivity: inputDataDCA?.startMonth,
        period: inputDataDCA?.initialFluidRate,
        userName: User.user_name,
      });
    } else {
      await mutationPostForecastFull.mutateAsync({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        wellid: dataFilterUserDCA.well_id,
        forcastHorizont: inputDataDCA?.forecastHorizont,
        start_select: inputDataDCA?.start_select,
        end_select: inputDataDCA?.end_select,
        startForecast: inputDataDCA?.startMonth,
        qi_custom: inputDataDCA?.initialFluidRate,
        userName: User.user_name,
      });
    }
  }

  if (mutateLoadRun || mutateLoadRunSemi || mutateLoadRunFull) {
    return (<LoadingForecast />)
  }

  const downloadTable = () => {
    let nameFile = "";
    let data = [];
    nameFile = "Table_forecast";
    data = !!dataForecast ? dataForecast.data : [];

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, 'Sheet1');
    writeFile(wb, `${nameFile}.xlsx`)
  }

  const downloadTableForecast = () => {
    let nameFile = "";
    let data = [];
    nameFile = "Table_forecast";
    data = !!dataForecast ? dataForecast.data : [];

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, 'Sheet1');
    writeFile(wb, `${nameFile}.xlsx`)
  }

  const handleClickMonth = (value) => {
    setInputDataDCA({ ...inputDataDCA, start_select: value.initialMonth, end_select: value.endMonth })
  }

  const getDisabledStatus = (condition) => (condition ? false : { status })

  function showWarning(message, currentStep) {
    handleOpenModal();
    setTypeAlert("Warning");
    setMessageAlert(message);
    setInputEmpty(true);
    if (currentStep !== undefined) setCurrent(currentStep);
  };

  function validationForCurrent(current) {
    let validate = false;
    switch (current) {
      case 0:
        validate = (
          !!inputDataDCA.well_id &&
          !!inputDataDCA.well_name &&
          !!inputDataDCA.well_platforms &&
          !!inputDataDCA.fluid_type &&
          !!inputDataDCA.region &&
          !!inputDataDCA.county &&
          !!inputDataDCA.field &&
          !!coordinate.latitudeDegrees &&
          !!coordinate.latitudeMinutes &&
          !!coordinate.latitudeSeconds &&
          !!coordinate.latitudeDirection &&
          !!coordinate.longitudeDegrees &&
          !!coordinate.longitudeMinutes &&
          !!coordinate.longitudeSeconds &&
          !!coordinate.longitudeDirection
        );
        break;
      case 1:
        validate = (
          !!inputDataDCA.productForescast &&
          !!inputDataDCA.iprModel &&
          !!flagUploadFile
        );
        break;
      case 2:
        validate = (
          !!inputDataDCA.generateForeOil &&
          !!inputDataDCA.reporting_frequency
        );
        break;
      case 3:
        validate = (
          Object.values(checkedValuesLastCol).some(value => !!value) &&
          dataTableDCAResult.length >= (totalPeriods - 1)
        );
        break;
      case 4:
        validate = (
          !!inputDataDCA.customScenario &&
          !!inputDataDCA.startMonth &&
          !!inputDataDCA.initialFluidRate &&
          !!inputDataDCA.forecastHorizont
        )
        break;
      default:
        break;
    }

    setInputEmpty(!validate);
    return validate;
  }

  return (
    <>
      <div className="flex flex-col w-full h-full min-h-screen bg-white">
        <div className="flex">
          <Navbar wellboreOption={wellboreOption}></Navbar>
          <Sidebar className="absolute flex h-full "></Sidebar>
        </div>
        <div className="flex w-full h-full">
          <Modal
            centered
            open={activeModal.tableForecast}
            onOk={() => handleCLickModalTable()}
            onCancel={() => handleCLickModalTable()}
            width={1200}
            className="rounded-md mt-[10px] mb-[10px]"
            okButtonProps={{
              className: "bg-[#662D91]",
            }}>
            <div className='h-full0 mt-[25px]'>
              <TableForecast active={activeModal.tableForecast} forecastSelected={bestScenario}></TableForecast>
            </div>
          </Modal>
        </div>
        <div className="flex w-full h-full">
          <AlertModal
            showAlert={showAlert}
            handleCloseModal={handleCloseModal}
            messageAlert={messageAlert}
            typeModal={typeAlert}
          ></AlertModal>
        </div>
        { (mutateLoadFile) 
        ?
          <Loading/>
        :
        <>
          <div className="flex w-[full] pl-[88px] text-center justify-center mt-[30px]">
            <Steps
              size="default"
              current={current}
              onChange={onChangeStep}
              className="site-navigation-steps flex w-[900px] custom-steps"
              items={[
                {
                  title: "Well definition",
                  disabled:  getDisabledStatus(flagUploadFile),
                  description: "",
                },
                {
                  title: "AI DCA Model ",
                  disabled:  getDisabledStatus(flagUploadFile),
                  description: "",
                },
                {
                  title: "AI DCA Parameters",
                  disabled:  getDisabledStatus(flagUploadFile),
                  description: "",
                },
                {
                  title: "AI DCA Results",
                  disabled: getDisabledStatus(!generateTable),
                  description: "",
                },
                {
                  title: "Forecasting",
                  disabled: getDisabledStatus(Object.values(checkedValuesLastCol).some(value => !!value ) && !generateTable),
                  description: "",
                }
              ]}
            />
          </div>
          <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center ">
            {current === 0 && (
              <>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                  <div className="flex flex-row w-[67%] h-[24px] gap-5">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      {" "}
                      Well definition
                    </h1>
                  </div>
                </div>
                <div className="flex w-full h-[42em] justify-center text-center mb-[0]">
                  <div className="grid grid-cols-2 text-right w-[67%]  justify-start items-center h-[10em]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Well API Number/Well ID
                    </label>
                    <div className="flex flex-row max-w-[592px]  h-[47px] gap-x-2 text-right justify-start items-center mb-[17px]">
                      <input
                        type="text"
                        name="well_id"
                        value={inputDataDCA.well_id}
                        onChange={handleChangeInputData}
                        className={`flex w-2/3 h-[47px] rounded-[4px] px-3  border border-solid  ${
                          (inputEmpty && inputDataDCA.well_id === "") ||
                          inputDataDCA.well_id === null
                            ? " border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                            } `}
                        />
                        <button
                          onClick={handleClickGenerateID}
                          className="flex w-1/3 h-[47px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                        >
                          Generate ID
                        </button>
                      </div>
                      <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Platform/Tank Battery
                        <Tooltip title="This option will become available once real-time data streaming begins">
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                      </label>
                      <Select
                        style={{
                          backgroundColor: inputEmpty && inputDataDCA.well_platforms === "" ? "#B91C1C" : "",
                          border: inputEmpty && inputDataDCA.well_platforms === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="well_platforms"
                        value={inputDataDCA.well_platforms || ""}
                        className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                        onChange={(value) => handleChangeSelect("well_platforms", value)}
                      >
                        <Option value="">Selected option</Option>
                        <Option value="LATER">Select Later</Option>
                        {dataListPlatforms?.data?.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>

                      <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Discretized IoT well
                        <Tooltip title="Enables IoT well discretization.">
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                      </label>
                      <div className="flex w-full max-w-[592px] h-[47px] items-center justify-start mb-[17px]">
                        <Tooltip title={inputDataDCA.discretized_well ? "Disable discretization" : "Enable discretization"} color={"#662D91"}>
                          <Switch
                            style={{
                              backgroundColor: inputDataDCA.discretized_well ? "#662D91" : "#707070",
                              borderColor: inputDataDCA.discretized_well ? "#662D91" : "#707070",
                            }}
                            checked={inputDataDCA.discretized_well}
                            onChange={handleSwitch}
                          ></Switch>
                        </Tooltip>
                      </div>
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Well name
                      </label>
                      {inputDataDCA.discretized_well ?
                        <Select
                          style={{
                            backgroundColor: inputEmpty && inputDataDCA.well_name === "" ? "#B91C1C" : "",
                            border: inputEmpty && inputDataDCA.well_name === "" ? "1px solid #B91C1C" : "",
                            borderRadius: "8px",
                            textAlignalign: "left"
                          }}
                          name="well_name"
                          value={inputDataDCA.well_name || ""}
                          className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                          onChange={(value) => handleChangeSelect("well_name", value)}
                        >
                          <Option value="">Selected option</Option>
                          {dataListPlatformsWells?.data?.map((item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                        :
                        <input
                          type="text"
                          name="well_name"
                          value={inputDataDCA.well_name}
                          onChange={handleChangeInputData}
                          className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid px-3 ${inputEmpty && inputDataDCA.well_name === ""
                            ? " border-red-700 bg-yellow-100"
                            : "border border-solid border-[#BCBCCC]"
                            } `}
                        />
                      }
                      <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Fluid type
                      </label>

                      <Select
                        style={{
                          backgroundColor: inputEmpty && inputDataDCA.fluid_type === "" ? "#B91C1C" : "",
                          border: inputEmpty && inputDataDCA.fluid_type === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="fluid_type"
                        value={inputDataDCA.fluid_type || "Selected option"}
                        className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                        onChange={(value) => handleChangeSelect("fluid_type", value)}
                      >
                        <Option value="oil">Oil Producer</Option>
                        <Option value="gas">Gas Producer</Option>
                        <Option value="injector">Injector</Option>
                      </Select>
                      <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Region
                      </label>
                      <input
                        type="text"
                        name="region"
                        onKeyDown={handleKeyDown}
                        value={inputDataDCA.region}
                        onChange={handleChangeInputData}
                        className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputDataDCA.region === ""
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        County (if not available please add NA)
                      </label>
                      <input
                        type="text"
                        name="county"
                        onKeyDown={handleKeyDown}
                        value={inputDataDCA.county}
                        onChange={handleChangeInputData}
                        className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputDataDCA.county === ""
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Field
                      </label>
                      <input
                        type="text"
                        name="field"
                        onKeyDown={handleKeyDown}
                        value={inputDataDCA.field}
                        onChange={handleChangeInputData}
                        className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputDataDCA.field === ""
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Latitude
                      </label>
                      <div className="grid grid-cols-4 max-w-[592px]  h-[47px] gap-[15px] text-right justify-start items-center mb-[17px] ">
                        <div className="relative">
                          <input
                            placeholder={"29°"}
                            type="text"
                            name="latitudeDegrees"
                            value={coordinate.latitudeDegrees}
                            onChange={handleChangeCoor}
                            maxLength={2}
                            className={`w-full h-[47px] rounded-[4px] px-3 mb-[12px] border border-solid ${inputEmpty && coordinate.latitudeDegrees === ""
                              ? "border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                              } `}
                          />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            °
                          </div>
                        </div>
                        <div className="relative">
                          <input
                            placeholder={"13'"}
                            type="text"
                            name="latitudeMinutes"
                            value={coordinate.latitudeMinutes}
                            onChange={handleChangeCoor}
                            maxLength={2}
                            className={`w-full h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid ${inputEmpty && coordinate.latitudeMinutes === ""
                              ? "border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                              } `}
                          />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            '
                          </div>
                        </div>
                        <div className="relative">
                          <input
                            placeholder={'47.9"'}
                            type="text"
                            name="latitudeSeconds"
                            value={coordinate.latitudeSeconds}
                            onChange={handleChangeCoor}
                            maxLength={5}
                            className={`w-full h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid ${inputEmpty && coordinate.latitudeSeconds === ""
                              ? "border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                              } `}
                          />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            "
                          </div>
                        </div>
                        <Select
                          style={{
                            backgroundColor:
                              inputEmpty && coordinate.latitudeDirection === ""
                                ? "#FEF9C3"
                                : "",
                            border:
                              inputEmpty && coordinate.latitudeDirection === ""
                                ? "1px solid #B91C1C"
                                : "",
                            borderRadius: "8px",
                            textAlignalign: "left",
                          }}
                          name="latitudeDirection"
                          value={coordinate.latitudeDirection || "Direction"}
                          className="w-full max-w-[592px]  h-[47px] text-left mb-[15px]"
                          onChange={(value) =>
                            handleChangeSelectCoor("latitudeDirection", value)
                          }
                        >
                          <Option value="N">N</Option>
                          <Option value="S">S</Option>
                        </Select>
                      </div>
                      <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Longitude
                      </label>
                      <div className="grid grid-cols-4 max-w-[592px]  h-[47px] gap-[15px] text-right justify-start items-center mb-[17px] ">
                        <div className="relative">
                          <input
                            placeholder={"29°"}
                            type="text"
                            name="longitudeDegrees"
                            value={coordinate.longitudeDegrees}
                            onChange={handleChangeCoor}
                            maxLength={3}
                            className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeDegrees === ""
                              ? " border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                              } `}
                          />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3 te" style={{ fontSize: '1.5rem' }}>
                            °
                          </div>
                        </div>
                        <div className="relative">
                          <input
                            placeholder={"13'"}
                            type="text"
                            name="longitudeMinutes"
                            value={coordinate.longitudeMinutes}
                            onChange={handleChangeCoor}
                            maxLength={2}
                            className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeMinutes === ""
                              ? " border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                              } `}
                          />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            '
                          </div>
                        </div>
                        <div className="relative">
                          <input
                            placeholder={'47.9"'}
                            type="text"
                            name="longitudeSeconds"
                            value={coordinate.longitudeSeconds}
                            onChange={handleChangeCoor}
                            maxLength={5}
                            className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeSeconds === ""
                              ? " border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                              } `}
                          />
                          <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                            "
                          </div>
                        </div>
                        <Select
                          style={{
                            backgroundColor:
                              inputEmpty && coordinate.longitudeDirection === ""
                                ? "#FEF9C3"
                                : "",
                            border:
                              inputEmpty && coordinate.longitudeDirection === ""
                                ? "1px solid #B91C1C"
                                : "",
                            borderRadius: "8px",
                            textAlignalign: "left",
                          }}
                          name="longitudeDirection"
                          value={coordinate.longitudeDirection || "Direction"}
                          className="w-full  h-[47px] text-left mb-[15px]"
                          onChange={(value) =>
                            handleChangeSelectCoor("longitudeDirection", value)
                          }
                        >
                          <Option value="W">W</Option>
                          <Option value="E">E</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {current === 1 && (
                <>
                  <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                    <div className="flex flex-row w-[67%] h-[24px] gap-5">
                      <h1 className="flex flex-row font-bold text-[20px]">
                        Model Definition
                      </h1>
                    </div>
                  </div>
                  <div className="flex w-full h-[42em] justify-center text-center  mb-[0]">
                    <div className="grid grid-cols-2 text-right w-[67%] items-center justify-start h-[10em]">
                      <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                        Production forecast model
                      </label>
                      <Select
                        style={{
                          backgroundColor: inputEmpty && inputDataDCA.productForescast === "" ? "#B91C1C" : "",
                          border: inputEmpty && inputDataDCA.productForescast === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="productForescast"
                        value={inputDataDCA.productForescast || "Selected option"}
                        className="min-w-[592px] h-[47px]  max-w-[592px] text-start rounded-[4px] mb-[17px]"
                        onChange={(value) =>
                          handleChangeSelectWellData("productForescast", value)
                        }
                      >
                        <Option value="hybrid_arps">Hybrid Arps</Option>
                        <Option value="physics_based" disabled>Physics based</Option>
                      </Select>

                      <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                        IPR model
                      </label>

                      <Select
                        className="min-w-[592px] h-[47px] max-w-[592px] text-start mb-[17px]"
                        style={{
                          backgroundColor: inputEmpty && inputDataDCA.iprModel === "" ? "#B91C1C" : "",
                          border: inputEmpty && inputDataDCA.iprModel === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="iprModel"
                        value={inputDataDCA.iprModel || "Selected option"}
                        onChange={(value) =>
                          handleChangeSelectWellData("iprModel", value)
                        }
                      >
                        <Option value="oil_vogel">Oil Vogel</Option>
                        <Option value="physics_based" disabled>Physics Based</Option>
                      </Select>
                      <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                        Data history
                      </label>
                      <div className="flex flex-row items-center justify-center w-full h-full gap-5 text-center">
                        <div className="flex w-[157px] h-[42px] justify-start items-start">
                          <ImportFileHistory uploadfile={handleUploadFile}></ImportFileHistory>
                        </div>
                        {flagUploadFile
                          ?
                          <div className="flex flex-row w-full h-[42px] items-center justify-start gap-2">
                            <IconCheckFile className="w-5 h-5 fill-green-600"></IconCheckFile>
                            <label htmlFor="" className="font-sans text-green-600">The file has been loaded</label>
                          </div>
                          :
                          <div className="flex flex-row w-full h-[42px] items-center justify-start gap-2">
                            <IconCircle className="w-5 h-5 fill-pa-purple"></IconCircle>
                            <label htmlFor="" className="font-sans text-red-600">The file has not been loaded</label>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </>
              )}
              {current === 2 && (
                <>
                  <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                    <div className="flex flex-row w-[67%] h-[24px] gap-5">
                      <h1 className="flex flex-row font-bold text-[20px]">
                        DCA Parameters
                      </h1>
                    </div>
                  </div>
                  <div className="flex w-full h-[42em] justify-center text-center mb-[0]">
                    <div className="grid grid-cols-2 text-right w-[67%]  justify-start items-center h-[10em]">
                      <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                        Generate forecast for oil production
                      </label>
                      <Select
                        className="min-w-[592px] h-[47px]  max-w-[592px] text-start mb-[17px]"
                        style={{
                          backgroundColor: inputEmpty && inputDataDCA.generateForeOil === "" ? "#B91C1C" : "",
                          border: inputEmpty && inputDataDCA.generateForeOil === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="generateForeOil"
                        value={inputDataDCA.generateForeOil || "Selected option"}
                        onChange={(value) =>
                          handleChangeSelectWellData("generateForeOil", value)
                        }
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>

                      <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                        Generate forecast for gas production
                      </label>
                      <Select
                        className="min-w-[592px] h-[47px] max-w-[592px] text-start mb-[17px]"
                        name="generateForeGas"
                        value={inputDataDCA.generateForeGas || "Selected option"}
                        onChange={(value) =>
                          handleChangeSelectWellData("generateForeGas", value)
                        }
                        disabled
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>

                      <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                        Generate forecast for water production
                      </label>
                      <Select
                        className="min-w-[592px] h-[47px] max-w-[592px] text-start mb-[17px]"
                        name="generateForeOilWater"
                        value={inputDataDCA.generateForeOilWater || "Selected option"}
                        onChange={(value) =>
                          handleChangeSelectWellData("generateForeOilWater", value)
                        }
                        disabled
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                      <label className="flexxam w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                        Reporting Frequency:
                      </label>
                      <div className="w-[592px]">
                        <Select
                          className="min-w-[592px] h-[47px]  max-w-[592px] text-start mb-[17px]"
                          style={{
                            backgroundColor: inputEmpty && inputDataDCA.reporting_frequency === "" ? "#B91C1C" : "",
                            border: inputEmpty && inputDataDCA.reporting_frequency === "" ? "1px solid #B91C1C" : "",
                            borderRadius: "8px",
                            textAlignalign: "left"
                          }}
                          name="reporting_frequency"
                          value={inputDataDCA.reporting_frequency || "Selected option"}
                          onChange={(value) =>
                            handleChangeSelectWellData("reporting_frequency", value)
                          }
                        >
                          <Option value="monthly">Monthly</Option>
                          <Option value="quarterly" disabled>Quarterly</Option>
                          <Option value="yearly" disabled>Yearly</Option>
                        </Select>
                      </div>
                      <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                        Forecasting Horizon (Month)
                      </label>
                      <input
                        type="text"
                        name="forecastHorizont"
                        value={inputDataDCA.forecastHorizont}
                        onChange={handleChangeInputData}
                        className={`w-full min-w-[592px] max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputDataDCA.forecastHorizont === ""
                          ? " border-red-700 bg-yellow-100"
                          : "border border-solid border-[#BCBCCC]"
                          } `}
                      />
                    </div>
                  </div>
                </>
              )}
              {current === 3 && (
                <>
                  <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                    <div className="flex flex-row w-[67%] h-[24px] gap-5">
                      <h1 className="flex flex-row font-bold text-[20px]">
                        AI DCA Results
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-row w-full min-h-[960px] justify-center text-center">
                    <div className="grid grid-cols-2 text-right w-[67%]  justify-start items-center h-full gap-3">
                      <div className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] gap-3 col-span-2">
                        <h1>
                          (x Axis "months", y Axis STB/d)
                        </h1>
                        {/* <Tooltip title={"Download table"}>
                        <IconDownload className={`w-3.5 h-3.5 mt-[4px] hover:fill-pa-purple bg-opacity-50 cursor-pointer`} onClick={downloadTable}/>
                      </Tooltip> */}
                      </div>
                      {tables.length > 0 ? (
                        tables.map((table, index) => (
                          <div key={index} className={`flex w-full h-[400px] text-start font bold text-[15px] mb-[7px] ${tables.length === 1 ? "col-span-2" : ""}`}>
                            <ChartResults table={table} />
                          </div>
                        ))
                      ) : null
                      }
                      <div className="flex w-full text-start font bold text-[15px] mb-[7px] col-span-2">
                        <ModalTable
                          active={activeModal}
                          sensitivitie={inputDataDCA.sensitivities}
                          period={inputDataDCA.periods}
                          dataTable={dataTableDCAResult}
                          onCheckChange={handleCheckChange}
                          onCheckChangeLast={handleCheckChangeLast}
                        ></ModalTable>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {current === 4 && (
                <>
                  <div className="flex flex-row w-[680px] h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                    <div className="items-center justify-start text-center ">
                      <h1 className="flex flex-row font-bold text-[20px]">
                        Production Forecasting
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-row w-full min-h-[1140px] justify-center text-center" >
                    <div className="grid grid-cols-2 text-right w-[67%]  justify-start items-center h-[10em] gap-3">
                      <div className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] gap-3 col-span-2">
                        <h1 className="flex flex-row font-bold">
                          Sensitivity Analysis
                        </h1>
                        <h1>
                          (x Axis "months", y Axis STB/d)
                        </h1>
                        <Tooltip title={"Data"}>
                          <IconTableForecast className={`w-3.5 h-3.5 mt-[4px] hover:fill-pa-purple bg-opacity-50 cursor-pointer`} onClick={() => handleCLickModalTable("tableForecast")} />
                        </Tooltip>
                        {/* <Tooltip title={"Download data"}>
                        <IconDownload className={`w-3.5 h-3.5 mt-[4px] hover:fill-pa-purple bg-opacity-50 cursor-pointer`} onClick={downloadTableForecast}/>
                      </Tooltip> */}
                      </div>
                      <div className="w-full min-h-[450px]  gap-x-[43px]">
                        <div className="flex flex-col items-start w-full text-right">
                          <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                            Custom Scenarios
                          </label>
                          <Select
                            className="w-full h-[47px]  max-w-[480px] text-start mb-[4px] mr-[10px]"
                            style={{
                              backgroundColor: inputEmpty && inputDataDCA.customScenario === "" ? "#B91C1C" : "",
                              border: inputEmpty && inputDataDCA.customScenario === "" ? "1px solid #B91C1C" : "",
                              borderRadius: "8px",
                              textAlignalign: "left"
                            }}
                            name="customScenario"
                            value={inputDataDCA.customScenario || "Selected option"}
                            onChange={(value) =>
                              handleChangeSelect("customScenario", value)
                            }
                          >
                            <Option value="default" >Default</Option>
                            <Option value="semi_custom">Semi custom</Option>
                            <Option value="full_custom">Full custom</Option>
                          </Select>
                        </div>
                        <div className="grid grid-cols-2 w-[1000px] h-[90px]  gap-x-[43px]">
                          <div className="flex flex-col  w-[480px] text-right items-start">
                            <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mt-[10px] mb-[7px]">
                              Start Month for Production Forecasting
                            </label>
                            <input
                              type="text"
                              name="startMonth"
                              value={inputDataDCA.startMonth}
                              onChange={handleChangeInputData}
                              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputDataDCA.startMonth === ""
                                ? " border-red-700 bg-yellow-100"
                                : "border border-solid border-[#BCBCCC]"
                                } `}
                            />
                          </div>
                          <div className="flex flex-col w-[480px] text-right items-start">
                            <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mt-[10px] mb-[7px]">
                              Initial Fluid Rate (STB/d)
                            </label>
                            <input
                              type="text"
                              name="initialFluidRate"
                              value={inputDataDCA.initialFluidRate}
                              onChange={handleChangeInputData}
                              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputDataDCA.initialFluidRate === ""
                                ? " border-red-700 bg-yellow-100"
                                : "border border-solid border-[#BCBCCC]"
                                } `}
                            />
                          </div>
                          <div className="flex flex-col w-[480px] text-right items-start">
                            <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mt-[10px] mb-[7px]">
                              Forecasting Horizon (Month)
                            </label>
                            <input
                              type="text"
                              name="forecastHorizont"
                              value={inputDataDCA.forecastHorizont}
                              onChange={handleChangeInputData}
                              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputDataDCA.forecastHorizont === ""
                                ? " border-red-700 bg-yellow-100"
                                : "border border-solid border-[#BCBCCC]"
                                } `}
                            />
                          </div>
                          <div className="flex flex-col w-[480px] text-right items-start">

                          </div>
                          <div className="flex flex-col w-[480px] text-right items-start">
                            {inputDataDCA.customScenario === "full_custom" ? (
                              <>
                                <div className="flex flex-col  w-[480px] text-right items-start">
                                  <button
                                    className="w-[480px] h-[47px] bg-pa-purple text-white rounded-md"
                                    onClick={() => handleCLickModalTable("periodSelection")}
                                  >
                                    Custom Production Period for Declination Rate
                                  </button>
                                </div>
                                <div className="flex flex-col  w-[480px] text-right items-start">
                                  <button className="flex w-full max-w-[480px] h-[47px] mt-[15px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                                    onClick={handleClickRun}
                                  >
                                    Run
                                  </button>
                                </div>
                                <div className="flex w-full h-full">
                                  {!!checkedValuesLastCol && Object.values(checkedValuesLastCol).some(value => !!value) && (
                                    (() => {

                                      const key = Object.keys(checkedValuesLastCol)[0];
                                      const result = "df_cp_hist_" + key;

                                      return (
                                        <div key={result} className={`flex flex-col w-full h-[700px] text-start font-bold text-[15px] mb-[7px] col-span-2 `}>
                                          <div className="flex flex-col w-full h-[800px]" >
                                            <ChartForecasting
                                              table={result}
                                              current={current}
                                              status={activeModal.periodSelection}
                                              updateMonths={handleClickMonth}
                                              dataDCA={inputDataDCA}
                                              stateEmpty={inputEmpty}
                                              updateStatus={handleCLickModalTable}
                                            // updateData = {handleChangeInputData}
                                            />
                                          </div>
                                        </div>
                                      );
                                    })()
                                  )
                                  }
                                </div>
                              </>
                            ) :
                              <button className="flex h-[47px] mt-[15px] w-full max-w-[480px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                                onClick={handleClickRun}
                              >
                                Run
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full h-[600px] text-start font bold text-[15px] mb-[7px] col-span-2">
                        {generateTable ?
                          <div className="flex w-full h-[600px] mt-[30px] justify-center">
                            <LoadingGrapchicForecast />
                          </div> :
                          <ChartProduction forecastSelected={bestScenario} status={activeModal.periodSelection} tableResults={dataTableDCAResult} typeCustom={inputDataDCA.customScenario} ></ChartProduction>
                        }
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="flex items-center justify-center w-full mt-auto text-center">
                <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                  {current > 0 && (
                    <button
                      className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                      onClick={() => handleClickPrev()}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickParcialDone()}
                  >
                    Save and Exit
                  </button>

                  {current < steps.length - 1 && (
                    <button
                      className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                      onClick={() => handleClickNext()}
                    >
                      Continue
                    </button>
                  )}
                  {current === steps.length - 1 && (
                    <button
                      className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                      onClick={() => handleClickDone()}
                    >
                      Done
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </>
  );
};