import React from 'react'
import { DashboardContext } from './DashboardContentx'
import { useDashboard}  from '../../hooks/useDashboard'

export const DashboardProvider = ({children}) => {
const {
  dataPage,
  optionsNavbar,
  userDataInfo,
  selectedWell,
  selectedWellDCA,
  selectedReservoirSCT,
  selectedWellPL,
  dataDomainGraph,
  dataDomainGraphPL,
  dataAlertGasLift,
  dataAlertPlungerLift,
  dataAlertDCA,
  someAlert,
  checkTypeGraph,
  deviceRealTime,
  selectIntervalVLP,
  standartParameters,
  viewLoadingGeneral,
  slideGraph,
  getDataPage,
  getOptionsNavbar,
  getUserDataInfo,
  getSelectedWell,
  getSelectedWellDCA,
  getSelectedReservoirSCT,
  getSelectedWellPL,
  getDataDomainGraph,
  getDataDomainGraphPL,
  getDataAlertGasLift,
  getDataAlertPlungerLift,
  getDataAlertDCA,
  getSomeAlert,
  getIsOpenModalAllocattion,
  dataGas,
  dataOil,
  openFirstModal,
  isOpen2,
  setOpenFirstModal,
  toggleOpenFirstModal,
  getCheckTypeGraph,
  getDeviceRealTime,
  getSelectIntervalVLP,
  getStandarParameters,
  getViewLoadingGeneral,
  getSlideGraph
} = useDashboard()
  return (
    <DashboardContext.Provider value={{    
      dataPage,
      optionsNavbar,
      userDataInfo,
      selectedWell,
      selectedWellDCA,
      selectedReservoirSCT,
      selectedWellPL,
      dataDomainGraph,
      dataDomainGraphPL,
      dataAlertGasLift,
      dataAlertPlungerLift,
      dataAlertDCA,
      someAlert,
      checkTypeGraph,
      deviceRealTime,
      selectIntervalVLP,
      standartParameters,
      viewLoadingGeneral,
      slideGraph,
      getDataPage,
      getOptionsNavbar,
      getUserDataInfo,
      getSelectedWell,
      getSelectedWellDCA,
      getSelectedReservoirSCT,
      getSelectedWellPL,
      getDataDomainGraph,
      getDataDomainGraphPL,
      getDataAlertGasLift,
      getDataAlertPlungerLift,
      getDataAlertDCA,
      getSomeAlert,
      getIsOpenModalAllocattion,
      dataGas,
      dataOil,
      openFirstModal,
      isOpen2,
      setOpenFirstModal,
      toggleOpenFirstModal,
      getCheckTypeGraph,
      getDeviceRealTime,
      getSelectIntervalVLP,
      getStandarParameters,
      getViewLoadingGeneral,
      getSlideGraph
    }}>
        {children}
    </DashboardContext.Provider>
  )
}
