import React, { useState, useEffect, useRef } from 'react';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  ComposedChart,
  Line,
} from 'recharts';
import { WELL_SENSOR_DEVICE } from '../../../elements/types/type_dashboard';
import { DATA_STANDARD_RT } from '../../../elements/types/type_standard_parameters';
import { REQUIRED_COLUMNS_DOCUMENT_HISTORY } from '../../../elements/types/type_gas_lift';
import { formatDateHist } from '../../../utils/utils';

export const ChartProdHistory = (props) => {

  const { dataDomainGraph, typeGraph, y, enableModal, dataGrapIOT, typeDevicePH, dataHistory, statusGraph, dataAverage, dataAverageRT, wellId } = props
  const chartRef = useRef(); // Referencia para el contenedor del gráfico
  const [dataIOT, setDataIOT] = useState();
  const averageData = !!dataAverage ? dataAverage : null
  const averageDataRT = !!dataAverageRT ? dataAverageRT : null
  const validateDataProp = !!dataIOT || !!dataHistory;

  const rateHisMax = !!dataDomainGraph?.rate_max_ph && validateDataProp ? dataDomainGraph?.rate_max_ph : 0;
  const rateHisMin = !!dataDomainGraph?.rate_min_ph && validateDataProp ? dataDomainGraph?.rate_min_ph : 0;
  const gasMax = !!dataDomainGraph?.gas_max_ph && validateDataProp ? dataDomainGraph?.gas_max_ph : 0;
  const gasMin = !!dataDomainGraph?.gas_min_ph && validateDataProp ? dataDomainGraph?.gas_min_ph : 0;

  const [yAxisDomain1, setYAxisDomain1] = useState([rateHisMin, rateHisMax]);
  const [yAxisDomain2, setYAxisDomain2] = useState([gasMin, gasMax]);
  const [valueOffset, setValueOffset] = useState();
  const [dataFinalGraph, setDataFinalGraph] = useState([]);
  const dataDeviceReplace = typeDevicePH === WELL_SENSOR_DEVICE?.data[6].sensor ? WELL_SENSOR_DEVICE?.data[5].sensor : typeDevicePH;
  const [bottomMargin, setBottomMargin] = useState(50);

  useEffect(() => {
    // Cálculo dinámico del margen inferior
    const calculateBottomMargin = () => {
      if (chartRef.current) {
        const chartHeight = chartRef.current.clientHeight;
        // Ajustar el margen inferior según la altura del gráfico y espacio necesario para el `Brush`
        const newBottomMargin = Math.max(30, Math.min(80, chartHeight * 0.1));
        setBottomMargin(newBottomMargin);
      }
    };

    // Calcular al montar el componente
    calculateBottomMargin();
    const handleResize = () => {
      if (chartRef.current) {
        setValueOffset(enableModal ? 628 : chartRef.current.clientHeight - 17);
      }
    };
    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);
    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();
    // Volver a calcular cuando se redimensiona la ventana
    window.addEventListener('resize', calculateBottomMargin);
    return () => {
      window.removeEventListener('resize', calculateBottomMargin);
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(dataGrapIOT) && dataGrapIOT.length > 0) {
      const dataIOT = dataGrapIOT.filter((item) => {
        return (
          item.oil_rate !== -999.25 &&
          item.water_rate !== -999.25 &&
          item.gas_rate !== -999.25 &&
          item.oil_rate != null &&
          item.water_rate != null &&
          item.gas_rate != null
        );
      });
      setDataIOT(dataIOT);
    }
  }, [dataGrapIOT, typeDevicePH]);

  useEffect(() => {
    // Actualizar el dominio de los ejes Y según los nuevos valores de los inputs
    setYAxisDomain1([rateHisMin, rateHisMax]);
    setYAxisDomain2([gasMin, gasMax]);
  }, [rateHisMin, rateHisMax, gasMin, gasMax]);

  useEffect(() => {
    // Cálculo dinámico del margen inferior
    const calculateBottomMargin = () => {
      if (chartRef.current) {
        const chartHeight = chartRef.current.clientHeight;
        // Ajustar el margen inferior según la altura del gráfico y espacio necesario para el `Brush`
        const newBottomMargin = Math.max(30, Math.min(80, chartHeight * 0.1));
        setBottomMargin(newBottomMargin);
      }
    };

    // Calcular al montar el componente
    calculateBottomMargin();

    // Volver a calcular cuando se redimensiona la ventana
    window.addEventListener('resize', calculateBottomMargin);
    const handleResize = () => {
      if (chartRef.current) {
        setValueOffset(enableModal ? 628 : chartRef.current.clientHeight - 17);
      }
    };
    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);
    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();
    // Limpiar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', calculateBottomMargin);
      window.removeEventListener('resize', handleResize);
    };
  }, [enableModal, chartRef]);

  useEffect(() => {
    // Concat Volume Tacker
    let filteredData = [];
    let filteredDataRT = [];

    const formatDate = (dateString) => {
      const [year, month, day] = dateString.split('-');
      return `${parseInt(month)}/${parseInt(day)}/${year}`;
    };

    const formatDateIOT = (dateString) => {
      const [datePart, timePart] = dateString.split(' '); // Separar fecha y hora
      const [year, month, day] = datePart.split('-');
      return `${parseInt(month)}/${parseInt(day)}/${year} ${timePart}`;
    };

    if (!!averageData) {
      Object.keys(averageData)?.forEach((key) => {
        if (key === wellId) {
          const dateKey = Object.keys(averageData[key]);
          const everydate = dateKey?.map((date) => {
            const rateData = averageData[key][date];
            return {
              date: new Date(formatDate(date)).getTime(), // Convertir fecha a timestamp
              oil_rate: rateData?.oil_rate_avg?.toFixed(2),
              gas_rate: rateData?.gas_rate_avg?.toFixed(2),
              water_rate: rateData?.water_rate_avg?.toFixed(2),
            };
          });
          filteredData = everydate;
        }
      });
    }

    if (!!averageDataRT && !!dataDeviceReplace) {
      const result = averageDataRT?.find((item) => item.LSD === dataDeviceReplace);
      if (result) {
        filteredDataRT = [
          {
            date: new Date(result[DATA_STANDARD_RT.GENERAL.PARAMETER_000]).getTime(), // Convertir fecha a timestamp
            oil_rate: result[DATA_STANDARD_RT.GENERAL.PARAMETER_003],
            gas_rate: result[DATA_STANDARD_RT.GENERAL.PARAMETER_004],
            water_rate: result[DATA_STANDARD_RT.GENERAL.PARAMETER_005],
          },
        ];
      }
    }

    if (!!dataHistory && statusGraph === false) {
      const dataHistoryMod = dataHistory
        ?.map((item) => ({
          date: new Date(formatDateHist(item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[0]])).getTime(), // Convertir fecha a timestamp
          water_rate:
            typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[2]] === "number"
              ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[2]]?.toFixed(2)
              : null,
          gas_rate:
            typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[3]] === "number"
              ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[3]]?.toFixed(2)
              : null,
          oil_rate:
            typeof item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[1]] === "number"
              ? item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[1]]?.toFixed(2)
              : null,
        }))
        .filter(
          (item) =>
            item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[2]] !== null &&
            item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[3]] !== null &&
            item[REQUIRED_COLUMNS_DOCUMENT_HISTORY[1]] !== null
        );
      const combineddata = [...dataHistoryMod, ...filteredData, ...filteredDataRT]
        .filter((item) => !isNaN(item.date))
        .sort((a, b) => a.date - b.date);
      setDataFinalGraph(combineddata);
    } else {
      if (!dataIOT) return;
      const formattedDataIOT = dataIOT
        .filter((item) => item.lsd === typeDevicePH)
        .map((item) => ({
          ...item,
          date: new Date(formatDateIOT(item.date)).getTime(),
        }))
        .filter((item) => !isNaN(item.date))
        .sort((a, b) => a.date - b.date);

      setDataFinalGraph(formattedDataIOT);
    }
  }, [dataIOT, dataHistory, statusGraph, averageData, averageDataRT, dataDeviceReplace]);



  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 5
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };
  const formatNumber = (value) => {
    if (!isNaN(value) && value !== "") {
      const num = parseFloat(value);
      return num % 1 === 0 ? num : num.toFixed(2);
    }
    return value;
  };
  function customTooltip({ active, payload, option }) {
    if (active && payload && payload.length) {
      const valuesData = payload[0]?.payload;

      // Formatear la fecha en el formato MM/DD/YYYY HH:mm:ss
      const formattedDate = new Date(valuesData?.date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "5px", textAlign: "left" }}
        >
          <p>
            {`Date: `}
            <span>{formattedDate}</span>
          </p>
          <p style={{ color: "#2bca41" }}>
            {`Oil: `}
            <span>{formatNumber(valuesData?.oil_rate)} (STB/D)</span>
          </p>
          <p style={{ color: "#194bff" }}>
            {`Water: `}
            <span>{formatNumber(valuesData?.water_rate)} (STB/D)</span>
          </p>
          <p style={{ color: "#ce0f41" }}>
            {`Gas: `}
            <span>{formatNumber(valuesData?.gas_rate)} (MSCF/D)</span>
          </p>
        </div>
      );
    }

    return null;
  }

  return (
    <div className="w-full h-full" ref={chartRef}>
      {dataFinalGraph && dataFinalGraph.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={200}
            data={dataFinalGraph}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: bottomMargin,
            }}
          >
            <Brush
              dataKey="date"
              height={10}
              y={valueOffset}
              stroke="#8884d8"
              tickFormatter={(tick) =>
                new Date(tick).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                })
              }
            />
            {/* Grilla para ambos ejes */}
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

            <XAxis
              dataKey="date"
              type="number"
              domain={['dataMin', 'dataMax']}
              tickFormatter={(tick) =>
                new Date(tick).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false, // Esto asegura el formato de 24 horas
                })
              }
              label={{
                value: 'Date',
                position: 'bottom',
                fill: 'black',
              }}
              tickCount={10}
              fill="black"
            />
            <YAxis
              yAxisId={1}
              domain={yAxisDomain1}
              allowDataOverflow={true}
              label={{
                value: 'Liquid Rates (STB/D)',
                style: { textAnchor: 'middle' },
                angle: -90,
                position: 'left',
                offset: 0,
              }}
              tickCount={10}
              tick={{ fill: 'black' }}
              fill="black"
              padding={{ bottom: 10 }}
            />
            <YAxis
              yAxisId={2}
              orientation="right"
              domain={yAxisDomain2}
              allowDataOverflow={true}
              label={{
                value: 'Gas Rate (MSCF/D)',
                angle: -90,
                position: 'right',
                style: { textAnchor: 'middle' },
              }}
              fill="black"
              padding={{ bottom: 10 }}
            />
            <Tooltip content={customTooltip} />
            <ReferenceLine y={0} stroke="#000" yAxisId={1} />
            {typeGraph === 'Bar' ? (
              <>
                <Bar dataKey="oil_rate" name="Oil" fill="#2bca41" yAxisId={1} />
                <Bar dataKey="water_rate" name="Water" fill="#194bff" yAxisId={1} />
                <Bar dataKey="gas_rate" name="Gas" fill="#ce0f41" yAxisId={2} />
              </>
            ) : (
              <>
                <Line
                  dataKey="oil_rate"
                  name="Oil"
                  fill="#2bca41"
                  stroke="#2bca41"
                  yAxisId={1}
                  dot={<CustomDot color="#2bca41" />}
                  connectNulls={true}
                />
                <Line
                  dataKey="water_rate"
                  name="Water"
                  fill="#194bff"
                  stroke="#194bff"
                  yAxisId={1}
                  dot={<CustomDot color="#194bff" />}
                  connectNulls={true}
                />
                <Line
                  dataKey="gas_rate"
                  name="Gas"
                  fill="#ce0f41"
                  stroke="#ce0f41"
                  yAxisId={2}
                  dot={<CustomDot color="#ce0f41" />}
                  connectNulls={true}
                />
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <p>Loading data...</p> // Mensaje temporal mientras se cargan los datos
      )}
    </div>
  );


};