import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../../../components/layout/Navbar";
import { Sidebar } from "../../../components/layout/Sidebar";
import { Menu, Modal } from "antd";
import ChartMultiWell from "../../../components/GasLift/GraphicsMultiWell/ChartMultiWell";
import { TableMultiWell } from "../../../components/GasLift/TableMultiWell/TableMultiWell"
import { TableProductionTest } from "../../../components/GasLift/TableMultiWell/TableProductionTest"
import { CaretLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { NAME_PAGES } from "../../../components/elements/types/type_pages";

export const menuItems = [
  {
    key: "0",
    label: "Productivity Index (Mscf/d/psi)",
  },
  {
    key: "1",
    label: "Pressure Drawdown (psi)",
  },
];

export const MultiWellTrending = () => {
  const Navigate = useNavigate();
  const [openModalMultiWell, setOpenModalMultiWell] = useState(false);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [selectedValue, setSelectedValue] = useState("0");
  

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleOpenModalMultiWell = () => {
    setOpenModalMultiWell(!openModalMultiWell);
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      <div className="flex flex-col w-full h-full gap-4 pr-2 pl-[90px] p-5">
        <Modal
          title={"Production Test Information"}
          centered
          open={openModalMultiWell}
          onOk={() => handleOpenModalMultiWell()}
          onCancel={() => handleOpenModalMultiWell()}
          okButtonProps={{
            className: "bg-[#662D91]",
          }}
          width="60%"
        >
          <div className="flex w-full h-full justify-end mt-[20px] mb-5 mr-5">
            <TableProductionTest/>
          </div>
        </Modal>
        <div className="flex flex-col gap-5 pl-[76px] pr-[76px] mt-[18px] items-start">
          <div className="flex flex-row items-center mt-2 mb-2">  
            <LeftOutlined
              onClick={() => Navigate(`/${NAME_PAGES.gas_lift.list_well}`)}
              className="flex w-3.5 h-3.5 mr-1 mb-0.5 fill-neutral-500 hover:fill-pa-purple bg-opacity-50"
            />
            <h1 className="font-bold text-[20px]">
              Multi-Well Trending Analysis
            </h1>
          </div>
          <div className="flex w-[210px] h-[42px]">
            <button
              onClick={() => handleOpenModalMultiWell()}
              className="flex w-[210px] h-[42px] justify-center items-center text-[15px] text-pa-white1 bg-pa-purple border border-pa-purple rounded-md hover:bg-pa-white1 hover:text-pa-purple"
            >
              Production Test Information
            </button>
          </div>
          <div className="w-full h-full justify-end mt-[20px] mb-5 mr-5">
            <TableMultiWell/>
          </div>
        </div>
        <div className="my-purple-menu w-[500px] pl-[55px]">
          <Menu
            className="w-full text-purple-600"
            mode="horizontal"
            defaultSelectedKeys={"0"}
            selectedKeys={[selectedValue]}
            onClick={(value) => setSelectedValue(value.key)}
            items={menuItems.map((item) => {
              return {
                ...item,
                className: `text-purple-600 ${item.key === selectedValue ? "font-bold" : ""}`
                
              };
            })}
          />
        </div>
        {selectedValue === "0" && (
          <div className="flex w-full h-[500px] justify-end mt-[20px] mb-5 mr-5 pr-11">
            <ChartMultiWell>
            </ChartMultiWell>
          </div>
        )
        }

        {selectedValue === "1" && (
          <div className="flex w-full h-[500px] justify-end mt-[20px] mb-5 mr-5 pr-11">
            {/* <ChartMultiWell>
            </ChartMultiWell> */}
          </div>
        )
        }
      </div>
    </div>
  )
}