import React, { useState, useEffect, useContext } from 'react'
import  ResetIcon  from '../../assets/icon/reset.png';
import { ReactComponent as EditIcon } from '../../assets/icon/edit.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icon/expand.svg';
import { ChartPerformance } from './GraphicsDashboard/ChartPerformance/ChartPerformance';
import { Modal } from 'antd';
import { useDashboardDCAGraphics } from '../../hooks/useDashboardDCAGraphics';
import {useTabIOT} from '../../hooks/useTabIOT';
import {useTabDCA} from '../../hooks/useTabDCA';
import { DashboardDCAContext } from '../../context/DasboardDCA/DashboardDCAContext';

export const FrameGraph2 = (props) => {
  const { dataPressure} = useDashboardDCAGraphics()
  const { open, onCloseModal, dataHistory, dataIOT, typeDevice } = props;
  const [openModal, setOpenModal] = useState(false)
  const [enableModal, setEnabledModal] = useState(false)
  const {dataFilterUserDCA} = useContext(DashboardDCAContext)
  const arrayDataHistory = dataHistory?.statusType === "SUCCESS" && dataFilterUserDCA != null ? dataHistory?.data.data : null;

  useEffect(() => {
      setOpenModal(open);
      if(open){
        setEnabledModal(true);
    }
  }, [open])
  return (
    <>
    <div className='flex flex-row w-full h-[50px]'>
      <div className='w-full h-full'>
          <div className='flex flex-row w-full h-full gap-2 justify-center items-center'>
              <div className='ml-1 w-3 h-3 appearance-none rounded-full bg-red-600'></div>
              <h2 className='text-bc-grey text-[13px]'>Temperature</h2>
              <div className='ml-1 w-3 h-3 appearance-none rounded-full bg-blue-700'></div>
              <h2 className='text-bc-grey text-[13px]'>Pressure</h2>
          </div>
      </div>
    </div>
    <div className='w-full h-full bg-white'>
        <ChartPerformance dataIOT={dataIOT} y={305} bottom={50} dataHistory={dataHistory} enableModal={enableModal} typeDevice={typeDevice}></ChartPerformance>
    </div>
    <Modal
        title="Performance"
        centered
        open={openModal}
        onOk={() => {
            setEnabledModal(false);
            setOpenModal(false);
            onCloseModal(2)
        }
        }
        onCancel={() => {
            setEnabledModal(false);
            setOpenModal(false);
            onCloseModal(2); // Aquí llamamos a la función para cerrar el modal en Dashboard
        }}
        width={1000}
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
      >
        <div className='h-[610px]'>
          <div className='flex flex-row w-full h-[50px]'>
            <div className='w-full h-full'>
                <div className='flex flex-row w-full h-full gap-2 justify-center items-center'>
                    <div className='ml-1 w-3 h-3 appearance-none rounded-full bg-red-600'></div>
                    <h2 className='text-bc-grey text-[13px]'>Temperature</h2>
                    <div className='ml-1 w-3 h-3 appearance-none rounded-full bg-blue-700'></div>
                    <h2 className='text-bc-grey text-[13px]'>Pressure</h2>
                </div>
            </div>
          </div>
          <div className='w-full h-[570px]'>
            <ChartPerformance dataIOT={dataIOT} y={305} bottom={50} dataHistory={dataHistory} typeDevice={typeDevice}  enableModal={enableModal}></ChartPerformance>
          </div>
        </div>
      </Modal>
    </>
  )
}
