import React, { useEffect, useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

function CustomTooltip({ active, payload, stateContent }) {
  if (active && payload && payload.length) {

    const data = payload[0].payload;
    const titleColor = "#000000";
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
        <p style={{ color: titleColor }}>{`Pressure: ` + data.pressure + ` (psia)`}</p>
        <p style={{ color: '#5D7EC6' }}>{stateContent.gas_density && data.gas_density? `Gas Density: ` + data.gas_density + ` (Ibm/ft3)`: ``}</p>
        <p style={{ color: '#dc2626' }}>{stateContent.gas_z_factor && data.gas_z_factor? `Gas Z Factor: ` + data.gas_z_factor + ` (dimensionless)` : ``}</p>
      </div>
    )
  }

}

export const ChartCalculationPL = ({ dataTable, stateCheck }) => {
  const linesAxis = Object.keys(stateCheck).filter(key => stateCheck[key]);
  const [dataGraph, setDataGraph] = useState([])

  useEffect(() => {
    if(!!dataTable){
      const convertData = dataTable.map((value, index) => ({
        id: index + 1,
        pressure: parseFloat((value.press).toFixed(2)) || null,
        gas_z_factor: parseFloat((value.z_factor).toFixed(4)) || null,
        gas_density: parseFloat((value.den_avg).toFixed(4)) || null
      }));

      setDataGraph(convertData);
    }else{
      setDataGraph([]);
    }
  },[dataTable, stateCheck]);

  const colorsByType = {
    gas_density: "#5D7EC6",
    gas_z_factor: "#dc2626",
  };
  
  const DataAxisY = Object.keys(stateCheck).filter(key => stateCheck[key]);

  const namData = {
    gas_density: {
      state: stateCheck.gas_density,
      name: "Gas Density (Ibm/ft3)"
    },
    gas_z_factor: {
      state: stateCheck.gas_z_factor,
      name: "Gas Z Factor (dimensionless)"
    },
  };
  
  
  const getColorByType = (type) => colorsByType[type] || "#4c0519";

  const getDomainX = (data, key) => {
    const values = data?.map(item => item[key]).filter(value => value !== null && value !== undefined);
    return [Math.min(...values), Math.max(...values)];
  };
  const xDomain = getDomainX(dataGraph, 'press');

  const DomainY = () => {
    if (DataAxisY.length === 1) {
      const key = DataAxisY[0];
      const values = dataGraph.map(item => parseFloat(item[key])).filter(value => !isNaN(value)); 
      const min = Math.min(...values);
      const max = Math.max(...values);
      return [min, max];
    }
    return ['auto', 'auto'];
  };
  
  return (
    <div className='flex w-[1500px] h-[700px]'>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={dataGraph}
          margin={{
            top: 20,
            right: 50,
            left: 38,
            bottom: 20
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type='number'
            dataKey="pressure"
            tickCount={14}
            allowDataOverflow={true}
            label={{ 
              value: "Pressure (psia)", 
              position: "insideBottom", 
              offset: -15,
              fontSize: 14 
            }}
            tick={{ fontSize: 12}}
          />
          {linesAxis.map((lineKey, index) => (
            <YAxis
              key={`yAxis-${namData[lineKey].name}`}
              type="number"
              label={{
                value: namData[lineKey]?.name, // Etiqueta del eje Y
                angle: -90,
                position: lineKey === "gas_density" ? 'outsideLeft' : lineKey === "gas_z_factor" ? 'outsideRight' : index % 2 === 0 ? 'outsideLeft' : 'outsideRight',
                fill: colorsByType[lineKey],
                style: { textAnchor: "middle" },
                fontSize: 14,
                dx: lineKey === "gas_density" ? -20 : lineKey === "gas_z_factor" ? 48 : index % 2 === 0 ? -20 : 20,
              }}
              tick={{ fill: colorsByType[lineKey], fontSize: 12 }}
              stroke={colorsByType[lineKey]}
              yAxisId={index}
              orientation={lineKey === "gas_density" ? 'left' : lineKey === "gas_z_factor" ? 'right' : index % 2 === 0 ? 'left' : 'right'}
              interval={0}
              width={80}
              tickCount={13}
              domain={DomainY()}
              allowDataOverflow={true}
              tickFormatter={(tick) => lineKey === "gas_density" ? tick.toFixed(2) : tick}
            />
          ))}
          <Tooltip content={<CustomTooltip stateContent={stateCheck}/>}/>
          {DataAxisY.map((key, index) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              yAxisId={index}
              stroke={getColorByType(key)}
              dot={{ r: 4 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
