import { CaretDownOutlined, CaretLeftOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { message, Modal, Select, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertModal } from "../../../components/AlertModal";
import { DATA_RESERVOIR } from "../../../components/elements/types/type_gas_lift";
import ChartComposite from "../../../components/GasLift/GraphicsReservoir/ChartComposite";
import { ChartStraightLine } from "../../../components/GasLift/GraphicsReservoir/ChartStraightLine";
import ChartVogel from "../../../components/GasLift/GraphicsReservoir/ChartVogel";
import { Navbar } from "../../../components/layout/Navbar";
import { Sidebar } from "../../../components/layout/Sidebar";
import { useTabGaslift } from "../../../hooks/useTabGaslift";
import { NAME_PAGES } from '../../../components/elements/types/type_pages';
import { ReactComponent as SaveData } from "../../../assets/img/icons/save.svg";
import { Loading } from '../../../components/Loading';
import { STATUS_TYPE_CALCULATE_IPR } from '../../../components/elements/types/type_response_API';

export const StepMenuReservoir = () => {
  const navigate = useNavigate();
  const {
    User,
    userDataInfo,
    dataFilterUserGasLift,
    mutationUpdateDataGasLift,
    mutationUploadJsonDataGraph,
    mutationDeleteDataGraphicIPR,
    dataWellGasLift,
    queryListDataGraphic,
    queryDataGraphic,
    refetchDataWellGasLift,
    refetchListDataGraphic,
    refetchDataGraphic,
    mutationSaveDataIPR,
    dataTableIPR, 
    inputReservoir, 
    setInputReservoir,
    listqIPR,
    getSelectDataIPR,
    statedataListIPR,
    setDataListIPR
  } = useTabGaslift();
  
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  const { Option } = Select;
  const [selectedValue, setSelectedValue] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [status, setStatus] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [dataInformationEnd, setDataInformationEnd] = useState();
  const [bodyIPR, setBodyIPR] = useState([])
  const [disabledCheck, setDisabledCheck] = useState([false,false,false, false]);
  const [open, setOpen] = useState(false);
  const [optionOpen, setOptionOpen] = useState();

  useEffect(() => {
    refetchDataWellGasLift();
    refetchListDataGraphic();
    if (inputReservoir.state_reservoir === 'saturated') {
      setSelectedValue('saturated')
    } else {
      setSelectedValue('undersaturated')
    }
  }, [])
    
  useEffect(() => {
    if (!!listqIPR?.data && statedataListIPR) {
      message.info("The calculation processed correctly")
      setDataListIPR(false)
    } else if (listqIPR?.statusType === STATUS_TYPE_CALCULATE_IPR.ERROR && statedataListIPR) {
      message.info("The calculation carried out was not processed correctly, validates the data")
      setDataListIPR(false)
    }
  }, [statedataListIPR]) 
  
  useEffect(() => {
    setTimeout(() => {
      if (dataWellGasLift) {
        if (dataWellGasLift.statusCode === 200075) {
          const filterDataCreate = dataWellGasLift.data;
          const filterDataEnd = DATA_RESERVOIR.initialInputReservoir
          const filteredJson = Object.fromEntries(
            Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEnd))
          );
          setDataInformationEnd(filteredJson);
          setInputReservoir((prevState) => ({
            ...prevState,
            layer_pressure: Number(filterDataCreate?.res_pressure),
            layer_temperature: Number(filterDataCreate?.res_temperature),
            bubblePointPressure: Number(filterDataCreate?.bubble_point_pressure)
          }));
        }
      } 
    }, 1000);
  }, [dataWellGasLift])

  const handleChangeSelect = (name, valueData) => {
    // 'value' contiene el valor seleccionado del Select
    setSelectedValue(valueData);
    const customEvent = {
      target: {
        name: name,
        value: valueData,
      }
    };
    handleChangeDataReservoir(customEvent);
    
    // determines the legend according to reservoir state

    if ((valueData === 'saturated' && Number(inputReservoir.layer_pressure) > Number(inputReservoir.bubblePointPressure))) {
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "Reservoir pressure must be less than bubble point pressure"
      );
    } else if ((valueData === 'undersaturated' && Number(inputReservoir.layer_pressure) < Number(inputReservoir.bubblePointPressure))){
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "Reservoir pressure must be greater than bubble point pressure"
      );
    }

    setInputReservoir((prevState) => ({
      ...prevState,
      test_pressure_1_s: '',
      test_flow_rate_1_s: '',
      test_pressure_2_s: '',
      test_flow_rate_2_s: '',
      test_pressure_1_us: '',
      test_flow_rate_1_us: '',
      test_pressure_2_us: '',
      test_flow_rate_2_us: '',
      check_phases: null,
      selectDataIPR: null
    }))
  };

  const handleChangeSelect2 = (name, valueData, isMultiple) => {
    let filteredValues = valueData;

    if (isMultiple) {
      filteredValues = valueData?.filter((option) => option && option !== "");
    }

    if (isMultiple && filteredValues.length > 0) {
      const lastSelectedValue = filteredValues[filteredValues.length - 1]; 
      getSelectDataIPR(lastSelectedValue);
      
      setTimeout(() => {
        refetchDataGraphic();
      }, 500); 
    } else  if  ( name === "check_phases" ) { 
      setInputReservoir((prevState) => ({
        ...prevState,
        selectDataIPR: null
      }))
    }

    const customEvent = {
      target: {
        name: name,
        value: filteredValues,
      },
    };
    handleChangeDataReservoir(customEvent);


  };

  const isValidateDataIPR = (inputReservoir) => {
    const { 
      state_reservoir, 
      layer_pressure, 
      bubblePointPressure,
      md_perf_depth, 
      water_cut_fraction, 
      ipr_model, 
      check_phases, 
      layer_temperature,
      test_pressure_1_s, 
      test_flow_rate_1_s, 
      productivity_index_j_s, 
      test_pressure_1_us, 
      test_flow_rate_1_us, 
      productivity_index_j_us,
      liquid_flow_rate_s,
      liquid_flow_rate_us
    } = inputReservoir
    const hasRequiredFields = !!bubblePointPressure &&
    !!water_cut_fraction &&
    ipr_model !== '' &&
    layer_pressure !== '' && !!layer_pressure &&
    md_perf_depth !== '' &&
    state_reservoir !== '' &&
    check_phases !== '' && !!check_phases &&
    layer_temperature !== ''
    switch (state_reservoir) {
      case "saturated":
          const validTest = check_phases === "three" ? test_pressure_1_s !== '' : test_pressure_1_s !== '' && test_flow_rate_1_s !== ''
          const isValidProductivity = validTest ? true : productivity_index_j_s !== '' ? true : false
      
          const isValidForPhases = check_phases === "three"  && productivity_index_j_s === ''
          ? test_pressure_1_s !== '' && liquid_flow_rate_s !== '' : true;
          
          return hasRequiredFields && isValidProductivity && isValidForPhases;
        break;
      case "undersaturated":
          const validTestus = check_phases === "three" ? test_pressure_1_us !== '' : test_pressure_1_us !== '' && test_flow_rate_1_us !== ''
          const isValidProductivityus =  validTestus ? true : productivity_index_j_us !== '' ? true : false
        
          const isValidForPhasesus = check_phases === "three"  && productivity_index_j_us === ''
          ? validTestus  && liquid_flow_rate_us !== '' : true;
          
          return hasRequiredFields && isValidProductivityus && isValidForPhasesus;
        break;
      default:
        break;
    }
  }
  
  const isValidReservoirState = (inputReservoir) => {
    const { 
      state_reservoir, 
      layer_pressure,
      layer_temperature, 
      bubblePointPressure,
      check_phases, 
      test_pressure_1_s, 
      test_flow_rate_1_s, 
      productivity_index_j_s, 
      test_pressure_1_us, 
      test_flow_rate_1_us, 
      productivity_index_j_us,
      liquid_flow_rate_s,
      liquid_flow_rate_us
    } = inputReservoir 

    const validLayerTemp = layer_temperature >= 70 && layer_temperature <= 450
    
    switch (state_reservoir) {
      case "saturated":
          const validTest = check_phases === "three" ? (test_pressure_1_s !== ''  && (test_pressure_1_s >= 14.65 && test_pressure_1_s <= 25000 ))  : (test_pressure_1_s !== '' && (test_pressure_1_s >= 14.65 && test_pressure_1_s <= 25000 )) && (test_flow_rate_1_s !== '' && (test_flow_rate_1_s >= 0.01 && test_flow_rate_1_s <= 30000 ))
          const isValidState = Number(layer_pressure) <= Number(bubblePointPressure);
          const isValidProductivity = validTest ? true  : productivity_index_j_s >= 0.001
          const validFlowRate = check_phases === "three" && productivity_index_j_s === "" ? (liquid_flow_rate_s !== ''  && liquid_flow_rate_s >= 0.01 && liquid_flow_rate_s <= 30000) : true
          return  isValidState && isValidProductivity && validFlowRate && validLayerTemp
        break;
      case "undersaturated":
          const validTestus = check_phases === "three" ? (test_pressure_1_us !== '' && (test_pressure_1_us >= 14.65 && test_pressure_1_us <= 25000 ))  : (test_pressure_1_us !== '' && (test_pressure_1_us >= 14.65 && test_pressure_1_us <= 25000 )) && (test_flow_rate_1_us !== '' && (test_flow_rate_1_us >= 0.01 && test_flow_rate_1_us <= 30000 ))
          const isValidStateus = Number(layer_pressure) >= Number(bubblePointPressure);
          const isValidProductivityus = validTestus ? (test_pressure_1_us >= 14.65 && test_pressure_1_us <= 25000 ) : productivity_index_j_us >= 0.001 
          const validFlowRateus = check_phases === "three" && productivity_index_j_us === "" ? (liquid_flow_rate_us >= 0.01 && liquid_flow_rate_us <= 30000) : true 
          return  isValidStateus && isValidProductivityus && validFlowRateus && validLayerTemp
        break;
      default:
        break;
    }
  };
  
  const handleCalculate = () => {
    
    const validateData = isValidateDataIPR(inputReservoir);
    const validateState = isValidReservoirState(inputReservoir);
    
    if (validateData) {
      if (bodyIPR && Object.keys(bodyIPR).length > 0 && validateState) {

        setTimeout(() => {
          mutationSaveDataIPR.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            county: dataFilterUserGasLift.county,
            field: dataFilterUserGasLift.field,
            well: dataFilterUserGasLift.well_id,
            username: User.user_name,
            data: bodyIPR
          });
        }, 100);
        setInputEmpty(false)
      } else if (!validateState) {
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Please, modify the invalid information in highlighted fields."
        );
      }
    } else {
      setInputEmpty(true)
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "Please, complete the missing information in empty fields"
      );
    }
  }
  const handleClickDone = () => {
    let validateInitial = false;
    if (
      inputReservoir.layer_pressure === "" ||
      inputReservoir.layer_temperature === "" ||
      inputReservoir.md_perf_depth === "" ||
      inputReservoir.water_cut_fraction === ""
    ) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "Please, complete the missing information in empty fields"
      );
      setInputEmpty(true);
      handleStatusChange();
      validateInitial = false;
    } else {
      validateInitial = true;
    }

    if (inputReservoir.state_reservoir === "saturated") {
      if (inputReservoir.productivity_index_j_s != '' && 
        inputReservoir.test_pressure_1_s === '' &&
        inputReservoir.test_pressure_2_s === '' && 
        inputReservoir.ipr_model != '' &&
        (inputReservoir.check_phases != '' ||  inputReservoir.check_phases !== undefined) &&
        inputReservoir.test_flow_rate_1_s === '' &&
        inputReservoir.test_flow_rate_2_s === '') {
        validateInitial = true
      } 
      else if (
        (inputReservoir.productivity_index_j_s === '' && (inputReservoir.test_pressure_1_s != '' && inputReservoir.test_pressure_1_s != '')) 
        || 
        (inputReservoir.productivity_index_j_s === '' && (inputReservoir.test_pressure_1_s != '' && inputReservoir.test_pressure_1_s != '') && inputReservoir.test_pressure_2_s != '' && inputReservoir.test_flow_rate_2_s != '') &&
        inputReservoir.ipr_model != '' && 
        (inputReservoir.check_phases != '' ||  inputReservoir.check_phases !== undefined)
      ) {
        validateInitial = true
      }
      else {
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Please, complete the missing information in empty fields"
        );
        setInputEmpty(true);
        handleStatusChange();
        validateInitial = false;
      }
    }
    if (inputReservoir.state_reservoir === "undersaturated") {
        if (inputReservoir.productivity_index_j_us !== '' &&
          inputReservoir.test_pressure_1_us === '' &&
          inputReservoir.test_pressure_2_us === '' &&
          inputReservoir.ipr_model !== '' &&
          (inputReservoir.check_phases != '' ||  inputReservoir.check_phases !== undefined) &&
          inputReservoir.test_flow_rate_1_us === '' &&
          inputReservoir.test_flow_rate_2_us === '') {
          validateInitial = true;
        } 
        else if (
          (inputReservoir.productivity_index_j_us === '' && (inputReservoir.test_pressure_1_us != '' && inputReservoir.test_pressure_1_us != '')) 
          || 
          (inputReservoir.productivity_index_j_us === '' && (inputReservoir.test_pressure_1_us != '' && inputReservoir.test_pressure_1_us != '') && inputReservoir.test_pressure_2_us != '' && inputReservoir.test_flow_rate_2_us != '') &&
          inputReservoir.ipr_model != '' && 
          (inputReservoir.check_phases != '' ||  inputReservoir.check_phases !== undefined)
        ) {
          validateInitial = true;
        } 
        else {
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert("Please, complete the missing information in empty fields");
          setInputEmpty(true);
          handleStatusChange();
          validateInitial = false;
        }
    }

    // if (inputReservoir.bubblePointPressure != dataWellGasLift?.data?.bubble_point_pressure_lab) {
    if (!inputReservoir.bubblePointPressure) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "The bubble point can't be diferent t the Fluid bubble point value"
      );
      setInputEmpty(true);
      setInputReservoir({...inputReservoir, bubblePointPressure: null})
      handleStatusChange();
      validateInitial = false;

    } else if (inputReservoir.state_reservoir === 'saturated' && (Number(inputReservoir.layer_pressure) > Number(inputReservoir.bubblePointPressure))) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "The layer pressure can't be greater than bubble point"
      );
      setInputEmpty(true);
      handleStatusChange();
      validateInitial = false;
    } else if (inputReservoir.state_reservoir === 'undersaturated' && (Number(inputReservoir.layer_pressure) < Number(inputReservoir.bubblePointPressure))) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "The layer pressure can't be less than bubble point"
      );
      setInputEmpty(true);
      handleStatusChange();
      validateInitial = false;
    }
    if (validateInitial === true) {
      setInputEmpty(false);
      const filterDataEnd = Object.assign({}, dataInformationEnd, inputReservoir, {dataRunIPR: bodyIPR});

      setTimeout(() => {
        mutationUpdateDataGasLift.mutate({ operator: userDataInfo.operator, lease: userDataInfo.lease, region: userDataInfo.region, county: dataFilterUserGasLift.county, field: dataFilterUserGasLift.field, well: dataFilterUserGasLift.well_id, username: User.user_name, data: filterDataEnd })
      }, 100);
      navigate(`/${NAME_PAGES.gas_lift.list_well}`);
      message.success("Processing complete!");
    }
  };

  useEffect(() => {
    const buildDataReservoirBody = () => {
      const reservoirType = inputReservoir.state_reservoir;
      const isSaturated = reservoirType === 'saturated';
      const Pb = Number(inputReservoir.bubblePointPressure);
      if ((inputReservoir.productivity_index_j_s === '' && isSaturated) || (inputReservoir.productivity_index_j_us === '' && !isSaturated)) {
        const pr = Number(inputReservoir.layer_pressure) || 0; // Asumiendo que también quieres capturar si este campo está vacío
        const w_cut = Number(inputReservoir.water_cut_fraction) || 0;
        let qliq_test = null;
        const pwfTest = isSaturated
          ? [
            Number(inputReservoir.test_pressure_1_s) || 0,
            Number(inputReservoir.test_pressure_2_s) ? Number(inputReservoir.test_pressure_2_s) : 0 // Si está vacío, asigna 0
          ].filter(value => value !== 0)
          : [
            Number(inputReservoir.test_pressure_1_us) || 0,
            Number(inputReservoir.test_pressure_2_us) ? Number(inputReservoir.test_pressure_2_us) : 0 // Si está vacío, asigna 0
          ].filter(value => value !== 0);
        const qoTest = isSaturated
          ? [
            Number(inputReservoir.test_flow_rate_1_s) || 0,
            Number(inputReservoir.test_flow_rate_2_s) ? Number(inputReservoir.test_flow_rate_2_s) : 0 // Si está vacío, asigna 0
          ].filter(value => value !== 0)
          : [
            Number(inputReservoir.test_flow_rate_1_us) || 0,
            Number(inputReservoir.test_flow_rate_2_us) ? Number(inputReservoir.test_flow_rate_2_us) : 0 // Si está vacío, asigna 0
          ].filter(value => value !== 0);

        if(isSaturated){
          if(inputReservoir.check_phases === "three" ){
            qliq_test= Number(inputReservoir.liquid_flow_rate_s)
          }
        }
        else{
          if(inputReservoir.check_phases === "three" ){
            qliq_test= Number(inputReservoir.liquid_flow_rate_us)
          }
        }
        const body = !isSaturated ? {
          pr,
          pb: Pb,
          j_index: null,
          pwf_test: pwfTest.map(value => value || 0), // Asegura que cualquier valor nulo o vacío se convierta en 0
          qo_test: qoTest.map(value => value || 0), // Asegura que cualquier valor nulo o vacío se convierta en 0
          reservoir_type: reservoirType,
          phase: inputReservoir.check_phases,
          w_cut: w_cut,
          qliq_test
        } : {
          pr,
          pb: Pb,
          j_index: null,
          pwf_test: pwfTest.map(value => value || 0), // Asegura que cualquier valor nulo o vacío se convierta en 0
          qo_test: qoTest.map(value => value || 0), // Asegura que cualquier valor nulo o vacío se convierta en 0
          reservoir_type: reservoirType,
          phase: inputReservoir.check_phases,
          w_cut: w_cut,
          qliq_test
        };
        setBodyIPR(body)
      } else {
        const Pr = Number(inputReservoir.layer_pressure) || 0;
        const w_cut = Number(inputReservoir.water_cut_fraction) || 0;
        const J = isSaturated
          ? Number(inputReservoir.productivity_index_j_s)
          : Number(inputReservoir.productivity_index_j_us);
        let pwfTest, qoTest, qliqtest;
        if ((isSaturated && J) || (!isSaturated && J)) {
          if (isSaturated) {
            const Pwf = Pr - 100;
            const q = (J * (Pr / 1.8)) * (1 - 0.2 * (Pwf / Pr) - 0.8 * ((Pwf / Pr) ** 2));
            const fw = w_cut / 100;
            const fo = 1 - fw;
            const A = (1 - 0.2 * (Pwf / Pb) - 0.8 * (Pwf / Pb)^2)
            const qlistValue = J * (fo * (Pr - Pb + (Pb * A / 1.8)) + fw * (Pr - Pwf))
            
            qliqtest = qlistValue;
            pwfTest = [Pwf];
            qoTest = [q];

            if (inputReservoir.check_phases === "three") {
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_s),
                pwf_test: [], // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: [], // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase: inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            } else {
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: null,
                pwf_test: pwfTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: qoTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase: inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            }
            
          } 
          else { // Reservorio subsaturado
            if (inputReservoir.check_phases === "one") {
              const Pwf = Pr - 100;
              const q = J * 100;
              pwfTest = [Pwf];
              qoTest = [q];
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: null,
                pwf_test: pwfTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: qoTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase:inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            } else if (inputReservoir.check_phases === "two") { // two-phase
              const Pwf = Number(Pb);
              const q = J * (Pr - Pwf);
              pwfTest = [Pwf];
              qoTest = [q];

              const body = {
                pr: Pr,
                pb: Pb,
                j_index: null,
                pwf_test: pwfTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: qoTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase:inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            } else {
              const Pwf = Number(Pb);
              const q = J * (Pr - Pwf);
              pwfTest = [Pwf];
              qoTest = [q];
              qliqtest = J * (Pr - Pb);

              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_us),
                pwf_test: [], // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: [], // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase:inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            }
          }
        }
      }
    }

    buildDataReservoirBody();
  }, [inputReservoir, inputReservoir.bubblePointPressure])

  const handleChangeDataReservoir = (e) => {
    const { name, value, checked } = e.target;

    const nameDataExist = name === "nameDataGraphic" ? queryListDataGraphic.data?.some(obj => obj.name === value ) : null;
    
    if (inputReservoir.state_reservoir === 'saturated') {
      evaluateConditions("saturated", value);
      if (name === 'check_phases') {
        if (value != '' && value != undefined ) {
          setInputReservoir((prevState) => ({
            ...prevState,
            check_phases: value,
            test_pressure_1_s: '',
            test_pressure_2_s: '',
            test_flow_rate_1_s: '',
            test_flow_rate_2_s: '',
            ipr_model: '',
            productivity_index_j_s: '',
          }))
        }
      }
      else if (name === 'productivity_index_j_s') {
        // Cuando 'productivity_index_j_s' es modificado directamente
        if (value !== '') {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_s: value,
            test_pressure_1_s: '',
            test_pressure_2_s: '',
            test_flow_rate_1_s: '',
            test_flow_rate_2_s: '',
            ipr_model: '',
            liquid_flow_rate_s: ''
          }));
        } else {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_s: '',
            // No vaciamos los otros campos aquí porque el usuario está vaciando este campo específicamente
          }));
        }
      } else if ( name === 'layer_pressure' && inputReservoir.test_pressure_1_s > inputReservoir.layer_pressure) {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setInputReservoir((prevState) => ({
          ...prevState,
          test_pressure_1_s: null,
          // We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
          ...(value === '' || value != '' && { productivity_index_j_s: '', ipr_model: '' }),
        }));
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "The value must be less than layer pressure"
        );
      } else if (
        name === 'test_pressure_1_s' ||
        name === 'test_pressure_2_s' ||
        name === 'test_flow_rate_1_s' ||
        name === 'test_flow_rate_2_s'
      ) {
        if (name === 'test_pressure_1_s' && (Number(value) > inputReservoir.layer_pressure)) {
          // Allow these fields to be cleared without automatically overwriting them
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: null,
            // We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
            ...(value === '' || value != '' && { productivity_index_j_s: '', ipr_model: '' }),
          }));
          setInputEmpty(true)
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert(
            "Bottomhole pressure in Test 1 must be less than layer pressure"
          );
        } else if (name === 'test_pressure_2_s' && (Number(value) > inputReservoir.test_pressure_1_s)) {
          // Allow these fields to be cleared without automatically overwriting them
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: null,
            // We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
            ...(value === '' || value != '' && { productivity_index_j_s: '', ipr_model: '' }),
          }));
          setInputEmpty(true)
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert(
            "Bottomhole pressure in Test 2 must be less than Test 1"
          );
        }else {
          // Allow these fields to be cleared without automatically overwriting them
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: value,
            // We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
            ...(value === '' && { productivity_index_j_s: '', ipr_model: '' }),
          }));
        }
      } else if (name === 'liquid_flow_rate_s'){
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value
        }))
      } else if (name === 'bubblePointPressure') {
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: value,
          }));
      } else if (name === "nameDataGraphic" && nameDataExist) {
        message.error("Please, use a different name for this scenario")
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } 
    else if (inputReservoir.state_reservoir === 'undersaturated') {
      evaluateConditions("undersaturated", value);
      if (name === 'check_phases') {
        if (value != '' && value != undefined) {
          setInputReservoir((prevState) => ({
            ...prevState,
            check_phases: value,
            test_pressure_1_us: '',
            test_pressure_2_us: '',
            test_flow_rate_1_us: '',
            test_flow_rate_2_us: '',
            ipr_model: '',
            productivity_index_j_us: '',
          }))
        } 
      } 
      else if (name === 'productivity_index_j_us') {
        // Cuando 'productivity_index_j_s' es modificado directamente
        if (value === '') {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_us: '',
            // No vaciamos los otros campos aquí porque el usuario está vaciando este campo específicamente
          }));
        } else {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_us: value,
            test_pressure_1_us: '',
            test_pressure_2_us: '',
            test_flow_rate_1_us: '',
            test_flow_rate_2_us: '',
            ipr_model: '',
            liquid_flow_rate_us: ''
          }));
        }
      }
      else if (
        name === 'test_pressure_1_us' ||
        name === 'test_pressure_2_us' ||
        name === 'test_flow_rate_1_us' ||
        name === 'test_flow_rate_2_us'
      ) {
        if (name === 'test_pressure_1_us' && (Number(value) > inputReservoir.layer_pressure)) {
          // Allow these fields to be cleared without automatically overwriting them
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: null,
            // We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
            ...(value === '' || value != '' && { productivity_index_j_us: '', ipr_model: '' }),
          }));
          setInputEmpty(true)
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert(
            "Bottomhole pressure in Test 1 must be less than layer pressure"
          );
        } else if (name === 'test_pressure_2_us' && (Number(value) > inputReservoir.test_pressure_1_us)) {
          // Allow these fields to be cleared without automatically overwriting them
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: null,
            // We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
            ...(value === '' || value != '' && { productivity_index_j_us: '', ipr_model: '' }),
          }));
          setInputEmpty(true)
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert(
            "Bottomhole pressure in Test 2 must be less than Test 1"
          );
        } else {
          // Allow these fields to be cleared without automatically overwriting them
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: value,
            // We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
            ...(value === '' || value != '' && { productivity_index_j_us: '', ipr_model: '' }),
          }));
        }
      } else if (name === 'liquid_flow_rate_us'){
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else if (name === 'bubblePointPressure') {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else if (name === "nameDataGraphic" && nameDataExist) {
        message.error("Please, use a different name for this scenario")
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value, 
        }));
      } else {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  }

  const handleBlurDataReservoir = (e) => {
    const { name, value } = e.target;

    if ((name === 'layer_pressure' || name === 'bubblePointPressure') && (parseFloat(value) < 14.65 || parseFloat(value) > 25000) ) {
      setInputEmpty(true)
      message.warning("The value must be between 14.65 and 25000");
    }
    else if (name === 'layer_temperature' && (parseFloat(value) < 70 || parseFloat(value) > 450)) {
      setInputEmpty(true)
      message.warning("The value must be between 70 and 450");
    }
    else if (name === 'water_cut_fraction' && (parseFloat(value) < 0 || parseFloat(value) > 100)) {
      setInputEmpty(true)
      message.warning("The value must be between 0 and 100");
    }
    else if (name === 'md_perf_depth' && (parseFloat(value) < 100 || parseFloat(value) > 50000)) {
      setInputEmpty(true)
      message.warning("The value must be between 100 and 50000");
    }
    else if ((name === 'productivity_index_j_s' || name === 'productivity_index_j_us') && (parseFloat(value) < 0.001 || parseFloat(value) > 100)) {
      setInputEmpty(true)
      message.warning("The value must be between 0.001 and 100");
    }

    if(inputReservoir.state_reservoir === 'saturated'){
      if (name === 'layer_pressure' && (Number(value) > Number(inputReservoir.bubblePointPressure))) {
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Reservoir pressure must be less than bubble point pressure"
        );
      } else if (name === 'bubblePointPressure' && (Number(value) < Number(inputReservoir.layer_pressure))) {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: null,
        }));
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Bubble point pressure must be greater than layer pressure"
        );
      } else if (name === 'test_flow_rate_2_s' && (Number(value) < inputReservoir.test_flow_rate_1_s)) {
        // Allow these fields to be cleared without automatically overwriting them
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: "",
          // We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
          ...(value === '' || value != '' && { productivity_index_j_s: '', ipr_model: '' }),
        }));
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Oil Rate in Test 2 must be greater than Test 1"
        );
      }
    } else if(inputReservoir.state_reservoir === 'undersaturated'){
      if (name === 'layer_pressure' && (Number(value) < Number(inputReservoir.bubblePointPressure))) {
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Reservoir pressure must be greater than bubble point pressure"
        );
      } else if (name === 'bubblePointPressure' && (Number(value) > Number(inputReservoir.layer_pressure))) {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: null,
        }));
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Bubble point pressure must be less than layer pressure"
        );
      } else if (name === 'test_flow_rate_2_us' && (Number(value) < inputReservoir.test_flow_rate_1_us)) {
        // Allow these fields to be cleared without automatically overwriting them
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: "",
          // We only clear 'productivity_index_j_s' and 'ipr_model' if the value is being flushed
          ...(value === '' || value != '' && { productivity_index_j_us: '', ipr_model: '' }),
        }));
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Oil Rate in Test 2 must be greater than Test 1"
        );
      }
    }
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    // Cambiar el estado según tu lógica
    setStatus(!status);
  };

  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  const handleKeyDownScenario = (event) => {
    const allowedKeys = ['-', '_'];
    const isLetterOrNumber = /^[a-zA-Z0-9]$/; 
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
  
    if (!allowedKeys.includes(event.key) && 
        !isLetterOrNumber.test(event.key) && 
        !controlKeys.includes(event.key)) {
      event.preventDefault();
    }
  };
  
  const evaluateConditions = (state, value) => {
    const conditions = [
      {
        condition: inputReservoir.check_phases !== "three" && state === "saturated" && inputReservoir.test_pressure_1_s === "" && inputReservoir.test_flow_rate_1_s === "" && inputReservoir.test_pressure_2_s === "" && inputReservoir.test_flow_rate_2_s === "" && value !== "",
        updates: [true, false, true, true]
      },
      {
        condition:  inputReservoir.check_phases !== "three" && state === "saturated" && inputReservoir.productivity_index_j_s === "" && (inputReservoir.test_pressure_1_s !== "" || inputReservoir.test_flow_rate_1_s !== "") && inputReservoir.test_pressure_2_s === "" && inputReservoir.test_flow_rate_2_s === "" && value !== "",
        updates: [true, false, true, true]
      },
      {
        condition:  inputReservoir.check_phases !== "three" && state === "saturated" && inputReservoir.productivity_index_j_s === "" && inputReservoir.test_pressure_1_s !== "" && inputReservoir.test_flow_rate_1_s !== "" && (inputReservoir.test_pressure_2_s === "" || inputReservoir.test_flow_rate_2_s === "") && value === "",
        updates: [true, false, true, true]
      },
      {
        condition:  inputReservoir.check_phases !== "three" && state === "saturated" && inputReservoir.productivity_index_j_s === "" && inputReservoir.test_pressure_1_s !== "" && inputReservoir.test_flow_rate_1_s !== "" && (inputReservoir.test_pressure_2_s !== "" || inputReservoir.test_flow_rate_2_s !== "") && inputReservoir.check_phases === "two" && value !== "",
        updates: [true, false, false, true]
      },
      {
        condition: inputReservoir.check_phases === "three" && state === "saturated" && inputReservoir.test_pressure_1_s === "" && inputReservoir.test_flow_rate_1_s === "" && inputReservoir.test_pressure_2_s === "" && inputReservoir.test_flow_rate_2_s === "" && value !== "",
        updates: [true, true, true, false]
      },
      {
        condition: inputReservoir.check_phases === "three" && state === "saturated" && inputReservoir.productivity_index_j_s === ""  && inputReservoir.test_flow_rate_1_s === "" && inputReservoir.test_pressure_2_s === "" && inputReservoir.test_flow_rate_2_s === "" && value !== "",
        updates: [true, true, true, false]
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.test_pressure_1_us === "" && inputReservoir.test_flow_rate_1_us === "" && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && inputReservoir.check_phases === "one" && value !== "",
        updates: [false, true, true, true] 
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.test_pressure_1_us === "" && inputReservoir.test_flow_rate_1_us === "" && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && inputReservoir.check_phases === "two" && value !== "",
        updates: [true, false, true, true]
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.productivity_index_j_us === "" && (inputReservoir.test_pressure_1_us !== "" || inputReservoir.test_flow_rate_1_us !== "") && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && inputReservoir.check_phases === "one" && value !== "",
        updates: [false, true, true, true]
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.productivity_index_j_us === "" && inputReservoir.test_pressure_1_us !== "" && inputReservoir.test_flow_rate_1_us !== "" && (inputReservoir.test_pressure_2_us === "" || inputReservoir.test_flow_rate_2_us === "") &&  inputReservoir.check_phases === "two" && value === "",
        updates: [true, false, true, true]
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.productivity_index_j_us === "" && inputReservoir.test_pressure_1_us !== "" && inputReservoir.test_flow_rate_1_us !== "" && (inputReservoir.test_pressure_2_us !== "" || inputReservoir.test_flow_rate_2_us !== "") && inputReservoir.check_phases === "two" && value !== "",
        updates: [true, false, false, true]
      },
      {
        condition: inputReservoir.check_phases === "three" && state === "undersaturated" && inputReservoir.test_pressure_1_us === "" && inputReservoir.test_flow_rate_1_us === "" && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && value !== "",
        updates: [true, true, true, false]
      },
      {
        condition: inputReservoir.check_phases === "three" && state === "undersaturated" && inputReservoir.productivity_index_j_us === ""  && inputReservoir.test_flow_rate_1_us === "" && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && value !== "",
        updates: [true, true, true, false]
      },
    ];
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].condition) {
        setDisabledCheck(prevArray => {
          const newArray = [...prevArray];
          newArray[1] = conditions[i].updates[0];
          newArray[2] = conditions[i].updates[1];
          newArray[3] = conditions[i].updates[2];
          newArray[4] = conditions[i].updates[3];
          return newArray;
        });
        break;
      }
    }
  };

  const handleOpenModalData = () => {
    if (!!dataTableIPR){
      setOpen(true)
    } else {
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "There is not scenario to save. Please, run a model."
      );
    }
  }
  
  const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert the string in a date object
    const day = String(date.getDate()).padStart(2, '0'); // obtain the day and secure two digits 
    const month = String(date.getMonth() + 1).padStart(2, '0'); // obtain the month
    const year = date.getFullYear(); // obtain the year
    return `${day}/${month}/${year}`; // Format DD/MM/YYYY
  };

  const handleClickUploadData = () => {

    const data = {...listqIPR?.data, 
      "date": formatDate(inputReservoir?.dateDataGraphic), 
      "name": inputReservoir?.nameDataGraphic, 
      "phase": inputReservoir?.check_phases, 
      "reservoir_type": inputReservoir.state_reservoir,
      "ipr_model": inputReservoir?.ipr_model
    };
    
    const nameDataExist = !!queryListDataGraphic ? queryListDataGraphic.data?.some(obj => obj.name === inputReservoir?.nameDataGraphic ) : null;

    if (!!data && !!listqIPR?.data && !nameDataExist && !!inputReservoir?.nameDataGraphic){
      setTimeout(() => {
        mutationUploadJsonDataGraph.mutate({ 
          operator: userDataInfo.operator, 
          lease: userDataInfo.lease, 
          region: userDataInfo.region, 
          county: dataFilterUserGasLift.county, 
          field: dataFilterUserGasLift.field,
          well: dataFilterUserGasLift.well_id, 
          username: User.user_name,
          data: data
        })
      }, 500);
      setOpen(false);
      setInputReservoir((prevState) => ({
        ...prevState,
        nameDataGraphic: ""
      }))
    } else if (!inputReservoir?.nameDataGraphic) {
      setInputReservoir((prevState) => ({
        ...prevState,
        nameDataGraphic: null
      }))
      message.info("Please, complete the missing information in empty fields")
    } else if (nameDataExist) {
      message.info("Please, use a different name for this scenario")
    } else {
      message.info("First run the process")
    }
  };

  const handleDeleteScenario = (name) => {
    
    setTimeout(() => {
      mutationDeleteDataGraphicIPR.mutate({ 
        operator: userDataInfo.operator, 
        lease: userDataInfo.lease, 
        region: userDataInfo.region, 
        county: dataFilterUserGasLift.county, 
        field: dataFilterUserGasLift.field,
        well: dataFilterUserGasLift.well_id, 
        username: User.user_name,
        nameIPR: name,
      })
    }, 500);

    if (inputReservoir?.selectDataIPR?.includes(name)) {
      const updatedSelections = inputReservoir.selectDataIPR.filter(selected => selected !== name);
      handleChangeSelect2('selectDataIPR', updatedSelections, true);
    }
    
  }
  
  const handleChangeOptionOpen = () => {
    if (optionOpen) {
      setOptionOpen(false);
    } else {
      setOptionOpen(true)
    }
  }
  const typeLine = ['3 3', '7 2', '4 1', '5 5', '4 3'];
  const getcolor = (index) => {
    const dynamicColors = ['#82ca9d', '#c73c00', '#0045c7', '#f0ad4e'];
    return dynamicColors[index % dynamicColors.length]
  }

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const index = inputReservoir?.selectDataIPR?.indexOf(value);

    return (
      <Tag
        closable={closable}
        onClose={onClose}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <svg width="20" height="10" style={{ marginRight: 4 }}>
          <line 
            x1="0" 
            y1="5" 
            x2="20" 
            y2="5" 
            stroke={inputReservoir.check_phases != "three" ? getcolor(index) : "black"}
            strokeWidth={3}
            strokeDasharray={typeLine[index % typeLine.length]}
          />
        </svg>
        {label}
      </Tag>
    );
  };

  const validateWarningfields = (name, value) => {
    if (inputEmpty) {
      switch (name) {
        case "bubblePointPressure":
          return value === "" || value === null || (value < 14.65 || value > 25000)
        break;
        case "md_perf_depth":
          return value === "" || (value < 100 || value > 50000)
        break;
        case "check_phases":
          return value === null
        break;
        case "layer_temperature":
          return value === "" || (value < 70 || value > 450)
        break;
        case "water_cut_fraction":
          return value === "" || (value < 0 || value > 100)
        break;
        default:
          break;
      }
      if (inputReservoir.state_reservoir === "saturated"){
        switch (name) {
          case "layer_pressure":
            return value === "" || value > inputReservoir.bubblePointPressure || (value < 14.65 || value > 25000)
          break;
          case "productivity_index_j_s":
            return (inputReservoir.test_pressure_1_s == "" && inputReservoir.test_flow_rate_1_s == "") && (value === "" || (value < 0.001 || value > 100)) 
          break;
          case "test_flow_rate_1_s":
            return   inputReservoir.productivity_index_j_s === "" && (value === "" || value === null || (value < 0.01 || value > 30000)) && inputReservoir.check_phases !== "three"
          break;
          case "test_flow_rate_2_s":
            return (value === null || (value !== "" && (value < 0.01 || value > 30000))) && inputReservoir.check_phases !== "three"
          break;
          case "test_pressure_1_s":
            return inputReservoir.productivity_index_j_s === "" && (value === "" || value === null || (value < 14.65 || value > 25000))
          break;
          case "test_pressure_2_s":
            return (value === null || (value !== "" && (value < 14.65 || value > 25000))) && inputReservoir.check_phases !== "three"
          break;
          case "liquid_flow_rate_s":
            return inputReservoir.productivity_index_j_s === "" && (value === "" || (value < 0.01 || value > 30000 )) && inputReservoir.check_phases === "three"
          break;
          default:
            break;
        }
      } else if (inputReservoir.state_reservoir === "undersaturated") {
        switch (name) {
          case "layer_pressure":
            return value === "" || value < inputReservoir.bubblePointPressure || (value < 14.65 || value > 25000)
          break;
          case "productivity_index_j_us":
            return (inputReservoir.test_pressure_1_us === "" && inputReservoir.test_flow_rate_1_us === "") && (value === "" || (value < 0.001 || value > 100)) 
          break;
          case "test_flow_rate_1_us":
            return inputReservoir.productivity_index_j_us === "" && (value === "" || value === null || (value < 0.01 || value > 30000)) && inputReservoir.check_phases !== "three"
          break;
          case "test_flow_rate_2_us":
            return (value === null || (value !== "" && (value < 0.01 || value > 30000))) && inputReservoir.check_phases !== "three"
          break;
          case "test_pressure_1_us":
            return inputReservoir.productivity_index_j_us === "" && (value === "" || value === null || (value < 14.65 || value > 25000))
          break;
          case "test_pressure_2_us":
            return (value === null || value !== "" && (value < 14.65 || value > 25000)) && inputReservoir.check_phases !== "three"
          break;
          case "liquid_flow_rate_us":
            return inputReservoir.productivity_index_j_us === "" && (value === "" || (value < 0.01 || value > 30000 )) && inputReservoir.check_phases === "three"
          break;
          default:
            break;
        }
      } 
    } else {
      return false
    }
    
  }
  
  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      <div className="flex w-full h-full">
        <AlertModal
          showAlert={showAlert}
          handleCloseModal={handleCloseModal}
          messageAlert={messageAlert}
          typeModal={typeAlert}
        ></AlertModal>
      </div>
      <Modal
        title='Saving Scenarios'
        centered
        open={open}
        onOk={() => handleClickUploadData()}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
        width={720}
      >
        <div className="flex flex-col w-full h-full mt-[45px] ">
          <div className=" w-[550px] h-[300px] p-2 ml-[20px] ">
              <label
                htmlFor=""
                className="flex w-[450px] max-w-[550px] text-start font bold text-[15px] mb-[15px] mt-[15px] "
              >
                Name 
                <span className="text-[12px] font-normal ml-1 align-baseline">(Please, do not use special characters like %, =, /, etc.)</span>
              </label>
              <input
                type="text" style={{ WebkitAppearance: 'none' }}
                name="nameDataGraphic"
                value={inputReservoir?.nameDataGraphic}
                onChange={handleChangeDataReservoir}
                onKeyDown={handleKeyDownScenario}
                className={`w-full max-w-[450px] h-[47px] rounded-[4px] px-3 border border-solid  mb-[17px] ${ inputReservoir?.nameDataGraphic === null
                  ? " border-red-700 bg-yellow-100"
                  : " border-[#BCBCCC]"
                  }`
                }
              />
              <label
                htmlFor=""
                className="flex w-[250px] max-w-[250px] text-start font bold text-[15px] mb-[7px]"
              >
                Date
              </label>
              <input
                type="date" style={{ WebkitAppearance: 'none' }}
                name="dateDataGraphic"
                value={inputReservoir?.dateDataGraphic}
                className={`w-full max-w-[450px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]`}
              />
            </div>
        </div>
      </Modal>
      <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center mt-[30px]">
        <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px]">
          <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Layer Information</h1>
          </div>
        </div>
        <div className="flex flex-row gap-10">
          <div className="flex flex-col w-1/2 items-end">
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Layer pressure (psia)
              <Tooltip title={'The value must be between 14.65 and 25000'}>
                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </label>
            <input
              type="number"
              name="layer_pressure"
              min="0"
              disabled={inputReservoir.bubblePointPressure === null || inputReservoir.bubblePointPressure === ''}
              onKeyDown={handleKeyDown}
              value={inputReservoir.layer_pressure}
              onChange={handleChangeDataReservoir}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("layer_pressure", inputReservoir.layer_pressure)
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
              onBlur={(e) => handleBlurDataReservoir(e)}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Bubble point pressure (psia)
              <Tooltip title={'The value must be between 14.65 and 25000'}>
                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </label>
            <input
              type="number"
              name="bubblePointPressure"
              min="0"
              onKeyDown={handleKeyDown}
              value={inputReservoir.bubblePointPressure}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleBlurDataReservoir(e)}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("bubblePointPressure", inputReservoir.bubblePointPressure)
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Mid-perf Measured Depth (feet)
              <Tooltip title={'The value must be between 100 and 50000'}>
                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </label>
            <input
              type="number"
              name="md_perf_depth"
              min="0"
              onKeyDown={handleKeyDown}
              value={inputReservoir.md_perf_depth}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleBlurDataReservoir(e)}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("md_perf_depth", inputReservoir.md_perf_depth)
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] font bold text-[15px] mb-[5px] justify-left"
            >
              Phase
            </label>
            <Select
              style={{ backgroundColor: validateWarningfields("check_phases", inputReservoir.check_phases) ? '#fef9c3' : '', border: validateWarningfields("check_phases", inputReservoir.check_phases) ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
              className="flex w-full h-[47px] max-w-[592px] text-start mb-[7px]"
              name="check_phases"
              value={inputReservoir.check_phases || "Selected option"}
              onChange={(e) => handleChangeSelect2('check_phases', e, false)}
            >
              <Option value="one" disabled={inputReservoir.state_reservoir === 'saturated'}>One (Oil)</Option>
              <Option value="two">Two (Oil + Gas)</Option>
              <Option value="three">Three (Oil + Gas + Water)</Option>
            </Select>
          </div>
          <div className="flex flex-col w-1/2 text-left items-start justify-start">
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Layer temperature (deg F)
              <Tooltip title={'The value must be between 70 and 450'}>
                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </label>
            <input
              type="number"
              name="layer_temperature"
              min="0"
              onKeyDown={handleKeyDown}
              value={inputReservoir.layer_temperature}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleBlurDataReservoir(e)}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("layer_temperature", inputReservoir.layer_temperature)
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Water cut (%)
              <Tooltip title={'The value must be between 0 and 100'}>
                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </label>
            <input
              type="number"
              name="water_cut_fraction"
              min="0"
              max={100}
              onKeyDown={handleKeyDown}
              value={inputReservoir.water_cut_fraction}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleBlurDataReservoir(e)}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("water_cut_fraction", inputReservoir.water_cut_fraction)
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir State {/* IPR Model */}
            </label>
            <Select
              className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
              name="state_reservoir"
              value={inputReservoir.state_reservoir || "Selected Option"}
              onChange={(e) => handleChangeSelect('state_reservoir', e)}
            >
              <Option value="saturated">Saturated</Option>
              <Option value="undersaturated">Undersaturated</Option>
            </Select>
            {selectedValue === "saturated" && (
              <>
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Productivity index J (STB/D/psi)
                  <Tooltip title={'The value must be between 0.001 and 100'}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="productivity_index_j_s"
                  min="0"
                  onKeyDown={handleKeyDown}
                  onBlur={(e) => handleBlurDataReservoir(e)}
                  value={inputReservoir.productivity_index_j_s}
                  onChange={handleChangeDataReservoir}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${validateWarningfields("productivity_index_j_s", inputReservoir.productivity_index_j_s)  
                    ? "border-red-700 bg-yellow-100" 
                    : "border-[#BCBCCC]"} `}
                  disabled={inputReservoir.test_pressure_1_s !== "" || inputReservoir.test_pressure_2_s !== "" || inputReservoir.test_flow_rate_1_s !== "" || inputReservoir.test_flow_rate_2_s !== ""} // Deshabilita si cualquier campo de test tiene un valor
                />
              </>
            )}
            {selectedValue === "undersaturated" && (
              <> 
                <label
                htmlFor=""
                className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Productivity Index J
                  <Tooltip title={'The value must be between 0.001 and 100'}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="productivity_index_j_us"
                  min="0"
                  onKeyDown={handleKeyDown}
                  onBlur={(e) => handleBlurDataReservoir(e)}
                  value={inputReservoir.productivity_index_j_us}
                  onChange={handleChangeDataReservoir}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("productivity_index_j_us", inputReservoir.productivity_index_j_us)
                    ? " border-red-700 bg-yellow-100"
                    : " border-[#BCBCCC]"
                    } `}
                  disabled={inputReservoir.test_pressure_1_us !== "" || inputReservoir.test_pressure_2_us !== "" || inputReservoir.test_flow_rate_1_us !== "" || inputReservoir.test_flow_rate_2_us !== ""} // Deshabilita si cualquier campo de test tiene un valor
                />
              </>
            )}
          </div>
        </div>
        {selectedValue === "saturated" && (
          <>
            <div className="flex flex-row gap-10 mt-[10px]">
              <div className="flex flex-col w-1/2 items-end">
                <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]" htmlFor="">Oil Flow Rate (STB/D)</label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]  gap-x-[1em]">  
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                      <Tooltip title={'The value must be between 0.01 and 30000'}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_1_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_1_s}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("test_flow_rate_1_s", inputReservoir.test_flow_rate_1_s) 
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}
                      disabled={inputReservoir.productivity_index_j_s !== "" || inputReservoir.check_phases === "three"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                      Test 2
                      <Tooltip title={'The value must be between 0.01 and 30000'}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_2_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_2_s}
                      onChange={handleChangeDataReservoir}
                      onBlur={(e) => handleBlurDataReservoir(e)}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("test_flow_rate_2_s", inputReservoir.test_flow_rate_2_s)
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}
                      disabled={inputReservoir.productivity_index_j_s !== "" || inputReservoir.test_flow_rate_1_s === ''|| inputReservoir.check_phases === "three"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]" htmlFor="">
                  Bottomhole Pressure (psia)
                  <Tooltip title={`To use Fetkovich model pressure test 1 and pressure test 2 must be below bubble point pressure`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                      <Tooltip title={'The value must be between 14.65 and 25000'}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_pressure_1_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_pressure_1_s}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${validateWarningfields("test_pressure_1_s", inputReservoir.test_pressure_1_s) 
                        ? "border-red-700 bg-yellow-100" 
                        : "border-[#BCBCCC]"}${inputReservoir.productivity_index_j_s !== '' ? ' disabled' : ''} `}
                      disabled={inputReservoir.productivity_index_j_s !== ""} 
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                      <Tooltip title={'The value must be between 14.65 and 25000'}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_pressure_2_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_pressure_2_s}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("test_pressure_2_s", inputReservoir.test_pressure_2_s) 
                        ? " border-red-700 bg-yellow-100" 
                        : " border-[#BCBCCC]"} `}
                      disabled={inputReservoir.productivity_index_j_s !== "" || inputReservoir.test_pressure_1_s === '' || inputReservoir.check_phases === "three"}
                    />
                  </div>
                </div>
              </div>
            </div>
            {inputReservoir.check_phases === "three" && (
              <>
                <div className="flex flex-row w-full  gap-10">
                  <div className="flex flex-col w-1/2  items-end">
                    <label
                    htmlFor=""
                    className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Liquid Flow Rate (STB/D)
                    </label>
                    <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] mt-[6px]"
                      >
                        Test 1
                        <Tooltip title={'The value must be between 0.01 and 30000'}>
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                      </label>
                    <input
                      type="number"
                      name="liquid_flow_rate_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.liquid_flow_rate_s}
                      onChange={handleChangeDataReservoir}
                      disabled={inputReservoir.productivity_index_j_s !== ""}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("liquid_flow_rate_s", inputReservoir.liquid_flow_rate_s)
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 items-start"></div>
                </div>
              </>
            )}
            <div className="flex flex-row w-full  gap-10 ">
              <div className="flex flex-col w-1/2  items-end">
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  IPR model
                </label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputReservoir.ipr_model === '' ? '#FEF9C3' : '', border: inputEmpty && inputReservoir.ipr_model === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full h-[47px] max-w-[592px] text-start mb-[7px]"
                  name="ipr_model"
                  value={inputReservoir.ipr_model || "Selected option"}
                  onChange={(e) => handleChangeSelect2('ipr_model', e, false)}
                >
                  <Option value="straight" disabled={disabledCheck[1]}>Straight line</Option>
                  <Option value="vogel" disabled={disabledCheck[2]}>Vogel</Option>
                  <Option value="fetko" disabled={disabledCheck[3]}>Fetkovich</Option>
                  <Option value="composite" disabled={disabledCheck[4]}>Composite</Option>
                </Select>
              </div>
              <div className="flex flex-col w-1/2 items-start"></div>
            </div>
            <div className="flex flex-row w-full text-center items-center justify-center gap-y-[2em] mt-[17px] mb-[17px]">
              <button
                className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                onClick={handleCalculate}
              >
                Run
              </button>
            </div>
            <div className="flex w-full h-full text-center items-center justify-center">
              <div className="flex flex-col w-[1220px] h-full text-center items-center justify-center border-solid" 
                style={{
                  border: '1px solid #BCBCCC', 
                }}
              >
                <div className="flex w-[1150px] h-6 text-center items-center justify-end gap-y-[2em] mt-[17px]">
                  {optionOpen &&
                    <Select
                      style={{ 
                        borderRadius: '8px', 
                        textAlignalign: "left" 
                      }}
                      className="min-w-[200px] min-h-[24px] mr-[15px] text-left overflow-x-auto"
                      name="selct_modelIPR" 
                      placeholder={"Select scenario"}
                      value={inputReservoir?.selectDataIPR?.length ? inputReservoir?.selectDataIPR : undefined}
                      onChange={(e) => {
                        const maxSelections = inputReservoir?.check_phases === "three" ? 2 : 4;
                        if (e.length <= maxSelections) {
                          handleChangeSelect2('selectDataIPR', e, true);
                        }
                      }}
                      mode="multiple"
                      tagRender={tagRender}
                      disabled= {!!dataTableIPR ? false : true}
                    >
                      {queryListDataGraphic?.data
                        ?.filter(item => item.phase === inputReservoir?.check_phases && item.reservoir_type === inputReservoir.state_reservoir ) 
                        ?.map((item, index) => (
                          <Option key={index} value={item.name}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>{item.name} ({item.date})</span>
                          <DeleteOutlined
                            onClick={(e) => {
                              e.stopPropagation(); // Detener la propagación para evitar seleccionar la opción
                              handleDeleteScenario(item.name);
                            }} 
                            style={{ marginRight: 8, cursor: 'pointer' }}
                          />
                        </div>
                          </Option>
                        ))
                      }
                    </Select>
                  }
                  {!optionOpen && (
                    <Tooltip placement="bottom" title={"expand options"} color={"#662D91"}>
                      <CaretDownOutlined
                        onClick={() => handleChangeOptionOpen()}
                        className="flex w-6 h-6 fill-neutral-500 mr-2 hover:fill-pa-purple bg-opacity-50"
                      />
                    </Tooltip>
                  )}
                  {optionOpen && (
                    <Tooltip placement="bottom" title={"hide options"} color={"#662D91"}>
                      <CaretLeftOutlined
                        onClick={() => handleChangeOptionOpen()}
                        className="flex w-6 h-6 fill-neutral-500 mr-2 hover:fill-pa-purple bg-opacity-50"
                      />
                    </Tooltip>
                  )}
                  <Tooltip placement="bottom" title={"save calculations"} color={"#662D91"}>
                    <SaveData
                      onClick={handleOpenModalData}
                      className="w-6 h-7 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple justify-end"
                    />
                  </Tooltip>
                </div>
                {inputReservoir.check_phases != "three" && (
                  <ChartVogel 
                    dataResults={dataTableIPR}
                    optionDataIPR={inputReservoir.selectDataIPR}
                    IPRModel={inputReservoir.ipr_model}
                    dataIPR={queryDataGraphic?.data[0]}
                  >
                  </ChartVogel>
                )}
                {inputReservoir.check_phases === "three" && (
                  <ChartComposite
                  dataResults={dataTableIPR}
                  optionDataIPR={inputReservoir.selectDataIPR}
                  dataIPR={queryDataGraphic?.data[0]}
                  >
                  </ChartComposite>
                )}
              </div>
            </div>
            <div className="flex w-full text-center justify-center items-center mt-[10px] mb-[90px]">
              <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                <button
                  className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                  onClick={() => handleClickDone()}
                >
                  Done
                </button>
              </div>
            </div>
          </>
        )}
        {selectedValue === "undersaturated" && (
          <>
            <div className="flex flex-row gap-10 mt-[10px]">
              <div className="flex flex-col w-1/2 items-end">
                <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]" htmlFor="">Oil Flow Rate (STB/D)</label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                      <Tooltip title={'The value must be between 0.01 and 30000'}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_1_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_1_us}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("test_flow_rate_1_us", inputReservoir.test_flow_rate_1_us) 
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}
                      disabled={inputReservoir.productivity_index_j_us !== "" || inputReservoir.check_phases === "three"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                      <Tooltip title={'The value must be between 0.01 and 30000'}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_2_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_2_us}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("test_flow_rate_2_us", inputReservoir.test_flow_rate_2_us)
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}

                      disabled={(inputReservoir.productivity_index_j_us !== "" || inputReservoir.test_flow_rate_1_us === '') || (inputReservoir.test_flow_rate_1_us !== '' &&inputReservoir.check_phases !== "two") || inputReservoir.check_phases === "three"}
                      onBlur={(e) => handleBlurDataReservoir(e)} // Pasa el evento completo
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]" htmlFor="">
                  Bottomhole Pressure (psia)
                  <Tooltip title={`To use Fetkovich model pressure test 1 and pressure test 2 must be below bubble point pressure`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold textt-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                      <Tooltip title={'The value must be between 14.65 and 25000'}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      min="0"
                      onKeyDown={handleKeyDown}
                      name="test_pressure_1_us"
                      value={inputReservoir.test_pressure_1_us}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${validateWarningfields("test_pressure_1_us", inputReservoir.test_pressure_1_us) 
                        ? "border-red-700 bg-yellow-100" 
                        : "border-[#BCBCCC]"}${inputReservoir.productivity_index_j_us !== '' ? ' disabled' : ''} `}
                      disabled={inputReservoir.productivity_index_j_us !== ""} 
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                      <Tooltip title={'The value must be between 14.65 and 25000'}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      name="test_pressure_2_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_pressure_2_us}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("test_pressure_2_us", inputReservoir.test_pressure_2_us) 
                        ? " border-red-700 bg-yellow-100" 
                        : " border-[#BCBCCC]"} `}
                      disabled={(inputReservoir.productivity_index_j_us !== "" || inputReservoir.test_pressure_1_us === '') || (inputReservoir.test_flow_rate_1_us !== '' && inputReservoir.check_phases !== "two") || inputReservoir.check_phases === "three"}
                    />
                  </div>
                </div>
              </div>
            </div>
            {inputReservoir.check_phases === "three" && (
              <>
                <div className="flex flex-row w-full  gap-10">
                  <div className="flex flex-col w-1/2  items-end">
                    <label
                    htmlFor=""
                    className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Liquid Flow Rate (STB/D)
                    </label>
                    <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] mt-[6px]"
                      >
                        Test 1
                        <Tooltip title={'The value must be between 0.01 and 30000'}>
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                      </label>
                    <input
                      type="number"
                      name="liquid_flow_rate_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.liquid_flow_rate_us}
                      disabled={inputReservoir.productivity_index_j_us !== ""}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${validateWarningfields("liquid_flow_rate_us", inputReservoir.liquid_flow_rate_us)
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 items-start"></div>
                </div>
              </>
            )}
            <div className="flex flex-row w-full  gap-10">
              <div className="flex flex-col w-1/2  items-end">
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  IPR model
                </label>
                <Select
                  className="w-full h-[47px] max-w-[592px] text-start mb-[7px]"
                  name="ipr_model"
                  value={inputReservoir.ipr_model || "Selected option"}
                  onChange={(e) => handleChangeSelect2('ipr_model', e, false)}
                >
                  <Option value="straight" disabled={disabledCheck[1]}>Straight line</Option>
                  <Option value="vogel" disabled={disabledCheck[2]}>Vogel</Option>
                  <Option value="fetko" disabled={disabledCheck[3]}>Fetkovich</Option>
                  <Option value="composite" disabled={disabledCheck[4]}>Composite</Option>
                </Select>
              </div>
              <div className="flex flex-col w-1/2   items-start"></div>
            </div>
            <div className="flex flex-row w-full text-center items-center justify-center gap-y-[2em] mt-[17px] mb-[17px]">
              <button
                className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                onClick={handleCalculate}
              >
                Run
              </button>
            </div>
            <div className="flex w-full h-full text-center items-center justify-center">
              <div className="flex flex-col w-[1220px] h-full text-center items-center justify-center border-solid" 
                style={{
                  border: '1px solid #BCBCCC',
                }}
              >
                <div className="flex w-[1150px] h-6 text-center items-center justify-end gap-y-[2em] mt-[17px]">
                  {optionOpen &&
                    <Select
                      style={{ 
                        borderRadius: '8px', 
                        textAlignalign: "left" 
                      }}
                      className="min-w-[180px] min-h-[24px] mr-[15px] text-left overflow-x-auto"
                      name="selct_modelIPR" 
                      placeholder={"Select scenario"}
                      value={inputReservoir?.selectDataIPR?.length ? inputReservoir?.selectDataIPR : undefined}
                      onChange={(e) => {
                        const maxSelections = inputReservoir?.check_phases === "three" ? 2 : 4;
                        if (e.length <= maxSelections) {
                          handleChangeSelect2('selectDataIPR', e, true);
                        }
                      }}
                      mode="multiple"
                      tagRender={tagRender}
                      disabled= {!!dataTableIPR ? false : true}
                    >
                      {queryListDataGraphic?.data
                        ?.filter(item => item.phase === inputReservoir?.check_phases && item.reservoir_type === inputReservoir.state_reservoir ) 
                        ?.map((item, index) => (
                          <Option key={index} value={item.name}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>{item.name} ({item.date})</span>
                          <DeleteOutlined
                            onClick={(e) => {
                              e.stopPropagation(); // Detener la propagación para evitar seleccionar la opción
                              handleDeleteScenario(item.name);
                            }} 
                            style={{ marginRight: 8, cursor: 'pointer' }}
                          />
                        </div>
                          </Option>
                        ))
                      }
                    </Select>
                  }
                  {!optionOpen && (
                    <Tooltip placement="bottom" title={"expand options"} color={"#662D91"}>
                      <CaretDownOutlined
                        onClick={() => handleChangeOptionOpen()}
                        className="flex w-6 h-6 fill-neutral-500 mr-2 hover:fill-pa-purple bg-opacity-50"
                      />
                    </Tooltip>
                  )}
                  {optionOpen && (
                    <Tooltip placement="bottom" title={"hide options"} color={"#662D91"}>
                      <CaretLeftOutlined
                        onClick={() => handleChangeOptionOpen()}
                        className="flex w-6 h-6 fill-neutral-500 mr-2 hover:fill-pa-purple bg-opacity-50"
                      />
                    </Tooltip>
                  )}
                  <Tooltip placement="bottom" title={"save calculations"} color={"#662D91"}>
                    <SaveData
                      onClick={handleOpenModalData}
                      className="w-6 h-7 ml-[10px] fill-neutral-500 bg-opacity-50 hover:fill-pa-purple justify-end"
                    />
                  </Tooltip>
                </div>
                {inputReservoir.check_phases != "three" && (
                  <ChartStraightLine
                    dataResults={dataTableIPR}
                    optionDataIPR={inputReservoir.selectDataIPR}
                    dataIPR={queryDataGraphic?.data[0]}
                    IPRModel={inputReservoir.ipr_model}
                  ></ChartStraightLine>
                )}
                {inputReservoir.check_phases === "three" && (
                  <ChartComposite
                  dataResults={dataTableIPR}
                  optionDataIPR={inputReservoir.selectDataIPR}
                  dataIPR={queryDataGraphic?.data[0]}
                  >
                  </ChartComposite>
                )}
              </div>
            </div>
            <div className="flex w-full text-center justify-center items-center mt-[10px] mb-[90px]">
              <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                <button
                  className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                  onClick={() => handleClickDone()}
                >
                  Done
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  );
};
