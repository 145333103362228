export const DATA_END_PARAMETERS = {
  //Info PVT
  res_pressure: "",
  res_temperature: "",
  res_initial_gor: "",
  res_oil_gravity: "",
  res_gas_gravity: "",
  res_H2S: "",
  res_CO2: "",
  res_N2: "",

  option_data_point: "",
  calculate_correlation: "",
  data_point_table: [],

  //Info Reservoir
  layer_pressure: "",
  layer_temperature: "",
  md_perf_depth: "",
  water_cut_fraction: "",
  state_reservoir: "",
  ipr_model: "",
  test_pressure_1_s: "",
  test_flow_rate_1_s: "",
  test_pressure_2_s: "",
  test_flow_rate_2_s: "",
  liquid_flow_rate_s: "",
  productivity_index_j_s: "",
  test_pressure_1_us: "",
  test_flow_rate_1_us: "",
  test_pressure_2_us: "",
  test_flow_rate_2_us: "",
  liquid_flow_rate_us: "",
  productivity_index_j_us: "",
  check_phases: false,
  pwf_ipr: false,
  straight_line: false,
  bubblePointPressure: "",

  //Info Plunger lift
  reservoir_mid_perf_tvd_depth: "",
  reservoir_temperature: "",
  reservoir_pressure: "",
  production_test_date_pp: "",
  production_liquid_rate: "",
  production_gas_rate: "",
  production_gor: "",
  production_water_cut: "",
  production_supplemental_gas_rate: "",
  twp_test_date: "",
  twp_wellhead_temperature: "",
  twp_wellhead_pressure: "",
  twp_real_time: false,
  cwp_test_date: "",
  cwp_wellhead_pressure: "",
  cwp_wellhead_temperature: "",
  cwp_real_time: false,
  plunger_depth: "",
  plunger_weight: "",
  plunger_fvg: "",
  plunger_fvl: "",
  plunger_rising_velocity: "",
  tubing_data: [],
  required_casing_pressure: "",
  supplemental_gas_rate: "",
  required_gas_liquid_ratio: "",
  required_gas_volume_per_cycle: "",
  liquid_rate: "",
  gas_rate: "",
  cycles_per_day: "",

  //Info Dashboard
  data_alert: [],
  data_check: [],
  data_domain_graph: [],
  slide_graph_plungerlift: 1,
  type_graph_dashboard: [],
  checksRealTime:{dashboard:false},
};

export const DATA_PLUNGER_VALUES = {
  fluidLab: true,
  res_pressure: 6000,
  res_temperature: 215,
  res_oil_gravity: 31,
  res_gas_gravity: 0.7,
  res_initial_gor: 2500,
  res_H2S: 0.014,
  res_CO2: 0.039,
  res_N2: 0.005,
  res_water_salinity: 105000,
  calculate_correlation: "petrosky",
  calculate_correlation_oil: "glaso_beggs",
  option_data_point: "automatic",
  bubble_point_pressure: 10015,
  data_point_table: [
    {
      id: 0,
      name: "From",
      from_temperature: 215,
      from_pressure: 50,
    },
    {
      id: 1,
      name: "To",
      to_temperature: 215,
      to_pressure: 12000,
    },
    {
      id: 2,
      name: "Values",
      values_temperature: 1,
      values_pressure: 20,
    },
  ],

  well_id: "C009",
  region: "gulfofmexico",
  field: "Vermilion",
  latitude: "28°50'21.0\"N",
  company: "W&T Offshore",
  well_type: "producer",
  artificial_method: "none",
  flow_type: "tubing",
  well_name: "C009",
  discretized_well: false,
  well_platforms: "V-191",
  country: "United Stated",
  county: "NA",
  longitude: "92°09'45.0\"W",
  objective: "T2",
  well_orientation: "vertical",
  well_reference: "offshore",
  fluid_type: "black_oil",
  dataDeviation: [
    {
      id: 0,
      measured_depth: 0,
      tvd: 0,
      inc: 0,
    },
    {
      id: 1,
      measured_depth: 1650,
      tvd: 1650,
      inc: 0,
    },
    {
      id: 2,
      measured_depth: 10000,
      tvd: 10000,
      inc: 0,
    },
  ],
  dataEquipment: [
    {
      id: 0,
      name: "Tubing 1",
      type: "tubing",
      measured_depth: "8000",
      internal_diameter: 2.441,
      external_diameter: "2.875",
      absolute_roughness: "0.0018",
    },
    {
      id: 1,
      name: "Tubing 2",
      type: "tubing",
      measured_depth: "1000",
      internal_diameter: 2.441,
      external_diameter: "2.875",
      absolute_roughness: "0.0018",
    },
    {
      id: 2,
      name: "Casing 2",
      type: "casing",
      measured_depth: "1000",
      internal_diameter: 4.56,
      external_diameter: "5",
      absolute_roughness: "0.0018",
    },
  ],
  to_pressure: "500",
  values_pressure: "10",
  from_pressure: "5",
  injrate_max_wic: null,
  injrate_min_wic: null,
  injpress_max_wic: null,
  injpress_min_wic: null,
  data_alert: {},
  data_check: {},
  data_domain_graph: {},
  type_graph_dashboard: {},
  layer_pressure: "6000",
  layer_temperature: "215",
  md_perf_depth: "10001",
  water_cut_fraction: "96",
  state_reservoir: "saturated",
  ipr_model: "composite",
  test_pressure_1_s: "",
  test_flow_rate_1_s: "",
  test_pressure_2_s: "",
  test_flow_rate_2_s: "",
  productivity_index_j_s: "0.17",
  liquid_flow_rate_s: "",
  test_pressure_1_us: "",
  test_flow_rate_1_us: "",
  test_pressure_2_us: "",
  test_flow_rate_2_us: "",
  productivity_index_j_us: "",
  liquid_flow_rate_us: "",
  check_phases: "three",
  pwf_ipr: false,
  straight_line: false,
  bubblePointPressure: "10015",
};

export const DATA_CALCULATE_PL = {
  id_tubing: "",
  od_tubing_in: "",
  wh_temp_casing: "",
  res_temp: "",
  plunger_weight: "",
  plunger_rising_vel: "",
  plunger_fall_vel_liq: "",
  plunger_fall_vel_gas: "",
  wh_press_tubing: "",
  plunger_depth_md: "",
  id1_casing: "",
  id2_casing: "",
  id3_casing: "",
  length1: "",
  length2: "",
  length3: "",
  sgg_mix: "",
  yn2: "",
  yco2: "",
  yh2s: "",
  qo_max: [],
  qg_sc: "",
  qg_supp_sc: "",
  ql_sc: "",
  wh_press_casing: "",
};

export const DATA_PLUNGER_LIFT = {
  initialInputPlungerLift: {
    reservoir_mid_perf_tvd_depth: "",
    reservoir_temperature: "",
    reservoir_pressure: "",
    production_test_date_pp: "",
    production_liquid_rate: "",
    production_gas_rate: "",
    production_gor: "",
    production_water_cut: "",
    production_supplemental_gas_rate: "",
    twp_test_date: "",
    twp_wellhead_temperature: "",
    twp_wellhead_pressure: "",
    twp_real_time: false,
    cwp_test_date: "",
    cwp_wellhead_pressure: "",
    cwp_wellhead_temperature: "",
    cwp_real_time: false,
    plunger_depth: "",
    plunger_weight: "",
    plunger_fvg: "",
    plunger_fvl: "",
    plunger_rising_velocity: 1000,
    tubing_data: [],
    required_casing_pressure: "",
    supplemental_gas_rate: "",
    required_gas_liquid_ratio: "",
    required_gas_volume_per_cycle: "",
    liquid_rate: "",
    gas_rate: "",
    cycles_per_day: "",
  },
  initialDataTubing: [
    {
      id: 0,
      nameTubing: "",
      measured_depth: "",
      internal_diameter: "",
      external_diameter: "",
    },
    {
      id: 1,
      nameTubing: "",
      measured_depth: "",
      internal_diameter: "",
      external_diameter: "",
    },
    {
      id: 2,
      nameTubing: "",
      measured_depth: "",
      internal_diameter: "",
      external_diameter: "",
    },
  ],
};

export const DATA_WELL_PLUNGER_LIFT = {
  InitialDataWell: {
    well_id: "",
    region: "",
    field: "",
    latitude: "",
    company: "",
    well_type: "",
    artificial_method: "Plunger Lift",
    flow_type: "",
    well_name: "",
    well_platforms: "",
    country: "",
    county: "",
    longitude: "",
    objective: "",
    well_orientation: "",
    well_reference: "",
    fluid_type: "",
  },
  InitialWellDeviation: [
    {
      id: 0,
      measured_depth: "",
      tvd: "",
      inc: "",
    },
    {
      id: 1,
      measured_depth: "",
      tvd: "",
      inc: "",
    },
    {
      id: 2,
      measured_depth: "",
      tvd: "",
      inc: "",
    },
  ],
  InitialWelEquipment: [
    {
      id: 0,
      name: "Intermediate casing",
      type: "Casing",
      measured_depth: "",
      internal_diameter: "",
      external_diameter: "",
      absolute_roughness: "",
    },
    {
      id: 1,
      name: "Production Casing",
      type: "Casing",
      measured_depth: "",
      internal_diameter: "",
      external_diameter: "",
      absolute_roughness: "",
    },
    {
      id: 2,
      name: "Tubing",
      type: "Tubing",
      measured_depth: "",
      internal_diameter: "",
      external_diameter: "",
      absolute_roughness: "",
    },
  ],
  initialCoordinates: {
    latitudeDegrees: "",
    latitudeMinutes: "",
    latitudeSeconds: "",
    latitudeDirection: "",
    longitudeDegrees: "",
    longitudeMinutes: "",
    longitudeSeconds: "",
    longitudeDirection: "",
  },
};

export const DATA_RESERVOIR_PLUNGER_LIFT = {
  initialInputReservoir: {
    layer_pressure: "",
    layer_temperature: "",
    md_perf_depth: "",
    water_cut_fraction: "",
    state_reservoir: "saturated",
    ipr_model: "",
    test_pressure_1_s: "",
    test_flow_rate_1_s: "",
    test_pressure_2_s: "",
    test_flow_rate_2_s: "",
    productivity_index_j_s: "",
    liquid_flow_rate_s: "",
    test_pressure_1_us: "",
    test_flow_rate_1_us: "",
    test_pressure_2_us: "",
    test_flow_rate_2_us: "",
    productivity_index_j_us: "",
    liquid_flow_rate_us: "",
    check_phases: "",
    pwf_ipr: false,
    straight_line: false,
    bubblePointPressure: 0,
  },
};

export const DATA_PVT_PLUNGER_LIFT = {
  initial_data_pvt: {
    res_pressure: "",
    res_temperature: "",
    res_initial_gor: "",
    res_oil_gravity: "",
    res_gas_gravity: "",
    res_H2S: "",
    res_CO2: "",
    res_N2: "",
  },
  initialInputFluidLab: {
    temperature_lab: "",
    solution_gor_pb: "",
    bubble_point_pressure_lab: "",
    oil_fvf: "",
  },
  initialDataTableFluidLab1: [
    {
      id: 0,
      pressureTable1: "",
      gas_oil_ratio: "",
      oil_fvf: "",
      oil_viscosity: "",
      oil_compressibility: "",
    },
    {
      id: 1,
      pressureTable1: "",
      gas_oil_ratio: "",
      oil_fvf: "",
      oil_viscosity: "",
      oil_compressibility: "",
    },
    {
      id: 2,
      pressureTable1: "",
      gas_oil_ratio: "",
      oil_fvf: "",
      oil_viscosity: "",
      oil_compressibility: "",
    },
    {
      id: 3,
      pressureTable1: "",
      gas_oil_ratio: "",
      oil_fvf: "",
      oil_viscosity: "",
      oil_compressibility: "",
    },
  ],
  initialAutomaticData: [
    {
      id: 0,
      name: "From",
      from_temperature: "",
      from_pressure: "",
    },
    {
      id: 1,
      name: "To",
      to_temperature: "",
      to_pressure: "",
    },
    {
      id: 2,
      name: "Values",
      values_temperature: 1,
      values_pressure: "",
    },
  ],
  initialDataCalculation: {
    option_data_point: "",
    calculate_correlation: "brill_and_beggs",
  },
  initialUserSelectionData: [
    {
      id: 0,
      temperature: "",
      pressure: "",
    },
    {
      id: 1,
      temperature: "",
      pressure: "",
    },
    {
      id: 2,
      temperature: "",
      pressure: "",
    },
  ],
  stateChecks: [
    {
      gas_z_factor: true,
      gas_density: true,
    },
  ],
  initialDataTablePVT: [
    {
      rs: [],
      press: [],
      bo: [],
      viso: [],
      co: [],
    },
  ],
};

export const REQUIRED_PROPIERTIES_PL = [
  "well_id",
  "well_platforms",
  "well_name",
  "fluid_type",
  "region",
  "country",
  "field",
  "latitude",
  "longitude",
  "res_pressure",
  "res_temperature",
  "res_initial_gor",
  "res_oil_gravity",
  "res_gas_gravity",
  "res_H2S",
  "res_CO2",
  "res_N2",
  "layer_pressure",
  "layer_temperature",
  "md_perf_depth",
  "water_cut_fraction",
  "reservoir_mid_perf_tvd_depth",
  "reservoir_temperature",
  "reservoir_pressure",
  "production_water_cut",
];

export const INITIAL_POST_DATA_PVT = {
  API_gravity_of_oil: null,
  specific_gravity_of_gas: null,
  temp_res: null,
  rsb_init: null,
  rsb_sep: null,
  bob_sep: null,
  sGg_sep: null,
  p_sep: null,
  t_sep: null,
  API_sep: null,
  lab_temp: null,
  pb_lab: null,
  rsb_lab: null,
  rsdb_lab: null,
  bodb_lab: null,
  rsw_calc: null,
  pb_calc: null,
  specific_gravity_of_oil: null,
};

export const INITIAL_POST_PVT_CALC = {
  p_lab: null,
  rs_lab: null,
  rs_init: null,
  co_lab: null,
  bo_lab: null,
  visc_oil_lab: null,
  press_calc: null,
  numbers_press_rows: null,
};

export const DATA_RESERVOIR = {
  initialInputReservoir: {
    layer_pressure: "",
    layer_temperature: "",
    md_perf_depth: "",
    water_cut_fraction: "",
    state_reservoir: "saturated",
    ipr_model: "",
    test_pressure_1_s: "",
    test_flow_rate_1_s: "",
    test_pressure_2_s: "",
    test_flow_rate_2_s: "",
    productivity_index_j_s: "",
    liquid_flow_rate_s: "",
    test_pressure_1_us: "",
    test_flow_rate_1_us: "",
    test_pressure_2_us: "",
    test_flow_rate_2_us: "",
    productivity_index_j_us: "",
    liquid_flow_rate_us: "",
    check_phases: "",
    pwf_ipr: false,
    straight_line: false,
    bubblePointPressure: 0,
  },
};

export const DATA_DOMAIN_GRAPH = {
  gas_rate_min_wpc: null,
  gas_rate_max_wpc: null,
  press_max_wpc: null,
  press_min_wpc: null,
  gas_rate_min_ph: null,
  gas_rate_max_ph: null,
  liquid_rate_min_ph: null,
  liquid_rate_max_ph: null,
};

export const WELL_SENSOR = {
  well: [
    {
      name: "SMI-27",
    },
    {
      name: "V-191",
    },
  ],
};

export const WELL_SENSOR_TYPE_DEFAULT = {
  data: [
    {
      name: "SMI-27",
      sensor: "SMI-27 IP Separator",
    },
    {
      name: "V-191",
      sensor: "V-191 Test Separator",
    },
  ],
};

export const STATE_ALERTS = {
  alert_alm_GLRrequired: "alm_GLRrequired",
  alert_alm_GLRproduced: "alm_GLRproduced",
  alert_alm_casing_pressure_required: "alm_casing_pressure_required",
  alert_alm_casing_pressure_available: "alm_casing_pressure_available",
  alert_alm_cycle_day: "alm_cycle_day",
  alert_twc_Pressure: "twc_Pressure",
  alert_twc_temperature: "twc_temperature",
  alert_em_npv: "em_npv",
  alert_em_roi: "em_roi",
  alert_cwc_Pressure: "cwc_Pressure",
  alert_cwc_temperature: "cwc_temperature",
  alert_pic_Pressure: "pic_Pressure",
  alert_pic_temperature: "pic_temperature",
  alert_prc_GasRate: "prc_GasRate",
  alert_prc_LiquidRate: "prc_LiquidRate",
  alert_prc_Glr: "prc_Glr",
  alert_prc_GasVolumePer: "prc_GasVolumePer",
  alert_prc_LiquidVolumPer: "prc_LiquidVolumPer",
};


export const INITIAL_DATA_ALERTS_PLUNGER_LIFT = {
  alm_GLRrequired_min: 0,
  alm_GLRrequired_max: 0,
  alm_GLRproduced_min: 0,
  alm_GLRproduced_max: 0,
  alm_casing_pressure_required_min: 0,
  alm_casing_pressure_required_max: 0,
  alm_casing_pressure_available_min: 0,
  alm_casing_pressure_available_max: 0,
  alm_cycle_day_min: 0,
  alm_cycle_day_max: 0,
  twc_Pressure_min: 0,
  twc_Pressure_max: 0,
  twc_temperature_min: 0,
  twc_temperature_max: 0,
  em_npv_min: 0,
  em_npv_max: 0,
  em_roi_min: 0,
  em_roi_max: 0,
  cwc_Pressure_min: 0,
  cwc_Pressure_max: 0,
  cwc_temperature_min: 0,
  cwc_temperature_max: 0,
  pic_Pressure_min: 0,
  pic_Pressure_max: 0,
  pic_temperature_min: 0,
  pic_temperature_max: 0,
  prc_GasRate_min: 0,
  prc_GasRate_max: 0,
  prc_LiquidRate_min: 0,
  prc_LiquidRate_max: 0,
  prc_Glr_min: 0,
  prc_Glr_max: 0,
  prc_GasVolumePer_min: 0,
  prc_GasVolumePer_max: 0,
  prc_LiquidVolumPer_min: 0,
  prc_LiquidVolumPer_max: 0,
};

export const dataCheckPVT = [
  {
    name: "Bubble point pressure",
    check: false,
  },
  {
    name: "Solution Gas-Oil ratio",
    check: false,
  },
  {
    name: "Oil formation volume factor",
    check: false,
  },
  {
    name: "Oil viscosity",
    check: false,
  },
];

export const EMPTY_DATA_PVT = {
  res_pressure: "",
  res_temperature: "",
  res_initial_gor: "",
  res_oil_gravity: "",
  res_gas_gravity: "",
  res_H2S: "",
  res_CO2: "",
  res_N2: "",
  option_data_point: "",
  calculate_correlation: "",
  data_point_table: [],
};

export const EMPTY_DATA_RESERVOIR = {
  layer_pressure: "",
  layer_temperature: "",
  md_perf_depth: "",
  water_cut_fraction: "",
  state_reservoir: "",
  ipr_model: "",
  test_pressure_1_s: "",
  test_flow_rate_1_s: "",
  test_pressure_2_s: "",
  test_flow_rate_2_s: "",
  productivity_index_j_s: "",
  test_pressure_1_us: "",
  test_flow_rate_1_us: "",
  test_pressure_2_us: "",
  test_flow_rate_2_us: "",
  productivity_index_j_us: "",
  check_phases: false,
  pwf_ipr: false,
  straight_line: false,
};

export const TYPE_GRAPH_FLUID_CONFIG = [
  {
    name: "Gas Z Factor",
    value: "gas_z_factor",
  },
  {
    name: "Gas Density",
    value: "gas_density",
  },
];

export const NUMBER_FORMAT_MAP = {
  cycles_per_day: "1-decimal",
  estimated_cycles_per_day: "1-decimal",
  current_cycles_per_day: "1-decimal",
  change_casing_pressure: "2-decimals",
  change_gas_liquid_ratio: "2-decimals",
  change_gas_rate: "2-decimals",
  change_liquid_rate: "2-decimals",

  required_casing_pressure: "2-decimals",
  liquid_rate: "2-decimals",
  estimated_liquid_rate: "2-decimals",
  current_liquid_rate: "2-decimals",
  gas_rate: "2-decimals",
  estimated_gas_rate: "2-decimals",
  current_gas_rate: "2-decimals",

  supplemental_gas_rate: "4-decimals",
  required_gas_liquid_ratio: "4-decimals",
  required_gas_volume_per_cycle: "4-decimals",
  plunger_depth: "4-decimals",
  plunger_fvl: "4-decimals",
  plunger_fvg: "4-decimals",

  cwp_wellhead_pressure: "cwp_wellhead_pressure",
  twp_wellhead_pressure: "twp_wellhead_pressure",
};

export const REQUIRED_COLUMNS_DOCUMENT_HISTORY = [
  "Date", 
  "gas_rate (MSCF/D)",
  "liquid_rate (STB/D)" ,
  "casing_pressure (psia)" ,
  "tubing_pressure (psia)" ,
  "pipeline_pressure (psia)" 
];

export const REQUIRED_COLUMNS_TABLE_DEVIATION = [
  "measured_depth",
  "tvd",
  "inc",
];

export const REQUIRED_COLUMNS_TABLE_EQUIPMENT = [
  "name",
  "type",
  "measured_depth",
  "internal_diameter",
  "external_diameter",
  "absolute_roughness",
];

export const SELECT_DASHBOARD_PL = {
  wellhead_production_conditions: {
    gas_rate: "",
    casing_pressure: "",
    tubing_pressure: "",
    pipeline_pressure: "",
  },
  production_history: {
    device_sensor: "",
  }
}