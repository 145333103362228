// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage_containerGeneral__wj3NT {
  width: 100%;
  height: 350px;
  padding: 5px;
}

.ErrorPage_containerError__54Go8 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/PlungerLift/Complements/ErrorPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":[".containerGeneral {\n  width: 100%;\n  height: 350px;\n  padding: 5px;\n}\n\n.containerError {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerGeneral": `ErrorPage_containerGeneral__wj3NT`,
	"containerError": `ErrorPage_containerError__54Go8`
};
export default ___CSS_LOADER_EXPORT___;
