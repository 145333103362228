import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dataExample from '../../../data/dataMultiWellTrending.json';
const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const ChartMultiWell = () => {

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={dataExample}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Date (MM/DD/YYYY)" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="Press DD C6" stroke="#8884d8" />
        <Line type="monotone" dataKey="Press DD C9" stroke="#ca6f1e" />
        <Line type="monotone" dataKey="PI C6" stroke="#82ca9d" />
        <Line type="monotone" dataKey="PI C9" stroke="#3498db" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default ChartMultiWell;
