//Funcion Para establecer formato de fecha string del straming
export const formatDateIOT = (dateString) => {
    if (dateString.includes(' ')) {
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-');
        const formattedDate = `${parseInt(month)}/${parseInt(day)}/${year} ${timePart}`;
        return isNaN(new Date(formattedDate).getTime()) ? null : formattedDate;
    } else {
        const [year, month, day] = dateString.split('-');
        const formattedDate = `${parseInt(month)}/${parseInt(day)}/${year} 00:00:00`;
        return isNaN(new Date(formattedDate).getTime()) ? null : formattedDate;
    }
};

//Funcion Para establecer formato de fecha numerica desde excel
export const formatDateHist = (excelDate) => {
    const dateEnd = Number(excelDate)
    // Validar si es un número válido
    if (isNaN(dateEnd)) {
        return null;
    }
    // Base date de Excel (1 de enero de 1900)
    const baseDate = new Date(1900, 0, 1);
    // Calcular los milisegundos desde el 1 de enero de 1970
    const jsTimestamp = baseDate.getTime() + (dateEnd - 2) * 24 * 60 * 60 * 1000;
    return jsTimestamp;
};