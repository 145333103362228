import { serviceEndPoints } from "../../config/config";
import { validateParams } from "../utils/utils";

const DB_URL_IOT = serviceEndPoints.IOT.BASE_URL_IOT;
const DB_URL_DEVICES_DCA = serviceEndPoints.DCA.BASE_URL_DEVICE;
const DB_URL_DEVICES_GASLIFT = serviceEndPoints.GASLIFT.BASE_URL_DEVICE;

export const getJsonIOT = async (operator, lease, region, wellplatform) => {
    if (!validateParams(operator, lease, region, wellplatform)) return;

    try {
        const response = await fetch(`${DB_URL_IOT}/iotwelldata/${operator}/${lease}/${region}/${wellplatform}/200`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs",
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the IoT data");
    }
};

export const getListPlatforms = async (operator, lease, region) => {
    if (!validateParams(operator, lease, region)) return;

    try {
        const response = await fetch(`${DB_URL_IOT}/platformslist/${operator}/${lease}/${region}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs",
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the platforms list");
    }
};

export const getListPlatformsWells = async (operator, lease, region, wellplatform) => {
    if (!validateParams(operator, lease, region, wellplatform)) return;

    try {
        const response = await fetch(`${DB_URL_IOT}/platformwells/${operator}/${lease}/${region}/${wellplatform}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs",
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the platform wells list");
    }
};

export const getListDevicesDCA = async (operator, lease, region, wellplatform) => {
    if (!validateParams(operator, lease, region, wellplatform)) return;

    try {
        const response = await fetch(`${DB_URL_DEVICES_DCA}/platformsensorsplotting/${operator}/${lease}/${region}/${wellplatform}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs",
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the devices list for DCA");
    }
};

export const getListDevicesGaslift = async (operator, lease, region, wellplatform) => {
    if (!validateParams(operator, lease, region, wellplatform)) return;

    try {
        const response = await fetch(`${DB_URL_DEVICES_GASLIFT}/platformsensorsplotting/${operator}/${lease}/${region}/${wellplatform}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs",
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the devices list for Gaslift");
    }
};
