import React, { useContext, useEffect, useState } from "react";
import { Sidebar } from "../../components/layout/Sidebar";
import { Navbar } from "../../components/layout/Navbar";
import { Steps, message, Select, Tooltip, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as IconDelete } from '../../assets/img/icons/delete.svg';
import { ReactComponent as IconAdd } from '../../assets/img/icons/add.svg';
import Img_Wellhead from '../../assets/img/wellhead-img.png'
import { ImportFile } from "../../components/PlungerLift/Complements/ImportFile";
import { AlertModal } from "../../components/AlertModal";
import { DATA_END_PARAMETERS, DATA_WELL_PLUNGER_LIFT } from "../../components/elements/types/type_plunger_Lift";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { STATUS_TYPE_WELL_GASLIFT } from "../../components/elements/types/type_response_API";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import { removeEmptyRows } from "../../helpers/helpersProcess";
import { AddKeysMissing } from "../../helpers/helpersValidate";

const steps = [
  {
    title: "Well",
    content: "First-content",
  },
  {
    title: "Second",
    content: "Second-content",
  },
  {
    title: "Last",
    content: "Last-content",
  },
];

export const StepMenuEditWellPlungerLift = () => {
  const { Option } = Select;
  const navigate = useNavigate();
  const {
    User,
    userDataInfo,
    dataFilterUserPL,
    refetchDataWellPL,
    dataWellPL,
    mutationUpdateWellPlungerLift
  } = useTabPlungerLift();

  const [ wellboreOptionState, setWellboreOptionState] = useState(false);
  const [ dataDeviation, setDataDeviation] = useState(DATA_WELL_PLUNGER_LIFT.InitialWellDeviation);
  const [ dataEquipment, setDataEquipment] = useState(DATA_WELL_PLUNGER_LIFT.InitialWelEquipment);
  const [ inputWellInformation, setInputWellInformation] = useState(DATA_WELL_PLUNGER_LIFT.InitialDataWell);
  const [ current, setCurrent] = useState(0);
  const [ status, setStatus] = useState(false);
  const [ showAlert, setShowAlert] = useState(false);
  const [ messageAlert, setMessageAlert] = useState(false);
  const [ typeAlert, setTypeAlert] = useState(false);
  const [ inputEmpty, setInputEmpty] = useState(false);
  const [ coordinate , setCoordinate] =  useState(DATA_WELL_PLUNGER_LIFT.initialCoordinates);
  const [ wellOrientation, setWellOrientation] = useState();
  const [ dataEndInformation, setDataEndInformation ] = useState();

  useEffect(()=>{
    refetchDataWellPL();
  },[]);
  
  useEffect(()=>{
    setTimeout(() => {
      if(dataWellPL?.statusType === STATUS_TYPE_WELL_GASLIFT.SUCCESS && dataWellPL?.data){

        const dataGetEdit = dataWellPL?.data
        const latitudeData = dataGetEdit?.latitude?.split(/[°'"]/);
        const longitudeData = dataGetEdit?.longitude?.split(/[°'"]/);
        const filterDataEnd = Object.assign({}, inputWellInformation, { dataDeviation: dataDeviation }, { dataEquipment: dataEquipment });

        const filteredJson = Object.fromEntries(
          Object.entries(dataGetEdit).filter(([key]) => !(key in filterDataEnd))
        );
        const filteredJsonEnd = AddKeysMissing(filteredJson, DATA_END_PARAMETERS)
        setDataEndInformation(filteredJsonEnd);

        setInputWellInformation({
          ...inputWellInformation,
          well_id: dataGetEdit?.well_id,
          region: dataGetEdit?.region,
          field: dataGetEdit?.field,
          latitude: dataGetEdit?.latitude,
          company: dataGetEdit?.company,
          well_type: dataGetEdit?.well_type,
          artificial_method: dataGetEdit?.artificial_method,
          flow_type: dataGetEdit?.flow_type,
          well_name: dataGetEdit?.well_name,
          discretized_well: dataGetEdit?.discretized_well,
          well_platforms: dataGetEdit?.well_platforms,
          country: dataGetEdit?.country,
          county: dataGetEdit?.county,
          longitude: dataGetEdit?.longitude,
          objective: dataGetEdit?.objective,
          well_orientation: dataGetEdit?.well_orientation,
          well_reference: dataGetEdit?.well_reference,
          fluid_type: dataGetEdit?.fluid_type
        });

        const dataEquipmentConvert = dataGetEdit?.dataEquipment?.map(item => ({
          ...item,
          measured_depth: Number(item.measured_depth),
          external_diameter: Number(item.external_diameter),
          internal_diameter: Number(item.internal_diameter),
          absolute_roughness: Number(item.absolute_roughness)
        }));

        const dataDeviationConvert = dataGetEdit?.dataDeviation?.map(item => ({
          ...item,
          inc: Number(item.inc),
          measured_depth: Number(item.measured_depth),
          tvd: Number(item.tvd),
        }));

        setDataDeviation(dataDeviationConvert);
        setDataEquipment(dataEquipmentConvert);

        setCoordinate({
          ...coordinate,
          latitudeDegrees: latitudeData[0],
          latitudeMinutes: latitudeData[1],
          latitudeSeconds: latitudeData[2],
          latitudeDirection: latitudeData[3],
          longitudeDegrees: longitudeData[0],
          longitudeMinutes: longitudeData[1],
          longitudeSeconds: longitudeData[2],
          longitudeDirection: longitudeData[3]
        });

        setWellOrientation(dataGetEdit?.well_orientation);

      }else{
        setInputWellInformation([]);
      }
    }, 1000);
  },[dataWellPL]);

  useEffect(() => {
    setInputWellInformation(prevState => ({
      ...prevState,
      latitude: `${coordinate.latitudeDegrees}${'°'}${coordinate.latitudeMinutes}${'\''}${coordinate.latitudeSeconds}${'"'}${coordinate.latitudeDirection}`
    }));
  
    setInputWellInformation(prevState => ({
      ...prevState,
      longitude: `${coordinate.longitudeDegrees}${'°'}${coordinate.longitudeMinutes}${'\''}${coordinate.longitudeSeconds}${'"'}${coordinate.longitudeDirection}`
    }));
  }, [coordinate]);
  
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const onChange = (value) => {
    setCurrent(value);
  };

  const handleClickNext = () => {
    if (current === 0) {
      if (inputWellInformation.well_id === '' || inputWellInformation.region === '' || inputWellInformation.field === '' || coordinate.latitudeDegrees === '' || coordinate.latitudeMinutes === '' || coordinate.latitudeSeconds === '' || coordinate.latitudeDirection === '' || inputWellInformation.company === ''
        || inputWellInformation.well_type === '' || inputWellInformation.artificial_method === '' || inputWellInformation.flow_type === '' || inputWellInformation.well_name === '' ||
        inputWellInformation.country === '' || inputWellInformation.county === '' || coordinate.longitudeDegrees === '' || coordinate.longitudeMinutes === '' || coordinate.longitudeSeconds === '' || coordinate.longitudeDirection === '' || inputWellInformation.objective === '' || inputWellInformation.well_orientation === '' ||
        inputWellInformation.well_reference === '' || inputWellInformation.fluid_type === ''
      ) {
        handleOpenModal();
        setTypeAlert("Warning")
        setMessageAlert('There are empty fields please validate that the information is complete')
        setInputEmpty(true)
        handleStatusChange();
      }
      else {
        setInputEmpty(false)
        setCurrent(current + 1);
      }
    }
    if (current === 1) {

      const validateDataNull = VerifyingNulls(dataDeviation)

      if (validateDataNull) {
        handleOpenModal();
        setTypeAlert("Warning")
        setMessageAlert('The table is empty please enter rows or load a file')
      }
      else {
        setCurrent(current + 1);
      }
    }
  };

  const handleClickPrev = () => {
    setCurrent(current - 1);
  };

  const handleClickDone = () => {
    const validateDataNull = VerifyingNulls(dataEquipment)
    if (validateDataNull ) {
      handleOpenModal();
      setTypeAlert("Warning")
      setMessageAlert('The table is empty or populated incorrectly, please enter rows or upload a file');
      setInputEmpty(true);
    }
    else {
      setInputEmpty(false);

      const dataCreateWellTables = { dataDeviation: [...dataDeviation], dataEquipment: [...dataEquipment] };      
      const dataCreateWell = Object.assign({}, dataEndInformation, dataCreateWellTables, inputWellInformation);
      
      setTimeout(() => {
        mutationUpdateWellPlungerLift.mutate({
          operator: userDataInfo.operator, 
          lease: userDataInfo.lease, 
          region: userDataInfo.region, 
          county: dataFilterUserPL.county, 
          field: dataFilterUserPL.field,
          well: dataFilterUserPL.well_id, 
          username: User.user_name, 
          data: dataCreateWell ,
          type: "parametersplungerlift"
        });
        navigate(`/${NAME_PAGES.plunger_lift.list_well}`);
        message.success("Processing Update complete!");
      }, 100);
    }
  };

  const handleAddDeviation = () => {
    const uniqueIds = [...new Set(dataDeviation.map(obj => obj.id))];
    const maxId = Math.max(...uniqueIds);
    const newDeviation = {
      id: maxId + 1,
      measured_depth: null,
      tvd: null,
      inc: null,
    };
    setDataDeviation([...dataDeviation, newDeviation]);
  };

  const handleDeleteDeviation = (id) => {
    const newData = dataDeviation.filter((item) => item.id !== id);
    setDataDeviation(newData);
  };

  const handleUploadFile = (dataFile) => {
      const dataTableProcessed = removeEmptyRows(dataDeviation);
    const uniqueIds = [...new Set(dataDeviation.map(obj => obj.id))];
    const maxId = (Math.max(...uniqueIds)) + 1;
    const dataFileEnd = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };

    });
    const previousIndex = (dataDeviation.length - 1);
    const lastValue = dataDeviation[dataDeviation.length - 1].measured_depth;

    const calculateMaxIndex = (array, currentIndex) => {
      let max = -Infinity;
      for (let i = 0; i < currentIndex; i++) {
          if (array[i].measured_depth > max) {
              max = array[i].measured_depth;
          }
      }
      return max;
    };
  
    const dataFileUp = dataFileEnd.map((item, index) => {
  
      const previousItem = dataFileEnd[index - 1];
      const previousMeasuredDepth = previousItem ? previousItem.measured_depth : null;
      const lastMax = calculateMaxIndex(dataFileEnd, index);
  
      const newMeasuredDepth = (
        (item.measured_depth < 0 || item.measured_depth > 50000) ||
        (item.measured_depth <= lastValue && (index + previousIndex) === previousIndex) ||
        (previousMeasuredDepth >= item.measured_depth) || 
        (
          (previousMeasuredDepth >= item.measured_depth  ? previousMeasuredDepth : lastMax) >= (item.measured_depth)
        ) || (item.measured_depth === lastMax)
    ) ? null : Number(item.measured_depth);
        
      return {
          ...item,
          measured_depth: newMeasuredDepth,
          tvd: (0 <= item.tvd  && item.tvd <= 60000 && item.tvd <= item.measured_depth) ? item.tvd : null
      };
    });
    const dataConcat =   dataTableProcessed.length ? [...dataTableProcessed,...dataFileUp] : dataFileUp;
    setDataDeviation(dataConcat);
  };

  const handleUploadFile2 = (dataFile) => {
      
    const dataTableProcessed = removeEmptyRows(dataEquipment);
    const uniqueIds = [...new Set(dataEquipment.map(obj => obj.id))];
    const maxId = (Math.max(...uniqueIds)) + 1;
    const dataFileEnd = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };
    });

      const dataFileValidate = dataFileEnd.map((item,index) => ({
      id: index,
        type: (item.type !== 'Tubing' && item.type !== 'Casing') ? null : item.type,
      name: item.name,
      ...item,
      measured_depth: item.measured_depth < 0 || item.measured_depth > 50000 ? null : item.measured_depth,
      external_diameter: ((item.external_diameter < 0.75|| item.external_diameter > 24)|| item.external_diameter <= item.internal_diameter )? null : item.external_diameter,
      internal_diameter:((item.internal_diameter < 0.75|| item.internal_diameter > 24) || (item.internal_diameter >= item.external_diameter)) ? null : item.internal_diameter,
      absolute_roughness: (item.absolute_roughness > 0.1 || item.absolute_roughness < 0) ? null : item.absolute_roughness
    }));
      const dataConcat =   dataTableProcessed.length ? [...dataTableProcessed,...dataFileValidate] : dataFileValidate;

    setDataEquipment(dataConcat);
  };

  const handleChangeInformation = (e) => {
    const { name, value } = e.target;
    setInputWellInformation(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeDeviation = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : null;
    setDataDeviation(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: dataValue } : item
        )
    );
  };

  const handleInputBlurTest = (e, id) => {
    const {name, value} = e.target;
    let dataValue = value !== "" ? Number(value) : null;

    const selectedIndex = dataDeviation.findIndex(
      (data) => data.id === id
    );
    const lastIndex = selectedIndex !== 0 ? (selectedIndex - 1) : selectedIndex;
    if (value !== "") {
      if (
        (name === "measured_depth" ) &&
        (dataValue < 0 || dataValue > 50000)
      ) {
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Message");
         setMessageAlert(`Length values must be between 0 and 50000 (feet)`);
         setDataDeviation(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: null } : item
          )
        );
      } else if (!!dataValue && name === 'measured_depth' && (dataValue <= dataDeviation[lastIndex].measured_depth && selectedIndex !== lastIndex)) {
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Message");
        setMessageAlert("Measured depth value can't be less than previous row value");
        setDataDeviation(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: null } : item
          )
        );
      } else if (name === 'tvd' && dataValue > dataDeviation[selectedIndex].measured_depth) {
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Message");
        setMessageAlert("The value can't be greater than measured depth value");
        setDataDeviation(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: null } : item
          )
        );
      } else if (name === "tvd" && (dataValue < 0 || dataValue > 50000)) {
        setInputEmpty(true)
        handleOpenModal();
        setTypeAlert("Message");
        setMessageAlert("TVD value can't be less than to 0 or greater than 60000");
        setDataDeviation(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: null } : item
          )
        );
      } else if (name === "inc" && (dataValue < -12 || dataValue > 120)) {
          setInputEmpty(true);
          handleOpenModal();
          setTypeAlert("Message");
          setMessageAlert("Inclination values must be between -12 and 120 (degrees)");
          setDataDeviation(prevState =>
              prevState.map(item =>
                  item.id === id ? { ...item, [name]: null } : item
              )
          );
      }
     }
    

  }

  const handleChangeEquipment = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? parseFloat(value) : null;

    setInputEmpty(false);

    setDataEquipment(prevState =>
      prevState.map(item =>
        item.id === id ? { ...item, [name]: dataValue } : item
      )
    );
  };

  const handleChangeBlur = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? parseFloat(value) : "";
    const selectedIndex = dataEquipment.findIndex(
      (data) => data.id === id
    );
    if (value !== "") {
      
      if (
        (name === "measured_depth" ) &&
        (dataValue < 0 || dataValue > 50000)
      ) {
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Message");
        setMessageAlert(`Length values must be between 0 and 50000 (feet)`);
        setDataEquipment(prevState =>
          prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
        );
      } 
      else if ((name === "internal_diameter" || name === "external_diameter") && (dataValue <= 0 || dataValue > 24)) {
          setInputEmpty(true);
          handleOpenModal();
          setTypeAlert("Message");
          setMessageAlert("Diameters values must be between 0.75 and 24 (inches)");
          setDataEquipment(prevState =>
              prevState.map(item =>
                  item.id === id ? { ...item, [name]: null } : item
              )
          );
      } 
      else if (name === "external_diameter" && dataValue <= dataEquipment[selectedIndex].internal_diameter) {
          setInputEmpty(true);
          handleOpenModal();
          setTypeAlert("Message");
          setMessageAlert("External diameter value can't be less than or equal to Internal diameter value");
          setDataEquipment(prevState =>
              prevState.map(item =>
                  item.id === id ? { ...item, [name]: null } : item
              )
          );
      } 
      else if (name === "internal_diameter" && dataValue >= dataEquipment[selectedIndex].external_diameter) {
          setInputEmpty(true);
          handleOpenModal();
          setTypeAlert("Message");
          setMessageAlert("Internal diameter value can't be greater than or equal to External diameter value");
          setDataEquipment(prevState =>
              prevState.map(item =>
                  item.id === id ? { ...item, [name]: null } : item
              )
          );
      } 
      else if (name === "measured_depth"){
        const valMeasuredDepth = sumMeasureDepth()
       
        if (valMeasuredDepth) {
          setInputEmpty(true)
          handleOpenModal();
          setTypeAlert("Message");
          setMessageAlert("the sum of MD Length in the table cannot exceed the maximum value of Measured Depth in Well Orientation");
          setDataEquipment(prevState =>
            prevState.map(item =>
              item.id === id ? { ...item, [name]: null } : item
            )
          );
        }
          
      }
      else if (
        (name === "absolute_roughness" && (dataValue > 0.1 || dataValue <= 0))
      ) {
        setInputEmpty(true);
        handleOpenModal();
        setTypeAlert("Message");
        setMessageAlert(`The absolute roughness value cannot be greater than 0.1 or less than or equal to 0.`);
        setDataEquipment(prevState =>
              prevState.map(item =>
                  item.id === id ? { ...item, [name]: null } : item
              )
          );  
      }
    }
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleChangeSelect = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInformation(customEvent);
    if(nameData === "well_orientation"){
      setWellOrientation(valueData)
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  }; 

  const handleChangeCoor = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "latitudeDegrees" || 
        name === "latitudeMinutes" || 
        name === "longitudeDegrees" || 
        name === "longitudeMinutes"
    ) {
      newValue = value.replace(/[^\d]/g, '');
    } else if (name === "latitudeSeconds" || name === "longitudeSeconds") {
      newValue = value.replace(/[^\d.]/g, '');
      if (value.length === 3 && value.indexOf(".") === -1) {
        newValue = value.slice(0, 2) + "." + value.slice(2);
      }
      const decimalIndex = newValue.indexOf('.');
      if (decimalIndex !== -1) {
        const integerPart = newValue.slice(0, decimalIndex);
        const decimalPart = newValue.slice(decimalIndex + 1, decimalIndex + 3);
        newValue = `${integerPart}.${decimalPart}`;
        if (value.indexOf(".") !== -1 && decimalPart === '') {
          newValue = value.slice(0, -1);
        }
      } 
    }

    setCoordinate((prevState) =>({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleChangeSelectCoor = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeCoor(customEvent);
  };

  function VerifyingNulls(array) {
    let validateValuesNull = false;
    if (wellOrientation === "vertical") {
      array.forEach(obj => {
        Object.entries(obj).forEach(([key, value]) => {
          if (key !== 'inc' && (value === null || value === '')) {
            validateValuesNull = true;
          }
        });
      });
    }else{
      array.forEach(obj => {
        Object.values(obj).forEach(value => {
          if (value === null || value === '') {
            validateValuesNull = true;
          }
        });
      });
    }

    return validateValuesNull;
  }

  function sumMeasureDepth() {
    const valuesDeviation = dataDeviation.length ? dataDeviation.length : null;
    const lastDeviation = dataDeviation[valuesDeviation - 1].measured_depth;
    
    const value= dataEquipment.reduce((total, item) => {
      return total + parseFloat(item.measured_depth);
    }, 0);
    return(value > lastDeviation);
  };
  
  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="absolute flex h-full "></Sidebar>
      </div>
      <div className="flex w-full h-full">
        <AlertModal showAlert={showAlert} handleCloseModal={handleCloseModal} messageAlert={messageAlert} typeModal={typeAlert}></AlertModal>
      </div>

      <div className="flex w-full pl-[88px] text-center justify-center mt-[30px]">
        <Steps
          size="default"
          current={current}
          onChange={onChange}
          className="site-navigation-steps flex w-[600px] custom-steps"
          items={[
            {
              title: "Well",
              disabled: true,
            },
            {
              title: "Well deviation",
              disabled: true,
            },
            {
              title: "Well equipment",
              disabled: true,
            },
          ]}
        />
      </div>
      <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center">
        {current == 0 &&
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[20px]a">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]" >Well information</h1>
              </div>
            </div>
            <div className="grid grid-cols-2 w-full h-[750px]  gap-x-[43px]">
              <div className="flex flex-col items-end text-right">
                <label htmlFor="" className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well API Number/Well ID</label>
                <div className="flex flex-row w-full max-w-[592px] gap-5">
                  <input 
                    type="text" 
                    name="well_id" 
                    disabled={true} 
                    value={inputWellInformation.well_id} 
                    onChange={handleChangeInformation} 
                    className={`w-full h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid ${inputEmpty && inputWellInformation.well_id === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}
                  />
                  {/* <button disabled onClick={handleClickGenerateId} className="w-1/3 h-[47px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1">Generate Id</button> */}
                </div>
                <label 
                  htmlFor="" 
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                  >
                    Region
                </label>
                <input 
                  type="text" 
                  disabled={true} 
                  name="region"
                  value={inputWellInformation.region} 
                  onChange={handleChangeInformation} 
                  className={`w-full max-w-[592px] px-3 h-[47px] rounded-[4px]  mb-[17px] border border-solid ${inputEmpty && inputWellInformation.region === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} 
                />
                <label 
                  htmlFor="" 
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Field
                </label>
                <input 
                  type="text" 
                  disabled={true} 
                  name="field" 
                  value={inputWellInformation.field} 
                  onChange={handleChangeInformation} 
                  className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border border-solid ${inputEmpty && inputWellInformation.field === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'}`} 
                />
                <label 
                  htmlFor="" 
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Latitude
                </label>
                <div className="grid grid-cols-4 w-[592px] h-[65px] gap-[15px]">
                <div className="relative">
                  <input placeholder={'29°'} type="number" name="latitudeDegrees"  value={coordinate.latitudeDegrees} onChange={handleChangeCoor} maxLength={2} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.latitudeDegrees === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                  <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                    °
                  </div>
                </div>
                <div className="relative">
                  <input placeholder={'13\''} type="text" name="latitudeMinutes" value={coordinate.latitudeMinutes} onChange={handleChangeCoor} maxLength={2} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.latitudeMinutes === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                  <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                      '
                  </div>
                </div>
                <div className="relative">
                  <input placeholder={'47.9"'} type="text" name="latitudeSeconds" value={coordinate.latitudeSeconds} onChange={handleChangeCoor} maxLength={5} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.latitudeSeconds === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                  <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                      "
                  </div>
                </div>
                <Select name="latitudeDirection"  value={coordinate.latitudeDirection}  className="w-full  h-[47px] text-left mb-[15px]"  onChange={(value) => handleChangeSelectCoor('latitudeDirection', value)}>
                  <Option value="N">N</Option>
                  <Option value="S">S</Option>
                </Select>
                </div>
                <label 
                  htmlFor="" 
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Company
                </label>
                <input 
                  type="text" 
                  name="company" 
                  value={inputWellInformation.company} 
                  onChange={handleChangeInformation} 
                  className={`w-full max-w-[592px] px-3 h-[47px] rounded-[4px]  mb-[17px] border border-solid ${inputEmpty && inputWellInformation.company === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'}`}
                />
                <label 
                  htmlFor="" 
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Well type
                </label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.well_type === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="well_type"
                  value={inputWellInformation.well_type || "Selected option"}
                  onChange={(value) => handleChangeSelect("well_type", value)}
                >
                  <Option value="producer">Producer</Option>
                </Select>
                <label 
                  htmlFor="" 
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Artificial lift method
                </label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.artificial_method === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.artificial_method === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="artificial_method"
                  value={inputWellInformation.artificial_method || "Selected option"}
                  onChange={(value) => handleChangeSelect("artificial_method", value)}
                >
                  <Option value="none">None</Option>
                  <Option value="continuous_gas_lift">Continuous Gas Lift</Option>
                  <Option value="plungerLift">Plunger Lift</Option>
                  <Option value="esp" disabled>Esp</Option>
                </Select>
                <label 
                  htmlFor="" 
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Flow type
                </label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.flow_type === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.flow_type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="flow_type"
                  value={inputWellInformation.flow_type || "Selected option"}
                  onChange={(value) => handleChangeSelect("flow_type", value)}
                >
                  <Option value="tubing">Tubing</Option>
                  <Option value="annular">Annular</Option>
                  <Option value="tubing_annular">Tubing Annular</Option>
                </Select>
              </div>
              <div className="flex flex-col items-start text-left">
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well Name</label>
                <input 
                  disabled 
                  type="text" 
                  name="well_name" 
                  value={inputWellInformation.well_name} 
                  onChange={handleChangeInformation} 
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid ${inputEmpty && inputWellInformation.well_name === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'}`}
                />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Country</label>
                <input 
                  type="text" 
                  disabled={true} 
                  name="country" 
                  value={inputWellInformation.country} 
                  onChange={handleChangeInformation} 
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid ${inputEmpty && inputWellInformation.country === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'}`}
                />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">County</label>
                <input 
                  type="text" 
                  disabled={true} 
                  name="county" 
                  value={inputWellInformation.county} 
                  onChange={handleChangeInformation} 
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid ${inputEmpty && inputWellInformation.county === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'}`}
                />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Longitude</label>
                <div className="grid grid-cols-4 w-[592px] h-[65px] gap-[15px] ">
                  <div className="relative">
                    <input placeholder={'29°'} type="text" name="longitudeDegrees"  value={coordinate.longitudeDegrees} onChange={handleChangeCoor} maxLength={3} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeDegrees === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                    <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                          °
                    </div>
                  </div>
                  <div className="relative">
                    <input placeholder={'13\''} type="text" name="longitudeMinutes" value={coordinate.longitudeMinutes} onChange={handleChangeCoor} maxLength={2} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeMinutes === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                    <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                        '
                    </div>
                  </div>
                  <div className="relative">
                    <input placeholder={'47.9"'} type="text" name="longitudeSeconds" value={coordinate.longitudeSeconds} onChange={handleChangeCoor} maxLength={5} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeSeconds === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                    <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                          "
                    </div>
                  </div>
                  <Select 
                    style={{ backgroundColor: inputEmpty && coordinate.longitudeDirection === '' ? '#FEF9C3' : '', border: inputEmpty && coordinate.longitudeDirection === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                    name="longitudeDirection" 
                    value={coordinate.longitudeDirection || "Direction"}
                    className="w-full  h-[47px] text-left mb-[15px]"
                    onChange={(value) => handleChangeSelectCoor('longitudeDirection', value)}
                  >
                    <Option value="W">W</Option>
                    <Option value="E">E</Option>
                  </Select>
                </div>
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Objective</label>
                <input 
                  type="text" 
                  name="objective" 
                  value={inputWellInformation.objective} 
                  onChange={handleChangeInformation} 
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid ${inputEmpty && inputWellInformation.objective === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}
                />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well orientation</label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.well_orientation === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_orientation === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="well_orientation"
                  value={inputWellInformation.well_orientation || "Selected option"}
                  onChange={(value) => handleChangeSelect("well_orientation", value)}
                >
                  <Option value="vertical">Vertical</Option>
                  <Option value="deviated">Deviated</Option>
                  <Option value="horizontal">Horizontal</Option>
                </Select>
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well reference</label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.well_reference === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_reference === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="well_reference"
                  value={inputWellInformation.well_reference || "Selected option"}
                  onChange={(value) => handleChangeSelect("well_reference", value)}
                >
                  <Option value="onshore">Onshore</Option>
                  <Option value="offshore">Offshore</Option>

                </Select>
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Fluid type</label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.fluid_type === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.fluid_type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-start"
                  name="fluid_type"
                  value={inputWellInformation.fluid_type || "Selected option"}
                  onChange={(value) => handleChangeSelect("fluid_type", value)}
                >
                  <Option value="black_oil">Black Oil</Option>
                  <Option value="wetGas">Wet Gas</Option>
                  <Option value="volatile_oil" disabled>Volatile Oil</Option>
                  <Option value="condensate" disabled>Condensatee</Option>
                  <Option value="dry_gas" disabled>Dry gas</Option>
                </Select>
              </div>
            </div>
          </>
        }
        {current === 1 &&
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[20px]">
              <div className="flex flex-row w-[1230px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">Well orientation ({wellOrientation})</h1>
                <ImportFile uploadfile={handleUploadFile}></ImportFile>
              </div>
            </div>
            <div className="flex flex-row w-full min-h-[710px] min-w-[1550px] item-center justify-center  overflow-x-auto">
              <ul className="flex flex-col">
                <div className="flex w-[1300px] gap-[26px] mb-[7px] ml-[124px]">
                  <label className="w-full max-w-[392px] text-[15px] item-start justify-start text-left">
                    Measured depth (ft)
                    <Tooltip title={'Depth values must be between 0 and 50000 (feet)'}>
                      <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    </Tooltip> 
                  </label>
                  <label className="w-full max-w-[392px] text-[15px] item-start justify-start text-left">
                    True vertical depth (ft)
                    <Tooltip title={'True vertical depth values must be between 0 and 50000 (feet)'}>
                      <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    </Tooltip>
                  </label>
                  <label className="w-full max-w-[392px] text-[15px] item-start justify-start text-left">
                  Inclination (°)
                    <Tooltip title={'Inclination values must be between -12 and 120 (degrees)'}>
                      <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    </Tooltip>
                  </label>
                </div>
                {dataDeviation.map((item, index) => (
                  <li
                    key={item.id}
                    className="flex flex-row w-full overflow-y-auto"
                  >
                    <div className="flex w-full gap-[26px] mb-[10px]">
                      <label htmlFor="" className="flex w-[20px] font-bold text-lg text-center items-center justify-center ">{index + 1}</label>
                      <input 
                        type="number" 
                        min="0"
                        onKeyDown={handleKeyDown}
                        style={{ WebkitAppearance: 'none' }} 
                        defaultValue={item.measured_depth} 
                        name={`measured_depth`} 
                        onChange={(e) => handleChangeDeviation(e, item.id)} 
                        onBlur={(e) => handleInputBlurTest(e, item.id)}
                        className={`w-full max-w-[392px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${inputEmpty && (item.measured_depth === null || item.measured_depth === "")  ? " border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"}`} />
                      <input 
                        type="number" min="0"
                        max={item.measured_depth} 
                        onKeyDown={handleKeyDown}
                        style={{ WebkitAppearance: 'none' }} 
                        defaultValue={item.tvd} 
                        name={`tvd`} 
                        onChange={(e) => handleChangeDeviation(e, item.id)} 
                        onBlur={(e) => handleInputBlurTest(e, item.id)}
                        disabled={item.measured_depth === null || item.measured_depth === ""} 
                        className={`w-full max-w-[392px] h-[47px] rounded-[4px] border border-solid border-[#BCBCCC] px-3  ${inputEmpty && (item.tvd === null ||item.tvd === "")  || item.tvd > item.measured_depth ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"}`} 
                      />
                      <input 
                        type="number" min="0" 
                        style={{ WebkitAppearance: 'none' }} 
                        defaultValue={item.inc}
                        name={`inc`}
                        onChange={(e) => handleChangeDeviation(e, item.id)} 
                        onBlur={(e) => handleInputBlurTest(e, item.id)}
                        className={`w-full max-w-[392px] h-[47px] rounded-[4px] border border-solid border-[#BCBCCC] px-3  ${inputEmpty && (item.inc === null || item.inc === "") ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"}`} 
                        />
                      {(dataDeviation.length - 1 === index) &&
                        <IconAdd className="w-6 h-[47px] fill-neutral-500 " onClick={() => handleAddDeviation()} />
                      }
                      {dataDeviation.length >= 4 &&
                        <IconDelete className="w-6 h-[47px] fill-neutral-500 " onClick={() => handleDeleteDeviation(item.id)} />
                      }
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        }
        {current === 2 &&
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[20px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">Well equipment
              </h1>
              <ImportFile uploadfile={handleUploadFile2} tableType="well_equipment"></ImportFile>
              </div>
            </div>
            <div className="flex flex-row w-full min-h-[710px] min-w-[1800px] overflow-x-auto">
              <div className="flex flex-row justify-center w-5/6 h-full item-center">
                <ul className="flex flex-col w-full pl-[20px]">
                  <div className="flex w-full gap-[26px] mb-[7px] pl-[90px] pr-[50px]">
                    <label className="w-full max-w-[280px] text-[15px] item-start justify-start text-left">Name</label>
                    <label className="w-full max-w-[177px] text-[15px] item-start justify-start text-left">Type</label>
                    <label className="w-full max-w-[177px] text-[15px] item-start justify-start text-left">Length MD (ft)
                      <Tooltip title={`Length values must be between 0 and 50000 (feet)`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <label className="w-full max-w-[177px] text-[15px] item-start justify-start text-left">External diameter (in)
                    <Tooltip
                      title={
                        <ul className="pl-4 list-disc">
                          <li>
                          External diameter values must be between 0.75 and 24 (inches).
                          </li>
                          <li>
                            value can't be less than Internal diameter.
                          </li>
                        </ul>}
                    >
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <label className="w-full max-w-[177px] text-[15px] item-start justify-start text-left">Internal diameter (in)
                      <Tooltip title={`Internal diameter values must be between 0.75 and 24 (inches)`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <label className="flex w-full max-w-[177px] text-[15px] item-start justify-start text-left ">Absolute roughness (in)
                      <Tooltip title={`Value can't be less than 0 and greater than 0.1`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                  </div>
                  {dataEquipment.map((item, index) => (
                    <li
                      key={item.id}
                      className="flex flex-row w-full overflow-y-auto"
                    >
                      <div className={`flex w-full gap-[26px] mb-[10px] pl-[30px]`}>
                        <label 
                          min="0" 
                          htmlFor="" 
                          className="flex w-[10px] font-bold text-lg text-center items-center justify-center"
                        >
                            {index + 1}
                        </label>
                        <input 
                          type="text" 
                          defaultValue={item.name} 
                          name="name" 
                          value={dataEquipment[index].name} 
                          onChange={(e) => handleChangeEquipment(e, item.id)} 
                          className="w-full max-w-[280px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3" 
                        />
                        <input 
                          type="text" 
                          name="name" 
                          value={dataEquipment[index].type} 
                          onChange={(e) => handleChangeEquipment(e, item.id)} 
                          className="w-12% max-w-[280px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3" 
                          readOnly
                        />
                        <input
                          type="number" 
                          min="0" 
                          onKeyDown={handleKeyDown} 
                          name="measured_depth" 
                          defaultValue={item.measured_depth} 
                          onChange={(e) => handleChangeEquipment(e, item.id)} 
                          onBlur={(e) => handleChangeBlur(e, item.id)}
                          className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${(inputEmpty && (item.measured_depth === null ||item.measured_depth === ""))? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"}`}
                        />
                        <input 
                          type="number" 
                          min="0"
                          max="36" 
                          onKeyDown={handleKeyDown} 
                          name="external_diameter"
                          defaultValue={item.external_diameter} 
                          onChange={(e) => handleChangeEquipment(e, item.id)}
                          onBlur={(e) => handleChangeBlur(e, item.id)}
                          className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${(inputEmpty && (item.external_diameter === null || item.external_diameter === "" )) ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"}`}
                        />
                        <input 
                          type="number" 
                          min="0" 
                          max={item.external_diameter} 
                          onKeyDown={handleKeyDown} 
                          name="internal_diameter"
                          disabled={item.external_diameter === "" || item.external_diameter === null } 
                          defaultValue={item.internal_diameter} 
                          onChange={(e) => handleChangeEquipment(e, item.id)}
                          onBlur={(e) => handleChangeBlur(e, item.id)}
                          className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${(inputEmpty && (item.internal_diameter === null || item.internal_diameter === ""))  ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"}`}
                        />
                        <input 
                          type="number" 
                          min="0"
                          max="1" 
                          onKeyDown={handleKeyDown}  
                          name="absolute_roughness" 
                          value={item.absolute_roughness}
                          onChange={(e) => handleChangeEquipment(e, item.id)}
                          onBlur={(e) => handleChangeBlur(e, item.id)}
                           className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${(inputEmpty && (item.absolute_roughness === null || item.absolute_roughness === "")) ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"}`}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex w-1/6 h-full justify-center items-center  text-end mt-[300px]">
                <img
                  src={Img_Wellhead}
                  className="w-[219px] h-[650px] lg:absolute"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </>
        }
        <div className="flex w-full text-center justify-center items-center mb-[90px]">
          <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
            {current > 0 && (
              <button
              className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
              onClick={() => handleClickPrev()}
              >
                Previous
              </button>
            )}
            {current < steps.length - 1 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickNext()}
              >
                Continue
              </button>
            )}
            {current === steps.length - 1 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickDone()}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
