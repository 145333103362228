import React from 'react'
import { DashboardPlottingContext } from './DashboardPlottingContext'
import { useDashboardPlotting } from '../../hooks/useDashboardPlotting'

export const DashboardPlottingProvider = ({children}) => {
const {
  dataPlotting,
  viewLoadingPlotting,
  getViewLoadingPlotting,
  getDataPlotting
} = useDashboardPlotting()
  return (
    <DashboardPlottingContext.Provider value={{    
      dataPlotting,
      viewLoadingPlotting,
      getViewLoadingPlotting,
      getDataPlotting
    }}>
        {children}
    </DashboardPlottingContext.Provider>
  )
}
