import React, { useEffect, useRef, useState } from 'react'
import { read, utils } from 'xlsx';
import { message, Tooltip } from 'antd'
import { ReactComponent as IconUpload } from '../../../assets/img/icons/upload.svg';
import { REQUIRED_COLUMNS_TABLE_DEVIATION, REQUIRED_COLUMNS_TABLE_EQUIPMENT, REQUIRED_COLUMNS_TEMPLATE_ALERTS, REQUIRED_COLUMNS_TABLE_LAB_PVT, REQUIRED_COLUMNS_DOCUMENT_IPR_DRYGAS } from '../../elements/types/type_gas_lift';
import { AlertModal } from '../../AlertModal';
export const ImportFile = ({uploadfile, tableType, messageTooltip}) => {

  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState([]);
  const handleImport = (event) => {
      const files = event.target.files;
      if(files){
          const file = files[0];
          const validExtensions = ['.xls', '.xlsx', '.csv'];
          const fileType = file.name.split('.').pop();
          const mimeType = file.type;

          if(
            (validExtensions.includes(`.${fileType}`)) || 
            (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
            mimeType === 'application/vnd.ms-excel')
          ){
            const reader = new FileReader();
            
            reader.onload = (e) => {
                const wb = read(e.target.result);
                const sheetName = wb.SheetNames[0];
                const sheets = wb.Sheets[sheetName];
                if(sheets){
                    const jsonData = utils.sheet_to_json(sheets);
                    switch (tableType) {
                      case "well_deviation":
                        const hasRequiredColumns = REQUIRED_COLUMNS_TABLE_DEVIATION.every((col) =>
                          Object.keys(jsonData[0]).includes(col)
                        );
          
                        if (!hasRequiredColumns) {
                          message.error(
                            'The uploaded file does not contain the corresponding columns.'
                          );
                          return;
                        }
                        break;
                      case "well_equipment":
                        const hasRequiredColumns2 = REQUIRED_COLUMNS_TABLE_EQUIPMENT.every((col) =>
                          Object.keys(jsonData[0]).includes(col)
                        );
          
                        if (!hasRequiredColumns2) {
                          message.error(
                            'The uploaded file does not contain the corresponding columns.'
                          );
                          return;
                        }
                        break;
                      case "data_domain_alerts":
                        const hasRequiredColumns3 = REQUIRED_COLUMNS_TEMPLATE_ALERTS.every((col) =>
                          Object.keys(jsonData[0]).includes(col)
                        );
          
                        if (!hasRequiredColumns3) {
                          message.error(
                            'The uploaded file does not contain the corresponding columns.'
                          );
                          return;
                        }
                        break;
                      case "pvt_information_lab":
                        const hasRequiredColumnsLab = REQUIRED_COLUMNS_TABLE_LAB_PVT.every((col) =>
                          Object.keys(jsonData[0]).includes(col)
                        );
                        
                        if (!hasRequiredColumnsLab) {
                          message.error(
                            'The uploaded file does not contain the corresponding columns.'
                          );
                          return;
                        } else if(jsonData.length < 4){
                          message.error(
                            'A file with more than 4 rows must be uploaded'
                          );
                          return;
                        }
                        break;
                      case "data_ipr_dry_gas":
                        const hasRequiredColumns4 = REQUIRED_COLUMNS_DOCUMENT_IPR_DRYGAS.every((col) =>
                          Object.keys(jsonData[0]).includes(col)
                        );
                        
                        if (!hasRequiredColumns4) {
                          handleOpenModalAlert();
                          setTypeAlert("Warning");
                          setMessageAlert(
                            "The uploaded file does not have the required template."
                          );
                          return;
                        }
                        break;
                      default:
                        break;
                    }

                    const noID = jsonData.map(item => {
                      const { id, ...excludeID } = item;
                      return excludeID;
                  });
                    setFile(noID);
                    uploadfile(noID);
                    message.success("The file data has been loaded successfully");
                }
              };
            reader.readAsArrayBuffer(file);
          }else{
            message.info('Please upload a valid Excel or Csv file');
          }
          event.target.value = '';
      }
  };
  const handleImageClick = () => {
      fileInputRef.current.click();
    };

  const handleOpenModalAlert = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  return (
    <div>
      <div className="flex w-full h-full">
        <AlertModal
          showAlert={showAlert}
          handleCloseModal={handleCloseModal}
          messageAlert={messageAlert}
          typeModal={typeAlert}
        ></AlertModal>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        name='file'
        id="inputGroupFile"
        onChange={handleImport}
        style={{ display: 'none' }}
      />
      {/* Este es el icono de carga */}
      <label htmlFor="fileInput">
        <Tooltip title={messageTooltip}>
          <IconUpload size={32} className="flex w-4 h-4 bg-opacity-50 cursor-pointer fill-neutral-500 hover:fill-pa-purple" onClick={handleImageClick}/>
        </Tooltip>
      </label>
    </div>
  )
}

