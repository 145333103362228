import React, { useEffect, useState } from 'react';

const initialDataPlotting = {
  selected_platform: "",
  selected_device: [],
  selected_parameter: [],
  dataConfigGraph: [],
};

const initialViewLoadingGasLift = {
  loadSaveParameters: false,
};

const isEmpty = (value) => {
  return (
    value == null || 
    (typeof value === "string" && value.trim() === "") || 
    (typeof value === "object" && Object.keys(value).length === 0) || 
    (Array.isArray(value) && value.length === 0) 
  );
};

const cleanDataPlotting = (data) => {
  if (!data || !data.dataPlotting) return data; 
  return {
    ...data,
    dataPlotting: data.dataPlotting.filter((item) => !isEmpty(item)), 
  };
};


export const useDashboardPlotting = () => {
  const [dataPlotting, setDataPlotting] = useState(initialDataPlotting);
  const [viewLoadingPlotting, setViewLoadingPlotting] = useState(initialViewLoadingGasLift);

  useEffect(() => {
    const getDomainPlotting = JSON.parse(sessionStorage.getItem('dataPlotting'));
    if (getDomainPlotting) {
      const cleanedData = cleanDataPlotting(getDomainPlotting);
      setDataPlotting(cleanedData);
    }
  }, []);

  const getDataPlotting = (options) => {
    const cleanedData = cleanDataPlotting(options);
    setDataPlotting(cleanedData);
    sessionStorage.setItem('dataPlotting', JSON.stringify(cleanedData));
  };

  const getViewLoadingPlotting = (options) => {
    setViewLoadingPlotting(options);
  };

  return {
    dataPlotting,
    viewLoadingPlotting,
    getViewLoadingPlotting,
    getDataPlotting,
  };
};
