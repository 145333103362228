import { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";

export const useAuth = () => {
    const [Auth, setAuth] = useState(false);
    const [User, setUser] = useState({});  
    const Navigate = useNavigate();

    useEffect(() => {
      // Recuperar el estado de autenticación de localStorage al cargar la página
      const storedUser = JSON.parse(sessionStorage.getItem('userData'));
      const auth = storedUser?.auth;

      if (auth && storedUser) {
        setAuth(auth);
        setUser(storedUser);
      }
    }, []);
  
    const signin = (userData) => {
      sessionStorage.setItem("userData", JSON.stringify(userData));
      setUser(userData);
      setAuth(true);
      sessionStorage.setItem('dataPage','Map');
    };
  
    const signout = (e) => {
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("dataPage");
      sessionStorage.removeItem("userDataInfo");
      sessionStorage.removeItem("dataCreated");
      sessionStorage.removeItem("dataCreatedDCA");
      sessionStorage.removeItem("dataWell");
      sessionStorage.removeItem("dataWellTables");
      sessionStorage.removeItem("dataFilterUserGaslift");
      sessionStorage.removeItem("dataFilterUserDCA");
      sessionStorage.removeItem("selectedWell");
      sessionStorage.removeItem("selectedWellDCA");
      sessionStorage.removeItem("historyDataGasLift");
      sessionStorage.removeItem("historyDataDCA");
      sessionStorage.removeItem("dataDomainGraph");
      sessionStorage.removeItem("checkedValuesFirstCol");
      sessionStorage.removeItem("checkedValuesLastCol");
      sessionStorage.removeItem("dataAlertGasLift");
      sessionStorage.removeItem("dataAlertDCA");
      sessionStorage.removeItem("someAlert");
      sessionStorage.removeItem("checkTypeGraph");
      sessionStorage.removeItem("deviceRealTime");
      sessionStorage.removeItem("domainPlotting");
      sessionStorage.removeItem("dataPlotting");
      sessionStorage.removeItem("dataFilterUserPL");
      sessionStorage.removeItem("selectedWellPL");
      sessionStorage.removeItem("selectedReservoirSCT");
      sessionStorage.removeItem("dataFilterUserSCT");
      sessionStorage.removeItem("enableCheckLab");
      sessionStorage.removeItem("isOpenModalAllocation");
      setAuth(false);
      Navigate("/Login");
    };
    return {
        Auth,
        User,
        signin,
        signout
    }
};