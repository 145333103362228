import { Alert } from "antd"
import styleErrorSpace from "./ErrorPage.module.css";

const {
  containerError,
  containerGeneral
} = styleErrorSpace;

export const ErrorPage= () => {
 
  return (
    <div className={containerGeneral}>
      <div className={containerError}>
        <Alert
          description={"Plunger Lift plot not available. Go to Plunger Lift module"}
          type="error"
          style={{fontSize: 20}}
        />
      </div>
    </div>
  )
} 